import { IconButton } from '@material-ui/core'

import { Description } from '@material-ui/icons'

import { ButtonBox, ContainerTable, ToolsDataTable } from '~/components'

import DialogFile from './DialogFile'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useObterGuiasEmpregador } from '~/hooks/queries/FGTSDigital/useObterGuiasEmpregador'

import { formatSimpleDateTime } from '~/utils/utils'

import { MUIDataTableColumnDef } from 'mui-datatables'

export const TITLE_PAGE = 'FGTS Digital - Guia Empregador'

interface ContentProps {
  empregadorId: string
}

export default function Content({ empregadorId }: ContentProps) {
  const { anoMes } = useAmbiente()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterGuiasEmpregador({
    params: {
      competencia: anoMes,
      empregadorId,
    },
  })
  const data = _d || []

  const {
    close: closeDialogFile,
    data: dataDialogFile,
    isOpen: isOpenDialogFile,
    open: openDialogFile,
  } = useDialog('')

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'tipo',
      label: 'Tipo',
    },
    {
      name: 'dtCriacao',
      label: 'Data da Criação',
      options: {
        customBodyRender: formatSimpleDateTime,
      },
    },
    {
      name: 'dataPagamento',
      label: 'Data de Pagamento',
      options: {
        customBodyRender: formatSimpleDateTime,
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Abrir detalhamento da consulta"
                color="primary"
                aria-label="Transportar"
                onClick={() => openDialogFile(value)}
              >
                <Description fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <>
      <ContainerTable>
        <ToolsDataTable
          data={data}
          columns={columns}
          isLoading={isLoading}
          isFetching={isFetching}
          disableAutoHeight
        />
      </ContainerTable>

      {isOpenDialogFile && (
        <DialogFile isOpen={isOpenDialogFile} onClose={closeDialogFile} id={dataDialogFile} />
      )}
    </>
  )
}
