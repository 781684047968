import { FaFileExcel } from 'react-icons/fa'
import { Button } from '~/components'

import { useObterFolhaAnaliticaExcel } from '~/hooks/queries/FolhaAnalitica'
import useAmbiente from '~/hooks/useAmbiente'

import { initialFilterFolhaAnalitica } from '../../Filter'
import { useTransferDataContext } from '~/contexts/TransferDataContext'

interface GeracaoDeExcelProps {
  filters: number[] | null
}

export default function GeracaoDeExcel({ filters }: GeracaoDeExcelProps) {
  const { estabelecimento, anoMes } = useAmbiente()
  const { dataTransfer } = useTransferDataContext<{ disabledExcelButton: boolean }>()

  const { mutateAsync: obterFolhaAnaliticaExcel, isLoading } = useObterFolhaAnaliticaExcel()

  async function handleObterFolhaAnaliticaExcel() {
    await obterFolhaAnaliticaExcel({
      data: filters || initialFilterFolhaAnalitica,
      params: {
        anoMes,
        estabelecimentoId: estabelecimento?.id,
      },
    })
  }

  return (
    <Button
      startIcon={<FaFileExcel />}
      variant="contained"
      isLoading={isLoading}
      disabled={dataTransfer?.disabledExcelButton}
      onClick={handleObterFolhaAnaliticaExcel}
    >
      Excel
    </Button>
  )
}
