import DataTableCellActionsDelete from './DataTableCellActionsDelete'
import DataTableCellActionsEdit from './DataTableCellActionsEdit'
import DataTableCellActionsRoot from './DataTableCellActionsRoot'
import DataTableCellAction from './DataTableCellAction'

const DataTableCellActions = {
  Delete: DataTableCellActionsDelete,
  Root: DataTableCellActionsRoot,
  Edit: DataTableCellActionsEdit,
  Action: DataTableCellAction,
}

export default DataTableCellActions
