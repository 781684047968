import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useQueryClient } from './useObterPorEstabelecimento'

import { notifyRemove } from '~/utils/notification'

interface RequestProps {
  params: {
    id: string
  }
}

export function useRemover() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useQueryClient()

  async function handleRequest({ params }: RequestProps) {
    await api.delete('/EventoAutomaticoEstabelecimento/Remover', {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyRemove()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
