import { Edit as EditIcon } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'

interface DataTableCellActionsEditProps {
  title?: string
  disabled?: boolean
  onClick?: (event: 'edit') => void
}

export default function DataTableCellActionsEdit({
  title = 'Editar este registro',
  disabled = false,
  onClick,
}: DataTableCellActionsEditProps) {
  return (
    <IconButton
      title={title}
      color="primary"
      aria-label="Editar"
      disabled={disabled}
      onClick={() => onClick && onClick('edit')}
      size="small"
    >
      <EditIcon fontSize="small" color="primary" />
    </IconButton>
  )
}
