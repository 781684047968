import { useState } from 'react'
import TableRowsSelectableProvider from '~/contexts/TableRowsSelectableContext'

import PageContainer from '~/components/PageContainer'
import { Finder } from '~/components'

import Filter, { FilterStateProps } from './components/Fillter'
import Actions from './components/Actions'
import Table from './components/Table'

export default function RelatorioAcordoJornada12x36() {
  const [filters, setFilters] = useState<FilterStateProps | null>(null)

  function handleSearch(query: string) {
    setFilters((prevState) => ({
      dtFim: prevState?.dtFim || '',
      dtInicio: prevState?.dtInicio || '',
      query,
    }))
  }

  return (
    <PageContainer.Root>
      <PageContainer.Header title="Relatório Acordo Jornada 12x36">
        <Finder onSearch={handleSearch} onClose={handleSearch} />
      </PageContainer.Header>

      <PageContainer.ActionsContainer marginBottom={0.5}>
        <Filter onChange={setFilters} />
      </PageContainer.ActionsContainer>

      <TableRowsSelectableProvider>
        <Table filters={filters} />
        <PageContainer.ActionsContainer>
          <Actions />
        </PageContainer.ActionsContainer>
      </TableRowsSelectableProvider>
    </PageContainer.Root>
  )
}
