import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { GuiaFGTSDigitalEmpregadorDTO } from './interfaces/GuiaFGTSDigitalEmpregadorDTO'

const query = '/FGTSDigital/ObterGuiasEmpregador'

interface RequestProps {
  params: {
    competencia: string
    empregadorId: string
  }
}

export function useObterGuiasEmpregador({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: GuiaFGTSDigitalEmpregadorDTO[] }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
