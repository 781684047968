import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { Box, IconButton, Typography } from '@material-ui/core'

import headerStyles from './headerStyles'

interface HeaderProps {
  currentYear: number
  onChangeYear: (event: 'next' | 'back') => void
}

export default function Header({ currentYear, onChangeYear }: HeaderProps) {
  const classes = headerStyles()

  return (
    <Box className={classes.container} component="header">
      <IconButton size="small" className={classes.iconButton} onClick={() => onChangeYear('back')}>
        <ArrowBackIos className={classes.arrow} />
      </IconButton>

      <Typography className={classes.yearLabel}>{currentYear}</Typography>

      <IconButton size="small" className={classes.iconButton} onClick={() => onChangeYear('next')}>
        <ArrowForwardIos className={classes.arrow} />
      </IconButton>
    </Box>
  )
}
