import { ReactNode, useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import moment from 'moment'
import { getCurrentMonth, getCurrentYear, getDateCurrent } from '~/utils/utils'

import calendarStyles from './calendarStyles'
import Header from './Header'
import Footer from './Footer'
import Main from './Main'

interface CalendarProps {
  value: string
  onChange: (value: string, year: string, month: string) => void
  onRenderAlertMessage?: (selectedYear: number) => ReactNode
}

interface DataStateProps {
  year: number
  month: number
}

export default function Calendar({
  value,
  onChange,
  onRenderAlertMessage,
  ...rest
}: CalendarProps) {
  const { onClose } = rest as { onClose?: () => void }

  const [data, setData] = useState<DataStateProps>({
    year: getCurrentYear(),
    month: getCurrentMonth(),
  })

  useEffect(() => {
    if (value) {
      const splitedYearMonth = value.split('/')
      const currentYear = parseInt(splitedYearMonth[0])
      const currentMonth = parseInt(splitedYearMonth[1])

      setData({
        year: currentYear,
        month: currentMonth,
      })
    }
  }, [value])

  function handleChangeYear(event: 'next' | 'back') {
    setData((prevState) => ({
      ...prevState,
      year: event === 'next' ? prevState.year + 1 : prevState.year - 1,
      month: 0,
    }))
  }

  function handleSelectMonth(selectedMonth: number) {
    const formattedYearMonth = moment({ year: data.year, month: selectedMonth - 1 }).format(
      'YYYY/MM',
    )
    const splitedFormattedYearMonth = formattedYearMonth.split('/')
    const yearStringified = splitedFormattedYearMonth[0]
    const monthStringified = splitedFormattedYearMonth[1]
    onChange(formattedYearMonth, yearStringified, monthStringified)
    handleClose()
  }

  function handleChangeToCurrentYearAndMonth() {
    const currentYearAndMonth = getDateCurrent({ format: 'YYYY/MM' })
    if (value === currentYearAndMonth) return handleClose()

    const splitedCurrentYearAndMonth = currentYearAndMonth.split('/')
    const currentYearStringified = splitedCurrentYearAndMonth[0]
    const currentMonthStringified = splitedCurrentYearAndMonth[1]
    onChange(currentYearAndMonth, currentYearStringified, currentMonthStringified)
    handleClose()
  }

  function handleClose() {
    if (onClose) onClose()
  }

  const classes = calendarStyles()

  return (
    <Box className={classes.container}>
      <Header currentYear={data.year} onChangeYear={handleChangeYear} />
      <Main currentMonth={data.month} onSelectMonth={handleSelectMonth} />
      <Footer
        onClickClose={handleClose}
        onClickGoBackToCurrentYearAndMonth={handleChangeToCurrentYearAndMonth}
      />

      {onRenderAlertMessage && onRenderAlertMessage(data.year)}
    </Box>
  )
}
