import { useState } from 'react'

import api from '~/services/api-pessoal'

import { Box, IconButton } from '@material-ui/core'

import { TextFieldChangeVinculoEmail, ToolsDataTable } from '~/components'
import { Ativo } from '~/components/Displays'

import useUtils from '~/hooks/useUtils'
import useDialogNotification from '~/hooks/useDialogNotification'
import { formatSimpleDateTime, formatTelefone } from '~/utils/utils'

export default function Table(props) {
  const {
    data: _data,
    isLoading,
    query,
    onRowSelected,
    rowsSelected,
    isRowSelectable,
    isFetching,
    afterChangeItemTable,
  } = props

  const [isLoadingFinalizado, setLoadingFinalizado] = useState(false)

  const dialogNotification = useDialogNotification()

  async function handleClickFinalizado(isFinalizado, rpId) {
    if (isFinalizado) return
    dialogNotification.info({
      descriptions: ['Deseja realmente finalizar o recibo?'],
      onConfirm: () => handleFinish(rpId),
      labelOnConfirm: 'Confirmar',
    })
  }

  async function handleFinish(rpId) {
    setLoadingFinalizado(true)
    try {
      await api.put(
        '/RP/Finalizar',
        {},
        {
          params: {
            rpId,
          },
        },
      )
      afterChangeItemTable()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingFinalizado(false)
    }
  }

  const { formatCurrency } = useUtils()

  const data = _data.map((d) => ({
    ...d,
    totalVencimento: d?.totalVencimento ? formatCurrency(d.totalVencimento) : '',
    totalDesconto: d?.totalDesconto ? formatCurrency(d.totalDesconto) : '',
    totalLiquido: d?.totalLiquido ? formatCurrency(d.totalLiquido) : '',
  }))

  const columns = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'rpId',
      label: 'Email',
      options: {
        setCellHeaderProps: () => ({
          style: { width: 400 },
        }),
        customBodyRender: (rpId) => {
          const item = _data.find((d) => d.rpId === rpId)
          return (
            <TextFieldChangeVinculoEmail
              vinculoId={item.vinculoId}
              email={item?.email || ''}
              afterSubmit={() => afterChangeItemTable()}
            />
          )
        },
      },
    },
    {
      name: 'telefone',
      label: 'Telefone',
      options: {
        customBodyRender: (value) => (value ? formatTelefone(value) : ''),
      },
    },
    {
      name: 'dtEmailEnviado',
      label: 'Email Enviado',
      options: {
        customBodyRender: (value) => (value ? formatSimpleDateTime(value) : ''),
      },
    },
    {
      name: 'dtEnvioWhatsApp',
      label: 'WhatsApp Enviado',
      options: {
        customBodyRender: (value) => (value ? formatSimpleDateTime(value) : ''),
      },
    },
    {
      name: 'rpId',
      label: 'Finalizado',
      options: {
        customBodyRender: (rpId) => {
          const item = _data.find((d) => d.rpId === rpId)
          const isFinalizado = item.isFinalizado
          return (
            <Box display="flex" justifyContent="center">
              <IconButton
                size="small"
                disabled={isFinalizado}
                onClick={() => handleClickFinalizado(isFinalizado, rpId)}
              >
                <Ativo ativo={isFinalizado} />
              </IconButton>
            </Box>
          )
        },
      },
    },
    {
      name: 'totalVencimento',
      label: 'Vencimento',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'end',
          },
        }),
      },
    },
    {
      name: 'totalDesconto',
      label: 'Desconto',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'end',
          },
        }),
      },
    },
    {
      name: 'totalLiquido',
      label: 'Líquido',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'end',
          },
        }),
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching | isLoadingFinalizado}
      sherlock={{
        columns: ['codigo', 'nome'],
        query,
      }}
      disableAutoHeight
      optionsSelectable={{
        onRowSelected,
        rowsSelected,
        type: 'multiple',
        isRowSelectable,
      }}
    />
  )
}
