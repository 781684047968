import moment from 'moment'

import { Box, CircularProgress, LinearProgress } from '@material-ui/core'

import { Button, Calendar, SimpleAlert } from '~/components'

import CalendarDay from './CalendarDay'
import Form from './Form'
import DialogDSR from './DialogDSR'
import DialogFaltasPeriodo from './DialogFaltasPeriodo'
import DialogSabadoPerdido from './DialogSabadoPerdido'

import useObterDataFaltas from '~/hooks/queries/VinculoFaltas/useObterDataFaltas'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { VinculoFaltas } from '~/hooks/queries/VinculoFaltas/VinculoFaltas'
import { VinculoDSR } from '~/hooks/queries/VinculoDSR/VinculoDSR'
import { VinculoSabadoCompensado } from '~/hooks/queries/VinculoSabadoCompensado/VinculoSabadoCompensado'
import { VinculoFaltasTipoDiaEnum } from '~/@types/enums/VinculoFaltasTipoDiaEnum'
import { VinculoFaltasRegistroDTO } from '~/hooks/queries/VinculoFaltas/VinculoFaltasDTO'

interface DataForm extends Partial<VinculoFaltas> {
  datesSeraoInseridas?: string[]
}

interface ContentProps {
  vinculoId: string
}

export default function Content({ vinculoId }: ContentProps) {
  const { anoMes } = useAmbiente()

  const { data, isLoading, isFetching } = useObterDataFaltas(vinculoId, anoMes)
  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<DataForm>({})
  const {
    close: closeDialogDSR,
    data: dataDialogDSR,
    isOpen: isOpenDialogDSR,
    open: openDialogDSR,
  } = useDialog<VinculoDSR | null>(null)
  const {
    close: closeDialogSabadoCompensado,
    data: dataDialogSabadoCompensado,
    isOpen: isOpenDialogSabadoCompensado,
    open: openDialogSabadoCompensado,
  } = useDialog<VinculoSabadoCompensado | null>(null)
  const {
    close: closeDialogFaltasPeriodo,
    data: dataDialogFaltasPeriodo,
    isOpen: isOpenDialogFaltasPeriodo,
    open: openDialogFaltasPeriodo,
  } = useDialog('')

  const isReciboFinalizado = data?.isReciboFinalizado || false

  function handleClickNovo(date: string) {
    if (isReciboFinalizado) return
    openForm({
      dtFalta: date,
      vinculoId,
    })
  }

  function handleClickMultipleDate(dateStart: string, dateEnd: string) {
    if (isReciboFinalizado) return

    const dataContadora = moment(dateStart)
    const datasInserir: string[] = []

    while (dataContadora.isSameOrBefore(dateEnd)) {
      const dtFormated = dataContadora.format('yyyy-MM-DD')
      datasInserir.push(dtFormated)
      dataContadora.add(1, 'day')
    }

    openFormWithMultiplesDates(datasInserir)
  }

  function openFormWithMultiplesDates(datesSeraoInseridas: string[]) {
    if (datesSeraoInseridas.length > 0) {
      openForm({
        dtFalta: datesSeraoInseridas[0],
        vinculoId,
        datesSeraoInseridas,
      })
    }
  }

  function handleClickCalendarDay(data: VinculoFaltasRegistroDTO) {
    switch (data.tipo) {
      case VinculoFaltasTipoDiaEnum.Falta:
        openForm(data.registro as VinculoFaltas)
        break
      case VinculoFaltasTipoDiaEnum.Atraso:
        openForm(data.registro as VinculoFaltas)
        break
      case VinculoFaltasTipoDiaEnum.DSRDescontado:
        openDialogDSR(data.registro as VinculoDSR)
        break
      case VinculoFaltasTipoDiaEnum.PercaSabadoCompensado:
        openDialogSabadoCompensado(data.registro as VinculoSabadoCompensado)
        break
    }
  }

  return (
    <Box position="relative" height="100%" display="flex" flexDirection="column">
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" onClick={() => openDialogFaltasPeriodo(vinculoId)}>
          Faltas por Período
        </Button>
      </Box>
      <SimpleAlert show={isReciboFinalizado} severity="warning">
        Recibo da competência atual está finalizado, lançamento de faltas apenas para consulta.
      </SimpleAlert>
      {isLoading && (
        <Box
          position="absolute"
          right={0}
          left={0}
          top={0}
          bottom={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="rgba(0,0,0,0.1)"
          zIndex={99}
        >
          <CircularProgress size={70} />
        </Box>
      )}
      <Box height={4}>{isFetching && <LinearProgress />}</Box>
      <Box flex={1}>
        <Calendar
          anoMes={anoMes}
          registros={data?.registros || []}
          onClickDate={handleClickNovo}
          onClickMultipleDate={handleClickMultipleDate}
          renderEventContent={(registro) => {
            return (
              <CalendarDay
                dateType={registro.tipo}
                onClick={() => handleClickCalendarDay(registro)}
              />
            )
          }}
        />
      </Box>
      {isOpenForm && (
        <Form
          disabled={isReciboFinalizado}
          data={dataForm}
          isOpen={isOpenForm}
          isIniciarDSRAtraso={data?.isIniciarDSRAtraso || false}
          isIniciarDSRFalta={data?.isIniciarDSRFalta || false}
          datesSeraoInseridas={dataForm?.datesSeraoInseridas}
          onClose={closeForm}
        />
      )}
      {isOpenDialogDSR && (
        <DialogDSR
          data={dataDialogDSR as VinculoDSR}
          isOpen={isOpenDialogDSR}
          onClickDate={handleClickCalendarDay}
          onClose={closeDialogDSR}
        />
      )}
      {isOpenDialogSabadoCompensado && (
        <DialogSabadoPerdido
          data={dataDialogSabadoCompensado as VinculoSabadoCompensado}
          isOpen={isOpenDialogSabadoCompensado}
          onClickDate={handleClickCalendarDay}
          onClose={closeDialogSabadoCompensado}
        />
      )}
      {isOpenDialogFaltasPeriodo && (
        <DialogFaltasPeriodo
          vinculoId={dataDialogFaltasPeriodo}
          isOpen={isOpenDialogFaltasPeriodo}
          onClose={closeDialogFaltasPeriodo}
        />
      )}
    </Box>
  )
}
