import { useState } from 'react'

import { formatToCPFOrCNPJ } from 'brazilian-values'
import { formatSimpleDate } from '~/utils/utils'

import { Print } from '@material-ui/icons'

import {
  ActionDialog,
  Button,
  ButtonBox,
  ContainerTable,
  CopyLabel,
  Finder,
  PDFViewer,
  Stack,
  ToolsDataTable,
} from '~/components'

import useDialog from '~/hooks/useDialog'

import { ManutencaoContratoExperienciaFuncionarioDTO } from '~/hooks/queries/ManutencaoContratoExperiencia/interfaces/ManutencaoContratoExperienciaFuncionarioDTO'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { contratoExperienciaConsts } from '~/values/contratoExperienciaValues'
import useAmbiente from '~/hooks/useAmbiente'

const TITLE_PAGE = 'Prorrogações Processadas'

interface DialogProrrogacoesProps {
  isOpen: boolean
  onClose: () => void
  data: ManutencaoContratoExperienciaFuncionarioDTO[]
}

export default function DialogProrrogacoes({ isOpen, onClose, data }: DialogProrrogacoesProps) {
  const [query, setQuery] = useState('')
  const [rowsSelected, setRowsSelected] = useState<number[]>([])

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<string[]>([])

  //Estão sendo usados na request mas não estão filtrando nada.
  const { anoMes, estabelecimento } = useAmbiente()

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'estabelecimentoNrInscricao',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'estabelecimentoNome',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'funcionarioNrInscricao',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'funcionarioNome',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'estabelecimentoId',
      label: 'Estabelecimento',
      options: {
        customBodyRender: (id) => {
          const estabelecimento = data.find((d) => d.estabelecimentoId == id)
          if (!estabelecimento) return <>-</>
          return (
            <div>
              {estabelecimento.estabelecimentoNome}
              <CopyLabel value={estabelecimento.estabelecimentoNrInscricao}>
                {formatToCPFOrCNPJ(estabelecimento.estabelecimentoNrInscricao)}
              </CopyLabel>
            </div>
          )
        },
      },
    },
    {
      name: 'funcionarioId',
      label: 'Funcionário',
      options: {
        customBodyRender: (id) => {
          const funcionario = data.find((d) => d.funcionarioId == id)
          if (!funcionario) return <>-</>
          return (
            <div>
              {funcionario.funcionarioNome}
              <CopyLabel value={funcionario.funcionarioNrInscricao}>
                {formatToCPFOrCNPJ(funcionario.funcionarioNrInscricao)}
              </CopyLabel>
            </div>
          )
        },
      },
    },
    {
      name: 'funcionarioDtAdmissao',
      label: 'Admissão',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'diasContratoExperiencia',
      label: 'Dias Experiência',
      options: {
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'dtContratoExperiencia',
      label: 'Data Experiência',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'diasContratoProrrogacao',
      label: 'Dias Prorrogação',
      options: {
        setCellProps: () => ({
          style: {
            textAlign: 'end',
          },
        }),
      },
    },
    {
      name: 'dtContratoProrrogacao',
      label: 'Data Prorrogação',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
  ]

  function handlePrint() {
    const vinculosIds = rowsSelected.map((d) => data[d].funcionarioId)
    openPDFViewer(vinculosIds)
  }

  return (
    <ActionDialog
      title={TITLE_PAGE}
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="lg"
      fullHeight
      renderRight={<Finder onSearch={setQuery} onClose={() => setQuery('')} />}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button
            disabled={rowsSelected.length > 0 ? false : true}
            startIcon={<Print />}
            variant="contained"
            onClick={handlePrint}
          >
            Imprimir
          </Button>
        </ButtonBox>
      }
    >
      <Stack height="100%">
        <ContainerTable>
          <ToolsDataTable
            columns={columns}
            data={data}
            disableAutoHeight
            optionsSelectable={{
              type: 'multiple',
              onRowSelected: setRowsSelected,
              rowsSelected,
            }}
            sherlock={{
              columns: [
                'estabelecimentoNrInscricao',
                'estabelecimentoNome',
                'funcionarioNrInscricao',
                'funcionarioNome',
              ],
              query,
            }}
          />
        </ContainerTable>
      </Stack>
      {isOpenPDFViewer && (
        <PDFViewer
          isOpen={isOpenPDFViewer}
          reportKey="ContratoExperiencia"
          title={TITLE_PAGE}
          onClose={closePDFViewer}
          isSalvarDocumento
          axiosConfig={{
            url: '/Relatorio/ContratoExperiencia/ObterRelatorio',
            method: 'post',
            data: dataPDFViewer,
            params: {
              estabelecimentoId: estabelecimento.id,
              anoMes,
              tipoContrato: contratoExperienciaConsts.prorrogacaoContrato,
            },
          }}
        />
      )}
    </ActionDialog>
  )
}
