import { useState, useMemo } from 'react'

import api from '~/services/api-pessoal'

import { makeStyles } from '@material-ui/core'
import {
  DynamicFeed as DynamicFeedIcon,
  MoreHoriz as MoreHorizIcon,
  PeopleAlt as PeopleAltIcon,
  ExitToApp as ExitToAppIcon,
  AttachMoney as AttachMoneyIcon,
  EventBusy as EventBusyIcon,
  Brightness5,
  Brightness6,
  ChildFriendly,
} from '@material-ui/icons'
import { ButtonBox } from 'mio-library-ui'

import DynamicContextMenu, { Item } from '~/components/DynamicContextMenu'
import { ActionDialog, PDFViewer } from '~/components'

import Dependentes from '~/pages/PessoaFisica/components/Container/PessoaFisicaDependente'
import EventosAutomaticos from '~/pages/VinculoEventoAutomatico/Content'
import Afastamento from '~/pages/VinculoAfastamento/components/Content'
import SalarioContratual from '~/pages/Funcionario/Container/VinculoSalarioContratual'
import Ferias from '~/pages/VinculoFerias/Content'

import MemoriaCalculoRP from './components/MemoriaCalculoRP'
import Observacao from './components/Observacao'
import TabelasIncidencias from './components/TabelasIncidencias'
import DialogDadosRescisao from './components/DialogDadosRescisao'
import DialogRPsConsulta from './components/DialogRPsConsulta'
import DialogFaltas from './components/DialogFaltas'
import DialogDesmembramentoFerias from './components/DialogDesmembramentoFerias'
import ControleDebitos from '../Menu/components/ControleDebitos'
import DialogRateioPensao from './components/DialogRateioPensao'
import DialogFormEdit from './components/DialogFormEdit'

import { pessoaFisicaCheckUso } from '~/hooks/queries/PessoaFisica/pessoaFisicaCheckUso'
import { formatAnoMes, formatIdfReciboToReportKey, openBlankTab } from '~/hooks/useUtils'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import { useContextRecibo } from '~/hooks/useRecibo'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import useOnlyDataC from '~/hooks/useOnlyDataC'
import { useQueryClient } from 'react-query'

import useMutationLimparCamposESocial from '~/hooks/queries/RP/useMutationLimparCamposESocial'

import { ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'

import { queryGetVinculosMenu2 } from '~/hooks/queries/RP/useGetVinculosMenu2'

const useStyles = makeStyles(() => ({
  heigthPaper: {
    height: '100%',
  },
  heigthPaperSmall: {
    height: '60%',
    minHeight: 200,
  },
}))

const DynamicMenu = (props) => {
  const { handleOpenConfirmaDeleteReciboPagamento, onHandleSelectItem } = props

  const { reciboPagamento, titlePage, setOpenDialogLembretes } = useContextRecibo()
  const queryClient = useQueryClient()

  const reportKeyImprimirRecibo = formatIdfReciboToReportKey(reciboPagamento?.idf)

  const [isOpenImprimirRecibo, setOpenImprimirRecibo] = useState(false)
  const [isOpenMemoriaCalculo, setOpenMemoriaCalculo] = useState(false)
  const [isOpenMemoriaCalculoRecibo, setOpenMemoriaCalculoRecibo] = useState(false)

  const [formObservacao, setFormObservacao] = useState({
    isOpen: false,
  })

  const [formTabelasIncidencias, setFormTabelasIncidencias] = useState({
    isOpen: false,
  })

  const [formDependentes, setFormDependentes] = useState({
    isOpen: false,
    pessoaFisica: {},
    isUsed: false,
    isLoading: false,
  })

  const [formEventosAutomaticos, setFormEventosAutomaticos] = useState({
    isOpen: false,
    vinculo: {},
  })

  const [formAfastamento, setFormAfastamento] = useState({
    isOpen: false,
    vinculo: {},
  })

  const [formSalarioContratual, setFormSalarioContratual] = useState({
    isOpen: false,
    vinculo: {},
  })

  const [formFerias, setFormFerias] = useState({
    isOpen: false,
    vinculo: {},
  })

  const {
    close: closeFormFaltas,
    data: dataFormFaltas,
    isOpen: isOpenFormFaltas,
    open: openFormFaltas,
  } = useDialog(null)

  const {
    close: closeDialogRateioPensao,
    data: dataDialogRateioPensao,
    isOpen: isOpenDialogRateioPensao,
    open: openDialogRateioPensao,
  } = useDialog({ rpId: '', vinculoId: '' })

  const {
    close: closeDialogDesmembramentoFerias,
    data: dataDialogDesmembramentoFerias,
    isOpen: isOpenDialogDesmembramentoFerias,
    open: openDialogDesmembramentoFerias,
  } = useDialog({
    rpId: null,
    subtitle: '',
  })

  const {
    close: closeDialogFormEdit,
    data: dataDialogFormEdit,
    isOpen: isOpenDialogFormEdit,
    open: openDialogFormEdit,
  } = useDialog(null)

  const [isLoadingRecalcular, setLoadingRecalcular] = useState(false)
  const [isLoadingFinishRP, setLoadingFinishRP] = useState(false)

  const { anoMes, estabelecimento } = useAmbiente()

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const classes = useStyles()
  const { isUserDataC } = useOnlyDataC()

  const {
    close: closeDadosRescisao,
    data: dataDadosRescisao,
    isOpen: isOpenDadosRescisao,
    open: openDadosRescisao,
  } = useDialog(null)
  const {
    close: closeDialogRPsConsulta,
    data: dataDialogRPsConsulta,
    isOpen: isOpenDialogRPsConsulta,
    open: openDialogRPsConsulta,
  } = useDialog(null)
  const {
    close: closeControleDebitos,
    data: dataControleDebitos,
    isOpen: isOpenControleDebitos,
    open: openControleDebitos,
  } = useDialog('')

  const { mutateAsync: mutateAsyncLimparCamposESocial, isLoading: isLoadingLimparCamposESocial } =
    useMutationLimparCamposESocial()

  const onSubmitFormObservacao = () => {
    onHandleSelectItem('reciboSelecionado', reciboPagamento?.id)
    setFormObservacao({ isOpen: false })
  }

  const handleRecalcular = async () => {
    setLoadingRecalcular(true)
    try {
      // await api.post(`RP/Recalcular?rpId=${reciboPagamento?.id}`)
      notification.success('Recibo recalculado com sucesso.')
      onHandleSelectItem('reciboSelecionado', reciboPagamento?.id)
      setLoadingRecalcular(false)
    } catch (err) {
      setLoadingRecalcular(false)
      dialogNotification.extractErrors(err)
    }
  }

  function handleImprimir() {
    setOpenImprimirRecibo(true)
  }

  function verifyRPESocial() {
    if (reciboPagamento?.isFinalizado && reciboPagamento?.reciboEsocial) {
      return dialogNotification.info({
        descriptions: [
          'O Recibo já foi enviado para o eSocial se você continuar com a liberação será gerado um evento de retificação para ser enviado para o eSocial',
        ],
        onConfirm: () => handleChangeFinalizarRecibo(),
      })
    }
    handleChangeFinalizarRecibo()
  }

  async function handleChangeFinalizarRecibo() {
    setLoadingFinishRP(true)
    try {
      let url = `/RP/Finalizar?rpId=${reciboPagamento?.id}`
      if (reciboPagamento?.isFinalizado) url = `/RP/Liberar?rpId=${reciboPagamento?.id}`
      await api.put(url)
      let msg = 'Recibo liberado com sucesso.'
      if (!reciboPagamento?.isFinalizado) msg = 'Recibo finalizado com sucesso.'
      notification.success(msg, '', 4000, 'top-right')
      await queryClient.invalidateQueries(queryGetVinculosMenu2)
      onHandleSelectItem('reciboSelecionado', reciboPagamento?.id)
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingFinishRP(false)
    }
  }

  async function openDependentes() {
    const pessoaFisica = reciboPagamento?.vinculo?.pessoaFisica || null
    if (!pessoaFisica?.id) return
    setFormDependentes((oldState) => ({
      ...oldState,
      isLoading: true,
    }))
    try {
      const isUsed = await pessoaFisicaCheckUso(pessoaFisica.id)
      setFormDependentes({
        isOpen: true,
        isUsed,
        pessoaFisica: pessoaFisica,
        isLoading: false,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setFormDependentes((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }
  }

  const memoizedDependentes = useMemo(() => {
    return (
      <ActionDialog
        title="Dependentes"
        isOpen={formDependentes.isOpen}
        onClose={() => {
          setFormDependentes({
            isOpen: false,
            pessoaFisica: {},
            isUsed: false,
            isLoading: false,
          })
        }}
        customActions={<div />}
        onCancelClick={() => {
          setFormDependentes({
            isOpen: false,
            pessoaFisica: {},
            isUsed: false,
            isLoading: false,
          })
        }}
        dialogProps={{
          maxWidth: 'lg',
          fullWidth: true,
          classes: {
            paper: classes.heigthPaper,
          },
        }}
      >
        <Dependentes
          pessoaFisicaId={formDependentes.pessoaFisica.id}
          isUsed={formDependentes.isUsed}
        />
      </ActionDialog>
    )
  }, [formDependentes, classes.heigthPaper])

  const memoizedEventosAutomaticos = useMemo(() => {
    return (
      <ActionDialog
        title="Eventos Automáticos"
        isOpen={formEventosAutomaticos.isOpen}
        onClose={() => {
          setFormEventosAutomaticos({
            isOpen: false,
            vinculo: {},
          })
        }}
        customActions={<div />}
        onCancelClick={() => {
          setFormEventosAutomaticos({
            isOpen: false,
            vinculo: {},
          })
        }}
        dialogProps={{
          maxWidth: 'lg',
          fullWidth: true,
          classes: {
            paper: classes.heigthPaper,
          },
        }}
      >
        <EventosAutomaticos
          vinculo={{
            vinculoId: formEventosAutomaticos?.vinculo?.id,
            vinculoNome: formEventosAutomaticos?.vinculo?.pessoaFisica?.nome,
          }}
        />
      </ActionDialog>
    )
  }, [formEventosAutomaticos, classes.heigthPaper])

  const memoizedAfastamento = useMemo(() => {
    return (
      <ActionDialog
        title="Afastamentos"
        subtitle={formAfastamento?.vinculo?.pessoaFisica?.nome || '-'}
        isOpen={formAfastamento.isOpen}
        onClose={() => {
          setFormAfastamento({
            isOpen: false,
            vinculo: {},
          })
          onHandleSelectItem('reciboSelecionado', reciboPagamento?.id)
        }}
        customActions={<div />}
        dialogProps={{
          maxWidth: 'lg',
          fullWidth: true,
          classes: {
            paper: classes.heigthPaper,
          },
        }}
      >
        <Afastamento
          vinculo={{
            ...formAfastamento.vinculo,
            vinculoId: formAfastamento?.vinculo?.id || '',
          }}
          noTitle
        />
      </ActionDialog>
    )
  }, [
    formAfastamento.vinculo,
    formAfastamento.isOpen,
    classes.heigthPaper,
    onHandleSelectItem,
    reciboPagamento?.id,
  ])

  const memoizedSalarioContratual = useMemo(() => {
    return (
      <ActionDialog
        title="Salário Contratual"
        isOpen={formSalarioContratual.isOpen}
        onClose={() => {
          setFormSalarioContratual({
            isOpen: false,
            vinculo: {},
          })
        }}
        customActions={<div />}
        onCancelClick={() => {
          setFormSalarioContratual({
            isOpen: false,
            vinculo: {},
          })
        }}
        dialogProps={{
          maxWidth: 'lg',
          fullWidth: true,
          classes: {
            paper: classes.heigthPaper,
          },
        }}
      >
        <SalarioContratual vinculo={formSalarioContratual.vinculo} />
      </ActionDialog>
    )
  }, [formSalarioContratual, classes.heigthPaper])

  const memoizedFerias = useMemo(() => {
    return (
      <ActionDialog
        title="Férias"
        subtitle={formFerias?.vinculo?.pessoaFisica?.nome || '-'}
        isOpen={formFerias.isOpen}
        onClose={() => {
          setFormFerias({
            isOpen: false,
            vinculo: {},
          })
        }}
        customActions={<div />}
        onCancelClick={() => {
          setFormFerias({
            isOpen: false,
            vinculo: {},
          })
        }}
        dialogProps={{
          maxWidth: 'lg',
          fullWidth: true,
          classes: {
            paper: classes.heigthPaper,
          },
        }}
      >
        <Ferias
          vinculo={{
            ...formFerias.vinculo,
            vinculoId: formFerias?.vinculo?.id || '',
          }}
          noTitle
        />
      </ActionDialog>
    )
  }, [formFerias, classes.heigthPaper])

  const isRescisao =
    reciboPagamento?.idf === ReciboIDFEnum.Rescisao_5 ||
    reciboPagamento?.idf === ReciboIDFEnum.RescisaoComplementar_6 ||
    reciboPagamento?.idf === ReciboIDFEnum.RescisaoSimulada_11

  return (
    <>
      <ButtonBox>
        <DynamicContextMenu forceContextMenu={false} disableHandleClickAway={false} threshold="lg">
          <Item
            label="Consultar Recibos"
            icon="search"
            onClick={() => openDialogRPsConsulta(reciboPagamento)}
          />

          <Item
            label="Editar Recibo"
            icon="edit"
            onClick={() => openDialogFormEdit(reciboPagamento)}
          />

          <Item
            label="Controle de Débitos"
            icon="local_atm"
            onClick={() => openControleDebitos(reciboPagamento?.vinculoId || '')}
          />

          <Item
            label="Limpar eSocial"
            icon="layers_clear"
            onClick={async () => {
              await mutateAsyncLimparCamposESocial(reciboPagamento.id)
              onHandleSelectItem('reciboSelecionado', reciboPagamento?.id)
            }}
            isLoading={isLoadingLimparCamposESocial}
            isVisible={isUserDataC}
          />

          <Item
            label="Transmissão eSocial"
            icon="description"
            onClick={() => openBlankTab('/transmissao-esocial/0')}
          />

          <Item label="Lembretes" icon="notes" onClick={() => setOpenDialogLembretes(true)} />

          <Item
            label="Memória de Cálculo"
            icon="functions"
            onClick={() => setOpenMemoriaCalculo(true)}
          />

          <Item
            label="Memória de Cálculo do Recibo"
            icon="account_balance_wallet"
            onClick={() => setOpenMemoriaCalculoRecibo(true)}
          />

          <Item
            label="Recalcular"
            icon="cached"
            onClick={handleRecalcular}
            isLoading={isLoadingRecalcular}
          />

          <Item
            label="Observação"
            icon={<MoreHorizIcon fontSize="small" color="primary" />}
            onClick={() =>
              setFormObservacao({
                isOpen: true,
              })
            }
          />

          <Item
            label="Tabelas de Incidências"
            icon={<DynamicFeedIcon fontSize="small" color="primary" />}
            onClick={() =>
              setFormTabelasIncidencias({
                isOpen: true,
              })
            }
          />

          <Item
            label="Dependentes"
            icon={<PeopleAltIcon fontSize="small" color="primary" />}
            onClick={openDependentes}
            isLoading={formDependentes.isLoading}
          />

          <Item
            label="Pensão Alimentícia"
            icon={<ChildFriendly fontSize="small" color="primary" />}
            onClick={() =>
              openDialogRateioPensao({
                rpId: reciboPagamento?.id || '',
                vinculoId: reciboPagamento?.vinculoId || '',
              })
            }
          />

          <Item
            label="Eventos Automáticos"
            icon="assignment"
            onClick={() =>
              setFormEventosAutomaticos({
                isOpen: true,
                vinculo: reciboPagamento?.vinculo,
              })
            }
          />

          <Item
            label="Afastamento"
            icon={<ExitToAppIcon fontSize="small" color="primary" />}
            onClick={() =>
              setFormAfastamento({
                isOpen: true,
                vinculo: reciboPagamento?.vinculo,
              })
            }
          />

          <Item
            label="Salário Contratual"
            icon={<AttachMoneyIcon fontSize="small" color="primary" />}
            onClick={() =>
              setFormSalarioContratual({
                isOpen: true,
                vinculo: reciboPagamento?.vinculo,
              })
            }
          />

          <Item
            label="Férias"
            icon={<Brightness5 fontSize="small" color="primary" />}
            onClick={() =>
              setFormFerias({
                isOpen: true,
                vinculo: reciboPagamento?.vinculo,
              })
            }
          />

          <Item
            label="Desmembramento de Férias"
            icon={<Brightness6 fontSize="small" color="primary" />}
            onClick={() => {
              openDialogDesmembramentoFerias({
                rpId: reciboPagamento.id,
                subtitle:
                  reciboPagamento.vinculo.pessoaFisica.nome +
                  ' - ' +
                  formatAnoMes(reciboPagamento.anoMes),
              })
            }}
          />

          <Item
            label="Faltas"
            icon={<EventBusyIcon fontSize="small" color="primary" />}
            onClick={() => openFormFaltas(reciboPagamento)}
          />

          <Item
            label="Imprimir"
            icon="print"
            onClick={handleImprimir}
            isVisible={reciboPagamento?.idf !== ReciboIDFEnum.RescisaoSimulada_11}
          />

          <Item
            label="Dados Rescisão"
            icon="emoji_people"
            onClick={() => openDadosRescisao(reciboPagamento?.vinculoId)}
            isVisible={isRescisao}
          />

          <Item
            label={reciboPagamento?.isFinalizado ? 'Liberar Recibo' : 'Finalizar Recibo'}
            icon={reciboPagamento?.isFinalizado ? 'cancel' : 'check_circle'}
            isLoading={isLoadingFinishRP}
            onClick={verifyRPESocial}
          />

          <Item
            label="Excluir"
            icon="delete"
            onClick={() => handleOpenConfirmaDeleteReciboPagamento(reciboPagamento?.id)}
            disabled={reciboPagamento?.isFinalizado || Boolean(reciboPagamento?.reciboEsocial)}
          />
        </DynamicContextMenu>
      </ButtonBox>

      {isOpenMemoriaCalculo && (
        <MemoriaCalculoRP
          isOpen={isOpenMemoriaCalculo}
          reciboPagamento={reciboPagamento}
          onClose={() => setOpenMemoriaCalculo(false)}
        />
      )}

      <Observacao
        isOpen={formObservacao.isOpen}
        reciboPagamento={reciboPagamento}
        onSubmitFormObservacao={onSubmitFormObservacao}
        onClose={() => {
          onHandleSelectItem('reciboSelecionado', reciboPagamento?.id)
          setFormObservacao({
            isOpen: false,
          })
        }}
      />

      <TabelasIncidencias
        isOpen={formTabelasIncidencias.isOpen}
        onClose={() => {
          setFormTabelasIncidencias({
            isOpen: false,
          })
        }}
      />

      <PDFViewer
        title={'Relatório / ' + titlePage}
        isOpen={isOpenImprimirRecibo}
        onClose={async () => {
          setOpenImprimirRecibo(false)
          onHandleSelectItem('reciboSelecionado', reciboPagamento?.id)
          await queryClient.invalidateQueries(queryGetVinculosMenu2)
        }}
        reportKey={reportKeyImprimirRecibo}
        isSalvarDocumento
        axiosConfig={{
          url: `/RP/GetById?rpId=${reciboPagamento?.id}`,
          method: 'get',
          params: {
            anoMes,
            estabelecimentoId: estabelecimento.id,
          },
        }}
      />

      {memoizedDependentes}

      {memoizedEventosAutomaticos}

      {memoizedAfastamento}

      {memoizedSalarioContratual}

      {memoizedFerias}

      <DialogFaltas
        isOpen={isOpenFormFaltas}
        onClose={() => {
          closeFormFaltas()
          onHandleSelectItem('reciboSelecionado', reciboPagamento?.id)
        }}
        reciboPagamento={dataFormFaltas}
      />

      {isOpenDadosRescisao && (
        <DialogDadosRescisao
          isOpen={isOpenDadosRescisao}
          vinculoId={dataDadosRescisao}
          onClose={closeDadosRescisao}
        />
      )}

      {isOpenDialogRPsConsulta && (
        <ActionDialog
          isOpen={isOpenDialogRPsConsulta}
          title="Consulta Recibo Pagamento"
          subtitle={dataDialogRPsConsulta?.vinculo?.pessoaFisica?.nome}
          customActions={<></>}
          onClose={closeDialogRPsConsulta}
          dialogProps={{
            fullWidth: true,
            maxWidth: 'md',
            classes: {
              paper: classes.heigthPaper,
            },
          }}
        >
          <DialogRPsConsulta vinculoId={dataDialogRPsConsulta.vinculo.id} />
        </ActionDialog>
      )}

      {isOpenDialogDesmembramentoFerias && (
        <DialogDesmembramentoFerias
          rpId={dataDialogDesmembramentoFerias.rpId}
          subtitle={dataDialogDesmembramentoFerias.subtitle}
          isOpen={isOpenDialogDesmembramentoFerias}
          onClose={closeDialogDesmembramentoFerias}
        />
      )}

      {isOpenControleDebitos && (
        <ControleDebitos
          isOpen={isOpenControleDebitos}
          onClose={closeControleDebitos}
          vinculoId={dataControleDebitos}
        />
      )}

      <PDFViewer
        title={'Memória de Cálculo do Recibo / ' + titlePage}
        isOpen={isOpenMemoriaCalculoRecibo}
        onClose={() => setOpenMemoriaCalculoRecibo(false)}
        isSalvarDocumento
        axiosConfig={{
          url: '/RPMemoriaCalculo/ObterRelatorio',
          method: 'get',
          params: {
            reciboId: reciboPagamento?.id,
          },
        }}
      />

      {isOpenDialogRateioPensao && (
        <DialogRateioPensao
          isOpen={isOpenDialogRateioPensao}
          onClose={closeDialogRateioPensao}
          rpId={dataDialogRateioPensao.rpId}
          vinculoId={dataDialogRateioPensao.vinculoId}
        />
      )}

      {isOpenDialogFormEdit && (
        <DialogFormEdit
          isOpen={isOpenDialogFormEdit}
          onClose={closeDialogFormEdit}
          onSubmit={() => onHandleSelectItem('reciboSelecionado', reciboPagamento?.id)}
          rp={dataDialogFormEdit}
        />
      )}
    </>
  )
}

export default DynamicMenu
