import { ReactNode } from 'react'
import { Box } from '@material-ui/core'
import VStack, { VStackProps } from '../VStack'

interface PageContainerRootProps extends VStackProps {
  children: ReactNode
}

export default function PageContainerRoot({ children, ...rest }: PageContainerRootProps) {
  return (
    <Box height="100%" width="100%" position="relative">
      <VStack
        width="100%"
        height="100%"
        position="absolute"
        right={0}
        left={0}
        bottom={0}
        top={0}
        p={1}
        {...rest}
      >
        {children}
      </VStack>
    </Box>
  )
}
