import { useState } from 'react'

export default function usePopover() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const isOpen = Boolean(anchorEl)

  function open(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }

  function close() {
    setAnchorEl(null)
  }

  return { isOpen, open, close, anchorEl, setAnchorEl }
}
