import { useEffect } from 'react'

import { Button, DatePickerNew } from '~/components'
import HStack from '~/components/HStack'

import useAmbiente from '~/hooks/useAmbiente'
import useForm from '~/hooks/useForm'

import * as yup from 'yup'

const schema = yup.object().shape({
  competencia: yup.string().required('Informe o Último Exercício Findo'),
})

export interface FilterStateProps {
  competencia: string
}

interface FilterProps {
  onChange: (filters: FilterStateProps) => void
}

export default function Filter({ onChange }: FilterProps) {
  const { ano } = useAmbiente()

  const { data, changeValue, validationErrors, handleSubmit } = useForm<FilterStateProps>({
    defaultData: { competencia: String(ano - 1) },
    schema,
    onSubmit: onChange,
  })

  useEffect(() => {
    onChange({ competencia: `${ano - 1}-01-01` })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleSearch() {
    if (data) handleSubmit(data)
  }

  return (
    <HStack gap={8}>
      <DatePickerNew
        name="competencia"
        label="Último Exercício Findo"
        views={['year']}
        format="YYYY"
        required
        value={data?.competencia || null}
        onChange={(date) => changeValue('competencia', date)}
        validationErrors={validationErrors}
      />
      <Button variant="contained" onClick={handleSearch}>
        Buscar
      </Button>
    </HStack>
  )
}
