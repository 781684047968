export enum VinculoFaltasTipoDiaEnum {
  Falta = 1,
  Atraso = 2,
  DSRDescontado = 3,
  PercaSabadoCompensado = 4,
  Ferias = 5,
  Afastado = 6,
  DescansoSemanal = 7,
  DiaNaoTrabalha = 8,
}
