import { useState } from 'react'

import { ActionDialog, CurrencyTextField, Stack } from '~/components'

import { useAtualizarProvisaoFerias } from '~/hooks/queries/ProvisaoCompetencia/useAtualizarProvisaoFerias'

import { ProvisaoFerias } from '~/hooks/queries/ProvisaoCompetencia/interfaces/ProvisaoFerias'

import { ProvisaoCompetenciaFeriasAtualizarDTO } from '~/hooks/queries/ProvisaoCompetencia/interfaces/ProvisaoCompetenciaFeriasAtualizarDTO'

interface FormUpdateProps {
  isOpen: boolean
  onClose: () => void
  data: ProvisaoFerias
}

export default function FormUpdate({ isOpen, onClose, data: _data }: FormUpdateProps) {
  const [data, setData] = useState<ProvisaoCompetenciaFeriasAtualizarDTO>(_data)

  const { mutateAsync, isLoading } = useAtualizarProvisaoFerias()

  async function handleSubmit() {
    await mutateAsync({
      data,
      params: {
        id: _data.id,
      },
    })
    onClose()
  }

  return (
    <ActionDialog
      title="Atualizar Provisão de Férias"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleSubmit}
      isOkProcessing={isLoading}
    >
      <Stack>
        <CurrencyTextField
          label="Valor Base Provisão"
          value={data.vrBaseProvisao}
          onChange={(_, value) => {
            const vrBaseProvisao = value
            setData((prev) => ({
              ...prev,
              vrBaseProvisao,
            }))
          }}
        />

        <CurrencyTextField
          label="Valor Provisão"
          value={data.vrProvisao}
          onChange={(_, value) => {
            const vrProvisao = value
            setData((prev) => ({
              ...prev,
              vrProvisao,
            }))
          }}
        />

        <CurrencyTextField
          label="Valor Contribuição Previdênciaria"
          value={data.vrContribuicaoPrevidenciaria}
          onChange={(_, value) => {
            const vrContribuicaoPrevidenciaria = value
            setData((prev) => ({
              ...prev,
              vrContribuicaoPrevidenciaria,
            }))
          }}
        />

        <CurrencyTextField
          label="Valor Contribuição Terceiros"
          value={data.vrContribuicaoTerceiros}
          onChange={(_, value) => {
            const vrContribuicaoTerceiros = value
            setData((prev) => ({
              ...prev,
              vrContribuicaoTerceiros,
            }))
          }}
        />

        <CurrencyTextField
          label="Valor FGTS"
          value={data.vrFGTS}
          onChange={(_, value) => {
            const vrFGTS = value
            setData((prev) => ({
              ...prev,
              vrFGTS,
            }))
          }}
        />

        <CurrencyTextField
          label="Valor PIS"
          value={data.vrPIS}
          onChange={(_, value) => {
            const vrPIS = value
            setData((prev) => ({
              ...prev,
              vrPIS,
            }))
          }}
        />
      </Stack>
    </ActionDialog>
  )
}
