import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { useInvalidateQuery } from '~/hooks/useInvalidateQuery'

import { HttpRequestInterface } from '~/interfaces'
import { notifyRemove } from '~/utils/notification'
import api from '~/services/api-pessoal'

import { query } from './useObterIndiceFgts'
import { IndiceFgtsRemoverDTO } from './dtos'

async function handleRequest({ data }: HttpRequestInterface<IndiceFgtsRemoverDTO>) {
  await api.delete(`/IndiceFGTS/${data?.id}`)
}

export default function useRemoverIndiceFgts() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidateQuery(query)

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyRemove()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
