import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoMenuDTO } from './VinculoMenuDTO'

import { ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'
import { CategoriaEnum } from '~/@types/enums/CategoriaEnum'

export const queryGetVinculosMenu2 = '/RP/GetVinculosMenu2'

interface RequestProps {
  data: ReciboIDFEnum[]
  params: {
    estabelecimentoId: string
    anoMes: string
    categoria: CategoriaEnum | null
    cargoId: string | null
    lotacaoId: string | null
  }
}

export function useGetVinculosMenu2({ data, params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: VinculoMenuDTO[] }>(queryGetVinculosMenu2, data, {
      params,
    })
    return response.data.data
  }

  return useQuery([queryGetVinculosMenu2, data, params], handleRequest, {
    onError: (err) => dialogNotification.extractErrors(err),
  })
}
