import api from '~/services/api-pessoal'

import { useQuery, useMutation } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'

import { RelFeriasVencidasDTO } from './dtos/RelFeriasVencidasDTO'
import { openDownloadData } from '~/hooks/useUtils'
import { notifySuccess } from '~/utils/notification'

const query = '/Relatorio/FeriasVencidas/ObterFeriasVencidas'

interface RequestProps {
  data: string[]
  params: { dtLimite: string; anoMes: string }
}

export default function useObterFeriasVencidas({ data, params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: RelFeriasVencidasDTO[] }>(query, data, {
      params,
    })
    return response.data.data
  }

  return useQuery([data, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useObterFeriasVencidasXlsx() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestProps) {
    const response = await api.post(query, data, {
      headers: {
        'DC-XlsResponse': true,
      },
      responseType: 'blob',
      params,
    })
    return response.data
  }

  return useMutation(handleRequest, {
    onSuccess: async (data) => {
      notifySuccess('Relatório gerado com sucesso')
      openDownloadData('ferias-vencidas.xlsx', data)
    },
    onError: dialogNotification.extractErrors,
  })
}
