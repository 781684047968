import { useMemo } from 'react'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'
import { ToolsDataTable } from '~/components'
import { useTableRowsSelectableContext } from '~/contexts/TableRowsSelectableContext'

import { useVinculoConsultaGetByEstabelecimento } from '~/hooks/queries/VinculoConsulta/useVinculoConsultaGetByEstabelecimento'
import { VinculoConsulta } from '~/hooks/queries/VinculoConsulta/VinculoConsulta'
import useAmbiente from '~/hooks/useAmbiente'

import { ToolsDataTableColumnDef } from '~/interfaces'

export default function Table({ queryFilter }: { queryFilter: string }) {
  const { estabelecimento, anoMes } = useAmbiente()
  const { indexSelecionados, setIndexSelecionados } =
    useTableRowsSelectableContext<VinculoConsulta>()

  const { data, isLoading, isFetching } = useVinculoConsultaGetByEstabelecimento({
    estabelecimentoId: estabelecimento.id,
    anoMes,
    vinculoTipo: [VinculoTipoEnum.Funcionario_1],
  })

  const columns = useMemo((): ToolsDataTableColumnDef[] => {
    return [
      {
        name: 'pessoaCodigo',
        label: 'Código',
        type: 'number',
      },
      {
        name: 'pessoaCPF',
        label: 'CPF',
        type: 'cpf-cnpj',
      },
      {
        name: 'pessoaNome',
        label: 'Nome',
      },
      {
        name: 'dtAdmissao',
        label: 'Admissão',
        type: 'full-date',
      },
    ]
  }, [])

  return (
    <ToolsDataTable
      columns={columns}
      data={data || []}
      isLoading={isLoading}
      isFetching={isFetching}
      cellHeaderWhiteSpaceNowrap
      cellWhiteSpaceNowrap
      sherlock={{ columns: ['pessoaCodigo', 'pessoaCPF', 'pessoaNome'], query: queryFilter }}
      optionsSelectable={{
        type: 'multiple',
        selectOnClick: true,
        rowsSelected: indexSelecionados,
        onRowSelected: (rowsSelected) => setIndexSelecionados(rowsSelected, data),
      }}
    />
  )
}
