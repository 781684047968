import React, { useState } from 'react'

import { Checkbox, Grid, MenuItem, FormControlLabel } from '@material-ui/core'
import { ActionDialog, DatePickerNew } from '~/components'

import { NumeroInscricaoTextField, TextField } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'

import * as yup from 'yup'

import { GrauParentescoEnum, GrauParentescoValues } from '~/@types/enums/GrauParentescoEnum'
import { PessoaFisicaDependentePartial } from '~/hooks/queries/PessoaFisicaDependente/PessoaFisicaDependente'
import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'
import { cpf } from 'cpf-cnpj-validator'

const schema = yup.object().shape({
  grauParentesco: yup
    .string()
    .required('Informe o Grau Parentesco')
    .when(['isDependenteSF'], (isDependenteSF, schema) => {
      if (isDependenteSF) {
        return schema.test(
          'teste',
          "Ao informar 'Dependente SF', apenas as opções ('03 - Filho(a) ou enteado(a)' ou '04 - Filho(a) ou enteado(a), universitário(a) ou cursando escola técnica de 2º grau') podem ser informadas",
          (grauParentesco: string) => {
            const parsedGrauParentesco = parseInt(grauParentesco)
            const inThisArrayOfOptionsGrauParentescoEnum = [
              GrauParentescoEnum.FilhoOuEnteado_03,
              GrauParentescoEnum.FilhoOuEnteadoEstudante_04,
            ]
            if (inThisArrayOfOptionsGrauParentescoEnum.includes(parsedGrauParentesco)) {
              return true
            }
          },
        )
      }

      return schema.required('Informe o Grau Parentesco')
    }),
  nome: yup.string().required('Informe o Nome'),
  dtNascimento: yup
    .date()
    .required('Informe a Data de Nascimento')
    .typeError('Informe uma data válida')
    .nullable(),
  nrInscricao: yup
    .string()
    .when(['isDependenteIR'], (isDependenteIR, schema) => {
      if (isDependenteIR === true) {
        return schema
          .required('Informe o CPF')
          .test('cpf-validator', 'Informe um CPF válido', (nrInscricao: string) =>
            cpf.isValid(nrInscricao),
          )
      }

      return schema
    })
    .test('CPF-Validator', 'Informe um CPF válido', (nrInscricao: string | null | undefined) => {
      if (nrInscricao && nrInscricao.length > 0) return cpf.isValid(nrInscricao)
      return true
    })
    .nullable(),
  descricaoDependente: yup
    .string()
    .when(['grauParentesco'], (grauParentesco, schema) =>
      parseInt(grauParentesco) === GrauParentescoEnum.AgregadoOutros_99
        ? schema.required('Informe a Descrição do Dependente')
        : schema,
    )
    .nullable(),
})

interface FormProps {
  isOpen: boolean
  isUpdate: boolean
  onClose: () => void
  data: PessoaFisicaDependentePartial
  onAfterSubmitForm: (event: 'update' | 'insert', data: PessoaFisicaDependentePartial) => void
}

export default function Form(props: FormProps) {
  const { isOpen, onClose, data: _data, onAfterSubmitForm, isUpdate } = props
  const [data, setData] = useState<PessoaFisicaDependentePartial>(_data)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  const handleSubmit = () => {
    const update = async () => {
      try {
        onAfterSubmitForm('update', data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
    }

    const insert = async () => {
      try {
        onAfterSubmitForm('insert', data)
        notification.post()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
    }

    if (isUpdate) {
      update()
      return
    }
    insert()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Pessoa Física | Dependentes"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'lg', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Grau Parentesco"
            options={GrauParentescoValues}
            renderOption={(option) => option.name}
            optionId="value"
            value={data.grauParentesco}
            required
            validationErrors={validationErrors}
            name="grauParentesco"
            onChange={(e, obj) => {
              const grauParentesco = obj ? obj.value : ''
              setData({ ...data, grauParentesco, descricaoDependente: '' })
            }}
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            value={data.nome || ''}
            size="small"
            required
            inputProps={{
              maxLength: 100,
            }}
            validationErrors={validationErrors}
            name="nome"
            onChange={(e) => {
              const nome = e.target.value
              setData({ ...data, nome })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <DatePickerNew
            label="Data Nascimento"
            size="small"
            value={data.dtNascimento || null}
            onChange={(date) => {
              const dtNascimento = date
              setData({ ...data, dtNascimento })
            }}
            required
            validationErrors={validationErrors}
            name="dtNascimento"
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <NumeroInscricaoTextField
            label="CPF"
            value={data?.nrInscricao || ''}
            typeMask={TipoInscricaoEnum.CPF_2}
            validationErrors={validationErrors}
            name="nrInscricao"
            required={data?.isDependenteIR}
            onChange={(e, value) => {
              const nrInscricao = value
              setData({ ...data, nrInscricao })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <TextField
            select
            label="Sexo"
            fullWidth
            size="small"
            value={data.sexo || ''}
            onChange={(e) => {
              const sexo = e.target.value
              setData({ ...data, sexo })
            }}
          >
            <MenuItem value="M">Masculino</MenuItem>
            <MenuItem value="F">Feminino</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="descricaoDependente"
            label="Descrição do Dependente"
            required={data?.grauParentesco === GrauParentescoEnum.AgregadoOutros_99}
            disabled={data?.grauParentesco !== GrauParentescoEnum.AgregadoOutros_99}
            fullWidth
            inputProps={{ maxLength: 30 }}
            value={data?.descricaoDependente || ''}
            onChange={(e) => {
              const descricaoDependente = e.target.value || ''
              setData((oldState) => ({
                ...oldState,
                descricaoDependente,
              }))
            }}
            validationErrors={validationErrors}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <FormControlLabel
            label="Dependente SF"
            control={
              <Checkbox
                // checked={data.isDependenteSF}
                checked={data.isDependenteSF || false}
                size="small"
                onChange={(e) => {
                  const isDependenteSF = e.target.checked
                  setData({ ...data, isDependenteSF })
                }}
                color="secondary"
              />
            }
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <FormControlLabel
            label="Dependente IR"
            control={
              <Checkbox
                checked={data.isDependenteIR || false}
                size="small"
                onChange={(e) => {
                  const isDependenteIR = e.target.checked
                  setData({ ...data, isDependenteIR })
                }}
                color="secondary"
              />
            }
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <FormControlLabel
            label="Deficiente"
            control={
              <Checkbox
                checked={data.isDeficiente || false}
                size="small"
                onChange={(e) => {
                  const isDeficiente = e.target.checked
                  setData({ ...data, isDeficiente })
                }}
                color="secondary"
              />
            }
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
