import { useState } from 'react'
import PageContainer from '~/components/PageContainer'
import TransferDataProvider from '~/contexts/TransferDataContext'

import Actions from './components/Actions'
import Filter from './components/Filter'
import Table from './components/Table'

export default function FolhaAnalitica() {
  const [filters, setFilters] = useState<number[] | null>(null)

  return (
    <PageContainer.Root>
      <PageContainer.Header title="Folha Analítica" />
      <PageContainer.ActionsContainer marginBottom={0.5}>
        <Filter onChange={(newFilters) => setFilters(newFilters)} />
      </PageContainer.ActionsContainer>

      <TransferDataProvider>
        <Table filters={filters} />
        <PageContainer.ActionsContainer>
          <Actions filters={filters} />
        </PageContainer.ActionsContainer>
      </TransferDataProvider>
    </PageContainer.Root>
  )
}
