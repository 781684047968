import { useEffect, useState } from 'react'

import moment from 'moment'
import clsx from 'clsx'
import { setImportantCSS } from '~/hooks/useUtils'

import { formatToCPFOrCNPJ } from 'brazilian-values'

import { Print } from '@material-ui/icons'
import { Box, IconButton, makeStyles } from '@material-ui/core'
import { ButtonBox, CopyLabel, DatePickerNew, TextField, ToolsDataTable } from '~/components'
import { Ativo } from '~/components/Displays'

import { ManutencaoContratoExperienciaFuncionarioDTO } from '~/hooks/queries/ManutencaoContratoExperiencia/interfaces/ManutencaoContratoExperienciaFuncionarioDTO'

import { ContratoFuncionarioManutencaoEnum } from '~/@types/enums/ContratoFuncionarioManutencaoEnum'
import { ToolsDataTableColumnDef } from '~/interfaces'

const useStyles = makeStyles((theme: FixLater) => ({
  hightLightRow: {
    backgroundColor: setImportantCSS(theme.palette.secondary.lighter),
    color: setImportantCSS(theme.palette.secondary.contrastText),
    '&:hover': {
      backgroundColor: setImportantCSS(theme.palette.secondary.light),
    },
  },
}))

interface TextFieldCellProps {
  value: number
  disabled: boolean
  onBlur: (value: number) => void
}

function TextFieldCell(props: TextFieldCellProps) {
  const { value: _value, disabled, onBlur } = props
  const [value, setValue] = useState(_value)

  useEffect(() => {
    setValue(_value)
  }, [_value])

  return (
    <TextField
      onlyNumber
      value={value}
      onChange={(e) => {
        const newValue = parseInt(e.target.value || '0')
        setValue(newValue)
      }}
      onBlur={() => onBlur(value)}
      disabled={disabled}
      inputProps={{
        style: {
          cursor: disabled ? 'not-allowed' : 'auto',
        },
      }}
    />
  )
}

interface TableProps {
  data: ManutencaoContratoExperienciaFuncionarioDTO[]
  onChangeData: (data: ManutencaoContratoExperienciaFuncionarioDTO[]) => void
  isLoading: boolean
  isFetching: boolean
  query: string
  tipoProcessamento: ContratoFuncionarioManutencaoEnum
  onPrint: ({
    funcionarioId,
    estabelecimentoId,
  }: {
    funcionarioId: string
    estabelecimentoId: string
  }) => void
}

export default function Table({
  data,
  onChangeData,
  isLoading,
  isFetching,
  query,
  tipoProcessamento,
  onPrint,
}: TableProps) {
  const classes = useStyles()

  const columns: ToolsDataTableColumnDef[] = [
    {
      name: 'estabelecimentoNrInscricao',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'estabelecimentoNome',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'funcionarioNrInscricao',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'funcionarioNome',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'estabelecimentoNome',
      label: 'Estabelecimento',
    },
    {
      name: 'estabelecimentoNrInscricao',
      label: 'Nr Inscrição',
      type: 'cpf-cnpj',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
    {
      name: 'funcionarioNome',
      label: 'Funcionário',
    },
    {
      name: 'funcionarioNrInscricao',
      label: 'Funcionário/CPF',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
    {
      name: 'funcionarioDtAdmissao',
      label: 'Admissão',
      type: 'full-date',
    },
    {
      name: 'diasContratoExperiencia',
      label: 'Dias Experiência',
      type: 'number',
    },
    {
      name: 'dtContratoExperiencia',
      label: 'Data Experiência',
      type: 'full-date',
    },
    {
      name: 'funcionarioId',
      label: 'Dias Prorrogação',
      headerZindex: 99,
      options: {
        customBodyRender: (id) => {
          const funcionario = data.find((d) => d.funcionarioId == id)
          if (!funcionario) return <>-</>
          return (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box maxWidth={130}>
                <TextFieldCell
                  disabled={tipoProcessamento !== ContratoFuncionarioManutencaoEnum.Prorrogacao_0}
                  value={funcionario.diasContratoProrrogacao}
                  onBlur={(dias) => {
                    const diasContratoProrrogacao = dias

                    if (funcionario.diasContratoProrrogacao === diasContratoProrrogacao) return

                    const dtContratoProrrogacao =
                      diasContratoProrrogacao > 0
                        ? moment(funcionario.dtContratoExperiencia)
                            .add(diasContratoProrrogacao, 'days')
                            .format('yyyy-MM-DD')
                        : null
                    const dtAlteracao2206 =
                      diasContratoProrrogacao > 0
                        ? moment(funcionario.dtContratoExperiencia)
                            .add(-1, 'days')
                            .format('yyyy-MM-DD')
                        : null

                    onChangeData(
                      data.map((d) =>
                        d.funcionarioId === funcionario.funcionarioId
                          ? {
                              ...funcionario,
                              diasContratoProrrogacao,
                              dtContratoProrrogacao,
                              dtAlteracao2206,
                            }
                          : d,
                      ),
                    )
                  }}
                />
              </Box>
            </Box>
          )
        },
      },
    },
    {
      name: 'dtContratoProrrogacao',
      label: 'Data Prorrogação',
      headerZindex: 99,
      minWidth: 170,
      options: {
        customBodyRender: (_, tableMeta) => {
          const rowData = data[tableMeta.rowIndex]
          if (!rowData) return <>-</>
          return (
            <DatePickerNew
              value={rowData?.dtContratoProrrogacao}
              onChange={(date) => {
                if (date === rowData?.dtContratoProrrogacao) return
                onChangeData(
                  data.map((item) =>
                    item.funcionarioId === rowData.funcionarioId
                      ? {
                          ...rowData,
                          dtContratoProrrogacao: date,
                        }
                      : item,
                  ),
                )
              }}
            />
          )
        },
      },
    },
  ]

  if (tipoProcessamento === ContratoFuncionarioManutencaoEnum.Rescisao_2) {
    columns.push(
      {
        name: 'isRescisaoImpressa',
        label: 'Impresso',
        options: {
          customBodyRender: (value) => <Ativo ativo={value} />,
        },
      },
      {
        name: 'funcionarioId',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (id) => {
            return (
              <ButtonBox spacing={0} justifyContent="center">
                <IconButton
                  title="Imprimir este registro"
                  size="small"
                  color="primary"
                  aria-label="Imprimir"
                  onClick={() => {
                    const funcionario = data.find((d) => d.funcionarioId == id)
                    if (!funcionario) return
                    onPrint({
                      funcionarioId: funcionario.funcionarioId,
                      estabelecimentoId: funcionario.estabelecimentoId,
                    })
                  }}
                >
                  <Print fontSize="small" />
                </IconButton>
              </ButtonBox>
            )
          },
        },
      },
    )
  } else {
    columns.push({
      name: 'funcionarioId',
      label: 'Data Alteração 2206',
      minWidth: 170,
      headerZindex: 99,
      options: {
        customBodyRender: (id) => {
          const funcionario = data.find((d) => d.funcionarioId == id)
          if (!funcionario) return <>-</>
          return (
            <DatePickerNew
              value={funcionario.dtAlteracao2206}
              onChange={(date) => {
                const dtAlteracao2206 = date
                if (dtAlteracao2206 === funcionario.dtAlteracao2206) return
                onChangeData(
                  data.map((d) =>
                    d.funcionarioId === funcionario.funcionarioId
                      ? {
                          ...funcionario,
                          dtAlteracao2206,
                        }
                      : d,
                  ),
                )
              }}
            />
          )
        },
      },
    })
  }

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      cellHeaderWhiteSpaceNowrap
      cellWhiteSpaceNowrap
      sherlock={{
        columns: [
          'estabelecimentoNrInscricao',
          'estabelecimentoNome',
          'funcionarioNrInscricao',
          'funcionarioNome',
        ],
        query,
      }}
      options={{
        setRowProps: (_: FixLater, index: number) => {
          const rowCurrent = data[index]
          if (!rowCurrent) return
          const isDtAlteracao = rowCurrent.dtAlteracao2206 !== null
          return {
            className: clsx({
              [classes.hightLightRow]: isDtAlteracao,
            }),
          }
        },
      }}
    />
  )
}
