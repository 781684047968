import { EstatisticaFuncionariosDTO } from '~/hooks/queries/EstatisticaFuncionarios/dtos'

export function formatarData(data?: EstatisticaFuncionariosDTO) {
  const saldoInicial = {
    desc: 'Total de empregados inicial do último exercício findo',
    value: data?.saldoInicial,
  }
  const admitidos = {
    desc: 'Número de empregados admitidos no último exercício findo',
    value: data?.admitidos,
  }
  const demitidos = {
    desc: 'Número de empregados demitidos no último exercício findo',
    value: data?.demitidos,
  }
  const saldoFinal = {
    desc: 'Total de empregados ativos 31/12 do último exercício findo',
    value: data?.saldoFinal,
  }
  const saldo1 = {
    desc: 'Número de empregados com tempo até 1 ano',
    value: data?.saldo1,
  }
  const saldo2 = {
    desc: 'Número de empregados com tempo acima de 1 até 2 anos',
    value: data?.saldo2,
  }
  const saldo3 = {
    desc: 'Número de empregados com tempo acima de 2 atè 3 anos',
    value: data?.saldo3,
  }
  const saldo4 = {
    desc: 'Número de empregados com tempo acima de 3 atè 4 anos',
    value: data?.saldo4,
  }
  const saldo5 = {
    desc: 'Número de empregados com tempo acima de 4 atè 5 anos',
    value: data?.saldo5,
  }
  const saldo9 = {
    desc: 'Número de empregados com tempo acima de 5 anos',
    value: data?.saldo9,
  }

  return [
    saldoInicial,
    admitidos,
    demitidos,
    saldoFinal,
    saldo1,
    saldo2,
    saldo3,
    saldo4,
    saldo5,
    saldo9,
  ]
}
