import { ReactNode, createContext, useContext, useState } from 'react'

interface FormContextData<T> {
  formData: T
  isOpen: boolean
  closeFormDialog: VoidFunction
  setData: (data: T) => void
  openFormDialog: (data?: T) => void
}

interface TableRowsSelectableProps {
  children: ReactNode
}

export const FormContext = createContext({} as FormContextData<any>)

export default function FormProvider<T>({ children }: TableRowsSelectableProps) {
  const [data, setData] = useState<T | null>(null)
  const [isOpen, setOpen] = useState(false)

  function handleSetData<T extends null>(_data: T) {
    setData(_data)
  }

  function handleOpenFormDialog(_data?: T) {
    setOpen(true)
    if (_data) {
      setData(_data)
    }
  }

  function handleCloseFormDialog() {
    setData(null)
    setOpen(false)
  }

  return (
    <FormContext.Provider
      value={{
        formData: data,
        isOpen,
        setData: handleSetData,
        openFormDialog: handleOpenFormDialog,
        closeFormDialog: handleCloseFormDialog,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export function useFormContext<T>() {
  return useContext<FormContextData<T>>(FormContext)
}
