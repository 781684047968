import { useState, useEffect, useCallback } from 'react'
import { getDateMinAnoMes } from '~/utils/utils'

import { Grid } from '@material-ui/core'
import { TextField } from 'mio-library-ui'
import { ActionDialog } from '~/components'

import { DatePicker } from '~/components'

import api from '~/services/api-pessoal'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

import * as yup from 'yup'

const schema = yup.object().shape({
  dtFAP: yup.date().required('Informe a Data FAP').typeError('Informe uma data válida').nullable(),
  aliqFAP: yup.string().required('Informe a Alíq. FAP'),
})

const CadastroFAP = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const { anoMes } = useAmbiente()

  useEffect(() => {
    if (_data?.id) {
      setData(_data)
    } else {
      setData({ ..._data, dtFAP: getDateMinAnoMes(anoMes) })
    }
  }, [_data, anoMes])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/fap/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/fap', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de FAP"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DatePicker
            label="Data FAP"
            size="small"
            disabled
            // required
            validationErrors={validationErrors}
            name="dtFAP"
            value={data?.dtFAP || null}
            onChange={(date) => {
              const dtFAP = date ? date.format('yyyy-MM-DD') : null
              setData((oldState) => ({ ...oldState, dtFAP }))
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <TextField
            label="Aliq. RAT"
            fullWidth
            value={data?.aliqRat || ''}
            variant="outlined"
            size="small"
            onlyNumber
            separator="."
            validationErrors={validationErrors}
            name="aliqRat"
            onChange={(e) => {
              const aliqRat = e?.target.value || ''
              setData({ ...data, aliqRat })
            }}
            onBlur={(e) => {
              let aliqRat = 1

              if (e?.target.value < 1 || e?.target.value > 3) {
                notification.warning('Valor inválido para o RAT', 'Ops!', 4000)
              } else {
                aliqRat = e?.target.value || ''
              }
              const aliqRatAjustada = aliqRat * data?.aliqFAP
              setData({
                ...data,
                aliqRat,
                aliqRatAjustada,
              })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <TextField
            name="aliqFAP"
            label="Aliq. FAP"
            fullWidth
            value={data?.aliqFAP || ''}
            variant="outlined"
            size="small"
            separator="."
            onlyNumber
            onChange={(e) => {
              let value = e.target.value

              // Permitir apenas números e ponto decimal, com no máximo 4 casas decimais
              if (/^\d*\.?\d{0,4}$/.test(value) || value === '') {
                setData({ ...data, aliqFAP: value })
              }
            }}
            onBlur={(e) => {
              let aliqFAP = e?.target.value

              if (aliqFAP < 0.5 || aliqFAP > 2) {
                notification.warning('O valor deve estar entre 0.5 e 2.0', 'Ops!', 4000)
                setData({
                  ...data,
                  aliqRatAjustada: '',
                  aliqFAP: '',
                })
                return
              }

              const aliqRatAjustada = aliqFAP * data?.aliqRat
              setData({
                ...data,
                aliqRatAjustada,
              })
            }}
            validationErrors={validationErrors}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <TextField
            label="Aliq. RAT Ajustada"
            fullWidth
            value={data?.aliqRatAjustada || ''}
            variant="outlined"
            size="small"
            onlyNumber
            disabled
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default CadastroFAP
