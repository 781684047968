import PageContainer from '~/components/PageContainer'
import Table from './components/Table'
import Form from './components/Form'
import FormProvider from '~/contexts/FormContext'

export default function IndiceFgts() {
  return (
    <PageContainer.Root>
      <FormProvider>
        <PageContainer.Header title="Índice FGTS">
          <PageContainer.AddButtonForm />
        </PageContainer.Header>
        <Table />
        <Form />
      </FormProvider>
    </PageContainer.Root>
  )
}
