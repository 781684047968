import { Box, Link } from '@material-ui/core'
import { ActionDialog, Button, ButtonBox } from '~/components'

import moment from 'moment'

import { IndLancamentoFaltaEnum } from '~/@types/enums/IndLancamentoFaltaEnum'
import { VinculoDSR } from '~/hooks/queries/VinculoDSR/VinculoDSR'
import { VinculoFaltasTipoDiaEnum } from '~/@types/enums/VinculoFaltasTipoDiaEnum'
import { VinculoFaltasRegistroDTO } from '~/hooks/queries/VinculoFaltas/VinculoFaltasDTO'

interface DialogDSRProps {
  data: VinculoDSR
  isOpen: boolean
  onClose: () => void
  onClickDate: (data: VinculoFaltasRegistroDTO) => void
}

export default function DialogDSR(props: DialogDSRProps) {
  const { data, isOpen, onClose, onClickDate } = props
  return (
    <ActionDialog
      title="Descanso Semanal Remunerado"
      subtitle={data?.dtDSR ? moment(data.dtDSR).format('DD/MM/yyyy') : '-'}
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button onClick={onClose} variant="contained">
            Fechar
          </Button>
        </ButtonBox>
      }
    >
      <Box>
        <Box>
          O DSR <strong>{data?.dtDSR ? moment(data.dtDSR).format('DD/MM/yyyy') : ''}</strong> foi
          perdido.
        </Box>
        <Box>
          Na data de{' '}
          <Link
            component="button"
            variant="body2"
            underline="always"
            onClick={() => {
              if (!data?.vinculoFaltas) return
              const tipoDia =
                data?.vinculoFaltas.indLancamento === IndLancamentoFaltaEnum.Atraso
                  ? VinculoFaltasTipoDiaEnum.Atraso
                  : VinculoFaltasTipoDiaEnum.Falta
              onClickDate({
                id: data.vinculoFaltas.id,
                data: data.vinculoFaltas.dtFalta,
                tipo: tipoDia,
                registro: data.vinculoFaltas,
              })
              onClose()
            }}
          >
            {data?.vinculoFaltas?.dtFalta
              ? moment(data.vinculoFaltas.dtFalta).format('DD/MM/yyyy')
              : ''}
          </Link>{' '}
          foi registrado uma falta com a opção Descontar DSR.
        </Box>
      </Box>
    </ActionDialog>
  )
}
