import { useEffect, useState } from 'react'

import { Box, Grid, Paper, Typography } from '@material-ui/core'

import {
  Button,
  ButtonBox,
  ConfirmDeleteDialog,
  ContainerTable,
  Finder,
  PageHeader,
  Stack,
  StackContainer,
  TreeView,
} from '~/components'
import { AutoCompleteGrupo } from '~/components/AutoComplete'

import Form from './components/Form'
import Table from './components/Table'

import { useEstabelecimentoObterFiltrado } from '~/hooks/queries/EstabelecimentoConsulta/useEstabelecimentoObterFiltrado'

import useLocalStorage from '~/hooks/useLocalStorage'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import constants from '~/utils/constants'

import { IndAtividadeEstabelecimentoEnum } from '~/@types/enums/IndAtividadeEstabelecimentoEnum'
import { EstabelecimentoConsulta } from '~/hooks/queries/EstabelecimentoConsulta/EstabelecimentoConsulta'
import { useRemover } from '~/hooks/queries/EventoAutomaticoEstabelecimento/useRemover'

export default function VinculoEventoAutomatico() {
  const [query, setQuery] = useState('')
  const [grupoId, setGrupoId] = useLocalStorage(constants.grupoId, '')
  const [estabelecimentoSelecionado, setEstabelecimentoSelecionado] =
    useState<EstabelecimentoConsulta | null>(null)

  const { anoMes } = useAmbiente()

  const {
    data: _d,
    isLoading: isLoadingMenu,
    isFetching: isFetchingMenu,
  } = useEstabelecimentoObterFiltrado({
    indAtividadeEstabelecimentoEnum: IndAtividadeEstabelecimentoEnum.ApenasAtivos,
    anoMes,
    grupoId,
  })

  const dataMenu = [{ groupName: 'Estabelecimentos', data: _d || [] }]

  useEffect(() => {
    setEstabelecimentoSelecionado(null)
  }, [_d])

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<string | null>(null)
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog<string>('')

  const { mutateAsync: mutateAsyncRemover, isLoading: isRemoving } = useRemover()

  function handleClickItem(event: 'edit' | 'delete', id: string) {
    switch (event) {
      case 'edit':
        openForm(id)
        break
      case 'delete':
        openConfirmDelete(id)
        break
    }
  }

  async function handleConfirmDelete() {
    await mutateAsyncRemover({
      params: {
        id: dataConfirmDelete,
      },
    })
    closeConfirmDelete()
  }

  return (
    <StackContainer>
      <Grid
        container
        spacing={1}
        style={{
          height: '100%',
        }}
      >
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <Stack height="100%" gap={0}>
            <Box component={Paper} borderRadius={0} p={1}>
              <AutoCompleteGrupo
                value={grupoId}
                onChange={(e, grupo) => {
                  const grupoId = grupo?.id || ''
                  setGrupoId(grupoId)
                }}
              />
            </Box>
            <Box flex={1}>
              <TreeView
                data={dataMenu}
                onSelectItem={(e: FixLater, estabelecimento: EstabelecimentoConsulta | null) =>
                  setEstabelecimentoSelecionado(estabelecimento)
                }
                searchBy="nome"
                renderOption={(option: EstabelecimentoConsulta) => `${option.nome}`}
                isLoading={isLoadingMenu}
                isLoadingOnClick={isFetchingMenu}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={9} xl={9}>
          <Stack height="100%">
            <PageHeader
              title="Eventos Automáticos Estabelecimento"
              subtitle={estabelecimentoSelecionado ? estabelecimentoSelecionado.nome : '-'}
            >
              {estabelecimentoSelecionado ? (
                <ButtonBox>
                  <Finder onSearch={setQuery} onClose={() => setQuery('')} />
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => openForm(null)}
                    variant="contained"
                  >
                    Adicionar
                  </Button>
                </ButtonBox>
              ) : (
                <></>
              )}
            </PageHeader>

            {estabelecimentoSelecionado ? (
              <>
                <ContainerTable>
                  <Table
                    estabelecimentoId={estabelecimentoSelecionado.id}
                    onItemClick={handleClickItem}
                    query={query}
                  />
                </ContainerTable>

                {isOpenForm && (
                  <Form
                    isOpen={isOpenForm}
                    onClose={closeForm}
                    id={dataForm}
                    dataInitial={{
                      estabelecimentoId: estabelecimentoSelecionado.id,
                    }}
                  />
                )}

                {isOpenConfirmDelete && (
                  <ConfirmDeleteDialog
                    isOpen={isOpenConfirmDelete}
                    isDeleting={isRemoving}
                    onCancel={closeConfirmDelete}
                    onConfirm={handleConfirmDelete}
                  />
                )}
              </>
            ) : (
              <Typography>Selecione um Estabelecimento</Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
    </StackContainer>
  )
}
