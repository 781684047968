import React, { useRef } from 'react'

import { createMuiTheme, ThemeProvider, Box, makeStyles } from '@material-ui/core'
import { DataTable } from 'mio-library-ui'
import theme from '~/styles/theme'

import useHeightParents from '~/hooks/useHeightParents'
import clsx from 'clsx'
import useAmbiente from '~/hooks/useAmbiente'

const useStyles = makeStyles((theme) => ({
  hightLightRow: {
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    backgroundColor: 'rgba(89, 195, 224, 30%)',
  },
}))

const HeightDataTable = (props) => {
  const { pagination, triggersHeight, delayResize, ...rest } = props

  const refRootTable = useRef(null)
  const classes = useStyles()

  useHeightParents({
    refElement: refRootTable,
    triggers: triggersHeight,
    delayResize,
    minHeight: 300,
  })

  const { estabelecimento } = useAmbiente()

  const height = pagination ? 'calc(100% - 60px - 1px)' : '100%'

  const tableTheme = createMuiTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MUIDataTableFooter: {
        root: {
          height: pagination ? '60px' : '0',
        },
      },
      MUIDataTable: {
        paper: {
          height: '100%',
        },
      },
    },
  })

  return (
    <Box ref={refRootTable}>
      <ThemeProvider theme={tableTheme}>
        <DataTable
          {...rest}
          options={{
            ...rest.options,
            tableBodyHeight: height,
            setRowProps: (_, __, rowIndex) => {
              const destacarEstabelecimentoOuEmpregador =
                props?.data?.[rowIndex]?.id === estabelecimento?.id ||
                props?.data?.[rowIndex]?.id === estabelecimento?.empregadorId
              return {
                className: clsx({
                  [classes.hightLightRow]: destacarEstabelecimentoOuEmpregador,
                }),
              }
            },
          }}
          pagination={pagination}
        />
      </ThemeProvider>
    </Box>
  )
}

export default HeightDataTable

HeightDataTable.defaultProps = {
  pagination: true,
  triggersHeight: {},
  delayResize: 500,
}
