import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifyPost } from '~/utils/notification'
import { query } from './useObterIndiceFgts'
import { useInvalidateQuery } from '~/hooks/useInvalidateQuery'
import { HttpRequestInterface } from '~/interfaces'
import { IndiceFgtsAdicionarDTO } from './dtos'

async function handleRequest({ data }: HttpRequestInterface<IndiceFgtsAdicionarDTO>) {
  await api.post('/IndiceFGTS', data)
}

export default function useAdicionarIndiceFgts() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidateQuery(query)

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
