import { formatToCPF } from 'brazilian-values'

import { ButtonBox } from 'mio-library-ui'
import { IconButton } from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { CopyLabel, ToolsDataTable } from '~/components'

import { useVinculoConsultaGetByEstabelecimento } from '~/hooks/queries/VinculoConsulta/useVinculoConsultaGetByEstabelecimento'

import useAmbiente from '~/hooks/useAmbiente'

import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

import { VinculoConsulta } from '~/hooks/queries/VinculoConsulta/VinculoConsulta'
import { ToolsDataTableColumnDef } from '~/interfaces'

interface TableProps {
  query: string
  onClickItem: (event: 'edit' | 'delete', vinculo: VinculoConsulta) => void
}

export default function Table(props: TableProps) {
  const { onClickItem, query } = props

  const { anoMes, estabelecimento } = useAmbiente()

  const {
    data: _data,
    isLoading,
    isFetching,
  } = useVinculoConsultaGetByEstabelecimento({
    vinculoTipo: [VinculoTipoEnum.Funcionario_1],
    anoMes,
    estabelecimentoId: estabelecimento.id,
  })
  const data = _data || []

  const columns: ToolsDataTableColumnDef[] = [
    {
      name: 'matricula',
      label: 'Matrícula',
      type: 'number',
      width: 80,
    },
    {
      name: 'pessoaNome',
      label: 'Nome',
    },
    {
      name: 'pessoaCPF',
      label: 'CPF',
      width: 100,
      options: {
        customBodyRender: (value) =>
          value ? <CopyLabel value={value}>{formatToCPF(value)}</CopyLabel> : '',
      },
    },
    {
      name: 'dtAdmissao',
      label: 'Data Admissão',
      type: 'full-date',
      width: 100,
    },
    {
      name: 'dtRescisao',
      label: 'Data Rescisão',
      type: 'full-date',
      width: 100,
    },
    {
      name: 'id',
      label: 'Ações',
      position: 'sticky',
      right: 0,
      options: {
        filter: true,
        sort: false,
        empty: true,

        customBodyRender: (value) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                size="small"
                onClick={() => {
                  const dataCurrent = data.find((d) => d.id === value)
                  if (!dataCurrent) return
                  onClickItem('edit', dataCurrent)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                size="small"
                onClick={() => {
                  const dataCurrent = data.find((d) => d.id === value)
                  if (!dataCurrent) return
                  onClickItem('delete', dataCurrent)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      cellHeaderWhiteSpaceNowrap
      cellWhiteSpaceNowrap
      onRowDoubleClick={(rowData) => onClickItem('edit', rowData)}
      sherlock={{
        query,
        columns: ['matricula', 'pessoaNome', 'pessoaCPF', 'dtAdmissao', 'dtRescisao'],
      }}
    />
  )
}
