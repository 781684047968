import { useEffect, lazy, Suspense, useState } from 'react'

import { Box, CircularProgress, Grid, LinearProgress } from '@material-ui/core'

import { AutoSizer } from 'react-virtualized'

import { Button, FullDialog, Stack } from '~/components'

import TagViewer from './TagViewer'
const DocumentEditor = lazy(() => import('./DocumentEditor'))

import { debounce } from 'lodash'

import { useObterArquivo } from '~/hooks/queries/ModeloRelatorio/useObterArquivo'
import { useAtualizarArquivo } from '~/hooks/queries/ModeloRelatorio/useAtualizarArquivo'

interface DialogRelatorioProps {
  idRelatorio: string
  isOpen: boolean
  onClose: () => void
}

export default function DialogRelatorio({ idRelatorio, isOpen, onClose }: DialogRelatorioProps) {
  const [documentContainer, setDocumentContainer] = useState<any>(null)

  const { data, isLoading, isFetching } = useObterArquivo({
    params: {
      id: idRelatorio,
    },
  })

  const { mutateAsync, isLoading: isUpdating } = useAtualizarArquivo()

  useEffect(() => {
    const handleResize = debounce(() => {
      if (documentContainer) {
        documentContainer.resize()
      }
    }, 300)
    handleResize()
  }, [documentContainer])

  useEffect(() => {
    if (!documentContainer) return
    if (!data) return
    documentContainer.documentEditor.open(data)
  }, [data, isLoading, isFetching, documentContainer])

  async function handleSave() {
    if (!documentContainer) return
    const blob = await documentContainer.documentEditor.saveAsBlob('Docx')
    const formData = new FormData()
    formData.append('file', blob)
    await mutateAsync({
      data: formData,
      params: {
        id: idRelatorio,
      },
    })
    onClose()
  }

  function handleAddTag(tag: string) {
    if (!documentContainer) return
    documentContainer.documentEditor.selection.moveToDocumentEnd()
    documentContainer.documentEditor.editor.insertText(' ' + tag)
  }

  return (
    <FullDialog isOpen={isOpen} title="Gerador de Relatórios" onClose={onClose} subtitle="">
      {isLoading && (
        <Box
          position="absolute"
          right={0}
          left={0}
          top={0}
          bottom={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={99}
          bgcolor="rgba(0,0,0,0.3)"
        >
          <CircularProgress size={150} />
        </Box>
      )}
      <Stack height="100%" gap={0.5}>
        <Grid container spacing={1} style={{ flex: 1 }}>
          <Grid item xs={12} sm={3}>
            <TagViewer onClick={handleAddTag} />
          </Grid>
          <Grid item xs={12} sm={9}>
            <AutoSizer>
              {({ height, width }) => (
                <Suspense fallback={<div>Carregando...</div>}>
                  <DocumentEditor
                    ref={(scp) => setDocumentContainer(scp)}
                    height={height}
                    width={width}
                  />
                </Suspense>
              )}
            </AutoSizer>
          </Grid>
        </Grid>
        <Box height={4}>{isFetching && <LinearProgress />}</Box>
        <Stack orientation="horizontal" justifyContent="flex-end">
          <Button onClick={onClose}>Cancelar</Button>
          <Button variant="contained" onClick={handleSave} isLoading={isUpdating}>
            Salvar
          </Button>
        </Stack>
      </Stack>
    </FullDialog>
  )
}
