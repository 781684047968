import api from '~/services/api-pessoal'

import { useMutation, useQueryClient } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'

import { queryObterEmpregadoresParaFechamento } from './useObterEmpregadoresParaFechamento'

interface RequestProps {
  competenciasEmpregadoresIds: string[]
}

export default function useProcessarFechamentoManual() {
  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const queryClient = useQueryClient()

  async function handleRequest({ competenciasEmpregadoresIds }: RequestProps) {
    await api.post('/Competencia/ProcessarFechamentoManual', competenciasEmpregadoresIds)
  }

  return useMutation(handleRequest, {
    onSuccess: async () => {
      notification.success('Fechamento manual finalizado Sucesso')
      await queryClient.invalidateQueries(queryObterEmpregadoresParaFechamento)
    },
    onError: dialogNotification.extractErrors,
  })
}
