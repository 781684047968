import { useQuery } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'
import { HttpResponseInterface } from '~/interfaces'
import api from '~/services/api-pessoal'
import { IndiceFgtsDTO } from './dtos'

export const query = '/IndiceFGTS'

async function handleRequest() {
  const response = await api.get(query)
  return response.data
}

export default function useObterIndiceFgts() {
  const dialogNotification = useDialogNotification()
  return useQuery<HttpResponseInterface<IndiceFgtsDTO>>([query], () => handleRequest(), {
    onError: dialogNotification.extractErrors,
  })
}
