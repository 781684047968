import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifyPut } from '~/utils/notification'

import { useQueryClient } from './useObterPorRecibo'

import { VinculoPensaoBeneficiario } from './interfaces/VinculoPensaoBeneficiario'

interface RequestProps {
  data: VinculoPensaoBeneficiario
  params: {
    id: string
  }
}

export function useAtualizar() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useQueryClient()

  async function handleRequest({ data, params }: RequestProps) {
    await api.put('/VinculoPensaoBeneficiario/Atualizar', data, { params })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPut()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
