import { Delete as DeleteIcon } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'

interface DataTableCellActionsDeleteProps {
  title?: string
  disabled?: boolean
  onClick?: (event: 'delete') => void
}

export default function DataTableCellActionsDelete({
  title = 'Deletar este registro',
  disabled = false,
  onClick,
}: DataTableCellActionsDeleteProps) {
  return (
    <IconButton
      title={title}
      color="primary"
      aria-label="Deletar"
      disabled={disabled}
      onClick={() => onClick && onClick('delete')}
      size="small"
    >
      <DeleteIcon fontSize="small" color="primary" />
    </IconButton>
  )
}
