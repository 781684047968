import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { GerenciadorDocumentos } from './GerenciadorDocumentos'

export const queryObterDocumentos = '/GerenciadorDocumentos/ObterDocumentos'

interface RequestProps {
  params: { estabelecimentoId: string; competencia: string; isMostrarTodosDocumentos: boolean }
}

export function useObterDocumentos({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: GerenciadorDocumentos[] }>(queryObterDocumentos, {
      params,
    })
    return response.data.data
  }

  return useQuery([queryObterDocumentos, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
