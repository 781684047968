import { useEffect, useState } from 'react'

import { Box, CircularProgress, Grid, LinearProgress } from '@material-ui/core'

import { Button, Stack } from '~/components'
import RowConta from '~/components/RowConta'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'

import { useAtualizarGRRF } from '~/hooks/queries/ExportacaoLancamentoConfig/useAtualizarGRRF'
import { useObterPorModeloIntegracao } from '~/hooks/queries/ExportacaoLancamentoConfig/useObterPorModeloIntegracao'

import { ModeloIntegracao } from '~/hooks/queries/ModeloIntegracao/dtos/ModeloIntegracao'
import { GRRFConfig } from '~/hooks/queries/ExportacaoLancamentoConfig/dtos/GRRFConfig'
import { FileCopy } from '@material-ui/icons'
import DialogCopyConfigsGuia from '../DialogCopyConfigsGuia'

const startData = {} as GRRFConfig

interface GRRFConfiguracaoProps {
  modeloIntegracao: ModeloIntegracao
}

export default function GRRFConfiguracao({ modeloIntegracao }: GRRFConfiguracaoProps) {
  const [data, setData] = useState(startData)

  const { estabelecimento } = useAmbiente()

  const { mutateAsync, isLoading: isSubmitting } = useAtualizarGRRF()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterPorModeloIntegracao({
    params: {
      estabelecimentoId: estabelecimento.id,
      modeloIntegracao: modeloIntegracao.id,
    },
  })

  const {
    close: closeDialogCopyConfigsGuia,
    isOpen: isOpenDialogCopyConfigsGuia,
    open: openDialogCopyConfigsGuia,
  } = useDialog('')

  const rescisaoGRRFConfig = _d?.rescisaoGRRF || startData

  useEffect(() => {
    setData(rescisaoGRRFConfig)
  }, [rescisaoGRRFConfig])

  function handleCancel() {
    setData(rescisaoGRRFConfig)
  }

  async function handleSave() {
    if (_d) {
      await mutateAsync({
        data: {
          ..._d,
          rescisaoGRRF: data,
        },
        params: {
          id: _d.id,
        },
      })
    }
  }

  return (
    <Stack position="relative" height="100%">
      <Stack alignItems="flex-end">
        <Button
          variant="contained"
          endIcon={<FileCopy />}
          onClick={() => openDialogCopyConfigsGuia(modeloIntegracao?.id || '')}
        >
          Copiar Configurações
        </Button>
      </Stack>

      <Box flex={1} overflow="auto" style={{ overflowX: 'hidden' }}>
        <Box height={4}>{isFetching && <LinearProgress />}</Box>

        {isLoading ? (
          <Box
            position="absolute"
            right={0}
            left={0}
            top={0}
            bottom={0}
            zIndex={999}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={80} />
          </Box>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <RowConta
                label="Vr. FGTS Mês Anterior"
                mask={modeloIntegracao.mascaraConta}
                valueDebito={data.grrffgtsMesAnterior?.normalDebito || ''}
                onChangeDebito={(value) => {
                  const normalDebito = value
                  setData((prev) => ({
                    ...prev,
                    grrffgtsMesAnterior: {
                      ...prev.grrffgtsMesAnterior,
                      normalDebito,
                    },
                  }))
                }}
                valueCredito={data.grrffgtsMesAnterior?.normalCredito || ''}
                onChangeCredito={(value) => {
                  const normalCredito = value
                  setData((prev) => ({
                    ...prev,
                    grrffgtsMesAnterior: {
                      ...prev.grrffgtsMesAnterior,
                      normalCredito,
                    },
                  }))
                }}
                valueIgnorar={data.grrffgtsMesAnterior?.ignorar}
                onChangeIgnorar={(value) => {
                  const ignorar = value
                  setData((prev) => ({
                    ...prev,
                    grrffgtsMesAnterior: {
                      ...prev.grrffgtsMesAnterior,
                      ignorar,
                    },
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <RowConta
                label="Vr. FGTS Mês"
                mask={modeloIntegracao.mascaraConta}
                valueDebito={data.grrffgtsMes?.normalDebito || ''}
                onChangeDebito={(value) => {
                  const normalDebito = value
                  setData((prev) => ({
                    ...prev,
                    grrffgtsMes: {
                      ...prev.grrffgtsMes,
                      normalDebito,
                    },
                  }))
                }}
                valueCredito={data.grrffgtsMes?.normalCredito || ''}
                onChangeCredito={(value) => {
                  const normalCredito = value
                  setData((prev) => ({
                    ...prev,
                    grrffgtsMes: {
                      ...prev.grrffgtsMes,
                      normalCredito,
                    },
                  }))
                }}
                valueIgnorar={data.grrffgtsMes?.ignorar}
                onChangeIgnorar={(value) => {
                  const ignorar = value
                  setData((prev) => ({
                    ...prev,
                    grrffgtsMes: {
                      ...prev.grrffgtsMes,
                      ignorar,
                    },
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <RowConta
                label="Vr. FGTS Aviso Prévio"
                mask={modeloIntegracao.mascaraConta}
                valueDebito={data.grrfAvisoPrevio?.normalDebito || ''}
                onChangeDebito={(value) => {
                  const normalDebito = value
                  setData((prev) => ({
                    ...prev,
                    grrfAvisoPrevio: {
                      ...prev.grrfAvisoPrevio,
                      normalDebito,
                    },
                  }))
                }}
                valueCredito={data.grrfAvisoPrevio?.normalCredito || ''}
                onChangeCredito={(value) => {
                  const normalCredito = value
                  setData((prev) => ({
                    ...prev,
                    grrfAvisoPrevio: {
                      ...prev.grrfAvisoPrevio,
                      normalCredito,
                    },
                  }))
                }}
                valueIgnorar={data.grrfAvisoPrevio?.ignorar}
                onChangeIgnorar={(value) => {
                  const ignorar = value
                  setData((prev) => ({
                    ...prev,
                    grrfAvisoPrevio: {
                      ...prev.grrfAvisoPrevio,
                      ignorar,
                    },
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <RowConta
                label="Vr. FGTS Multa Rescisória"
                mask={modeloIntegracao.mascaraConta}
                valueDebito={data.grrfMultaRescisoria?.normalDebito || ''}
                onChangeDebito={(value) => {
                  const normalDebito = value
                  setData((prev) => ({
                    ...prev,
                    grrfMultaRescisoria: {
                      ...prev.grrfMultaRescisoria,
                      normalDebito,
                    },
                  }))
                }}
                valueCredito={data.grrfMultaRescisoria?.normalCredito || ''}
                onChangeCredito={(value) => {
                  const normalCredito = value
                  setData((prev) => ({
                    ...prev,
                    grrfMultaRescisoria: {
                      ...prev.grrfMultaRescisoria,
                      normalCredito,
                    },
                  }))
                }}
                valueIgnorar={data.grrfMultaRescisoria?.ignorar}
                onChangeIgnorar={(value) => {
                  const ignorar = value
                  setData((prev) => ({
                    ...prev,
                    grrfMultaRescisoria: {
                      ...prev.grrfMultaRescisoria,
                      ignorar,
                    },
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <RowConta
                label="Vr. Recolhimento"
                mask={modeloIntegracao.mascaraConta}
                valueDebito={data.grrfRecolhimento?.normalDebito || ''}
                onChangeDebito={(value) => {
                  const normalDebito = value
                  setData((prev) => ({
                    ...prev,
                    grrfRecolhimento: {
                      ...prev.grrfRecolhimento,
                      normalDebito,
                    },
                  }))
                }}
                valueCredito={data.grrfRecolhimento?.normalCredito || ''}
                onChangeCredito={(value) => {
                  const normalCredito = value
                  setData((prev) => ({
                    ...prev,
                    grrfRecolhimento: {
                      ...prev.grrfRecolhimento,
                      normalCredito,
                    },
                  }))
                }}
                valueIgnorar={data.grrfRecolhimento?.ignorar}
                onChangeIgnorar={(value) => {
                  const ignorar = value
                  setData((prev) => ({
                    ...prev,
                    grrfRecolhimento: {
                      ...prev.grrfRecolhimento,
                      ignorar,
                    },
                  }))
                }}
              />
            </Grid>
          </Grid>
        )}
      </Box>

      <Stack orientation="horizontal" justifyContent="flex-end">
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button variant="contained" onClick={handleSave} isLoading={isSubmitting}>
          Salvar
        </Button>
      </Stack>

      {isOpenDialogCopyConfigsGuia && (
        <DialogCopyConfigsGuia
          modeloIntegracaoId={modeloIntegracao.id}
          onClose={closeDialogCopyConfigsGuia}
        />
      )}
    </Stack>
  )
}
