import { makeStyles } from '@material-ui/core/styles'

import { Box, CircularProgress, LinearProgress } from '@material-ui/core'

import Paper from '@material-ui/core/Paper'
import MUITable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { formatSimpleDate } from '~/utils/utils'
import clsx from 'clsx'

import { EscalaRevezamentoResponseDTO } from '~/hooks/queries/EscalaRevezamento/interfaces/EscalaRevezamentoResponseDTO'
import { Stack } from '~/components'

const useStyles = makeStyles((theme) => ({
  tableRoot: {
    border: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.divider,
  },
  tableCell: {
    fontSize: '13px',
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  tableCellHover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tableCellHead: {
    paddingLeft: theme.spacing(1),
    fontWeight: 600,
    fontSize: '13px',
  },
  tableRow: {
    height: '30px',
    '&:nth-child(even)': {
      backgroundColor: '#FAFAFA',
    },
    '&:hover': {
      backgroundColor: '#ffffde!important',
    },
  },
}))

interface TableColumn {
  id: string
  label: string
}

interface TableRowType {
  id: string
  number: number
  name: string
}

interface TableProps {
  data: EscalaRevezamentoResponseDTO
  isLoading: boolean
  isFetching: boolean
  dataSelected: string[]
  onChangeDataSelected: (dataSelectedNova: string[]) => void
}

export default function Table({
  data,
  isLoading,
  isFetching,
  dataSelected,
  onChangeDataSelected,
}: TableProps) {
  const classes = useStyles()

  const mainColumns: TableColumn[] = data.colunaPeriodos.map((d) => ({
    id: d.dtInicio,
    label: `${formatSimpleDate(d.dtInicio)} A ${formatSimpleDate(d.dtFim)}`,
  }))

  const secondaryColumns: TableColumn[] = data.colunaDatas.map((d) => ({
    id: d.data,
    label: d.descricao,
  }))

  const rows: TableRowType[] = data.vinculos.map((d) => ({
    id: d.vinculoId,
    number: d.vinculoNumero,
    name: d.vinculoNome,
  }))

  function handleClick(vinculoId: string, data: string) {
    const newId = vinculoId + ',' + data
    const dataFiltrada = dataSelected.filter((d) => d !== newId)
    const filtrouAlgo = dataFiltrada.length !== dataSelected.length
    onChangeDataSelected(filtrouAlgo ? dataFiltrada : [...dataSelected, newId])
  }

  return (
    <Box component={Paper} p={1} height="100%">
      <Box height={4}>{isFetching && <LinearProgress />}</Box>
      {isLoading ? (
        <Stack height="100%" width="100%" alignItems="center" justifyContent="center">
          <CircularProgress size={70} />
        </Stack>
      ) : (
        <TableContainer>
          <MUITable stickyHeader aria-label="sticky table" className={classes.tableRoot}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} className={clsx([classes.tableCellHead])} />
                {mainColumns.map((column) => (
                  <TableCell key={column.id} colSpan={7} className={clsx([classes.tableCellHead])}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell className={clsx([classes.tableCellHead])}>N°</TableCell>
                <TableCell className={clsx([classes.tableCellHead])}>Nome</TableCell>
                {secondaryColumns.map((column) => (
                  <TableCell key={column.id} className={clsx([classes.tableCellHead])} width={30}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    className={classes.tableRow}
                  >
                    <TableCell className={classes.tableCell}>{row.number}</TableCell>
                    <TableCell className={classes.tableCell}>{row.name}</TableCell>
                    {secondaryColumns.map((column) => {
                      return (
                        <TableCell
                          key={column.id}
                          className={clsx([classes.tableCell, classes.tableCellHover])}
                          onClick={() => handleClick(row.id, column.id)}
                        >
                          {dataSelected.some((x) => x === row.id + ',' + column.id) ? 'X' : ' '}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </MUITable>
        </TableContainer>
      )}
    </Box>
  )
}
