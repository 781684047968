import { MovimentoAuditoriaDTO } from '~/hooks/queries/MovimentoAuditoria/dtos'
import { GrupoEventosESocialKey, GrupoEventosESocialValue } from '~/interfaces'
import { grupoEventosESocialMapping } from '~/mapping'

export interface MovimentoAuditoriaDadosNormalizadosDTO {
  empregadorCodigo: number
  empregadorId: string
  empregadorNome: string
  empregadorNrInscricao: string
  primeiroEvento?: GrupoEventosESocialKey
  grupoEvento: GrupoEventosESocialValue
}

export interface MovimentoAuditoriaFormatadoDTO {
  colunasEventos: string[]
  dadosNormalizados: MovimentoAuditoriaDadosNormalizadosDTO[]
}

export default function formatarDataMovimentoAuditoria(
  data?: MovimentoAuditoriaDTO,
): MovimentoAuditoriaFormatadoDTO {
  if (!data || data?.colunas?.length === 0 || data?.empregadores?.length === 0)
    return { colunasEventos: [], dadosNormalizados: [] }

  const eventosUnicos = new Set<number>()
  data.colunas.forEach((coluna) => {
    eventosUnicos.add(coluna.evento)
  })
  const colunasEventos = Array.from(eventosUnicos)
    .sort((a, b) => a - b)
    .map(String)

  const dadosNormalizados = data.empregadores.map((empregador) => {
    const eventosMap = new Map(
      empregador.colunas.map((coluna) => [coluna.evento.toString(), coluna.evento]),
    )

    const primeiroEvento = eventosMap.values().next()?.value as GrupoEventosESocialKey
    const grupoEvento = grupoEventosESocialMapping[primeiroEvento || 0]

    return {
      empregadorCodigo: empregador?.empregadorCodigo,
      empregadorId: empregador?.empregadorId,
      empregadorNome: empregador?.empregadorNome,
      empregadorNrInscricao: empregador?.empregadorNrInscricao,
      primeiroEvento,
      grupoEvento,
      ...Object.fromEntries(colunasEventos.map((evento) => [evento, eventosMap.get(evento)])),
    }
  })

  return { colunasEventos, dadosNormalizados }
}
