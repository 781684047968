import { useCallback, useMemo } from 'react'

import { Theme, useMediaQuery } from '@material-ui/core'
import { Pageview } from '@material-ui/icons'

import { useHistory } from 'react-router-dom'

import DataTableCellActions from '~/components/DataTableCellActions'
import { ToolsDataTable } from '~/components'

import { useObterMovimentoEmpregadores } from '~/hooks/queries/MovimentoAuditoria'
import useAmbiente from '~/hooks/useAmbiente'

import TableClickItemProps from '~/interfaces/TableClickItemProps'
import { ToolsDataTableColumnDef } from '~/interfaces'

import formatarDataMovimentoAuditoria, {
  MovimentoAuditoriaDadosNormalizadosDTO,
} from './utils/formatarDataMovimentoAuditoria'
import { FilterStateProps } from '../Filter'

interface TableProps {
  filters: FilterStateProps | null
  tableBodyHeight?: string
  disableAutoHeight?: boolean
}

export default function Table({ filters, tableBodyHeight, disableAutoHeight = false }: TableProps) {
  const { anoMes } = useAmbiente()

  const { data, isLoading, isFetching } = useObterMovimentoEmpregadores({
    params: { anoMes, grupoId: filters?.grupoId || '' },
    enabled: !!anoMes,
  })

  const dataFormatada = useMemo(() => {
    return formatarDataMovimentoAuditoria(data?.data)
  }, [data?.data])

  const isDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const history = useHistory()

  const handleClickItem = useCallback(
    async (event: TableClickItemProps, rowData: MovimentoAuditoriaDadosNormalizadosDTO) => {
      const { empregadorNome, grupoEvento } = rowData || {}
      switch (event) {
        case 'page_view': {
          if (grupoEvento === 'fechamento') {
            history.push({
              pathname: 'fechamento-esocial',
              state: { empregadorNome },
            })
            break
          }

          history.push({
            pathname: '/transmissao-esocial/0',
            state: { empregadorNome, grupoEvento },
          })
          break
        }
        default:
          break
      }
    },
    [history],
  )

  const columns = useMemo((): ToolsDataTableColumnDef[] => {
    return [
      {
        name: 'empregadorNome',
        label: 'Nome',
        position: isDownSm ? undefined : 'sticky',
        left: 0,
      },
      ...dataFormatada.colunasEventos.map((evento) => ({
        name: evento,
        label: evento,
        width: 80,
        type: 'ativo' as any,
      })),
      {
        name: 'id',
        label: 'Ações',
        position: 'sticky',
        right: 0,
        width: 80,
        options: {
          customBodyRender(_, { rowIndex }) {
            const rowData = dataFormatada.dadosNormalizados[rowIndex]
            return (
              <DataTableCellActions.Root onClick={() => handleClickItem('page_view', rowData)}>
                <DataTableCellActions.Action icon={<Pageview />} />
              </DataTableCellActions.Root>
            )
          },
        },
      },
    ]
  }, [dataFormatada, handleClickItem, isDownSm])

  return (
    <ToolsDataTable
      columns={columns}
      data={dataFormatada.dadosNormalizados || []}
      isLoading={isLoading}
      isFetching={isFetching}
      cellHeaderWhiteSpaceNowrap
      cellWhiteSpaceNowrap
      disableAutoHeight={disableAutoHeight}
      tableBodyHeight={tableBodyHeight}
      onRowDoubleClick={(rowData) => handleClickItem('page_view', rowData)}
    />
  )
}
