import { useState } from 'react'

import * as yup from 'yup'

import { Typography } from '@material-ui/core'

import { ActionDialog, Stack } from '~/components'

import { useCopiarCofiguracoesLotacao } from '~/hooks/queries/ExportacaoLancamentoConfig/useCopiarCofiguracoesLotacao'

import { FPMovimentoTipoEnum } from '~/@types/enums/FPMovimentoTipoEnum'
import AutoCompleteLotacaoConfigConsulta from '~/components/AutoComplete/AutoCompleteLotacaoConfigConsulta'
import { AutoCompleteEstabelecimento } from '~/components/AutoComplete'
import useValidationErrors from '~/hooks/useValidationErrors'

const schema = yup.object().shape({
  estabelecimentoId: yup.string().required('Informe o Estabelecimento'),
  lotacaoOrigem: yup.string().required('Informe a Lotação de Origem'),
})

interface DialogCopyConfigsProps {
  onClose: () => void
  movimentoTipo: FPMovimentoTipoEnum
  lotacaoDestino: string
  modeloIntegracaoId: string
}

export default function DialogCopyConfigs({
  onClose,
  movimentoTipo,
  lotacaoDestino,
  modeloIntegracaoId,
}: DialogCopyConfigsProps) {
  const [data, setData] = useState({
    lotacaoOrigem: '',
    estabelecimentoId: '',
  })

  const { mutateAsync, isLoading: isSubmtting } = useCopiarCofiguracoesLotacao()

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    await mutateAsync({
      params: { movimentoTipo, lotacaoDestino, lotacaoOrigem: data.lotacaoOrigem },
    })
    onClose()
  }

  return (
    <ActionDialog
      title="Copiar Configurações"
      isOpen={true}
      onClose={onClose}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
      okLabel="Processar"
      onOkClick={handleValidate}
      isOkProcessing={isSubmtting}
    >
      <Stack>
        <Typography>
          Selecione o Estabelecimento e a Lotação para copiar as configurações.
        </Typography>

        <AutoCompleteEstabelecimento
          value={data.estabelecimentoId}
          onChange={(_: any, obj: any) =>
            setData((prev) => ({ ...prev, estabelecimentoId: obj?.id || '' }))
          }
          required
          validationErrors={validationErrors}
          name="estabelecimentoId"
        />

        <AutoCompleteLotacaoConfigConsulta
          modeloIntegracaoId={modeloIntegracaoId}
          estabelecimentoId={data.estabelecimentoId}
          label="Lotação de Origem"
          value={data.lotacaoOrigem}
          required
          validationErrors={validationErrors}
          name="lotacaoOrigem"
          onChange={(obj) => setData((prev) => ({ ...prev, lotacaoOrigem: obj?.id || '' }))}
          getOptionDisabled={(option: any) => option.id === lotacaoDestino}
        />
      </Stack>
    </ActionDialog>
  )
}
