import { ReactNode, createContext, useContext, useState } from 'react'

interface TransferDataContextData<T> {
  dataTransfer: T
  setDataTrasfer: (data: T) => void
}

interface TableRowsSelectableProps {
  children: ReactNode
}

export const TransferDataContext = createContext({} as TransferDataContextData<any>)

export default function TransferDataProvider<T>({ children }: TableRowsSelectableProps) {
  const [dataTransfer, setDataTransfer] = useState<T | null>(null)

  function handleSetData<T extends null>(_data: T) {
    setDataTransfer(_data)
  }

  return (
    <TransferDataContext.Provider
      value={{
        dataTransfer,
        setDataTrasfer: handleSetData,
      }}
    >
      {children}
    </TransferDataContext.Provider>
  )
}

export function useTransferDataContext<T>() {
  return useContext<TransferDataContextData<T>>(TransferDataContext)
}
