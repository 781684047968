import { CSSProperties } from 'react'

import clsx from 'clsx'

import { Box, makeStyles, CircularProgress } from '@material-ui/core'
import { useStepperContext } from '../StepperProvider'

const useStyles = makeStyles(() => ({
  isLoadingProgress: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '1',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: '0.6',
  },
}))

interface StepperContentProps {
  style?: CSSProperties
}

export default function StepperContent(props: StepperContentProps) {
  const { style } = props
  const { steps, stepCurrent, isLoading, disabled } = useStepperContext()
  const classes = useStyles()

  return (
    <>
      <Box
        className={clsx({
          [classes.disabled]: isLoading || disabled,
        })}
        style={style}
      >
        {steps[stepCurrent].children}
      </Box>
      {isLoading && (
        <Box className={classes.isLoadingProgress}>
          <CircularProgress size={70} />
        </Box>
      )}
    </>
  )
}
