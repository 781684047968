import { cloneElement, ReactElement } from 'react'
import moment from 'moment'
import { Button } from '~/components'
import usePopover from '~/hooks/usePopover'
import triggerStyles from './triggerStyles'
import { ArrowDropDown } from '@material-ui/icons'

interface TriggerProps {
  label: string
  children: ReactElement
}

export default function Trigger({ label, children }: TriggerProps) {
  const { open: openMenu, close, isOpen, anchorEl } = usePopover()

  function handleClick(event: any) {
    openMenu(event)
  }

  const classes = triggerStyles()
  const labelFormatted = () => {
    const [year, month] = label.split('/')
    const date = moment(`${year}-${month}-01`, 'YYYY-MM-DD')
    const formattedCurrentMonthName = date.format('MMMM')

    return `${
      formattedCurrentMonthName.charAt(0).toUpperCase() + formattedCurrentMonthName.slice(1)
    } de ${year}`
  }

  return (
    <>
      <Button
        size="medium"
        className={classes.button}
        endIcon={<ArrowDropDown />}
        onClick={handleClick}
      >
        {labelFormatted()}
      </Button>
      {cloneElement(children, {
        anchorEl,
        open: isOpen,
        onClose: close,
      })}
    </>
  )
}
