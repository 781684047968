import { useEffect, useState, memo } from 'react'

import moment from 'moment'

import { Box } from '@material-ui/core'

import { ActionDialog,  Checkbox, Stack, ToolsDataTable } from '~/components'

import useSessionStorage from '~/hooks/useSessionStorage'
import useAmbiente from '~/hooks/useAmbiente'
import { useObterAlertaFeriasVencidas } from '~/hooks/queries/RPFerias/useObterAlertaFeriasVencidas'

import { MUIDataTableColumnDef } from 'mui-datatables'

interface DialogAlertasFeriasProps {
  vinculoId: string
}

const DialogAlertasFerias = ({ vinculoId }: DialogAlertasFeriasProps) => {
  const [isOpen, setOpen] = useState(false)
  const [isNaoExibirAlertaMais, setNaoExibirAlertaMais] = useSessionStorage("isNaoExibirAlertaFerias", false)

  const { anoMes } = useAmbiente()
  const {
    data: _data,
    isLoading,
    isFetching,
  } = useObterAlertaFeriasVencidas({
    anoMes,
    vinculoId,
  })
  const data = _data || []

  useEffect(() => {
    if (_data && _data.length > 0 && !isNaoExibirAlertaMais) {
      setOpen(true)
    }
  }, [_data])

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dtPeriodoInicio',
      label: 'Período Início',
      options: {
        customBodyRender: (value) => moment(value).format('DD/MM/yyyy'),
      },
    },
    {
      name: 'dtPeriodoFim',
      label: 'Período Fim',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/yyyy') : ''),
      },
    },
    {
      name: 'dtLimiteConcessao',
      label: 'Limite Concessão',
      options: {
        customBodyRender: (value) => (value ? moment(value).format('DD/MM/yyyy') : ''),
      },
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
  ]

  return (
    <ActionDialog
      title="Alerta Férias Vencidas"
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
      customActions={
        <Stack p={1} orientation='horizontal' justifyContent='flex-end'>
          <Box>
            <Checkbox label="Não exibir este alerta novamente durante esta sessão." value={isNaoExibirAlertaMais} onChange={(_, checked) => setNaoExibirAlertaMais(checked)} />
          </Box>
        </Stack>}
    >
      <Box pb={2}>
        <ToolsDataTable
          data={data}
          columns={columns}
          isFetching={isFetching}
          isLoading={isLoading}
          disableAutoHeight
          pagination={false}
        />
      </Box>
    </ActionDialog>
  )
}

export default memo(DialogAlertasFerias)
