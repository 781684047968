import { useState, memo, useEffect } from 'react'

import * as yup from 'yup'
import { getDateMaxAnoMes, getDateMinAnoMes } from '~/utils/utils'

import { Box, Grid, Paper, Typography, useTheme } from '@material-ui/core'

import { Button, DatePickerNew, Finder, SimpleSelect, Stack } from '~/components'

import DialogTerminoContrato from './DialogTerminoContrato'
import DialogProrrogacoes from './DialogProrrogacoes'
import Table from './Table'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'

import {
  useObterFuncionarios,
  useClient,
} from '~/hooks/queries/ManutencaoContratoExperiencia/useObterFuncionarios'
import { useProcessarManutencaoContratoFuncionario } from '~/hooks/queries/ManutencaoContratoExperiencia/useProcessarManutencaoContratoFuncionario'

import {
  ContratoFuncionarioManutencaoEnum,
  ContratoFuncionarioManutencaoValues,
} from '~/@types/enums/ContratoFuncionarioManutencaoEnum'
import { ManutencaoContratoExperienciaFuncionarioDTO } from '~/hooks/queries/ManutencaoContratoExperiencia/interfaces/ManutencaoContratoExperienciaFuncionarioDTO'
import PageContainer from '~/components/PageContainer'

const MemoTable = memo(Table)

const schema = yup.object().shape({
  dtInicio: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Início')
    .nullable(),
  dtFim: yup.date().typeError('Informe uma data válida').required('Informe a Data Fim').nullable(),
})

export default function ManutencaoContratoExperiencia() {
  const { anoMes } = useAmbiente()

  const [dataForm, setDataForm] = useState({
    tipo: ContratoFuncionarioManutencaoEnum.Prorrogacao_0,
    dtInicio: getDateMinAnoMes(anoMes),
    dtFim: getDateMaxAnoMes(anoMes),
  })
  const [query, setQuery] = useState('')
  const [data, setData] = useState<ManutencaoContratoExperienciaFuncionarioDTO[]>([])

  const countItemsProcessar = data.filter((d) => d.dtAlteracao2206 !== null).length

  const theme = useTheme()

  const {
    data: _d,
    isLoading,
    isFetching,
    refetch,
  } = useObterFuncionarios({
    params: {
      dtInicio: dataForm.dtInicio,
      dtFim: dataForm.dtFim,
      tipoProcessamento: dataForm.tipo,
    },
  })

  useEffect(() => {
    setData(_d || [])
  }, [_d])

  const { mutateAsync: mutateAsyncProcess, isLoading: isSubmitting } =
    useProcessarManutencaoContratoFuncionario()
  const { handleRefetch } = useClient()

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: refetch,
    data: dataForm,
  })

  const {
    close: closeDialogProrrogacoes,
    data: dataDialogProrrogacoes,
    isOpen: isOpenDialogProrrogacoes,
    open: openDialogProrrogacoes,
  } = useDialog<ManutencaoContratoExperienciaFuncionarioDTO[]>([])

  const {
    close: closeDialogTerminoContrato,
    data: dataDialogTerminoContrato,
    isOpen: isOpenDialogTerminoContrato,
    open: openDialogTerminoContrato,
  } = useDialog({
    funcionarioId: '',
    estabelecimentoId: '',
  })

  async function handleProcess() {
    const funcionarios = data.filter((d) => d.dtAlteracao2206 !== null)
    const dataResponse = await mutateAsyncProcess({
      data: {
        tipo: dataForm.tipo,
        funcionarios,
      },
    })
    if (dataForm.tipo === ContratoFuncionarioManutencaoEnum.Prorrogacao_0) {
      openDialogProrrogacoes(dataResponse)
    }
  }

  function handleCloseDialogTerminoContrato() {
    handleRefetch()
    closeDialogTerminoContrato()
  }

  return (
    <PageContainer.Root>
      <PageContainer.Header title="Manutenção do Contrato de Experiência">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageContainer.Header>

      <Stack component={Paper} p={1} mb={0.5} alignItems="end">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <DatePickerNew
              label="Data de Início"
              value={dataForm.dtInicio || null}
              validationErrors={validationErrors}
              name="dtInicio"
              onChange={(date) => setDataForm((prev) => ({ ...prev, dtInicio: date || '' }))}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3}>
            <DatePickerNew
              label="Data Fim"
              value={dataForm.dtFim || null}
              validationErrors={validationErrors}
              name="dtFim"
              onChange={(date) => setDataForm((prev) => ({ ...prev, dtFim: date || '' }))}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={5}>
            <SimpleSelect
              label="Tipo de Processamento"
              options={ContratoFuncionarioManutencaoValues}
              optionId="value"
              renderOption={(d) => d.name}
              value={dataForm.tipo}
              onChange={(_, value) =>
                setDataForm((prev) => ({
                  ...prev,
                  tipo: value || ContratoFuncionarioManutencaoEnum.Prorrogacao_0,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1}>
            <Button
              fullWidth
              size="medium"
              isLoading={isLoading || isFetching}
              variant="contained"
              onClick={handleValidate}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Stack>

      <MemoTable
        data={data}
        onChangeData={setData}
        isLoading={isLoading}
        isFetching={isFetching}
        query={query}
        tipoProcessamento={dataForm.tipo}
        onPrint={openDialogTerminoContrato}
      />

      {dataForm.tipo !== ContratoFuncionarioManutencaoEnum.Rescisao_2 && (
        <Stack
          orientation="horizontal"
          component={Paper}
          p={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            style={{
              display: 'flex',
              gap: theme.spacing(1),
              justifyContent: 'center',
              alignItems: 'center',
              paddingRight: theme.spacing(1),
              paddingLeft: theme.spacing(1),
            }}
          >
            Registros alterados:
            <Box fontWeight="bolder" fontSize="12pt" color="#2E8BC0">
              {countItemsProcessar}
            </Box>
          </Typography>

          <Button
            onClick={handleProcess}
            variant="contained"
            disabled={countItemsProcessar > 0 ? false : true}
            isLoading={isSubmitting}
          >
            Processar
          </Button>
        </Stack>
      )}

      {isOpenDialogProrrogacoes && (
        <DialogProrrogacoes
          isOpen={isOpenDialogProrrogacoes}
          onClose={closeDialogProrrogacoes}
          data={dataDialogProrrogacoes}
        />
      )}

      {isOpenDialogTerminoContrato && (
        <DialogTerminoContrato
          isOpen={isOpenDialogTerminoContrato}
          onClose={handleCloseDialogTerminoContrato}
          funcionarioId={dataDialogTerminoContrato.funcionarioId}
          estabelecimentoId={dataDialogTerminoContrato.estabelecimentoId}
        />
      )}
    </PageContainer.Root>
  )
}
