import { useState } from 'react'

import { Box } from '@material-ui/core'

import { Stack } from '~/components'

import CardMenu from '../CardMenu'

import { VinculoMenuDataDTO } from '~/hooks/queries/RP/VinculoMenuDataDTO'
import { VinculoTipoEnum } from '~/@types/enums/VinculoTipoEnum'

interface CardItemProps {
  vinculo: VinculoMenuDataDTO
  agrupamentoId: string | VinculoTipoEnum
  onContextMenuItemClick: any
}

export default function CardVinculo({
  vinculo,
  agrupamentoId,
  onContextMenuItemClick,
}: CardItemProps) {
  const [isHover, setHover] = useState(false)

  return (
    <Box onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} pt={0.5} pb={0.5}>
      <Stack orientation="horizontal" justifyContent="space-between" alignItems="center">
        <Box
          title={vinculo.vinculoNome}
          fontSize="9.2pt"
          // fontWeight="bold"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {vinculo.vinculoNome}
        </Box>
        <CardMenu
          vinculo={vinculo}
          agrupamentoId={agrupamentoId}
          showActions={isHover}
          onContextMenuItemClick={onContextMenuItemClick}
        />
      </Stack>

      {/* <Stack gap={0}>
        <Stack
          orientation="horizontal"
          alignItems="center"
          gap={0.5}
          fontSize="9pt"
          color={theme.palette.grey[700]}
        >
          <CalendarIcon fontSize="small" color="action" />
          <Stack orientation="horizontal" justifyContent="space-between">
            <Box title={'Admissão: ' + formatSimpleDate(vinculo.dtAdmissao)}>
              {formatSimpleDate(vinculo.dtAdmissao)}
            </Box>
            {isRescisao && (
              <Box title={'Rescisão: ' + formatSimpleDate(vinculo.dtRescisao)}>
                {formatSimpleDate(vinculo.dtRescisao)}
              </Box>
            )}
          </Stack>
        </Stack>

        <Stack
          orientation="horizontal"
          alignItems="center"
          title={'Cargo: ' + vinculo.vinculoCargo}
          gap={0.5}
          fontSize="9pt"
          color={theme.palette.grey[700]}
        >
          <WorkIcon fontSize="small" color="action" />
          <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {vinculo.vinculoCargo}
          </Box>
        </Stack>

        <Stack
          orientation="horizontal"
          alignItems="center"
          title={'Lotação: ' + vinculo.vinculoLotacao}
          gap={0.5}
          fontSize="9pt"
          color={theme.palette.grey[700]}
        >
          <GroupIcon fontSize="small" color="action" />
          <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {vinculo.vinculoLotacao}
          </Box>
        </Stack>
      </Stack> */}
    </Box>
  )
}
