import { useQuery, useQueryClient } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ManutencaoContratoExperienciaFuncionarioDTO } from './interfaces/ManutencaoContratoExperienciaFuncionarioDTO'

import { ContratoFuncionarioManutencaoEnum } from '~/@types/enums/ContratoFuncionarioManutencaoEnum'

const query = '/ManutencaoContratoExperiencia/ObterFuncionarios'

interface RequestProps {
  params: {
    dtInicio: string
    dtFim: string
    tipoProcessamento: ContratoFuncionarioManutencaoEnum
  }
}

export function useObterFuncionarios({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: ManutencaoContratoExperienciaFuncionarioDTO[] }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
    enabled: false,
  })
}

export function useClient() {
  const queryClient = useQueryClient()

  function handleReset() {
    queryClient.resetQueries(query)
  }

  function handleRefetch() {
    queryClient.refetchQueries(query)
  }

  return { handleReset, handleRefetch }
}
