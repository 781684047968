import { useEffect } from 'react'

import { Button, DatePickerNew } from '~/components'
import HStack from '~/components/HStack'

import useAmbiente from '~/hooks/useAmbiente'
import useForm from '~/hooks/useForm'

import { getDateMaxAnoMes, getDateMinAnoMes } from '~/utils/utils'

export interface FilterStateProps {
  dtInicio: string
  dtFim: string
  query: string
}

interface FilterProps {
  onChange: (filters: FilterStateProps) => void
}

export default function Filter({ onChange }: FilterProps) {
  const { anoMes } = useAmbiente()

  const { data, changeValue } = useForm<FilterStateProps>({
    defaultData: {
      dtInicio: getDateMinAnoMes(anoMes),
      dtFim: getDateMaxAnoMes(anoMes),
      query: '',
    },
  })

  function handleClick() {
    if (data) onChange(data)
  }

  useEffect(() => {
    if (data) {
      onChange(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HStack gap={8}>
      <DatePickerNew
        name="dtInicio"
        label="Data de Início"
        value={data?.dtInicio || null}
        onChange={(date) => changeValue('dtInicio', date)}
      />
      <DatePickerNew
        name="dtFim"
        label="Data Final"
        value={data?.dtFim || null}
        onChange={(date) => changeValue('dtFim', date)}
      />

      <Button variant="contained" onClick={handleClick}>
        Buscar
      </Button>
    </HStack>
  )
}
