export const grupoEventosESocialMapping = {
  1000: 'empregador',
  1005: 'tabelas',
  1010: 'tabelas',
  1020: 'tabelas',
  1070: 'tabelas',
  1200: 'periodico',
  1210: 'periodico',
  1260: 'periodico',
  1270: 'periodico',
  1280: 'periodico',
  1290: 'periodico',
  1299: 'fechamento',
  2190: 'nao-periodico',
  2200: 'nao-periodico',
  2205: 'nao-periodico',
  2206: 'nao-periodico',
  2210: 'nao-periodico',
  2220: 'nao-periodico',
  2221: 'nao-periodico',
  2230: 'nao-periodico',
  2241: 'nao-periodico',
  2298: 'nao-periodico',
  2299: 'nao-periodico',
  2300: 'nao-periodico',
  2306: 'nao-periodico',
  2399: 'nao-periodico',
  2500: 'nao-periodico',
  2501: 'nao-periodico',
  0: 'Nenhum Evento',
} as const
