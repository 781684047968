import { useEffect, useState } from 'react'

import { Box, CircularProgress, Grid, LinearProgress } from '@material-ui/core'

import { Button, Checkbox, ContentDivider, Stack } from '~/components'
import RowConta from '~/components/RowConta'
import DialogCopyConfigs from './DialogCopyConfigs'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useAdicionar } from '~/hooks/queries/LancamentoLiquidacaoConfig/useAdicionar'
import { useAlterar } from '~/hooks/queries/LancamentoLiquidacaoConfig/useAlterar'

import { LancamentoLiquidacaoConfig } from '~/hooks/queries/LancamentoLiquidacaoConfig/dtos/LancamentoLiquidacaoConfig'
import { ModeloIntegracao } from '~/hooks/queries/ModeloIntegracao/dtos/ModeloIntegracao'
import { FileCopy } from '@material-ui/icons'

const startData = {
  pContaMensalNormalDebito: '',
  pContaMensalNormalCredito: '',
  pContaFeriasNormalDebito: '',
  pContaFeriasNormalCredito: '',
  pContaRescisaoNormalDebito: '',
  pContaRescisaoNormalCredito: '',
  pContaProLaboreNormalDebito: '',
  pContaProLaboreNormalCredito: '',
  pContaRPANormalDebito: '',
  pContaRPANormalCredito: '',
  pContaDecimoTerceiroNormalDebito: '',
  pContaDecimoTerceiroNormalCredito: '',
} as LancamentoLiquidacaoConfig

interface ConfiguracoesGeraisProps {
  modeloIntegracao: ModeloIntegracao
  data?: LancamentoLiquidacaoConfig | null
  isFetching: boolean
  isLoading: boolean
}

export default function ConfiguracoesGerais({
  modeloIntegracao,
  data: _d,
  isFetching,
  isLoading,
}: ConfiguracoesGeraisProps) {
  const [data, setData] = useState<LancamentoLiquidacaoConfig>(startData)

  const { mutateAsync: mutateAsyncAdicionar, isLoading: isLoadingAdicionar } = useAdicionar()
  const { mutateAsync: mutateAsyncAlterar, isLoading: isLoadingAlterar } = useAlterar()

  const isSubmitting = isLoadingAdicionar || isLoadingAlterar

  const { estabelecimento } = useAmbiente()

  const {
    close: closeDialogCopyConfigs,
    data: dataDialogCopyConfigs,
    isOpen: isOpenDialogCopyConfigs,
    open: openDialogCopyConfigs,
  } = useDialog('')

  useEffect(() => {
    setData(_d || startData)
  }, [_d])

  function handleCancel() {
    setData(_d || ({} as LancamentoLiquidacaoConfig))
  }

  async function handleSave() {
    if (_d) {
      await mutateAsyncAlterar({
        data,
        params: {
          id: data.id,
        },
      })
    } else {
      await mutateAsyncAdicionar({
        data: {
          ...data,
          estabelecimentoId: estabelecimento.id,
          modeloIntegracaoId: modeloIntegracao.id,
        },
      })
    }
  }

  return (
    <Stack position="relative" height="100%" p={0} px={2}>
      <Stack alignItems="flex-end">
        <Button
          variant="contained"
          endIcon={<FileCopy />}
          onClick={() => openDialogCopyConfigs(modeloIntegracao?.id || '')}
        >
          Copiar Configurações
        </Button>
      </Stack>

      <Box flex={1} overflow="auto" style={{ overflowX: 'hidden' }}>
        <Box height={4}>{isFetching && <LinearProgress />}</Box>

        {isLoading ? (
          <Box
            position="absolute"
            right={0}
            left={0}
            top={0}
            bottom={0}
            zIndex={999}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={80} />
          </Box>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ContentDivider title="Parâmetros Gerais" />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Checkbox
                label="Utiliza liquidação mensal"
                value={data.utilizaLiquidacaoMensal}
                onChange={(e, value) => {
                  const utilizaLiquidacaoMensal = value
                  setData((prev) => ({
                    ...prev,
                    utilizaLiquidacaoMensal,
                    utilizaLiquidacaoIndividualizada: false,
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Checkbox
                label="Realiza provisão de férias"
                value={data.provisaoFerias}
                onChange={(e, value) => {
                  const provisaoFerias = value
                  setData((prev) => ({ ...prev, provisaoFerias }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Checkbox
                label="Décimo terceiro 1°parcela"
                value={data.decimoTerceiroPrimeiraParcela}
                onChange={(e, value) => {
                  const decimoTerceiroPrimeiraParcela = value
                  setData((prev) => ({ ...prev, decimoTerceiroPrimeiraParcela }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Checkbox
                label="Integrar guia rescisória"
                value={data.utilizaIntegracaoFGTSRescisorio}
                onChange={(e, value) => {
                  const utilizaIntegracaoFGTSRescisorio = value
                  setData((prev) => ({ ...prev, utilizaIntegracaoFGTSRescisorio }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Checkbox
                label="Utiliza liquidação individualizada"
                value={data.utilizaLiquidacaoIndividualizada}
                disabled={data.utilizaLiquidacaoMensal ? false : true}
                onChange={(e, value) => {
                  const utilizaLiquidacaoIndividualizada = value
                  setData((prev) => ({ ...prev, utilizaLiquidacaoIndividualizada }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Checkbox
                label="Realiza provisão de 13°salário"
                value={data.provisaoDecimoTerceiro}
                onChange={(e, value) => {
                  const provisaoDecimoTerceiro = value
                  setData((prev) => ({ ...prev, provisaoDecimoTerceiro }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Checkbox
                label="Décimo terceiro 2°parcela"
                value={data.decimoTerceiroSegundaParcela}
                onChange={(e, value) => {
                  const decimoTerceiroSegundaParcela = value
                  setData((prev) => ({ ...prev, decimoTerceiroSegundaParcela }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Checkbox
                label="Utiliza Lançamento Simplificado"
                value={data.utilizaLancamentoSimplificado}
                onChange={(e, value) => {
                  const utilizaLancamentoSimplificado = value
                  setData((prev) => ({ ...prev, utilizaLancamentoSimplificado }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <RowConta
                label="Recibo Mensal"
                mask={modeloIntegracao.mascaraConta}
                valueDebito={data.pContaMensalNormalDebito}
                onChangeDebito={(value) => {
                  const pContaMensalNormalDebito = value
                  setData((prev) => ({ ...prev, pContaMensalNormalDebito }))
                }}
                valueCredito={data.pContaMensalNormalCredito}
                onChangeCredito={(value) => {
                  const pContaMensalNormalCredito = value
                  setData((prev) => ({ ...prev, pContaMensalNormalCredito }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <RowConta
                label="Recibo de Férias"
                mask={modeloIntegracao.mascaraConta}
                valueDebito={data.pContaFeriasNormalDebito}
                onChangeDebito={(value) => {
                  const pContaFeriasNormalDebito = value
                  setData((prev) => ({ ...prev, pContaFeriasNormalDebito }))
                }}
                valueCredito={data.pContaFeriasNormalCredito}
                onChangeCredito={(value) => {
                  const pContaFeriasNormalCredito = value
                  setData((prev) => ({ ...prev, pContaFeriasNormalCredito }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <RowConta
                label="Rescisão"
                mask={modeloIntegracao.mascaraConta}
                valueDebito={data.pContaRescisaoNormalDebito}
                onChangeDebito={(value) => {
                  const pContaRescisaoNormalDebito = value
                  setData((prev) => ({ ...prev, pContaRescisaoNormalDebito }))
                }}
                valueCredito={data.pContaRescisaoNormalCredito}
                onChangeCredito={(value) => {
                  const pContaRescisaoNormalCredito = value
                  setData((prev) => ({ ...prev, pContaRescisaoNormalCredito }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <RowConta
                label="Pró-Labore"
                mask={modeloIntegracao.mascaraConta}
                valueDebito={data.pContaProLaboreNormalDebito}
                onChangeDebito={(value) => {
                  const pContaProLaboreNormalDebito = value
                  setData((prev) => ({ ...prev, pContaProLaboreNormalDebito }))
                }}
                valueCredito={data.pContaProLaboreNormalCredito}
                onChangeCredito={(value) => {
                  const pContaProLaboreNormalCredito = value
                  setData((prev) => ({ ...prev, pContaProLaboreNormalCredito }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <RowConta
                label="Autônomo"
                mask={modeloIntegracao.mascaraConta}
                valueDebito={data.pContaRPANormalDebito}
                onChangeDebito={(value) => {
                  const pContaRPANormalDebito = value
                  setData((prev) => ({ ...prev, pContaRPANormalDebito }))
                }}
                valueCredito={data.pContaRPANormalCredito}
                onChangeCredito={(value) => {
                  const pContaRPANormalCredito = value
                  setData((prev) => ({ ...prev, pContaRPANormalCredito }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <RowConta
                label="Décimo Terceiro"
                mask={modeloIntegracao.mascaraConta}
                valueDebito={data.pContaDecimoTerceiroNormalDebito}
                onChangeDebito={(value) => {
                  const pContaDecimoTerceiroNormalDebito = value
                  setData((prev) => ({ ...prev, pContaDecimoTerceiroNormalDebito }))
                }}
                valueCredito={data.pContaDecimoTerceiroNormalCredito}
                onChangeCredito={(value) => {
                  const pContaDecimoTerceiroNormalCredito = value
                  setData((prev) => ({ ...prev, pContaDecimoTerceiroNormalCredito }))
                }}
              />
            </Grid>
          </Grid>
        )}
      </Box>

      <Stack orientation="horizontal" justifyContent="flex-end">
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button variant="contained" onClick={handleSave} isLoading={isSubmitting}>
          Salvar
        </Button>
      </Stack>

      {isOpenDialogCopyConfigs && (
        <DialogCopyConfigs
          modeloIntegracaoId={dataDialogCopyConfigs}
          onClose={closeDialogCopyConfigs}
        />
      )}
    </Stack>
  )
}
