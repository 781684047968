import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { EventoAutomaticoEstabelecimento } from './interfaces/EventoAutomaticoEstabelecimento'

const query = '/EventoAutomaticoEstabelecimento/ObterPorId'

interface RequestProps {
  params: { id: string | null }
}

export function useObterPorId({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    if (!params.id) return
    const response = await api.get<{ data: EventoAutomaticoEstabelecimento }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
