import { Box, BoxProps } from '@material-ui/core'

export interface HStackProps extends Omit<BoxProps, 'gridGap'> {
  gap?: number
  backgroundColor?: string
}

export default function HStack({ gap, backgroundColor, ...rest }: HStackProps) {
  return <Box display="flex" style={{ backgroundColor }} gridGap={gap} {...rest} />
}
