import { useQuery } from 'react-query'

import api from '~/services/api-log'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ConsultaPessoalResponse } from './@types/ConsultaPessoalResponse'
import { ConsultaPessoalFilters } from './@types/ConsultaPessoalFilters'
import { ResponseType } from '~/@types/ResponseType'
import { HeaderRequestType } from '~/@types/HeaderRequestType'

export const query = '/Consulta/Pessoal'

interface RequestProps {
  params: ConsultaPessoalFilters
  headers: HeaderRequestType
}

export function useConsultaPessoal({ params, headers }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<ResponseType<ConsultaPessoalResponse[]>>(query, {
      params,
      headers,
    })
    return response.data
  }

  return useQuery([query, headers], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
