import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { notifySuccess } from '~/utils/notification'

interface RequestProps {
  data: string[]
  params: {
    competencia: string
  }
}

export function useEnviarVariosPorEmailEmpregadores() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data, params }: RequestProps) {
    await api.post('/FGTSDigital/EnviarVariosPorEmailEmpregadores', data, {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('As guias foram enviadas com sucesso para os empregadores')
    },
    onError: dialogNotification.extractErrors,
  })
}
