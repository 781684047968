import { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Box, CircularProgress, Grid, LinearProgress } from '@material-ui/core'

import { AutoCompleteEvento } from '~/components/AutoComplete'
import { ActionDialog, CurrencyTextField, DatePickerNew, TextField } from '~/components'

import { useCriar } from '~/hooks/queries/EventoAutomaticoEstabelecimento/useCriar'
import { useAtualizar } from '~/hooks/queries/EventoAutomaticoEstabelecimento/useAtualizar'
import { useObterPorId } from '~/hooks/queries/EventoAutomaticoEstabelecimento/useObterPorId'

import useValidationErrors from '~/hooks/useValidationErrors'

import { EventoAutomaticoEstabelecimento } from '~/hooks/queries/EventoAutomaticoEstabelecimento/interfaces/EventoAutomaticoEstabelecimento'

const schema = yup.object().shape({
  eventoId: yup.string().required('Informe Evento').nullable(),
  dtInicio: yup.string().required('Informe Data Início').nullable(),
})

interface FormProps {
  dataInitial: { estabelecimentoId: string }
  id: string | null
  onClose: () => void
  isOpen: boolean
}

export default function Form(props: FormProps) {
  const { dataInitial, id, isOpen, onClose } = props

  const [data, setData] = useState<EventoAutomaticoEstabelecimento>(
    {} as EventoAutomaticoEstabelecimento,
  )

  const {
    data: _d,
    isFetching,
    isLoading,
  } = useObterPorId({
    params: {
      id,
    },
  })
  const { mutateAsync: mutateAsyncCriar, isLoading: isLoadingCriar } = useCriar()
  const { mutateAsync: mutateAsyncAtualizar, isLoading: isLoadingAtualizar } = useAtualizar()

  const isSubmitting = isLoadingCriar || isLoadingAtualizar

  useEffect(() => {
    if (_d) {
      setData(_d)
    }
  }, [_d])

  const { handleValidate, validationErrors } = useValidationErrors({
    data,
    schema,
    handleSubmit,
  })

  async function handleSubmit() {
    if (id) {
      await mutateAsyncAtualizar({
        data,
        params: {
          id,
        },
      })
    } else {
      await mutateAsyncCriar({ data: { ...data, ...dataInitial } })
    }
    onClose()
  }

  return (
    <ActionDialog
      isOpen={isOpen}
      title="Cadastro Evento Automático Estabelecimento"
      onClose={onClose}
      onCancelClick={onClose}
      onOkClick={handleValidate}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
    >
      {isLoading ? (
        <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Box height={4}>{isFetching && <LinearProgress />}</Box>
          <Grid container spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <AutoCompleteEvento
                label="Evento"
                required
                validationErrors={validationErrors}
                name="eventoId"
                onChange={(e: FixLater, evento: FixLater) => {
                  const eventoId = evento?.id || null
                  setData((prev) => ({ ...prev, eventoId }))
                }}
                value={data.eventoId || null}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <DatePickerNew
                label="Data Início"
                size="small"
                value={data.dtInicio || null}
                required
                validationErrors={validationErrors}
                name="dtInicio"
                onChange={(date) => {
                  const dtInicio = date
                  setData((prev) => ({
                    ...prev,
                    dtInicio,
                  }))
                }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <DatePickerNew
                label="Data Fim"
                size="small"
                value={data.dtFim || null}
                onChange={(date) => {
                  const dtFim = date
                  setData((prev) => ({
                    ...prev,
                    dtFim,
                  }))
                }}
              />
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <CurrencyTextField
                label="Quantidade"
                fullWidth
                variant="outlined"
                size="small"
                value={data?.quantidade || ''}
                onChange={(e, value) => {
                  const quantidade = value || 0
                  setData((prev) => ({ ...prev, quantidade }))
                }}
              />
            </Grid>

            <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
              <CurrencyTextField
                label="Valor Evento"
                fullWidth
                value={data?.vrEvento || ''}
                variant="outlined"
                size="small"
                onChange={(e, value) => {
                  const vrEvento = value || 0
                  setData((prev) => ({ ...prev, vrEvento }))
                }}
              />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                label="Observação"
                value={data.observacao || ''}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => {
                  const observacao = e.target.value
                  setData((prev) => ({ ...prev, observacao }))
                }}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </ActionDialog>
  )
}
