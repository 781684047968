import { Box, makeStyles } from '@material-ui/core'

import Icon from './Icon'
import { VinculoFaltasTipoDiaEnum } from '~/@types/enums/VinculoFaltasTipoDiaEnum'

function changeTitle(dateType: VinculoFaltasTipoDiaEnum) {
  switch (dateType) {
    case VinculoFaltasTipoDiaEnum.Falta:
      return 'Falta'
    case VinculoFaltasTipoDiaEnum.Atraso:
      return 'Atraso'
    case VinculoFaltasTipoDiaEnum.DSRDescontado:
      return 'DSR Perdido'
    case VinculoFaltasTipoDiaEnum.PercaSabadoCompensado:
      return 'Sábado Perdido'
    case VinculoFaltasTipoDiaEnum.Ferias:
      return 'Afastamento Férias'
    case VinculoFaltasTipoDiaEnum.Afastado:
      return 'Afastado'
    case VinculoFaltasTipoDiaEnum.DescansoSemanal:
      return 'Descanso'
    case VinculoFaltasTipoDiaEnum.DiaNaoTrabalha:
      return 'Não Trabalha'
    default:
      return 'Título'
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'ghostwhite',
    },
  },
  title: {
    display: 'flex',
    gridGap: theme.spacing(0.5),
    justifyContent: 'center',
    alignItems: 'center',

    fontWeight: 'bold',
    color: '#666',
  },
}))

interface CalendarDayProps {
  dateType: VinculoFaltasTipoDiaEnum
  onClick: () => void
}

export default function CalendarDay({ dateType, onClick }: CalendarDayProps) {
  const classes = useStyles()

  return (
    <Box className={classes.root} onClick={onClick}>
      <Box className={classes.title}>
        <Icon dateType={dateType} />
        <Box>{changeTitle(dateType)}</Box>
      </Box>
    </Box>
  )
}
