import { useEffect, useMemo, useState } from 'react'

import * as yup from 'yup'
import moment from 'moment'
import { isCPF } from 'brazilian-values'

import { Grid, Container as MUIContainer, Paper, makeStyles } from '@material-ui/core'

import {
  PageHeader,
  Container,
  ButtonBox,
  Button,
  DatePickerNew,
  NumeroInscricaoTextField,
  PDFViewer,
  AnoMesTextField,
  Stack,
} from '~/components'
import { AutoCompleteEmpregador, AutoCompleteSociosResponsaveis } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useDIRFGerarArquivo } from '~/hooks/queries/DIRF/useDIRFGerarArquivo'

import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'

const schemaRelatorio = yup.object().shape({
  fatoGeradorInicio: yup.string().required('Informe o Fato Gerador Início'),
  fatoGeradorFim: yup.string().required('Informe o Fato Gerador Fim'),
})

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))

interface DataProps {
  empregadorId: string | null
  anoBase: number | null
  socioResponsavelId: string | null
  cpfResponsavelEmpresa: string | null
  cpfResponsavelArquivo: string | null
}

const schema = yup.object().shape({
  empregadorId: yup.string().required('Informe o Empregador').nullable(),
  anoBase: yup.number().required('Informe o Ano Base').nullable(),
  cpfResponsavelEmpresa: yup
    .string()
    .required('Informe o CPF do Sócio Diretor')
    .test({
      message: 'Informe um CPF válido',
      test: (cpf) => isCPF(cpf),
    })
    .nullable(),
  cpfResponsavelArquivo: yup
    .string()
    .required('Informe o CPF do Responsável pelo Arquivo')
    .test({
      message: 'Informe um CPF válido',
      test: (cpf) => isCPF(cpf),
    })
    .nullable(),
})

export default function DIRF() {
  const { empregador, ano } = useAmbiente()
  const [dataRelatorio, setDataRelatorio] = useState({
    fatoGeradorInicio: '',
    fatoGeradorFim: '',
  })

  const dataInitial: DataProps = useMemo(
    () => ({
      empregadorId: empregador.id,
      anoBase: ano,
      socioResponsavelId: null,
      cpfResponsavelEmpresa: null,
      cpfResponsavelArquivo: null,
    }),
    [empregador, ano],
  )

  const [data, setData] = useState<DataProps>(dataInitial)

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog({
    params: { fatoGeradorInicio: '', fatoGeradorFim: '' },
  })

  const { mutateAsync, isLoading } = useDIRFGerarArquivo()
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: onSubmit,
    data,
  })
  const classes = useStyles()

  const { validationErrors: validationErrorsRelatorio, handleValidate: handleValidateRelatorio } =
    useValidationErrors({
      schema: schemaRelatorio,
      handleSubmit: handlePrint,
      data: dataRelatorio,
    })

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      ...dataInitial,
      cpfResponsavelArquivo: prev.cpfResponsavelArquivo || '',
    }))
  }, [dataInitial])

  function onReset() {
    setData(dataInitial)
  }

  async function onSubmit() {
    await mutateAsync({
      empregadorId: data.empregadorId || '',
      anoBase: data.anoBase || 0,
      cpfResponsavelEmpresa: data.cpfResponsavelEmpresa || '',
      cpfResponsavelArquivo: data.cpfResponsavelArquivo || '',
    })
  }

  function handlePrint() {
    openPDFViewer({
      params: {
        fatoGeradorInicio: dataRelatorio.fatoGeradorInicio,
        fatoGeradorFim: dataRelatorio.fatoGeradorFim,
      },
    })
  }

  return (
    <Container>
      <PageHeader title="DIRF" />
      <MUIContainer maxWidth="sm">
        <Paper className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutoCompleteEmpregador
                required
                validationErrors={validationErrors}
                name="empregadorId"
                value={data.empregadorId}
                onChange={(_, obj) => {
                  const empregadorId = obj?.id || null
                  setData((prev) => ({
                    ...prev,
                    empregadorId: empregadorId,
                    cpfResponsavelEmpresa: null,
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <DatePickerNew
                required
                validationErrors={validationErrors}
                name="anoBase"
                format="yyyy"
                views={['year']}
                label="Ano Base"
                value={data.anoBase ? moment(data.anoBase, 'yyyy').format('yyyy-MM-DD') : null}
                onChange={(date, dateMoment) => {
                  const anoBase = dateMoment ? parseInt(dateMoment.format('yyyy')) : null
                  setData((prev) => ({
                    ...prev,
                    anoBase,
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <AutoCompleteSociosResponsaveis
                empregadorId={data.empregadorId}
                value={data.socioResponsavelId}
                onChange={(socioResponsavel) => {
                  const socioResponsavelId = socioResponsavel?.id || null
                  setData((prev) => ({
                    ...prev,
                    socioResponsavelId,
                    cpfResponsavelEmpresa: socioResponsavel?.pessoaFisica?.nrInscricao || '',
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <NumeroInscricaoTextField
                required
                validationErrors={validationErrors}
                typeMask={TipoInscricaoEnum.CPF_2}
                name="cpfResponsavelEmpresa"
                label="CPF do Sócio Diretor"
                value={data.cpfResponsavelEmpresa || ''}
                onChange={(e, value) => {
                  const cpfResponsavelEmpresa = value || null
                  setData((prev) => ({
                    ...prev,
                    cpfResponsavelEmpresa,
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <NumeroInscricaoTextField
                required
                validationErrors={validationErrors}
                typeMask={TipoInscricaoEnum.CPF_2}
                name="cpfResponsavelArquivo"
                label="CPF do Responsável pelo Arquivo"
                value={data.cpfResponsavelArquivo || ''}
                onChange={(e, value) => {
                  const cpfResponsavelArquivo = value || null
                  setData((prev) => ({
                    ...prev,
                    cpfResponsavelArquivo,
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <ButtonBox>
                <Button onClick={onReset}>Cancelar</Button>
                <Button isLoading={isLoading} onClick={handleValidate} variant="contained">
                  Gerar DIRF
                </Button>
              </ButtonBox>
            </Grid>
          </Grid>
        </Paper>
      </MUIContainer>
      <MUIContainer maxWidth="sm" style={{ marginTop: 8 }}>
        <Stack component={Paper} p={2}>
          <AnoMesTextField
            label="Fato Gerador Início"
            required
            name="fatoGeradorInicio"
            validationErrors={validationErrorsRelatorio}
            value={dataRelatorio.fatoGeradorInicio}
            onChange={(e) => {
              const fatoGeradorInicio = e.target.value.replace('/', '')
              setDataRelatorio((prev) => ({
                ...prev,
                fatoGeradorInicio,
              }))
            }}
          />

          <AnoMesTextField
            label="Fato Gerador Fim"
            required
            name="fatoGeradorFim"
            validationErrors={validationErrorsRelatorio}
            value={dataRelatorio.fatoGeradorFim}
            onChange={(e) => {
              const fatoGeradorFim = e.target.value.replace('/', '')
              setDataRelatorio((prev) => ({
                ...prev,
                fatoGeradorFim,
              }))
            }}
          />
          <Stack alignItems="flex-end">
            <Button variant="contained" onClick={handleValidateRelatorio}>
              Imprimir Relatório
            </Button>
          </Stack>
        </Stack>
      </MUIContainer>

      {isOpenPDFViewer && (
        <PDFViewer
          isOpen={isOpenPDFViewer}
          onClose={closePDFViewer}
          reportKey="Dirf"
          title="DIRF"
          axiosConfig={{
            method: 'post',
            url: '/Dirf/ObterRelatorio',
            params: dataPDFViewer.params,
          }}
        />
      )}
    </Container>
  )
}
