import { useState, useEffect, useCallback } from 'react'

import { Grid, Box, MenuItem, Tooltip, IconButton, Zoom, useTheme } from '@material-ui/core'
import { FileCopy as FileCopyIcon } from '@material-ui/icons'
import { TextField, ButtonBox, ContentDivider, PageHeader } from 'mio-library-ui'

import {
  CollapseContent,
  DatePicker,
  UFSelect,
  NumeroInscricaoTextField,
  CEPTextField,
  ContainerForm,
  Checkbox,
  TelefoneTextField,
  MaskedTextField,
  Button,
} from '~/components'
import {
  AutoCompleteMemoryTable,
  AutoCompletePais,
  AutoCompleteCidade,
  AutoCompleteLogradouro,
  MUIAutoComplete,
} from '~/components/AutoComplete'

import {
  FiFolderPlus as CollapseOpenIcon,
  FiFolderMinus as CollapseCloseIcon,
} from 'react-icons/fi'

import * as yup from 'yup'

import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import { useHistory } from 'react-router-dom'
import api from '~/services/api-pessoal'
import { imigranteCondicaoTrabalhadorValues } from '~/values/imigranteCondicaoTrabalhadorValues'
import { copyToClipboard } from '~/hooks/useUtils'

const schema = yup.object().shape({
  dtNascimento: yup.string().required('Informe a Data Nascimento').nullable(),
  sexo: yup.string().required('Informe o Sexo').nullable(),
  grauInstrucao: yup.string().required('Informe o Grau Instrução').nullable(),
  estadoCivil: yup.string().required('Informe o Estado Cívil').nullable(),
  racaCor: yup.string().required('Informe a Cor').nullable(),
  naturalidadeCidade: yup.string().required('Informe a Naturalidade').nullable(),
  nacionalidadePaisId: yup.string().required('Informe a Nacionalidade'),
  nascimentoPaisId: yup.string().required('Informe o País Nascimento'),

  nome: yup.string().required('Informe o Nome').nullable(),
  cep: yup.string().required('Informe o CEP').min(8, 'Inserir no mínimo 8 caracteres.').nullable(),
  logradouroId: yup.string().required('Informe o Logradouro').nullable(),
  endereco: yup.string().required('Informe o Endereço'),
  imigranteCondicaoTrabalhador: yup.string().required('Informe a Condição do Imigrante'),
  bairro: yup.string().required('Informe o Bairro'),
  cidade: yup.string().required('Informe a Cidade').nullable(),
  ctpsNumero: yup.string().required('Informe o Número CTPS'),
  ctpsSerie: yup.string().required('Informe a Série CTPS'),
})

const HEIGHT_HEADER = '70px'

const Form = (props) => {
  const { data: _data, onClickClose, hasAdmissaoPreliminar = false, isUsed = false } = props

  const [data, setData] = useState({})
  const [collapseItens, setCollapseItens] = useState({
    primaria: true,
    documentos: true,
    outras: true,
    imigrante: true,
    pcd: true,
  })
  const [isLoading, setIsLoading] = useState(false)

  const notification = useNotification()
  const history = useHistory()
  const dialogNotification = useDialogNotification()
  const theme = useTheme()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setIsLoading(true)
      try {
        await api.put(`/pessoaFisica/${data.id}`, data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setIsLoading(false)
    }

    const insert = async () => {
      setIsLoading(true)
      try {
        const response = await api.post('/pessoaFisica', data)
        notification.post()
        onClickClose && onClickClose()
        history.push({
          pathname: `/pessoa-fisica/${response.data.data.id}`,
        })
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setIsLoading(false)
    }

    data.nome = data.nome.trim()
    if (data?.id) {
      update()
      return
    }
    insert()
    // eslint-disable-next-line
  }, [data, onClickClose])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const toggleCollapse = (item) => {
    setCollapseItens({ ...collapseItens, [item]: !collapseItens[item] })
  }

  const handleCollapseAll = (open) => {
    let newItens = {}
    for (const item in collapseItens) {
      newItens = { ...newItens, [item]: open }
    }
    setCollapseItens({ ...newItens })
  }

  return (
    <Box height="100%">
      <Box height={HEIGHT_HEADER}>
        <PageHeader>
          <ButtonBox top={1} bottom={1}>
            <Tooltip title="Expandir todos" arrow TransitionComponent={Zoom}>
              <IconButton size="small" onClick={() => handleCollapseAll(true)}>
                <CollapseOpenIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Retrair todos" arrow TransitionComponent={Zoom}>
              <IconButton size="small" onClick={() => handleCollapseAll(false)}>
                <CollapseCloseIcon />
              </IconButton>
            </Tooltip>
          </ButtonBox>
        </PageHeader>
      </Box>

      <ContainerForm
        customActions={
          <ButtonBox bottom={1} right={1}>
            {onClickClose && (
              <Button
                onClick={
                  _data?.id
                    ? () =>
                        history.push({
                          pathname: '/pessoa-fisica',
                        })
                    : onClickClose
                }
              >
                Cancelar
              </Button>
            )}
            <Button variant="contained" onClick={handleValidate}>
              Salvar
            </Button>
          </ButtonBox>
        }
        isLoading={isLoading}
        removeHeight={HEIGHT_HEADER}
      >
        <Grid container spacing={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CollapseContent
              title="Informações primárias"
              isOpen={collapseItens.primaria}
              onClick={() => toggleCollapse('primaria')}
            >
              <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    label="Nome"
                    name="nome"
                    validationErrors={validationErrors}
                    variant="outlined"
                    size="small"
                    fullWidth
                    autoFocus
                    value={data?.nome || ''}
                    required
                    inputProps={{
                      maxLength: 100,
                    }}
                    onChange={(e) => {
                      const nome = e?.target?.value || ''
                      setData((oldState) => ({
                        ...oldState,
                        nome,
                      }))
                    }}
                    disabled={isUsed || hasAdmissaoPreliminar ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                  />
                </Grid>
                <Grid xl={6} lg={6} md={6} sm={12} xs={12} item>
                  <Box display="flex" gridGap={theme.spacing(0.5)} alignItems="center">
                    <NumeroInscricaoTextField
                      label="CPF"
                      value={data?.nrInscricao || ''}
                      disabled
                    />
                    <IconButton
                      size="small"
                      title="Copiar para a Área de Transferência"
                      onClick={() => copyToClipboard(data?.nrInscricao)}
                    >
                      <FileCopyIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Grid>

                <Grid item xl={2} lg={4} md={4} sm={12} xs={12}>
                  <DatePicker
                    label="Data Nascimento"
                    size="small"
                    value={data.dtNascimento || null}
                    required
                    validationErrors={validationErrors}
                    name="dtNascimento"
                    disabled={hasAdmissaoPreliminar ? true : false}
                    onChange={(date) => {
                      const dtNascimento = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, dtNascimento })
                    }}
                  />
                </Grid>
                <Grid item xl={5} lg={4} md={4} sm={12} xs={12}>
                  <TextField
                    label="Nome Mãe"
                    fullWidth
                    value={data.nomeMae || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onChange={(e) => {
                      const nomeMae = e.target.value
                      setData({ ...data, nomeMae })
                    }}
                  />
                </Grid>

                <Grid item xl={5} lg={4} md={4} sm={12} xs={12}>
                  <TextField
                    label="Nome Pai"
                    fullWidth
                    value={data.nomePai || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onChange={(e) => {
                      const nomePai = e.target.value
                      setData({ ...data, nomePai })
                    }}
                  />
                </Grid>

                <Grid item xl={2} lg={2} md={6} sm={12} xs={12}>
                  <TextField
                    select
                    label="Sexo"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={data.sexo || ''}
                    required
                    validationErrors={validationErrors}
                    name="sexo"
                    onChange={(e) => {
                      const sexo = e.target.value
                      setData({ ...data, sexo })
                    }}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                  >
                    <MenuItem key={1} value="M">
                      Masculino
                    </MenuItem>
                    <MenuItem key={2} value="F">
                      Feminino
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
                  <AutoCompleteMemoryTable
                    table="GrauInstrucao"
                    label="Grau Instrução"
                    value={data.grauInstrucao || null}
                    required
                    validationErrors={validationErrors}
                    name="grauInstrucao"
                    onChange={(e, memoryTable) => {
                      const grauInstrucao = memoryTable ? memoryTable.codigo : null
                      setData({ ...data, grauInstrucao })
                    }}
                    disabled={isUsed ? true : false}
                    optionId="codigo"
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                  />
                </Grid>

                <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                  <AutoCompleteMemoryTable
                    table="EstadoCivil"
                    label="Estado Cívil"
                    value={data.estadoCivil || null}
                    required
                    validationErrors={validationErrors}
                    name="estadoCivil"
                    optionId="codigo"
                    onChange={(e, memoryTable) => {
                      const estadoCivil = memoryTable ? memoryTable.codigo : null
                      setData({ ...data, estadoCivil })
                    }}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                  <AutoCompletePais
                    label="Nacionalidade"
                    value={data?.nacionalidadePaisId || ''}
                    required
                    validationErrors={validationErrors}
                    name="nacionalidadePaisId"
                    onChange={(e, obj) => {
                      const nacionalidadePaisId = obj?.id || ''
                      setData({
                        ...data,
                        nacionalidadePaisId,
                      })
                    }}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                  <AutoCompletePais
                    label="País Nascimento"
                    value={data?.nascimentoPaisId || ''}
                    required
                    validationErrors={validationErrors}
                    name="nascimentoPaisId"
                    onChange={(e, obj) => {
                      const nascimentoPaisId = obj?.id || ''
                      setData({ ...data, nascimentoPaisId })
                    }}
                  />
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <AutoCompleteCidade
                    label="Naturalidade"
                    value={data?.naturalidadeCidade || null}
                    required
                    validationErrors={validationErrors}
                    name="naturalidadeCidade"
                    onChange={(e, obj) => {
                      const naturalidadeCidadeId = obj?.id || null
                      const naturalidadeCidade = obj || null
                      setData({
                        ...data,
                        naturalidadeCidadeId,
                        naturalidadeCidade,
                      })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <AutoCompleteMemoryTable
                    table="racacor"
                    label="Cor"
                    value={data.racaCor || null}
                    required
                    validationErrors={validationErrors}
                    name="racaCor"
                    optionId="codigo"
                    onChange={(e, memoryTable) => {
                      const racaCor = memoryTable ? memoryTable.codigo : null
                      setData({ ...data, racaCor })
                    }}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                  />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ContentDivider title="Endereço" />
                </Grid>
                <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
                  <CEPTextField
                    name="cep"
                    label="CEP"
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                    validationErrors={validationErrors}
                    value={data?.cep || ''}
                    getAddress={
                      data?.id
                        ? undefined
                        : (address) =>
                            setData((oldState) => ({
                              ...oldState,
                              ...address,
                            }))
                    }
                    onChange={(e) => {
                      const cep = e?.target?.value?.replace(/\D/g, '')
                      setData({ ...data, cep })
                    }}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                  <AutoCompleteLogradouro
                    name="logradouroId"
                    required
                    validationErrors={validationErrors}
                    value={data?.logradouroId || null}
                    optionId="id"
                    onChange={(e, logradouro) => {
                      const logradouroId = logradouro?.id || null
                      setData({ ...data, logradouroId, logradouro })
                    }}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                  />
                </Grid>
                <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
                  <TextField
                    name="endereco"
                    label="Endereço"
                    fullWidth
                    required
                    validationErrors={validationErrors}
                    value={data.endereco || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onChange={(e) => {
                      const endereco = e.target.value
                      setData({ ...data, endereco })
                    }}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                  />
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                  <TextField
                    label="Número"
                    fullWidth
                    value={data.numero || ''}
                    variant="outlined"
                    size="small"
                    onlyNumber
                    inputProps={{ maxLength: 10 }}
                    onChange={(e) => {
                      const numero = e.target.value
                      setData({ ...data, numero })
                    }}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={10} sm={12} xs={12}>
                  <TextField
                    name="bairro"
                    label="Bairro"
                    fullWidth
                    required
                    validationErrors={validationErrors}
                    value={data.bairro || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 80,
                    }}
                    onChange={(e) => {
                      const bairro = e.target.value
                      setData({ ...data, bairro })
                    }}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                  />
                </Grid>
                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                  <TextField
                    label="Complemento"
                    fullWidth
                    value={data.complemento || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 100,
                    }}
                    onChange={(e) => {
                      const complemento = e.target.value
                      setData({ ...data, complemento })
                    }}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <AutoCompleteCidade
                    name="cidade"
                    required
                    validationErrors={validationErrors}
                    onChange={(e, cidade) => {
                      const cidadeId = cidade ? cidade.id : null
                      setData({ ...data, cidadeId, cidade })
                    }}
                    value={data?.cidade || null}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TelefoneTextField
                    value={data?.telefone || ''}
                    onChange={(e, value) => {
                      const telefone = value
                      setData((oldState) => ({
                        ...oldState,
                        telefone,
                      }))
                    }}
                  />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ContentDivider title="Informações adicionais" />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Observação"
                    fullWidth
                    multiline
                    rows={4}
                    value={data.observacao || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 500,
                    }}
                    onChange={(e) => {
                      const observacao = e.target.value
                      setData({ ...data, observacao })
                    }}
                  />
                </Grid>
              </Grid>
            </CollapseContent>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CollapseContent
              title="Documentos"
              isOpen={collapseItens.documentos}
              onClick={() => toggleCollapse('documentos')}
              top={1}
            >
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ContentDivider title="Registro Geral" />
                </Grid>

                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                  <TextField
                    label="RG"
                    fullWidth
                    value={data.rgNumero || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 14,
                    }}
                    onChange={(e) => {
                      const rgNumero = e.target.value
                      setData({ ...data, rgNumero })
                    }}
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                  <TextField
                    label="Orgão Emissor RG"
                    fullWidth
                    value={data.rgOrgaoEmissor || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 20,
                    }}
                    onChange={(e) => {
                      const rgOrgaoEmissor = e.target.value
                      setData({ ...data, rgOrgaoEmissor })
                    }}
                  />
                </Grid>

                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                  <DatePicker
                    label="Emissão RG"
                    size="small"
                    value={data.rgDtEmissao || null}
                    onChange={(date) => {
                      // const rgDtEmissao = e.target.value;
                      const rgDtEmissao = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, rgDtEmissao })
                    }}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ContentDivider title="CTPS" />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <TextField
                    name="ctpsNumero"
                    label="CTPS"
                    fullWidth
                    required
                    validationErrors={validationErrors}
                    value={data?.ctpsNumero || ''}
                    variant="outlined"
                    size="small"
                    onlyNumber
                    inputProps={{
                      maxLength: 10,
                    }}
                    onChange={(e) => {
                      const ctpsNumero = e?.target?.value || ''
                      setData({ ...data, ctpsNumero })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <TextField
                    name="ctpsSerie"
                    label="Série CTPS"
                    fullWidth
                    required
                    validationErrors={validationErrors}
                    value={data?.ctpsSerie || ''}
                    variant="outlined"
                    size="small"
                    onlyNumber
                    inputProps={{
                      maxLength: 5,
                    }}
                    onChange={(e) => {
                      const ctpsSerie = e?.target?.value || ''
                      setData({ ...data, ctpsSerie })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <DatePicker
                    label="Emissão CTPS"
                    size="small"
                    value={data.ctpsDtEmissao || null}
                    onChange={(date) => {
                      const ctpsDtEmissao = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, ctpsDtEmissao })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <UFSelect
                    label="UF CTPS"
                    value={data?.ctpsuf || ''}
                    onChange={(e, obj) => {
                      const ctpsuf = obj?.sigla || ''
                      setData({ ...data, ctpsuf })
                    }}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ContentDivider title="CNH" />
                </Grid>

                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                  <TextField
                    label="CNH"
                    fullWidth
                    value={data.cnhNumero || ''}
                    variant="outlined"
                    size="small"
                    onlyNumber
                    inputProps={{
                      maxLength: 15,
                    }}
                    onChange={(e) => {
                      const cnhNumero = e.target.value
                      setData({ ...data, cnhNumero })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                  <TextField
                    label="Cat CNH"
                    fullWidth
                    value={data.cnhCategoria || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 2,
                    }}
                    onChange={(e) => {
                      const cnhCategoria = e.target.value
                      setData({ ...data, cnhCategoria })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                  <UFSelect
                    label="UF CNH"
                    value={data?.cnhuf || ''}
                    onChange={(e, obj) => {
                      const cnhuf = obj?.sigla || ''
                      setData({ ...data, cnhuf })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                  <TextField
                    label="Emissor CNH"
                    fullWidth
                    value={data.cnhOrgaoEmissor || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 20,
                    }}
                    onChange={(e) => {
                      const cnhOrgaoEmissor = e.target.value
                      setData({ ...data, cnhOrgaoEmissor })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                  <DatePicker
                    label="Emissão CNH"
                    size="small"
                    value={data.cnhDtEmissao || null}
                    onChange={(date) => {
                      // const cnhDtEmissao = e.target.value;
                      const cnhDtEmissao = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, cnhDtEmissao })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                  <DatePicker
                    label="Expedição CNH"
                    size="small"
                    disabled //Não vem backend
                    value={data.cnhDtExpedicao || null}
                    onChange={(date) => {
                      const cnhDtExpedicao = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, cnhDtExpedicao })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                  <DatePicker
                    label="Validade CNH"
                    size="small"
                    value={data.cnhDtValidade || null}
                    onChange={(date) => {
                      // const cnhDtValidade = e.target.value;
                      const cnhDtValidade = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, cnhDtValidade })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                  <DatePicker
                    label="Primeira Hab."
                    size="small"
                    value={data.cnhDtPrimeiraHabilitacao || null}
                    disabled //Não vem backend
                    onChange={(date) => {
                      const cnhDtPrimeiraHabilitacao = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, cnhDtPrimeiraHabilitacao })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                  <DatePicker
                    label="Emissão MOPP"
                    size="small"
                    value={data.moppDtEmissao || null}
                    onChange={(date) => {
                      const moppDtEmissao = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, moppDtEmissao })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                  <DatePicker
                    label="Vencimento MOPP"
                    size="small"
                    value={data.moppDtVencimento || null}
                    onChange={(e) => {
                      // const moppDtVencimento = e.target.value;
                      const moppDtVencimento = e.format('yyyy-MM-DD')
                      setData({ ...data, moppDtVencimento })
                    }}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ContentDivider title="Orgão de Classe" />
                </Grid>

                <Grid item xl={3} lg={4} md={12} sm={12} xs={12}>
                  <TextField
                    label="OC Habilitação"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={data.ocHabilitacao || ''}
                    inputProps={{
                      maxLength: 20,
                    }}
                    onChange={(e) => {
                      const ocHabilitacao = e.target.value
                      setData({ ...data, ocHabilitacao })
                    }}
                  />
                </Grid>

                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                  <TextField
                    label="OC Registro"
                    fullWidth
                    value={data.ocRegistro || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 14,
                    }}
                    onChange={(e) => {
                      const ocRegistro = e.target.value
                      setData({ ...data, ocRegistro })
                    }}
                  />
                </Grid>

                <Grid item xl={2} lg={4} md={6} sm={12} xs={12}>
                  <TextField
                    label="OC Orgão Emissor"
                    fullWidth
                    value={data.ocOrgaoEmissor || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 20,
                    }}
                    onChange={(e) => {
                      const ocOrgaoEmissor = e.target.value
                      setData({ ...data, ocOrgaoEmissor })
                    }}
                  />
                </Grid>

                <Grid item xl={2} lg={6} md={6} sm={12} xs={12}>
                  <DatePicker
                    label="OC Dt. Expedição"
                    size="small"
                    value={data.ocDtExpedicao || null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(date) => {
                      const ocDtExpedicao = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, ocDtExpedicao })
                    }}
                  />
                </Grid>

                <Grid item xl={2} lg={6} md={6} sm={12} xs={12}>
                  <DatePicker
                    label="OC Dt. Validade"
                    size="small"
                    value={data.ocDtValidade || null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(date) => {
                      const ocDtValidade = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, ocDtValidade })
                    }}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <ContentDivider title="PIS" />
                </Grid>

                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                  <MaskedTextField
                    mask="999.99999-99-9"
                    label="PIS"
                    fullWidth
                    value={data?.pis || ''}
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      const pis = e?.target?.value?.replace(/\D/g, '') || ''
                      setData({ ...data, pis })
                    }}
                  />
                </Grid>

                <Grid xl={3} lg={3} md={6} sm={12} xs={12} item>
                  <DatePicker
                    label="Emissão PIS"
                    size="small"
                    value={data.pisDtEmissao || null}
                    onChange={(date) => {
                      // const pisDtEmissao = e.target.value;
                      const pisDtEmissao = date ? date.format('yyyy-MM-DD') : null
                      setData({ ...data, pisDtEmissao })
                    }}
                  />
                </Grid>
              </Grid>
            </CollapseContent>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CollapseContent
              title="Pessoa com Deficiência"
              isOpen={collapseItens.pcd}
              onClick={() => toggleCollapse('pcd')}
              top={1}
            >
              <Grid container spacing={2}>
                <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                  <Checkbox
                    label="Deficiência Física"
                    value={data?.isDeficienciaFisica || false}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                    onChange={(e, value) => {
                      const isDeficienciaFisica = value
                      setData({ ...data, isDeficienciaFisica })
                    }}
                  />
                </Grid>

                <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                  <Checkbox
                    label="Deficiência Auditiva"
                    value={data?.isDeficienciaAuditiva || false}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                    onChange={(e, value) => {
                      const isDeficienciaAuditiva = value
                      setData({ ...data, isDeficienciaAuditiva })
                    }}
                  />
                </Grid>

                <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                  <Checkbox
                    label="Deficiência Visual"
                    value={data?.isDeficienciaVisual || false}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                    onChange={(e, value) => {
                      const isDeficienciaVisual = value
                      setData({ ...data, isDeficienciaVisual })
                    }}
                  />
                </Grid>

                <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                  <Checkbox
                    label="Deficiência Mental"
                    value={data?.isDeficienciaMental || false}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                    onChange={(e, value) => {
                      const isDeficienciaMental = value
                      setData({ ...data, isDeficienciaMental })
                    }}
                  />
                </Grid>

                <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                  <Checkbox
                    label="Deficiência Intelectual"
                    value={data?.isDeficienciaIntelectual || false}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                    onChange={(e, value) => {
                      const isDeficienciaIntelectual = value
                      setData({ ...data, isDeficienciaIntelectual })
                    }}
                  />
                </Grid>

                <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
                  <Checkbox
                    label="Reabilitado"
                    value={data?.isDeficienciaReabilitado || false}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                    onChange={(e, value) => {
                      const isDeficienciaReabilitado = value
                      setData({ ...data, isDeficienciaReabilitado })
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Checkbox
                    label="Preenche Cota"
                    value={data?.isDeficienciaInfoCota || false}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                    onChange={(e, value) => {
                      const isDeficienciaInfoCota = value
                      setData({ ...data, isDeficienciaInfoCota })
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Observação"
                    fullWidth
                    value={data.deficienciaObservacao || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 250,
                    }}
                    onChange={(e) => {
                      const deficienciaObservacao = e.target.value
                      setData({ ...data, deficienciaObservacao })
                    }}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                  />
                </Grid>
              </Grid>
            </CollapseContent>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CollapseContent
              title="Trabalho do Imigrante"
              isOpen={collapseItens.imigrante}
              onClick={() => toggleCollapse('imigrante')}
              top={1}
            >
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Tempo Residente"
                    options={[
                      { value: '1', nome: 'Prazo determinado' },
                      { value: '2', nome: 'Prazo Inderteminado' },
                    ]}
                    optionId="value"
                    renderOption={(option) => `${option.nome}`}
                    value={data?.imigranteTempoResidencia || ''}
                    onChange={(e, obj) => {
                      const imigranteTempoResidencia = obj?.value || ''
                      setData((oldState) => ({
                        ...oldState,
                        imigranteTempoResidencia,
                        imigranteCondicaoTrabalhador: '',
                      }))
                    }}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                  />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <MUIAutoComplete
                    label="Condição do Imigrante"
                    required
                    validationErrors={validationErrors}
                    name="imigranteCondicaoTrabalhador"
                    options={imigranteCondicaoTrabalhadorValues}
                    optionId="value"
                    renderOption={(option) => option.name}
                    value={data.imigranteCondicaoTrabalhador}
                    disabled={isUsed ? true : false}
                    onChange={(e, obj) => {
                      const imigranteCondicaoTrabalhador = obj ? obj.value : ''
                      setData((oldState) => ({
                        ...oldState,
                        imigranteCondicaoTrabalhador,
                      }))
                    }}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                  />
                </Grid>
              </Grid>
            </CollapseContent>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CollapseContent
              title="Outras Informações"
              isOpen={collapseItens.outras}
              onClick={() => toggleCollapse('outras')}
              top={1}
            >
              <Grid container spacing={2}>
                <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                  <TextField
                    label="Chave PIX"
                    fullWidth
                    value={data.chavePIX || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 77,
                    }}
                    onChange={(e) => {
                      const chavePIX = e.target.value
                      setData({ ...data, chavePIX })
                    }}
                  />
                </Grid>

                <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                  <TextField
                    label="Email"
                    fullWidth
                    value={data.email || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 60,
                    }}
                    onChange={(e) => {
                      const email = e.target.value
                      setData({ ...data, email })
                    }}
                    disabled={isUsed ? true : false}
                    title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
                  />
                </Grid>
              </Grid>
            </CollapseContent>
          </Grid>
        </Grid>
      </ContainerForm>
    </Box>
  )
}

export default Form
