import {
  ActionDialog,
  Button,
  ButtonBox,
  ConfirmDeleteDialog,
  ContainerTable,
  Stack,
} from '~/components'

import Table from './Table'
import Form from './Form'

import { useObterPorRecibo } from '~/hooks/queries/VinculoPensaoBeneficiario/useObterPorRecibo'
import { VinculoPensaoBeneficiario } from '~/hooks/queries/VinculoPensaoBeneficiario/interfaces/VinculoPensaoBeneficiario'
import useDialog from '~/hooks/useDialog'
import { useRemover } from '~/hooks/queries/VinculoPensaoBeneficiario/useRemover'
import useAmbiente from '~/hooks/useAmbiente'

interface DialogRateioPensaoProps {
  isOpen: boolean
  onClose: () => void
  rpId: string
  vinculoId: string
}

export default function DialogRateioPensao({
  isOpen,
  onClose,
  rpId,
  vinculoId,
}: DialogRateioPensaoProps) {
  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterPorRecibo({
    params: {
      rpId,
    },
  })
  const data = _d || []

  const { mutateAsync: mutateAsyncRemove, isLoading: isRemoving } = useRemover()

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<VinculoPensaoBeneficiario | null>(null)

  const { anoMes } = useAmbiente()

  function handleClickItem(event: 'edit' | 'delete', _data: VinculoPensaoBeneficiario) {
    switch (event) {
      case 'edit':
        openForm(_data)
        break
      case 'delete':
        openConfirmDelete(_data.id)
        break
    }
  }

  async function handleDelete() {
    await mutateAsyncRemove({
      params: {
        id: dataConfirmDelete,
      },
    })
    closeConfirmDelete()
  }

  return (
    <ActionDialog
      title="Pensão Alimentícia do Recibo - Dependentes"
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="md"
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
      renderRight={
        <Button variant="contained" onClick={() => openForm(null)}>
          Adicionar
        </Button>
      }
    >
      <Stack height="100%">
        <ContainerTable>
          <Table
            data={data}
            isLoading={isLoading}
            isFetching={isFetching}
            onItemClick={handleClickItem}
          />
        </ContainerTable>
      </Stack>

      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          isDeleting={isRemoving}
          onCancel={closeConfirmDelete}
          onConfirm={handleDelete}
        />
      )}

      {isOpenForm && (
        <Form
          isOpen={isOpenForm}
          onClose={closeForm}
          data={dataForm}
          dataInitial={{
            anoMes,
            rpId,
            vinculoId,
          }}
        />
      )}
    </ActionDialog>
  )
}
