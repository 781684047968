import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import { RelSalarialCargoRequestDTO } from './RelSalarialCargoRequestDTO'

import { openDownloadData } from '~/hooks/useUtils'

export default function useRelSalarialCargoObterRelatorioExcel() {
  const notification = useNotification()
  const dialogNotification = useDialogNotification()

  async function handleRequest(props: RelSalarialCargoRequestDTO) {
    const response = await api.post('/Relatorio/RelSalarialCargo/ObterRelatorio/Excel', props, {
      headers: {
        'DC-XlsResponse': true,
      },
      responseType: 'blob',
    })
    return response.data
  }

  return useMutation(handleRequest, {
    onSuccess: async (data) => {
      notification.success('Relatório gerado com sucesso')
      console.log(data)
      openDownloadData('RELATORIO-SALARIAL-CARGO.xlsx', data)
    },
    onError: dialogNotification.extractErrors,
  })
}
