import api from '~/services/api-pessoal'
import { notifySuccess } from '~/utils/notification'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ManutencaoContratoExperienciaRequestDTO } from './interfaces/ManutencaoContratoExperienciaRequestDTO'
import { useClient } from './useObterFuncionarios'
import { ManutencaoContratoExperienciaFuncionarioDTO } from './interfaces/ManutencaoContratoExperienciaFuncionarioDTO'

interface RequestProps {
  data: ManutencaoContratoExperienciaRequestDTO
}

export function useProcessarManutencaoContratoFuncionario() {
  const dialogNotificaiton = useDialogNotification()
  const { handleReset } = useClient()

  async function handleRequest({ data }: RequestProps) {
    const response = await api.post<{ data: ManutencaoContratoExperienciaFuncionarioDTO[] }>(
      '/ManutencaoContratoExperiencia/ProcessarManutencaoContratoFuncionario',
      data,
    )
    return response.data.data
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      handleReset()
      notifySuccess('O processamento foi realizado com sucesso')
    },
    onError: dialogNotificaiton.extractErrors,
  })
}
