import { useEffect, useState, useMemo } from 'react'

import { formatToCPFOrCNPJ } from 'brazilian-values'

import { Box, Paper, useTheme } from '@material-ui/core'

import { Button, CopyLabel, ToolsDataTable } from '~/components'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'

import { useObterEmpregadores } from '~/hooks/queries/ApurarEncargos/useObterEmpregadores'
import { TabsApuracao, useApuracaoEncargos } from '../..'

export default function CalcularApuracao() {
  const {
    indApuracao,
    isMostrarTodasEmpresas,
    grupoId,
    query,
    changeTab,
    changeMostrarTodasEmpresas,
  } = useApuracaoEncargos()

  const [isLoadingCalculcar, setLoadingCalcular] = useState(false)
  const [rowsSelecteds, setRowSelecteds] = useState([])

  const dialogNotification = useDialogNotification()
  const notifications = useNotification()
  const { anoMes } = useAmbiente()
  const theme = useTheme()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterEmpregadores({
    params: {
      grupoId: grupoId || undefined,
      anoMes,
      indApuracao,
      isMostrarTodasEmpresas,
    },
  })
  const data = _d || []

  useEffect(() => {
    setRowSelecteds([])
  }, [_d])

  useEffect(() => {
    changeMostrarTodasEmpresas(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo(
    () => [
      {
        name: 'codigo',
        label: 'Código',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'nrInscricao',
        label: 'Número de Inscrição',
        options: {
          customBodyRender: (value: FixLater) => (
            <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
          ),
        },
      },
    ],
    [],
  )

  async function handleCalcular() {
    setLoadingCalcular(true)
    try {
      const idsEmpregadores = rowsSelecteds.map((indexCurrent) => data[indexCurrent].id)
      await api.post('/ApurarEncargos/Apurar', idsEmpregadores, {
        params: {
          indApuracao,
          periodoApuracao: anoMes,
        },
      })
      notifications.success('Apuração calculada com sucesso.')
      setRowSelecteds([])
      changeTab(TabsApuracao.Demonstracao)
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingCalcular(false)
    }
  }

  return (
    <Box height="100%" display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
      <Box flex={1} position="relative" overflow="auto">
        <Box position="absolute" width="100%">
          <ToolsDataTable
            data={data}
            isLoading={isLoading}
            isFetching={isFetching}
            columns={columns}
            sherlock={{
              query,
              columns: ['codigo', 'nome', 'nrInscricao'],
            }}
            disableAutoHeight
            optionsSelectable={{
              type: 'multiple',
              rowsSelected: rowsSelecteds,
              onRowSelected: setRowSelecteds,
            }}
          />
        </Box>
      </Box>

      <Box
        component={Paper}
        p={1}
        paddingBottom={1}
        display="flex"
        justifyContent="flex-end"
        gridGap={theme.spacing(1)}
      >
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleCalcular}
          isLoading={isLoadingCalculcar}
          disabled={!(rowsSelecteds.length > 0)}
        >
          Calcular
        </Button>
      </Box>
    </Box>
  )
}
