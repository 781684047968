import { useState, useCallback } from 'react'

import { useQueryClient } from 'react-query'
import api from '~/services/api-pessoal'

import { Button, Finder, ButtonBox } from 'mio-library-ui'
import { ExcelButton, PDFViewer, PrintButton } from '~/components'
import ConfirmDeleteAlert from '~/components/ConfirmInscricaoDelete'

import Table from './components/Table'
import Container from './components/Container'
import DrawerFilter from './components/DrawerFilter'

import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import {
  empregadorObterFiltradoQuery,
  useEmpregadorObterFiltrado,
} from '~/hooks/queries/EmpregadorConsulta/useEmpregadorObterFiltrado'
import { useEmpregadorObterRelatorio } from '~/hooks/queries/EmpregadorConsulta/useEmpregadorObterRelatorio'

import { indPontoEletronicoConsts } from '~/values/indPontoEletronicoValues'
import { IndHomologacaoEnum } from '~/@types/enums/IndHomologacaoEnum'
import { IconButton } from '@material-ui/core'
import { FilterList } from '@material-ui/icons'
import { IndAtividadeEmpregadorEnum } from '~/@types/enums/IndAtividadeEmpregadorEnum'
import { IndCadastroMatriculaEnum } from '~/@types/enums/IndCadastroMatriculaEnum'
import PageContainer from '~/components/PageContainer'

const { CNPJ_1 } = tipoInscricaoConsts
const { CPF_5 } = indPontoEletronicoConsts

const Empregador = () => {
  const [form, setForm] = useState({
    isOpen: false,
    data: {},
  })
  const [filter, setFilter] = useState({
    isOpen: false,
    data: {
      indAtividadeEmpregador: IndAtividadeEmpregadorEnum.Todos,
      grupoId: undefined,
    },
  })

  const [isLoadingConfirmDelete, setLoadingConfirmDelete] = useState(false)
  const [isLoadingEdit, setLoadingEdit] = useState(false)
  const [query, setQuery] = useState('')
  const notification = useNotification()
  const dialogNotification = useDialogNotification()
  const { anoMes } = useAmbiente(false, true)

  const queryClient = useQueryClient()
  const {
    data: _d,
    isLoading,
    isFetching,
  } = useEmpregadorObterFiltrado({
    indAtividadeEmpregadorEnum: filter.data.indAtividadeEmpregador,
    grupoId: filter.data.grupoId,
    anoMes,
  })
  const data = _d || []

  const { mutateAsync: obterRelatorio, isLoading: isLoadingRelatorio } =
    useEmpregadorObterRelatorio()

  const {
    isOpen: isOpenConfirmDeleteAlert,
    open: openConfirmDeleteAlert,
    close: closeConfirmDeleteAlert,
    data: dataConfirmDeleteAlert,
  } = useDialog({
    nrInscricao: '',
    id: '',
  })
  const {
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
    close: closePDFViewer,
    data: dataPDFViewer,
  } = useDialog({
    indAtividadeEmpregadorEnum: null,
    grupoId: null,
  })

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleClickItem = (event, value) => {
    const handleClickEditItem = async (id) => {
      setLoadingEdit(true)
      try {
        const response = await api.get(`/empregador/${id}`)
        handleOpenForm(response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoadingEdit(false)
      }
    }

    const handleClickDeleteItem = (id) => {
      const empregadorCurrent = data.find((d) => d.id === id)
      openConfirmDeleteAlert({
        nrInscricao: empregadorCurrent.nrInscricao,
        id,
      })
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event](value)
  }

  const handleDeleteItem = async () => {
    try {
      setLoadingConfirmDelete(true)
      await api.post('/Empregador/ExcluirEmpregador', [dataConfirmDeleteAlert.id])
      await queryClient.invalidateQueries(empregadorObterFiltradoQuery)
      closeConfirmDeleteAlert()
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingConfirmDelete(false)
    }
  }

  const handleClickAddItem = useCallback(() => {
    handleOpenForm()
  }, [])

  const handleOpenForm = (
    data = {
      indAmbienteESocial: '1',
      indTabelaRubricas: 'DataC_01',
      tipoInscricao: CNPJ_1,
      isDCTFWeb: true,
      isDiaMaximoMes: true,
      isDSRAtraso: true,
      isDSRFalta: true,
      indPontoEletronico: CPF_5,
      indHomologacao: IndHomologacaoEnum.EstabelecimentoEmpregador,
      indCadastroMatricula: IndCadastroMatriculaEnum.SequencialCPF_02,
    },
  ) => {
    setForm((oldState) => ({
      ...oldState,
      isOpen: true,
      data,
    }))
  }

  const handleCloseForm = () => {
    setForm({
      data: {},
      isOpen: false,
    })
  }

  const handleAfterSubmitForm = async (event, value) => {
    const handleAfterInsert = (data) => {
      handleCloseForm()
      handleOpenForm(data)
    }

    const handleAfterUpdate = () => {
      handleCloseForm()
    }

    const functions = {
      insert: handleAfterInsert,
      update: handleAfterUpdate,
    }

    await queryClient.invalidateQueries(empregadorObterFiltradoQuery)
    functions[event](value)
  }

  async function handlePrintExcel() {
    await obterRelatorio({
      indAtividadeEmpregadorEnum: filter.data.indAtividadeEmpregador,
      grupoId: filter.data.grupoId,
      anoMes,
    })
  }

  return (
    <PageContainer.Root>
      <PageContainer.Header title="Empregador">
        <ButtonBox>
          <IconButton
            size="small"
            onClick={() =>
              setFilter((prev) => ({
                ...prev,
                isOpen: true,
              }))
            }
            title="Empregador - Filtros"
            color="primary"
          >
            <FilterList />
          </IconButton>
          <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
          <PrintButton
            onClick={() =>
              openPDFViewer({
                indAtividadeEmpregador: filter.data.indAtividadeEmpregador,
                grupoId: filter.data.grupoId,
              })
            }
          />
          <ExcelButton onClick={() => handlePrintExcel()} isLoading={isLoadingRelatorio} />
          <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
            Adicionar
          </Button>
        </ButtonBox>
      </PageContainer.Header>

      <Table
        data={data}
        isLoading={isLoading}
        isFetching={isFetching || isLoadingEdit}
        query={query}
        onItemClick={handleClickItem}
      />

      <Container
        isOpen={form.isOpen}
        data={form.data}
        onClose={handleCloseForm}
        onAfterSubmitForm={handleAfterSubmitForm}
        anoMes={anoMes}
      />

      {isOpenConfirmDeleteAlert && (
        <ConfirmDeleteAlert
          isLoading={isLoadingConfirmDelete}
          isOpen={isOpenConfirmDeleteAlert}
          onCancel={closeConfirmDeleteAlert}
          onConfirm={handleDeleteItem}
          nrInscricao={dataConfirmDeleteAlert.nrInscricao}
        />
      )}

      <DrawerFilter
        isOpen={filter.isOpen}
        data={filter.data}
        onSubmit={(data) =>
          setFilter({
            isOpen: false,
            data,
          })
        }
        onClose={() =>
          setFilter((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
      />

      <PDFViewer
        isOpen={isOpenPDFViewer}
        reportKey="Empregador"
        onClose={closePDFViewer}
        title="Relação de Empregadores"
        axiosConfig={{
          method: 'get',
          url: '/EmpregadorConsulta/ObterRelatorio',
          params: {
            indAtividadeEmpregadorEnum: dataPDFViewer.indAtividadeEmpregadorEnum,
            grupoId: dataPDFViewer.grupoId,
            isExcel: false,
            anoMes,
          },
        }}
      />
    </PageContainer.Root>
  )
}

export default Empregador
