import { useQuery } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'
import { HttpRequestInterface, HttpResponseInterface } from '~/interfaces'
import api from '~/services/api-pessoal'
import { AcordoJornada12x36DTO, AcordoJornada12x36RequestParams } from './dtos'

export const query = '/Relatorio/AcordoJornada12X36/ObterVinculos'

async function handleRequest({
  params,
}: HttpRequestInterface<unknown, AcordoJornada12x36RequestParams>) {
  const response = await api.get(query, {
    params,
  })
  return response.data
}

export default function useObterRelatorioAcordoJornada12x36Vinculos(
  props: HttpRequestInterface<unknown, AcordoJornada12x36RequestParams>,
) {
  const dialogNotification = useDialogNotification()
  return useQuery<HttpResponseInterface<AcordoJornada12x36DTO>>(
    [query, props],
    () => {
      return handleRequest(props)
    },
    {
      onError: dialogNotification.extractErrors,
      enabled: props?.enabled,
    },
  )
}
