import { Box, IconButton } from '@material-ui/core'
import moment from 'moment'
import clsx from 'clsx'
import FlatList from '~/components/FlatList'
import mainStyles from './mainStyles'

interface MainProps {
  currentMonth: number
  onSelectMonth: (selectedMonth: number) => void
}

export default function Main({ currentMonth, onSelectMonth }: MainProps) {
  const months = Array.from({ length: 12 }, (_, i) => {
    return {
      id: i + 1,
      month: moment().month(i).format('MMM'),
    }
  })

  const classes = mainStyles()

  return (
    <Box component="main">
      <FlatList
        data={months}
        keyExtractor={(item) => item.id}
        renderItem={(item) => {
          return (
            <IconButton
              className={clsx(classes.iconButton, { [classes.active]: item.id === currentMonth })}
              onClick={() => onSelectMonth(item.id)}
            >
              {item.month}
            </IconButton>
          )
        }}
        boxProps={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          gridGap: 5,
          flexWrap: 'wrap',
        }}
        renderItemBoxProps={{ justifyContent: 'center' }}
      />
    </Box>
  )
}
