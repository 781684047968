import { useState, useEffect, useCallback } from 'react'

import { formatToCPFOrCNPJ } from 'brazilian-values'

import {
  Box,
  Checkbox,
  useTheme,
  useMediaQuery,
  Theme,
  Paper,
  TablePagination,
} from '@material-ui/core'

import { CopyLabel, ToolsDataTable } from '~/components'

import ActionsButtons from '../ActionsButtons'

import TableEventos from './TableEventos'

import useAmbiente from '~/hooks/useAmbiente'
import { useStepperContext } from '~/components/StepperForm'

import { useTransmitirEventosESocial } from '~/hooks/queries/ESocial/useTransmitirEventosESocial'
import { useObterEventosTransmissaoEnvio } from '~/hooks/queries/ESocial/useObterEventosTransmissaoEnvio'
import { useAlterarStatusEvento } from '~/hooks/queries/ESocial/useAlterarStatusEvento'

import { ESocialGrupoEventoEnum } from '~/@types/enums/ESocialGrupoEventoEnum'

import { MUIDataTableColumnDef, MUIDataTableCheckboxProps } from 'mui-datatables'
import { DataContextProps, LocationState } from '../..'
import { AutoSizer } from 'react-virtualized'
import { ESocialStatusEnum } from '~/@types/enums/ESocialStatusEnum'
import useDialogNotification from '~/hooks/useDialogNotification'
import { useLocation } from 'react-router-dom'

interface TransmissaoAuditoriaProps {
  grupo: ESocialGrupoEventoEnum
}

export default function TransmissaoAuditoria(props: TransmissaoAuditoriaProps) {
  const { grupo } = props

  const location = useLocation<LocationState | undefined>()
  const { state } = location || {}

  const { dataControlled: dataContext } = useStepperContext<DataContextProps>()
  const { query, indApuracao, eventosFilter, grupoId } = dataContext

  const [auditoriasIds, setAuditoriasIds] = useState<string[]>([])
  const [rowsExpanded, setRowExpanded] = useState<number[]>([])

  const [pagination, setPagination] = useState<{
    page: number
    pageSize: number
  }>({
    page: 0,
    pageSize: 25,
  })

  const isXSmallWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const { anoMes, empregador } = useAmbiente()
  const theme: FixLater = useTheme()

  const { mutateAsync, isLoading: isLoadingSubmit } = useTransmitirEventosESocial()
  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterEventosTransmissaoEnvio({
    params: {
      grupoEvento: grupo,
      anoMes,
      grupoId,
      indApuracao,
      page: pagination.page + 1,
      pageSize: pagination.pageSize,
      empregadorNome: query,
    },
    data: eventosFilter,
  })

  const data = _d?.data || []
  const totalRecords = _d?.totalRecords || 0

  const { mutateAsync: mutateAsyncAlterarStatusEvento, isLoading: isLoadingAlterarStatusEvento } =
    useAlterarStatusEvento()
  const dialogNotification = useDialogNotification()

  const allAuditoriasIds: string[] = []

  data.forEach((empregador) => {
    empregador.grupoEventos.forEach((grupo) =>
      grupo.eventos.forEach((auditoria) => allAuditoriasIds.push(auditoria.auditoriaId)),
    )
  })

  const handleReset = useCallback(() => {
    setAuditoriasIds([])
    setRowExpanded([])
  }, [])

  useEffect(() => {
    handleReset()
  }, [anoMes, indApuracao, handleReset])

  useEffect(() => {
    if (state?.empregadorNome) {
      setRowExpanded([0])
    }
  }, [state?.empregadorNome])

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'empregadorId',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'empregadorNrInscricao',
      label: 'Número de Inscrição',
      options: {
        customBodyRender: (value) => (
          <CopyLabel value={value}>{formatToCPFOrCNPJ(value)}</CopyLabel>
        ),
      },
    },
    {
      name: 'empregadorNome',
      label: 'Descrição',
    },
    {
      name: 'totalEventos',
      label: 'Quantidade',
      options: {
        setCellProps: () => ({
          style: {
            width: isXSmallWidth ? undefined : '80px',
          },
        }),
      },
    },
  ]

  async function handleSubmit() {
    await mutateAsync(auditoriasIds)
  }

  function handleInativarEventos() {
    dialogNotification.info({
      descriptions: [
        'Deseja realmente inativar os eventos selecionados?',
        'O evento só deve ser inativado se já estiver registrado no eSocial ou se não houver necessidade de envio.',
      ],
      onConfirm: async () => {
        await mutateAsyncAlterarStatusEvento({
          alterarParaStatus: ESocialStatusEnum.RegistroInativo_99,
          eventosIds: auditoriasIds,
        })
      },
      labelOnConfirm: 'Confirmar',
    } as FixLater)
  }

  return (
    <Box height="100%">
      <Box height="100%">
        <AutoSizer>
          {({ height, width }) => (
            <Box height={height} width={width}>
              <ToolsDataTable
                disableAutoHeight
                data={data}
                isLoading={isLoading}
                isFetching={isFetching}
                columns={columns}
                components={{
                  Checkbox: (props: MUIDataTableCheckboxProps) => {
                    if (props['data-description'] === 'row-select-header') {
                      const checked = allAuditoriasIds.every((auditoriaId) =>
                        auditoriasIds.includes(auditoriaId),
                      )
                      const indeterminate = auditoriasIds.some((d) => allAuditoriasIds.includes(d))
                      return (
                        <Checkbox
                          checked={checked}
                          indeterminate={checked ? undefined : indeterminate}
                          onChange={() =>
                            setAuditoriasIds(() => {
                              if (indeterminate) {
                                return []
                              } else {
                                return allAuditoriasIds
                              }
                            })
                          }
                        />
                      )
                    } else {
                      const indexRow = props['data-index'] || 0
                      const empregadorCurrent = data[indexRow]
                      if (!empregadorCurrent) return <></>
                      const auditoriasIdsEmpregador: string[] = []
                      empregadorCurrent.grupoEventos.forEach((grupo) =>
                        grupo.eventos.forEach((evento) =>
                          auditoriasIdsEmpregador.push(evento.auditoriaId),
                        ),
                      )
                      const checked = auditoriasIdsEmpregador.every((auditoriaEmpregadorId) =>
                        auditoriasIds.includes(auditoriaEmpregadorId),
                      )
                      const indeterminate = auditoriasIds.some((d) =>
                        auditoriasIdsEmpregador.includes(d),
                      )
                      return (
                        <Checkbox
                          checked={checked}
                          indeterminate={checked ? undefined : indeterminate}
                          onChange={() =>
                            setAuditoriasIds((prev) => {
                              if (indeterminate) {
                                return prev.filter((d) => !auditoriasIdsEmpregador.includes(d))
                              } else {
                                return [...prev, ...auditoriasIdsEmpregador]
                              }
                            })
                          }
                        />
                      )
                    }
                  },
                }}
                optionsExpandable={{
                  type: 'single',
                  expandedChildren: (index: number) => {
                    const empregadorCurrent = data[index]
                    return (
                      <TableEventos
                        data={empregadorCurrent.grupoEventos}
                        auditoriasIds={auditoriasIds}
                        setAuditoriasIds={setAuditoriasIds}
                      />
                    )
                  },
                  onRowExpanded: setRowExpanded,
                  rowsExpanded: rowsExpanded,
                }}
                options={{
                  setRowProps: (row: any) => {
                    const empregadorId = row[0]
                    const isHightLightRow = empregador?.id === empregadorId ? true : false
                    return {
                      style: {
                        backgroundColor: isHightLightRow
                          ? theme.palette.secondary.lighter
                          : 'inherit',
                      },
                    }
                  },
                  selectableRows: 'multiple',
                  selectableRowsHeader: !isLoading && data.length > 0,
                }}
                pagination={false}
              />
              <Box component={Paper}>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  labelRowsPerPage="Linhas por página:"
                  component="div"
                  count={totalRecords}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.page}
                  onChangePage={(_, page) =>
                    setPagination((prev) => ({
                      ...prev,
                      page,
                    }))
                  }
                  onChangeRowsPerPage={(e) =>
                    setPagination((prev) => ({
                      ...prev,
                      pageSize: +e.target.value,
                      page: 0,
                    }))
                  }
                />
              </Box>
            </Box>
          )}
        </AutoSizer>
      </Box>

      <ActionsButtons
        disabled={auditoriasIds.length > 0 ? false : true}
        isLoading={isLoadingSubmit}
        isLoadingInativarEventos={isLoadingAlterarStatusEvento}
        onCancel={handleReset}
        onSubmit={handleSubmit}
        onInativarEventos={handleInativarEventos}
      />
    </Box>
  )
}
