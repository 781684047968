import { useState } from 'react'
import { Box } from '@material-ui/core'
import { Button, MultipleSelect } from '~/components'
import { ReciboIDFEnum, ReciboIDFValues } from '~/@types/enums/ReciboIDFEnum'

const recibosIdfsRelacaoBancaria = [
  ReciboIDFEnum.Mensal_1,
  ReciboIDFEnum.Ferias_2,
  ReciboIDFEnum.Complementar_3,
  ReciboIDFEnum.Adiantamento_4,
  ReciboIDFEnum.Rescisao_5,
  ReciboIDFEnum.RescisaoComplementar_6,
  ReciboIDFEnum.DecimoTerceiro1aParcela_7,
  ReciboIDFEnum.DecimoTerceiro2aParcela_8,
  ReciboIDFEnum.Ferias_Coletivas_21,
]

const recibosIdfs = ReciboIDFValues.filter((item) =>
  recibosIdfsRelacaoBancaria.includes(item.value),
)

export const initialFilterFolhaAnalitica = [ReciboIDFEnum.Mensal_1, ReciboIDFEnum.Ferias_2]

interface FilterProps {
  onChange: (filters: number[]) => void
}

export default function Filter({ onChange }: FilterProps) {
  const [data, setData] = useState(() =>
    ReciboIDFValues.filter((item) => initialFilterFolhaAnalitica.includes(item.value)),
  )

  function handleClick() {
    const values = data.map((item) => item.value)
    onChange(values)
  }

  return (
    <Box gridGap={8} display="flex">
      <MultipleSelect
        inputProps={{
          label: 'Identificação dos Recibos',
        }}
        value={data}
        options={recibosIdfs}
        getOptionLabel={(option) => option.name}
        onChange={(_, options) => setData(options)}
        limitTags={4}
      />
      <Button variant="contained" onClick={handleClick}>
        Buscar
      </Button>
    </Box>
  )
}
