import { cloneElement, ReactElement } from 'react'
import { IconButton } from '@material-ui/core'

interface DataTableCellActionProps {
  title?: string
  disabled?: boolean
  icon: ReactElement
  onClick?: () => void
}

export default function DataTableCellAction({
  title = '',
  disabled = false,
  icon,
  onClick,
}: DataTableCellActionProps) {
  return (
    <IconButton
      title={title}
      color="primary"
      aria-label="Editar"
      disabled={disabled}
      onClick={onClick}
      size="small"
    >
      {cloneElement(icon, { fontSize: 'small', color: 'primary' })}
    </IconButton>
  )
}
