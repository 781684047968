import { useState } from 'react'

import * as yup from 'yup'

import { Typography } from '@material-ui/core'

import { ActionDialog, Stack } from '~/components'

import { useCopiarConfiguracoesGuias } from '~/hooks/queries/ExportacaoLancamentoConfig/useCopiarConfiguracoesGuias'

import { AutoCompleteEstabelecimento } from '~/components/AutoComplete'
import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

const schema = yup.object().shape({
  estabelecimentoId: yup.string().required('Informe o Estabelecimento'),
})

interface DialogCopyConfigsGuiaProps {
  onClose: () => void
  modeloIntegracaoId: string
}

export default function DialogCopyConfigsGuia({
  onClose,
  modeloIntegracaoId,
}: DialogCopyConfigsGuiaProps) {
  const [data, setData] = useState({
    estabelecimentoId: '',
  })

  const { mutateAsync, isLoading: isSubmtting } = useCopiarConfiguracoesGuias()

  const { estabelecimento } = useAmbiente()

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    await mutateAsync({
      params: {
        estabelecimentoOrigem: data.estabelecimentoId,
        estabelecimentoDestino: estabelecimento.id,
        modeloIntegracaoId,
      },
    })
    onClose()
  }

  return (
    <ActionDialog
      title="Copiar Configurações"
      isOpen={true}
      onClose={onClose}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
      okLabel="Processar"
      onOkClick={handleValidate}
      isOkProcessing={isSubmtting}
    >
      <Stack>
        <Typography>Selecione o Estabelecimento para copiar as configurações.</Typography>

        <AutoCompleteEstabelecimento
          label="Estabelecimento Origem"
          value={data.estabelecimentoId}
          onChange={(_: any, obj: any) =>
            setData((prev) => ({ ...prev, estabelecimentoId: obj?.id || '' }))
          }
          required
          validationErrors={validationErrors}
          getOptionDisabled={(option: any) => option.id === estabelecimento.id}
          name="estabelecimentoId"
        />
      </Stack>
    </ActionDialog>
  )
}
