import { useState, useEffect, useCallback } from 'react'

import { Checkbox, Grid, MenuItem, FormControlLabel } from '@material-ui/core'
import { ContentDivider, TextField } from 'mio-library-ui'
import { ActionDialog, TimePicker } from '~/components'
import { cpf } from 'cpf-cnpj-validator'
import { NumeroInscricaoTextField, DatePicker } from '~/components'
import { AutoCompleteCidade, MUIAutoComplete } from '~/components/AutoComplete'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'

import api from '~/services/api-pessoal'

import * as yup from 'yup'
import { grauParentescoValues, grauParentescoConsts } from '~/values/grauParentescoValues'
import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'
import { obterDataFinalSF } from '~/utils/utils'

const { agregadoOutros_99, filhoOuEnteado_03, filhoOuEnteadoEstudante_04 } = grauParentescoConsts
const { CPF_2 } = tipoInscricaoConsts

const inThisArrayOfOptionsGrauParentescoEnum = [filhoOuEnteado_03, filhoOuEnteadoEstudante_04]

const schema = yup.object().shape({
  grauParentesco: yup
    .string()
    .required('Informe o Grau Parentesco')
    .when(['isDependenteSF'], (isDependenteSF, schema) => {
      if (isDependenteSF) {
        return schema.test(
          'teste',
          "Ao informar 'Dependente SF', apenas as opções ('03 - Filho(a) ou enteado(a)' ou '04 - Filho(a) ou enteado(a), universitário(a) ou cursando escola técnica de 2º grau') podem ser informadas",
          (grauParentesco) => {
            const parsedGrauParentesco = parseInt(grauParentesco)
            if (inThisArrayOfOptionsGrauParentescoEnum.includes(parsedGrauParentesco)) {
              return true
            }
          },
        )
      }

      return schema.required('Informe o Grau Parentesco')
    }),
  nome: yup.string().required('Informe o Nome'),
  nrInscricao: yup
    .string()
    .when(['isDependenteIR'], (isDependenteIR, schema) => {
      if (isDependenteIR === true) {
        return schema
          .required('Informe o CPF')
          .test('cpf-validator', 'Informe um CPF válido', (nrInscricao) => cpf.isValid(nrInscricao))
      }

      return schema
    })
    .test('CPF-Validator', 'Informe um CPF válido', (nrInscricao) => {
      if (nrInscricao?.length > 0) return cpf.isValid(nrInscricao)
      return true
    })
    .nullable(),
  descricaoDependente: yup
    .string()
    .when(['grauParentesco'], (grauParentesco, schema) =>
      parseInt(grauParentesco) === agregadoOutros_99
        ? schema.required('Informe a Descrição do Dependente')
        : schema,
    )
    .nullable(),
  dtNascimento: yup
    .date()
    .required('Informe a Data de Nascimento')
    .typeError('Informe uma data válida')
    .nullable(),
})

const Form = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm, isUsed } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
  }, [_data])

  const handleSubmit = useCallback(() => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/pessoaFisicaDependente/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/pessoaFisicaDependente', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }
    if (data.id) {
      update()
      return
    }
    insert()
    //eslint-disable-next-line
  }, [data, onAfterSubmitForm])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const teste = true

  function disableDependenteSF() {
    if (
      inThisArrayOfOptionsGrauParentescoEnum.includes(data.grauParentesco) ||
      (teste && data.isDependenteSF === false)
    ) {
      return true
    }
    return false
  }

  return (
    <ActionDialog
      title="Cadastro de Pessoa Física | Dependentes"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'md', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            value={data.nome || ''}
            variant="outlined"
            size="small"
            required
            inputProps={{
              maxLength: 100,
            }}
            validationErrors={validationErrors}
            name="nome"
            onChange={(e) => {
              const nome = e.target.value
              setData({ ...data, nome })
            }}
            disabled={isUsed ? true : false}
            title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
          />
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
          <MUIAutoComplete
            label="Grau Parentesco"
            options={grauParentescoValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.grauParentesco}
            required
            validationErrors={validationErrors}
            name="grauParentesco"
            onChange={(e, obj) => {
              const grauParentesco = obj ? obj.value : ''
              setData({
                ...data,
                grauParentesco,
                descricaoDependente: '',
              })
            }}
            disabled={isUsed ? true : false}
            title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePicker
            label="Data Nascimento"
            size="small"
            value={data.dtNascimento || null}
            onChange={(date) => {
              const dtNascimento = date ? date.format('yyyy-MM-DD') : null
              const dtExclusaoSF = dtNascimento ? obterDataFinalSF(dtNascimento) : null
              setData({ ...data, dtNascimento, dtExclusaoSF })
            }}
            required
            validationErrors={validationErrors}
            name="dtNascimento"
            disabled={isUsed ? true : false}
            title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <NumeroInscricaoTextField
            label="CPF"
            name="nrInscricao"
            typeMask={CPF_2}
            validationErrors={validationErrors}
            value={data?.nrInscricao || ''}
            onChange={(e, value) => {
              const nrInscricao = value
              setData({ ...data, nrInscricao })
            }}
            disabled={isUsed ? true : false}
            required={data?.isDependenteIR === true}
            title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <TextField
            label="Matricula"
            fullWidth
            value={data.matricula || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 32,
            }}
            onChange={(e) => {
              const matricula = e.target.value
              setData({ ...data, matricula })
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="descricaoDependente"
            label="Descrição do Dependente"
            variant="outlined"
            size="small"
            required={data?.grauParentesco === agregadoOutros_99}
            disabled={data?.grauParentesco !== agregadoOutros_99 || isUsed}
            fullWidth
            inputProps={{ maxLength: 30 }}
            value={data?.descricaoDependente || ''}
            onChange={(e) => {
              const descricaoDependente = e.target.value || ''
              setData((oldState) => ({
                ...oldState,
                descricaoDependente,
              }))
            }}
            validationErrors={validationErrors}
          />
        </Grid>

        <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
          <TextField
            label="Local Nascimento"
            fullWidth
            value={data.localNascimento || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 80,
            }}
            onChange={(e) => {
              const localNascimento = e.target.value
              setData({ ...data, localNascimento })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <TimePicker
            label="Hora Nascimento"
            fullWidth
            value={data.horaNascimento || null}
            variant="outlined"
            size="small"
            onChange={(time) => {
              const horaNascimento = time
              setData({ ...data, horaNascimento })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <TextField
            label="Cartório"
            fullWidth
            value={data.cartorio || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 80,
            }}
            onChange={(e) => {
              const cartorio = e.target.value
              setData({ ...data, cartorio })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <TextField
            label="Registro"
            fullWidth
            value={data.registro || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 20,
            }}
            onChange={(e) => {
              const registro = e.target.value
              setData({ ...data, registro })
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
          <TextField
            label="Livro"
            fullWidth
            value={data.livro || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 10,
            }}
            onChange={(e) => {
              const livro = e.target.value
              setData({ ...data, livro })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <TextField
            label="Folha"
            fullWidth
            value={data.folha || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 10,
            }}
            onChange={(e) => {
              const folha = e.target.value
              setData({ ...data, folha })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePicker
            label="Registro"
            size="small"
            value={data.dtRegistro || null}
            onChange={(date) => {
              const dtRegistro = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtRegistro })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePicker
            label="Exclusão SF"
            size="small"
            value={data.dtExclusaoSF || null}
            onChange={(date) => {
              const dtExclusaoSF = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtExclusaoSF })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePicker
            label="Exclusão IR"
            size="small"
            value={data.dtExclusaoIR || null}
            onChange={(date) => {
              const dtExclusaoIR = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtExclusaoIR })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePicker
            label="Vacinação"
            size="small"
            value={data.dtCadernetaVacinacao || null}
            onChange={(date) => {
              const dtCadernetaVacinacao = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtCadernetaVacinacao })
            }}
          />
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
          <DatePicker
            label="Frequência Escolar"
            size="small"
            value={data.dtFrequenciaEscolar || null}
            onChange={(date) => {
              const dtFrequenciaEscolar = date ? date.format('yyyy-MM-DD') : null
              setData({ ...data, dtFrequenciaEscolar })
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <AutoCompleteCidade
            label="Registro Cidade"
            value={data?.registroCidadeId || ''}
            optionId="id"
            onChange={(e, registroCidade) => {
              const registroCidadeId = registroCidade?.id || ''
              setData({ ...data, registroCidadeId, registroCidade })
            }}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
          <TextField
            select
            label="Sexo"
            variant="outlined"
            fullWidth
            size="small"
            value={data.sexo || ''}
            onChange={(e) => {
              const sexo = e.target.value
              setData({ ...data, sexo })
            }}
            disabled={isUsed ? true : false}
            title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
          >
            <MenuItem value="M">Masculino</MenuItem>
            <MenuItem value="F">Feminino</MenuItem>
          </TextField>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={6} xs={12}>
          <TextField
            label="Declaração Nascido Vivo"
            fullWidth
            value={data.declaracaoNascidoVivo || ''}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 20,
            }}
            onChange={(e) => {
              const declaracaoNascidoVivo = e.target.value
              setData({ ...data, declaracaoNascidoVivo })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Observação"
            variant="outlined"
            size="small"
            fullWidth
            multiline
            rows={4}
            value={data.observacao || ''}
            inputProps={{
              maxLength: 200,
            }}
            onChange={(e) => {
              const observacao = e.target.value
              setData({ ...data, observacao })
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ContentDivider />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControlLabel
            label="Dependente SF"
            title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
            control={
              <Checkbox
                // checked={data.isDependenteSF}
                checked={data.isDependenteSF || false}
                size="small"
                onChange={(e) => {
                  const isDependenteSF = e.target.checked
                  setData({ ...data, isDependenteSF })
                }}
                color="secondary"
                // disabled={isUsed ? true : false}
                disabled={disableDependenteSF()}
              />
            }
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControlLabel
            label="Dependente IR"
            title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
            control={
              <Checkbox
                checked={data?.isDependenteIR || false}
                size="small"
                onChange={(e) => {
                  const isDependenteIR = e?.target?.checked
                  setData({ ...data, isDependenteIR })
                }}
                color="secondary"
                disabled={isUsed ? true : false}
              />
            }
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControlLabel
            label="Deficiente"
            title={isUsed ? 'Esse campo pode ser alterado em Alterações Cadastrais' : ''}
            control={
              <Checkbox
                checked={data.isDeficiente || false}
                size="small"
                onChange={(e) => {
                  const isDeficiente = e.target.checked
                  setData({ ...data, isDeficiente })
                }}
                color="secondary"
                disabled={isUsed ? true : false}
              />
            }
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}

export default Form
