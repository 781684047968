import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'

import {
  AutoCompleteBanco,
  AutoCompleteBancoAgencia,
  AutoCompleteSociosResponsaveis,
} from '~/components/AutoComplete'
import {
  ActionDialog,
  Button,
  DatePickerNew,
  NumeroInscricaoTextField,
  PDFViewer,
  PrintButton,
} from '~/components'
import VStack from '~/components/VStack'
import HStack from '~/components/HStack'

import { useTableRowsSelectableContext } from '~/contexts/TableRowsSelectableContext'

import { AberturaContaSalarioRelatorioRequestBody } from '~/hooks/queries/SolicitacaoAberturaContaSalario/dtos'
import { VinculoConsulta } from '~/hooks/queries/VinculoConsulta/VinculoConsulta'
import { BancoAgencia } from '~/hooks/queries/BancoAgencia/BancoAgencia'
import { Banco } from '~/hooks/queries/Banco/Banco'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import useForm from '~/hooks/useForm'

import { getDateCurrent } from '~/utils/utils'
import * as yup from 'yup'

const schema = yup.object().shape({
  dataDocumento: yup.string().required('Informe a Data do Documento').nullable(),
  bancoAgencia: yup
    .object()
    .shape({
      id: yup.string().required('Informe a Agência').nullable(),
    })
    .nullable(),
  banco: yup
    .object()
    .shape({
      id: yup.string().required('Informe o Banco').nullable(),
    })
    .nullable(),
  responsavelNome: yup.string().required('Informe o Sócio Responsável pela Empresa').nullable(),
  responsavelNrInscricao: yup.string().required('Informe o CPF do Sócio Diretor').nullable(),
})

interface StateFormProps extends AberturaContaSalarioRelatorioRequestBody {
  bancoAgencia?: BancoAgencia | null
  banco?: Banco | null
  responsavelId?: string
}

const defaultData = {
  dataDocumento: getDateCurrent(),
  vinculosIds: [],
}

interface FormProps {
  isOpen: boolean
  onClose: VoidFunction
}

export default function Form({ isOpen, onClose }: FormProps) {
  const { empregador } = useAmbiente()
  const { idsSelecionados } = useTableRowsSelectableContext<VinculoConsulta>()

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<AberturaContaSalarioRelatorioRequestBody>({
    dataDocumento: '',
    vinculosIds: [],
  })

  function handleCloseActionDialog() {
    onClose()
    setData(defaultData)
  }

  const { data, changeValue, setData, handleSubmit, validationErrors } = useForm<StateFormProps>({
    defaultData,
    schema,
    onSubmit(vData) {
      handleCloseActionDialog()

      const { dataDocumento, bancoAgencia, banco, responsavelNome, responsavelNrInscricao } = vData
      openPDFViewer({
        vinculosIds: idsSelecionados,
        dataDocumento,
        agenciaNome: bancoAgencia?.nome,
        bancoNome: banco?.nome,
        responsavelNome,
        responsavelNrInscricao,
      })
    },
  })

  return (
    <>
      <ActionDialog
        title="Preencha as informações para obter o relatório de conta salário"
        maxWidth="xs"
        isOpen={isOpen}
        onClose={handleCloseActionDialog}
        customActions={
          <HStack gap={8} padding={1} justifyContent="flex-end">
            <Button onClick={handleCloseActionDialog}>Cancelar</Button>
            <PrintButton onClick={() => handleSubmit(data as StateFormProps)} />
          </HStack>
        }
      >
        <VStack minHeight={280} gap={12}>
          <DatePickerNew
            name="dataDocumento"
            label="Data Documento"
            required
            value={data?.dataDocumento || null}
            onChange={(date) => changeValue('dataDocumento', date)}
            validationErrors={validationErrors}
          />
          <AutoCompleteBancoAgencia
            name="bancoAgencia.id"
            label="Agência"
            required
            value={data?.bancoAgencia || null}
            onChange={(_: unknown, bancoAgencia: BancoAgencia) => {
              changeValue('bancoAgencia', bancoAgencia)
            }}
            validationErrors={validationErrors}
          />
          <AutoCompleteBanco
            name="banco.id"
            required
            value={data?.banco || null}
            onChange={(_: unknown, banco: Banco) => {
              changeValue('banco', banco)
            }}
            validationErrors={validationErrors}
          />
          <AutoCompleteSociosResponsaveis
            name="responsavelNome"
            freeSolo
            required
            empregadorId={empregador?.id}
            value={data?.responsavelId || ''}
            onChange={(socioResponsavel) => {
              changeValue('responsavelNrInscricao', socioResponsavel?.pessoaFisica?.nrInscricao)
              changeValue('responsavelNome', socioResponsavel?.pessoaFisica?.nome)
              changeValue('responsavelId', socioResponsavel?.id)
            }}
            validationErrors={validationErrors}
          />
          <NumeroInscricaoTextField
            name="responsavelNrInscricao"
            label="CPF do Sócio Diretor"
            required
            typeMask={TipoInscricaoEnum.CPF_2}
            value={data?.responsavelNrInscricao || ''}
            onChange={(_, value) => changeValue('responsavelNrInscricao', value)}
            validationErrors={validationErrors}
          />
        </VStack>
      </ActionDialog>

      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        title="Relatórios Solicitação Abertura de Conta Salário"
        reportKey="solicitacaoAberturaContaCorrente"
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/SolicitacaoAberturaContaCorrente/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
    </>
  )
}
