import { Box, Link } from '@material-ui/core'
import { ActionDialog, Button, ButtonBox } from '~/components'

import moment from 'moment'

import { IndLancamentoFaltaEnum } from '~/@types/enums/IndLancamentoFaltaEnum'
import { VinculoSabadoCompensado } from '~/hooks/queries/VinculoSabadoCompensado/VinculoSabadoCompensado'
import { VinculoFaltasTipoDiaEnum } from '~/@types/enums/VinculoFaltasTipoDiaEnum'
import { VinculoFaltasRegistroDTO } from '~/hooks/queries/VinculoFaltas/VinculoFaltasDTO'

interface DialogSabadoPerdidoProps {
  data: VinculoSabadoCompensado
  isOpen: boolean
  onClose: () => void
  onClickDate: (data: VinculoFaltasRegistroDTO) => void
}

export default function DialogSabadoPerdido(props: DialogSabadoPerdidoProps) {
  const { data, isOpen, onClose, onClickDate } = props
  return (
    <ActionDialog
      title="Sabádo Compensado Perdido"
      subtitle={data?.dtSabado ? moment(data.dtSabado).format('DD/MM/yyyy') : '-'}
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button onClick={onClose} variant="contained">
            Fechar
          </Button>
        </ButtonBox>
      }
    >
      <Box>
        {data.horasSabado > 0 && (
          <Box>
            O total de{' '}
            <strong>
              {data.horasSabado} {data.horasSabado > 1 ? 'horas' : 'hora'}
            </strong>{' '}
            de compensação do sábado foi perdido em{' '}
            <strong>{data?.dtSabado ? moment(data.dtSabado).format('DD/MM/yyyy') : ''}</strong>.
          </Box>
        )}

        {data.porcentagemHorasSabado > 0 && (
          <Box>
            O total de <strong>{data.porcentagemHorasSabado}%</strong> de compensação do sábado foi
            perdido em{' '}
            <strong>{data?.dtSabado ? moment(data.dtSabado).format('DD/MM/yyyy') : ''}</strong>.
          </Box>
        )}
        <Box>
          A perda ocorreu devido a uma falta registrada em{' '}
          <Link
            component="button"
            variant="body2"
            underline="always"
            onClick={() => {
              if (!data?.vinculoFaltas) return
              const tipoDia =
                data?.vinculoFaltas.indLancamento === IndLancamentoFaltaEnum.Atraso
                  ? VinculoFaltasTipoDiaEnum.Atraso
                  : VinculoFaltasTipoDiaEnum.Falta
              onClickDate({
                id: data.vinculoFaltas.id,
                data: data.vinculoFaltas.dtFalta,
                tipo: tipoDia,
                registro: data.vinculoFaltas,
              })
              onClose()
            }}
          >
            {data?.vinculoFaltas?.dtFalta
              ? moment(data.vinculoFaltas.dtFalta).format('DD/MM/yyyy')
              : ''}
          </Link>
          .
        </Box>
      </Box>
    </ActionDialog>
  )
}
