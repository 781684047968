import { Box } from '@material-ui/core'
import { Finder } from '~/components'
import useForm from '~/hooks/useForm'

export interface FilterStateProps {
  query: string
}

interface FilterProps {
  onChange: (filters: FilterStateProps) => void
}

export default function Filter({ onChange }: FilterProps) {
  const { data } = useForm<FilterStateProps>({ defaultData: { query: '' } })

  function handleSearch(query: string) {
    if (data) {
      onChange({ query })
    }
  }

  return (
    <Box>
      <Finder onSearch={(query) => handleSearch(query)} onClose={() => handleSearch('')} />
    </Box>
  )
}
