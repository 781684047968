import { useQuery } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'

import { HttpObjResponseInterface } from '~/interfaces/HttpObjResponseInterface'
import { HttpRequestInterface } from '~/interfaces'

import api from '~/services/api-pessoal'
import { EstatisticaFuncionariosDTO, EstatisticaFuncionariosRequestParams } from './dtos'

export const query = '/Relatorio/EstatisticaFuncionarios/ObterEstatistica'

async function handleRequest({
  params,
}: HttpRequestInterface<unknown, EstatisticaFuncionariosRequestParams>) {
  const response = await api.get(query, {
    params,
  })
  return response.data
}

export default function useObterEstatisticaFuncionarios(
  props: HttpRequestInterface<unknown, EstatisticaFuncionariosRequestParams>,
) {
  const dialogNotification = useDialogNotification()
  return useQuery<HttpObjResponseInterface<EstatisticaFuncionariosDTO>>(
    [query, props],
    () => {
      return handleRequest(props)
    },
    {
      onError: dialogNotification.extractErrors,
      enabled: props?.enabled,
    },
  )
}
