import { makeStyles } from '@material-ui/core'

const triggerStyles = makeStyles(() => ({
  button: {
    width: '10rem',
    whiteSpace: 'nowrap',
    border: 'solid 1px rgba(0, 0, 0, 0.23)',
    color: '#262626',
    height: '36.98px',
    fontWeight: 'normal',
    fontSize: '0.75rem',
    backgroundColor: 'transparent',
    '&:hover': {
      border: 'solid 1px rgba(0, 0, 0, 1)',
    },
  },
}))

export default triggerStyles
