import { createMuiTheme } from '@material-ui/core/styles'

const primaryLighter = 'rgba(89, 195, 224, 10%);'
const primaryLight = '#83EDFF'
const primaryMain = '#59C3E0'
const primaryDark = '#2C96B3'
const primaryContrastText = '#808080'

const border = '1px solid #D0D0D0'

const theme = createMuiTheme({
  palette: {
    common: { black: '#000', white: '#fff' },
    background: { paper: '#fff', default: '#F2F2F2' },
    primary: {
      lighter: primaryLighter,
      light: primaryLight,
      main: primaryMain,
      dark: primaryDark,
      contrastText: primaryContrastText,
      primaryLighter: primaryLighter,
    },
    secondary: {
      lighter: '#CDEDF6',
      light: '#8BD5E9',
      main: '#59C3E0',
      dark: '#48B5D8',
      darker: '#2EA1CB',
      contrastText: 'rgba(38, 51, 83, 1)',
    },
    grey: {
      300: '#F5F5F5',
    },
    error: {
      lighter: '#F2A9A9',
      light: '#FF6C5F',
      main: '#F44336',
      dark: '#BE0D00',
      contrastText: '#fff',
    },
    warning: {
      lighter: '#F2C07B',
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: '#474747',
    },
    info: {
      lighter: '#96cdf7',
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    success: {
      lighter: '#a3eda6',
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: '#fff',
    },
    alerts: {
      primary: '#0275d8',
      success: '#5cb85c',
      info: '#5bc0de',
      warning: '#f0ad4e',
      danger: '#d9534f',
    },
    text: {
      primary: '#262626',
      secondary: '#808080',
      // primary: 'rgba(0, 0, 0, 0.87)',
      // secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  shape: {
    border: border,
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: 0,
        '&.selected': {
          backgroundColor: '#199',
        },
      },
      head: {
        background: '#FAFAFA',
        border: '1px solid #D0D0D0',
        color: primaryContrastText,
        '& span': {
          color: primaryContrastText,
        },
      },
      body: {
        backgroundColor: '#ffffff00',
        border: '1px solid #D0D0D0',
        borderTop: 'none',
        borderBottom: 'none',
        '& > div': {
          fontSize: '13px',
          paddingLeft: '6px',
          paddingRight: '6px',
          paddingTop: '2px',
          paddingBottom: '2px',
        },
      },
    },
    MUIDataTableHeadCell: {
      // fixedHeader: {
      //   backgroundColor: primaryMain,
      // },
      toolButton: {
        height: '48px',
      },
      sortActive: {
        color: primaryContrastText,
      },
      sortAction: {
        '& path': {
          color: primaryContrastText,
        },
      },
    },

    MUIDataTableSelectCell: {
      headerCell: {
        // backgroundColor: primaryMain,
        color: primaryMain,
        border: '1px solid #D0D0D0',
        // border: border,
      },
      fixedLeft: {
        zIndex: 0,
      },
    },

    MuiTableRow: {
      root: {
        height: '30px',
        '&$hover:hover': { backgroundColor: '#ffffde' },
      },
      head: {
        // backgroundColor: '#F5F5F5',
        borderRadius: '4px',
        '& div': {
          fontWeight: '600',
          fontSize: '12px',
          padding: '4px',
        },
      },
    },

    MUIDataTableBodyCell: {
      root: {
        padding: '0px',
      },
    },
    MUIDataTableBodyRow: {
      root: {
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: '#333',
        },
        '&:nth-child(even)': {
          backgroundColor: '#F5F5F5',
        },
        '&:last-child': {
          borderBottom: '1px solid #D0D0D0',
        },
      },
    },
    MuiTableHead: {
      root: {
        height: '48px',
      },
    },
    MuiOutlinedInput: {
      root: {
        fontSize: '11pt',
      },
      input: {
        // padding: '16px 14px',
        // height: 56,
        // boxSizing: 'border-box',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '10pt',
      },
      outlined: {
        transform: 'translate(14px, 19px) scale(1)',
      },
    },

    MuiFormControlLabel: {
      label: { fontSize: '10pt' },
    },
    // MuiIconButton: {
    //   root: {
    //     '&:focus': {
    //       backgroundColor: 'red',
    //       borderWidth: 1,
    //       borderColor: 'red',
    //     },
    //   },
    // },
    // .MuiListItem-root.Mui-selected,
    // MuiListItemIcon: {
    //   root: {
    //     "&.selected p": {
    //       color: 'rgba(255, 255, 255, 1)',
    //     },
    //   },
    // },

    MuiListItemIcon: {
      root: {
        justifyContent: 'center',
        '& span': {
          color: primaryContrastText,
        },
      },
    },
    MuiListItemText: {
      root: {
        '& p': {
          // color: '#B8C7CE',
          color: primaryContrastText,
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },
    // MuiListItem: {
    //   root: {
    //     "&$selected": {
    //       backgroundColor: "rgba(38, 51, 83, 1)",
    //       "& span, p": {
    //         color: "rgba(255, 255, 255, 1)",
    //       },
    //       "&:hover": {
    //         backgroundColor: "rgba(38, 51, 83, 1)",
    //       },
    //     },
    //   },
    //   button: {
    //     "&:hover": {
    //       backgroundColor: "rgba(0, 0, 0, 0.2)",
    //     },
    //   },
    //   gutters: {
    //     // paddingLeft: 0,
    //     // paddingRight: 0
    //   },
    // },

    MuiToolbar: {
      root: {
        height: 48,
        '&.MuiPickersToolbar-toolbar': {
          backgroundColor: '#fff',
        },
      },
    },

    MuiButtonBase: {
      root: {
        '&.MuiPaginationItem-textSecondary.Mui-selected': {
          color: primaryMain,
          backgroundColor: primaryLighter,
          border: `solid 1px ${primaryMain}`,
          '&:hover': {
            backgroundColor: primaryLighter,
          },
        },
        '&.MuiPickersDay-day': {
          '&:hover': {
            backgroundColor: primaryLighter,
          },
        },
        '&.MuiPickersDay-daySelected': {
          color: '#fff',
        },
        '&.MuiPickersCalendarHeader-iconButton': {
          '&:hover': {
            backgroundColor: primaryLighter,
          },
        },
        '&.MuiPickersToolbarButton-toolbarBtn': {
          '&:hover': {
            backgroundColor: primaryLighter,
          },
        },
        '&.MuiListItem-button': {
          '&:hover': {
            color: primaryMain,
            backgroundColor: primaryLighter,
          },
        },
      },
    },

    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: 'bold',
      },
      outlined: {
        color: primaryMain,
        borderColor: primaryMain,
        background: '#FFF',
        ':disabled': {
          background: 'none',
          boxShadow: 'none',
          borderColor: '#E0E0E0',
        },
      },
      text: {
        fontWeight: 'normal',
      },
      outlinedSecondary: {
        color: '#FF6C5F',
        borderColor: '#FF6C5F',
        '&:hover': {
          borderColor: '#F44336',
          color: '#F44336',
        },
      },
      containedPrimary: {
        background: primaryMain,
        color: '#FFF',
        boxShadow: 'none',
      },
    },

    MuiAppBar: {
      root: {
        height: 48,
      },
      colorPrimary: {
        color: primaryContrastText,
        backgroundColor: primaryMain,
      },
      colorDefault: {
        color: primaryContrastText,
        backgroundColor: '#F2F2F2',
      },
    },
    MuiTabs: {
      root: {
        height: '16px',
      },
      indicator: {
        height: '3px',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        '&$selected': {
          // color: '#fff',
          color: primaryMain,
        },
      },
    },

    MuiPopover: {
      paper: {
        borderRadius: '12px',
      },
    },

    MuiStepIcon: {
      text: {
        fill: '#fff',
      },
    },

    MuiDialogTitle: {
      root: {
        padding: '16px 24px 0px',
      },
    },
    MuiDialog: {
      container: {
        paddingTop: '46px',
      },
    },
  },
  typography: {
    fontFamily: [
      'Source Sans Pro',
      'sans-serif',
      'Roboto',
      'Open Sans',
      'Google Sans',
      'Lucida Sans',
      'Helvetica Neue',
      'Helvetica',
      'Arial Unicode MS',
      'Arial',
      'Verdana',
      'Cormorant',
    ].join(','),
  },
})

export default theme
