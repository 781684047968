import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifySuccess } from '~/utils/notification'

interface RequestProps {
  data: string[]
}

export function useEnviarWhatsApp() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data }: RequestProps) {
    await api.post('/RP/EnviarWhatsApp', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => notifySuccess('Demonstrativos enviados com sucesso.'),
    onError: dialogNotification.extractErrors,
  })
}
