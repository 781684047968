import { FolhaAnaliticaDTO } from '~/hooks/queries/FolhaAnalitica/dtos'

export default function formatarDataFolhaAnalitica(data: FolhaAnaliticaDTO[]) {
  const eventosUnicos = new Set<string>()
  data.forEach((registro) => {
    registro.eventos.forEach((evento) => {
      eventosUnicos.add(evento.nome)
    })
  })

  const colunasEventos = Array.from(eventosUnicos)

  const dadosNormalizados = data.map((registro) => {
    const eventosMap = new Map(registro.eventos.map((evento) => [evento.nome, evento.vrEvento]))

    return {
      nome: registro.nome,
      nrInscricao: registro.nrInscricao,
      vrTotalVencimento: registro.vrTotalVencimento,
      vrTotalDesconto: registro.vrTotalDesconto,
      vrTotalLiquido: registro.vrTotalLiquido,
      ...Object.fromEntries(colunasEventos.map((evento) => [evento, eventosMap.get(evento)])),
    }
  })

  return { colunasEventos, dadosNormalizados }
}
