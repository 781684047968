import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifySuccess } from '~/utils/notification'
import { useInvalidate } from './useObterPorModeloIntegracao'

interface RequestProps {
  params: {
    modeloIntegracaoId: string
    estabelecimentoDestino: string
    estabelecimentoOrigem: string
  }
}

export function useCopiarConfiguracoesGuias() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidate()

  async function handleRequest({ params }: RequestProps) {
    await api.get('/ExportacaoLancamentoConfig/CopiarConfiguracoesGuias', {
      params,
    })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifySuccess('Configurações copiadas com sucesso')
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
