import { useState } from 'react'

import moment from 'moment'
import * as yup from 'yup'

import { Grid } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { ActionDialog, Button, ErrorMessage } from '~/components'
import StepperForm from '~/components/StepperForm'

import MainAfastamento from './MainAfastamento'
import InformacaoCessao from './InformacaoCessao'
import MandatoSindical from './MandatoSindical'
import MandatoEleitoral from './MandatoEleitoral'
import InformacaoRetificacao from './InformacaoRetificacao'

import useValidationErrors from '~/hooks/useValidationErrors'

import {
  VinculoAfastamento,
  VinculoAfastamentoForm,
} from '~/hooks/queries/VinculoAfastamento/VinculoAfastamento'
import { obterTodosErroValidationErrors } from '~/hooks/useUtils'

const schema = yup.object().shape({
  dtInicioAfastamento: yup.string().required('Informe Início').nullable(),
  dtRetorno: yup
    .date()
    .typeError('Informe uma Data válida')
    .when(['dtInicioAfastamento'], (dtInicioAfastamento, schema, { value: dtRetorno }) => {
      if (!dtRetorno) return

      return schema.test(
        'Date-Validator',
        'A Data do Retorno não pode ser inferior a Data do Início',
        (dtRetorno: string) => moment(dtRetorno).isSameOrAfter(dtInicioAfastamento),
      )
    })
    .nullable(),
  dtPrevistaRetorno: yup
    .date()
    .typeError('Informe uma Data válida')
    .when(['dtInicioAfastamento'], (dtInicioAfastamento, schema, { value: dtPrevistaRetorno }) => {
      if (!dtPrevistaRetorno) return
      return schema.test(
        'Date-Validator',
        'A Data de Previsão de Retorno não pode ser inferior a Data do Início',
        (dtPrevistaRetorno: string) => moment(dtPrevistaRetorno).isSameOrAfter(dtInicioAfastamento),
      )
    })
    .nullable(),
  tipoProcesso: yup.string().required('Informe o Tipo Processo'),
  motivoAfastamento: yup.string().required('Informe Motivo Afastamento'),
  tipoAcidenteTransito: yup.string().required('Informe o Tipo de Acidente'),
  origemRetificacao: yup.string().required('Informe a Origem Retificação'),
  sindicalInformacaoOnus: yup.string().required('Informe o Sindical Informação Ônus'),
  cessaoInformacaoOnus: yup.string().required('Informe a Cessão Informação Ônus'),
})

export const titleAllFieldsDisabled = 'Afastamento enviado para o eSocial, não é possível editar.'

export interface ContextProps {
  disabledAllFields: boolean
  disabledFields: string[]
  validationErrors: ValidationErrors
}

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoAfastamentoForm
  onAfterSubmitForm: (afastamento: VinculoAfastamento) => void
  isLoading: boolean
  disabledFields?: string[]
  disabledAllFields?: boolean
}

export default function Form(props: FormProps) {
  const {
    isOpen,
    onClose,
    data: _data,
    onAfterSubmitForm,
    isLoading,
    disabledFields = [],
    disabledAllFields = false,
  } = props

  const [data, setData] = useState(_data)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })
  const errors = obterTodosErroValidationErrors(validationErrors).join(' - ')

  async function handleSubmit() {
    await onAfterSubmitForm(data as VinculoAfastamento)
    onClose()
  }

  return (
    <StepperForm.Provider
      steps={[
        {
          icon: 'account_circle',
          label: 'Dados do Afastamento',
          children: <MainAfastamento />,
        },
        {
          icon: 'assignment',
          label: 'Informação de Cessão',
          children: <InformacaoCessao />,
        },
        {
          icon: 'group',
          label: 'Mandato Sindical',
          children: <MandatoSindical />,
        },
        {
          icon: 'stars',
          label: 'Mandato Eleitoral',
          children: <MandatoEleitoral />,
        },
        {
          icon: 'replay',
          label: 'Informação de retificação',
          children: <InformacaoRetificacao />,
        },
      ]}
      dataControlled={data}
      onChangeControlled={setData}
      context={{ validationErrors, disabledFields, disabledAllFields }}
    >
      <ActionDialog
        title="Cadastro de Afastamento"
        isOpen={isOpen}
        onClose={onClose}
        dialogProps={{
          maxWidth: 'md',
          fullWidth: true,
        }}
        customActions={<></>}
      >
        <Grid container spacing={1}>
          <Grid item xs={1} md={2}>
            <StepperForm.Steps orientation="vertical" />
          </Grid>
          <Grid item xs={11} md={10}>
            <StepperForm.Content />
          </Grid>
          <Grid item xs={12}>
            <ErrorMessage
              error={errors.length > 0 ? ['Validação de Formulário: ', ...errors] : []}
            />
            <ButtonBox>
              <Button onClick={onClose}>Cancelar</Button>
              <Button onClick={handleValidate} isLoading={isLoading} variant="contained">
                Salvar
              </Button>
            </ButtonBox>
          </Grid>
        </Grid>
      </ActionDialog>
    </StepperForm.Provider>
  )
}
