import { Typography } from '@material-ui/core'
import { ActionDialog, Button, ButtonBox } from '~/components'

export default function DialogPerguntaReplicar(props) {
  const { isOpen, onClose, onSubmit } = props

  return (
    <ActionDialog
      title="Replicar configurações?"
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{ maxWidth: 'xs', fullWidth: true }}
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button size="small" color="primary" onClick={onClose}>
            Não
          </Button>
          <Button size="small" color="primary" variant="contained" onClick={onSubmit}>
            Sim
          </Button>
        </ButtonBox>
      }
    >
      <Typography>Deseja replicar as configurações feitas para outros Empregadores?</Typography>
    </ActionDialog>
  )
}
