import { Box, Grid, Paper, useTheme } from '@material-ui/core'

import { AutoCompleteGrupo, MUIAutoComplete } from '~/components/AutoComplete'
import { Checkbox, Finder } from '~/components'

import { TabsApuracao, useApuracaoEncargos } from '../..'

import { IndApuracaoEnum, IndApuracaoValues } from '~/@types/enums/IndApuracaoEnum'

export default function Header() {
  const {
    tab,
    indApuracao,
    grupoId,
    isOnlyError,
    isSomenteNegativos,
    isMostrarTodasEmpresas,
    changeIndApuracao,
    changeGrupoId,
    changeOnlyError,
    changeSomenteNegativos,
    changeQuery,
    changeMostrarTodasEmpresas,
  } = useApuracaoEncargos()

  const theme = useTheme()

  return (
    <Box display="flex" gridGap={theme.spacing(1)}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={tab === TabsApuracao.Apuracao ? 8 : 6}
          lg={tab === TabsApuracao.Apuracao ? 10 : 8}
        >
          <Box component={Paper} padding={1} paddingY={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={5}>
                <MUIAutoComplete
                  label="Indicador de Apuração"
                  options={IndApuracaoValues}
                  optionId="value"
                  renderOption={(option: FixLater) => option.name}
                  value={indApuracao}
                  onChange={(e: FixLater, obj: FixLater) =>
                    changeIndApuracao(obj?.value || IndApuracaoEnum.Mensal_1)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={5}>
                <AutoCompleteGrupo
                  value={grupoId}
                  onChange={(e, grupo) => {
                    changeGrupoId(grupo?.id || null)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={2}>
                <Finder onSearch={changeQuery} onClose={() => changeQuery('')} />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={tab === TabsApuracao.Apuracao ? 4 : 6}
          lg={tab === TabsApuracao.Apuracao ? 2 : 4}
        >
          <Box
            component={Paper}
            padding={1}
            paddingY={1}
            display="flex"
            alignItems="center"
            gridGap={theme.spacing(1)}
            height="100%"
          >
            {tab === TabsApuracao.Demonstracao && (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6}>
                  <Box minWidth={190}>
                    <Checkbox
                      label="Somente com Diferença"
                      value={isOnlyError}
                      onChange={(e, checked) => changeOnlyError(checked)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box minWidth={230}>
                    <Checkbox
                      label="Somente empresas negativas"
                      value={isSomenteNegativos}
                      onChange={(e, checked) => changeSomenteNegativos(checked)}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}

            {tab === TabsApuracao.Apuracao && (
              <Box minWidth={220}>
                <Checkbox
                  label="Mostrar todas empresas"
                  value={isMostrarTodasEmpresas}
                  onChange={(e, checked) => changeMostrarTodasEmpresas(checked)}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
