import { useState, memo } from 'react'
import * as yup from 'yup'
import moment from 'moment'

import { isSameYearAndMonth } from '~/utils/utils'

import { Box, Collapse, Grid, IconButton, Paper } from '@material-ui/core'
import { FilterList } from '@material-ui/icons'

import {
  PageHeader,
  StackContainer,
  Button,
  DatePickerNew,
  SimpleSelect,
  Stack,
  TextField,
} from '~/components'
import AutoCompleteEntidades from '~/components/AutoComplete/AutoCompleteEntidades'

import Table, { PaginationType } from './Table'

import useDialog from '~/hooks/useDialog'
import useCredentials from '~/hooks/useCredentials'
import useValidationErrors from '~/hooks/useValidationErrors'

import DetalhamentoConsulta from './DetalhamentoConsulta'

import {
  OperacaoConsultaEnum,
  OperacaoConsultaValues,
} from '~/hooks/queries-log/@types/OperacaoConsultaEnum'
import { HeaderRequestType } from '~/@types/HeaderRequestType'

import { useConsultaPessoal } from '~/hooks/queries-log/useConsultaPessoal'
import { ConsultaPessoalFilters } from '~/hooks/queries-log/@types/ConsultaPessoalFilters'

const MemoTable = memo(Table)

const schema = yup.object().shape({
  inicio: yup
    .date()
    .required('Informe a Data Início')
    .nullable()
    .typeError('Informe uma data válida'),
  fim: yup
    .date()
    .required('Informe a Data Fim')
    .nullable()
    .typeError('Informe uma data válida')
    .when(['inicio'], (inicio, schema) => {
      return schema.test(
        'same-month',
        'Período deve pertencer ao mesmo Mês/Ano',
        (value: string) => {
          return isSameYearAndMonth(inicio, value)
        },
      )
    }),
  entidade: yup.string().required('Informe a Entidade').nullable(),
})

interface DataProps {
  inicio?: string | Date | null
  fim?: string | Date | null
  entidade: string
  entidadeId: string
  usuarioEmail?: string
  operacao: OperacaoConsultaEnum
}

export default function LogUsuario() {
  const [isOpenFilter, setOpenFilter] = useState(true)
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageSize: 25,
  })

  const { userLogged } = useCredentials()

  const startData = {
    inicio: moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
    fim: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
    operacao: OperacaoConsultaEnum.TodasOperacoes,
    entidadeId: '',
    usuarioEmail: userLogged.email,
    entidade: 'RP',
  }

  const [data, setData] = useState<DataProps>(startData)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const {
    close: closeDetalhamentoConsulta,
    data: dataDetalhamentoConsulta,
    isOpen: isOpenDetalhamentoConsulta,
    open: openDetalhamentoConsulta,
  } = useDialog('')

  const headers: HeaderRequestType = {
    'DC-Page': 1 + pagination.page,
    'DC-PageSize': pagination.pageSize,
  }

  const {
    data: _d,
    isLoading,
    isFetching,
    refetch,
  } = useConsultaPessoal({ params: data as ConsultaPessoalFilters, headers })

  const collection = _d?.data || []

  function handleSubmit() {
    refetch()
  }

  return (
    <StackContainer gap={0}>
      <PageHeader title="Consulta Log">
        <IconButton
          size="small"
          onClick={() => setOpenFilter((prev) => !prev)}
          title="Filtros"
          color="primary"
        >
          <FilterList />
        </IconButton>
      </PageHeader>
      <Collapse in={isOpenFilter}>
        <Box component={Paper} p={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <DatePickerNew
                required
                label="Início"
                name="inicio"
                invalidDateMessage=""
                cancelLabel="Cancelar"
                validationErrors={validationErrors}
                placeholder="Selecione a data inicial"
                KeyboardButtonProps={{ size: 'small' }}
                format="DD/MM/yyyy - HH:mm:ss"
                value={data?.inicio || null}
                onChangeSimple={(date) => {
                  if (!date) return
                  const inicio = date.format('YYYY-MM-DDTHH:mm:ss')
                  setData((prev) => ({ ...prev, inicio }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePickerNew
                required
                label="Fim"
                name="fim"
                invalidDateMessage=""
                cancelLabel="Cancelar"
                validationErrors={validationErrors}
                placeholder="Selecione a data final"
                KeyboardButtonProps={{ size: 'small' }}
                format="DD/MM/yyyy - HH:mm:ss"
                value={data?.fim || null}
                onChangeSimple={(date) => {
                  if (!date) return
                  const fim = date.format('YYYY-MM-DDTHH:mm:ss')
                  setData((prev) => ({ ...prev, fim }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <AutoCompleteEntidades
                name="entidade"
                validationErrors={validationErrors}
                required
                value={data.entidade}
                onChange={(d) => {
                  const entidade = d?.identificacao || ''
                  setData((prev) => ({ ...prev, entidade }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SimpleSelect
                label="Tipo de Operação"
                options={OperacaoConsultaValues}
                optionId="value"
                value={data.operacao}
                renderOption={(d) => d.name}
                onChange={(_, value) => {
                  const operacao = value
                  setData((prev) => ({ ...prev, operacao }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="E-mail / Usuário"
                value={data?.usuarioEmail || ''}
                onChange={(e) => {
                  const usuarioEmail = e.target.value
                  setData((prev) => ({
                    ...prev,
                    usuarioEmail,
                  }))
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Identificação"
                value={data?.entidadeId || ''}
                onChange={(e) => {
                  const entidadeId = e.target.value
                  setData((prev) => ({
                    ...prev,
                    entidadeId,
                  }))
                }}
              />
            </Grid>

            <Stack orientation="horizontal" width="100%" justifyContent="flex-end">
              <Button size="small" variant="outlined" onClick={() => setData(() => startData)}>
                Cancelar
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleValidate}
                isLoading={isLoading}
              >
                Buscar
              </Button>
            </Stack>
          </Grid>
        </Box>
      </Collapse>
      <Box flex={1} mt={1}>
        <MemoTable
          data={collection}
          isFetching={isFetching}
          isLoading={isLoading}
          openDetalhamento={openDetalhamentoConsulta}
          totalRecords={_d?.pagination?.totalRecords || 0}
          changePagination={setPagination}
          pagination={pagination}
        />
        {isOpenDetalhamentoConsulta && (
          <DetalhamentoConsulta
            isOpen={isOpenDetalhamentoConsulta}
            logId={dataDetalhamentoConsulta}
            onClose={closeDetalhamentoConsulta}
          />
        )}
      </Box>
    </StackContainer>
  )
}
