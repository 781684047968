import { cloneElement, ReactElement } from 'react'
import Button from '../Button'
import { useFormContext } from '~/contexts/FormContext'

interface PageContainerAddButtonFormProps {
  initialFormData?: unknown
  asChild?: boolean
  children?: ReactElement
}

export default function PageContainerAddButtonForm({
  initialFormData,
  asChild = false,
  children,
}: PageContainerAddButtonFormProps) {
  const { openFormDialog } = useFormContext()

  function handleOpenFormDialog() {
    openFormDialog(initialFormData)
  }

  return asChild && children ? (
    cloneElement(children, { onClick: handleOpenFormDialog })
  ) : (
    <Button variant="contained" onClick={handleOpenFormDialog}>
      Adicionar
    </Button>
  )
}
