import { Box, Tooltip } from '@material-ui/core'
import { Button } from '~/components'
import footerStyles from './footerStyles.ts'

interface FooterProps {
  onClickClose: VoidFunction
  onClickGoBackToCurrentYearAndMonth: VoidFunction
}

export default function Footer({ onClickClose, onClickGoBackToCurrentYearAndMonth }: FooterProps) {
  const classes = footerStyles()

  return (
    <Box component="footer" className={classes.container}>
      <Button variant="text" className={classes.button} onClick={onClickClose}>
        Cancelar
      </Button>
      <Tooltip title="Voltar ao ano/mês atual">
        <Box>
          <Button
            className={classes.button}
            variant="text"
            onClick={onClickGoBackToCurrentYearAndMonth}
          >
            Mês Atual
          </Button>
        </Box>
      </Tooltip>
    </Box>
  )
}
