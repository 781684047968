import { MUIAutoComplete } from '~/components/AutoComplete'

import { useLogUsuarioObterEntidades } from '~/hooks/queries/LogUsuario/useLogUsuarioObterEntidades'
import { LogUsuarioEntidadeDTO } from '~/hooks/queries/LogUsuario/interfaces/LogUsuarioEntidadeDTO'

import { MUIAutoCompleteProps } from '../MUIAutoComplete/MUIAutoCompleteProps'

interface AutoCompleteEntidadesProps extends MUIAutoCompleteProps {
  value: string
  onChange: (value: LogUsuarioEntidadeDTO | null) => void
}

export default function AutoCompleteEntidades(props: AutoCompleteEntidadesProps) {
  const { value, onChange, ...rest } = props

  const {
    data: _data,
    isLoading: _isLoading,
    isFetching: _isFetching,
    refetch,
  } = useLogUsuarioObterEntidades()
  const options = _data || []
  const isLoading = _isLoading || _isFetching

  const renderOption = (option: LogUsuarioEntidadeDTO) => option.descricao

  return (
    <MUIAutoComplete
      isLoading={isLoading}
      options={options}
      getOptions={() => refetch()}
      renderOption={renderOption}
      label="Entidades"
      value={value}
      onChange={(_, obj: LogUsuarioEntidadeDTO) => onChange(obj)}
      optionId="identificacao"
      {...rest}
    />
  )
}
