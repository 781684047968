import React, { useEffect, useState } from 'react'

import { Grid, Collapse, IconButton, Box, Paper } from '@material-ui/core'
import { Button, PageHeader, ButtonBox } from 'mio-library-ui'
import { RiFilterLine, RiFilterOffLine } from 'react-icons/ri'

import { AnoMesTextField } from '~/components'
import { AutoCompleteEstabelecimento } from '~/components/AutoComplete'
import MultipleSelect from '~/components/MultipleSelect'

import Table from './components/Table'

import useDialogNotification from '~/hooks/useDialogNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'

import api from '~/services/api-pessoal'

import * as yup from 'yup'
import moment from 'moment'

import { ReciboIDFEnum, ReciboIDFValues } from '~/@types/enums/ReciboIDFEnum'

const optionMensal = ReciboIDFValues.find((d) => d.value === ReciboIDFEnum.Mensal_1)

const schema = yup.object().shape({
  estabelecimento: yup.string().required('Informe um Estabelecimento').nullable(),
  reciboIdf: yup.string().required('Informe a Identificação do Recibo'),
  anoMesInicio: yup
    .string()
    .required('Informe o Ano/Mês Inicial')
    .min(6, 'Informe o Ano/Mês válido'),
  anoMesFim: yup
    .string()
    .required('Informe o Ano/Mês Final')
    .min(6, 'Informe o Ano/Mês válido')
    .when(['anoMesInicio'], (anoMesInicio, schema, { value }) => {
      if (moment(anoMesInicio).isAfter(value)) {
        return schema.min(anoMesInicio, 'Ano/Mês Final deve ser maior que Ano Inicial')
      }
    }),
})

const ConsultaRPEstabelecimento = () => {
  const [data, setData] = useState({
    estabelecimento: '',
    anoMesInicio: '',
    anoMesFim: '',
  })
  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })
  const [formCollapseIsOpen, setFormCollapseOpen] = useState(true)
  const [rowsExpanded, setRowsExpanded] = useState([])

  const { estabelecimento, anoMes, ano } = useAmbiente()
  const dialogNotification = useDialogNotification()

  useEffect(() => {
    setRowsExpanded([])
    setData({
      anoMesInicio: `${ano}01`,
      anoMesFim: anoMes,
      estabelecimento: estabelecimento,
      reciboIdf: [optionMensal],
    })
    setCollection((oldState) => ({ ...oldState, itens: [] }))
  }, [anoMes, ano, estabelecimento])

  useEffect(() => {
    const resetTable = () => {
      setRowsExpanded([])
      setCollection({ isLoading: false, itens: [] })
    }
    resetTable()
  }, [data.estabelecimento, data.anoMesInicio, data.anoMesFim, data.reciboIdf])

  const handleSearch = async () => {
    setCollection((oldState) => ({
      ...oldState,
      isLoading: true,
    }))

    try {
      const dataToSend = data?.reciboIdf.map((d) => d.value)
      const response = await api.post('/Vinculo/GetVinculoRP', dataToSend,{
        params: {
          estabelecimentoId: data?.estabelecimento?.id,
          anoMesInicio: data?.anoMesInicio,
          anoMesFim: data?.anoMesFim,
        },
      })
      if (response.data.data) {
        setCollection((oldState) => ({
          ...oldState,
          itens: response.data.data,
          isLoading: false,
        }))
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setCollection((oldState) => ({
      ...oldState,
      isLoading: false,
    }))
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleSearch,
    data,
  })

  return (
    <Box height="100%" width="100%" p={2}>
      <Box>
        <PageHeader title="Consultar Recibos por Estabelecimento">
          <IconButton size="small" onClick={() => setFormCollapseOpen(!formCollapseIsOpen)}>
            {formCollapseIsOpen ? <RiFilterOffLine /> : <RiFilterLine />}
          </IconButton>
        </PageHeader>

        <Box component={Paper} padding={2}>
          <Collapse in={formCollapseIsOpen}>
            <Grid container spacing={2}>
              <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                <AutoCompleteEstabelecimento
                  name="estabelecimento"
                  validationErrors={validationErrors}
                  value={data?.estabelecimento || null}
                  onChange={(e, estabelecimento) => {
                    const estabelecimentoId = estabelecimento?.id || ''
                    setData((oldState) => ({
                      ...oldState,
                      estabelecimentoId,
                      estabelecimento,
                    }))
                  }}
                />
              </Grid>

              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <MultipleSelect
                  inputProps={{
                    label: 'Identificação do Recibo',
                  }}
                  validationErrors={validationErrors}
                  name="reciboIdf"
                  value={data?.reciboIdf || []}
                  options={ReciboIDFValues}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, selecteds) => {
                    const reciboIdf = selecteds
                    setData((oldState) => ({
                      ...oldState,
                      reciboIdf,
                    }))
                  }}
                />
              </Grid>

              <Grid item xl={2} lg={2} md={2} sm={4} xs={6}>
                <AnoMesTextField
                  label="Ano/Mês Início"
                  fullWidth
                  bottom={1}
                  variant="outlined"
                  size="small"
                  validationErrors={validationErrors}
                  name="anoMesInicio"
                  value={data?.anoMesInicio}
                  onChange={(e) => {
                    const anoMesInicio = e.target.value.replace('/', '')
                    setData((oldState) => ({ ...oldState, anoMesInicio }))
                  }}
                />
              </Grid>

              <Grid item xl={2} lg={2} md={2} sm={4} xs={6}>
                <AnoMesTextField
                  label="Ano/Mês Fim"
                  fullWidth
                  bottom={1}
                  variant="outlined"
                  size="small"
                  validationErrors={validationErrors}
                  name="anoMesFim"
                  value={data?.anoMesFim}
                  onChange={(e) => {
                    const anoMesFim = e.target.value.replace('/', '')
                    setData((oldState) => ({ ...oldState, anoMesFim }))
                  }}
                />
              </Grid>

              <Grid item xl={8} lg={8} md={8} sm={4} xs={12}>
                <ButtonBox bottom={2}>
                  <Box>
                    <Button
                      size="small"
                      color="primary"
                      onClick={handleValidate}
                      variant="contained"
                    >
                      Buscar
                    </Button>
                  </Box>
                </ButtonBox>
              </Grid>
            </Grid>
          </Collapse>
        </Box>
      </Box>

      <Table
        data={collection.itens}
        isLoading={collection.isLoading}
        anoMesInicio={data?.anoMesInicio}
        anoMesFim={data?.anoMesFim}
        reciboIdf={data?.reciboIdf}
        triggersHeight={{ formCollapseIsOpen, validationErrors }}
        setRowsExpanded={setRowsExpanded}
        rowsExpanded={rowsExpanded}
      />
    </Box>
  )
}

export default ConsultaRPEstabelecimento
