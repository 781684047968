import { createContext, useContext, useState } from 'react'

import { Box, Tab, Tabs, useTheme } from '@material-ui/core'

import { PageHeader, TabPanel } from '~/components'

import DemonstracaoApuracao from './components/DemonstracaoApuracao'
import CalcularApuracao from './components/CalcularApuracao'
import Header from './components/Header'
import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'

export enum TabsApuracao {
  Apuracao,
  Demonstracao,
}

interface ContextApuracaoEncargosProps {
  tab: TabsApuracao
  indApuracao: IndApuracaoEnum
  grupoId: string | null
  isOnlyError: boolean
  isSomenteNegativos: boolean
  isMostrarTodasEmpresas: boolean
  query: string
  changeTab: (tab: TabsApuracao) => void
  changeIndApuracao: (indApuracao: IndApuracaoEnum) => void
  changeGrupoId: (grupoId: string | null) => void
  changeOnlyError: (isOnlyError: boolean) => void
  changeSomenteNegativos: (isSomenteNegativos: boolean) => void
  changeMostrarTodasEmpresas: (isMostrarTodasEmpresas: boolean) => void
  changeQuery: (grupoId: string) => void
}

const ContextApuracaoEncargos = createContext<ContextApuracaoEncargosProps>({
  tab: TabsApuracao.Apuracao,
  indApuracao: IndApuracaoEnum.Mensal_1,
  grupoId: null,
  isOnlyError: false,
  isSomenteNegativos: false,
  isMostrarTodasEmpresas: false,
  query: '',
  changeTab: () => undefined,
  changeIndApuracao: () => undefined,
  changeGrupoId: () => undefined,
  changeSomenteNegativos: () => undefined,
  changeOnlyError: () => undefined,
  changeQuery: () => undefined,
  changeMostrarTodasEmpresas: () => undefined,
})

export function useApuracaoEncargos() {
  return useContext(ContextApuracaoEncargos)
}

export default function ApuracaoEncargos() {
  const [tab, setTab] = useState(TabsApuracao.Apuracao)
  const [indApuracao, setApuracao] = useState<IndApuracaoEnum>(IndApuracaoEnum.Mensal_1)
  const [grupoId, setGrupoId] = useState<string | null>(null)
  const [isOnlyError, setOnlyError] = useState<boolean>(false)
  const [isSomenteNegativos, setSomenteNegativos] = useState<boolean>(false)
  const [isMostrarTodasEmpresas, setMostrarTodasEmpresas] = useState(false)
  const [query, setQuery] = useState<string>('')

  const theme = useTheme()

  function changeTab(tab: TabsApuracao) {
    setTab(tab)
  }

  function changeIndApuracao(indApuracao: IndApuracaoEnum) {
    setApuracao(indApuracao)
  }

  function changeGrupoId(grupoId: string | null) {
    setGrupoId(grupoId)
  }

  function changeQuery(query: string) {
    setQuery(query)
  }

  function changeOnlyError(isOnlyError: boolean) {
    setOnlyError(isOnlyError)
  }

  function changeSomenteNegativos(isSomenteNegativos: boolean) {
    setSomenteNegativos(isSomenteNegativos)
  }

  function changeMostrarTodasEmpresas(isMostrarTodasEmpresas: boolean) {
    setMostrarTodasEmpresas(isMostrarTodasEmpresas)
  }

  return (
    <ContextApuracaoEncargos.Provider
      value={{
        tab,
        indApuracao,
        grupoId,
        isOnlyError,
        isSomenteNegativos,
        isMostrarTodasEmpresas,
        query,
        changeTab,
        changeIndApuracao,
        changeGrupoId,
        changeOnlyError,
        changeSomenteNegativos,
        changeMostrarTodasEmpresas,
        changeQuery,
      }}
    >
      <Box
        padding={2}
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(1)}
      >
        <PageHeader title="Apuração de Encargos">
          <Box>
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, value) => setTab(value)}
            >
              <Tab label="Apuração" />
              <Tab label="Demonstração" />
            </Tabs>
          </Box>
        </PageHeader>

        <Header />

        <Box flex={1}>
          <TabPanel value={tab} index={TabsApuracao.Apuracao} style={{ height: '100%' }}>
            <CalcularApuracao />
          </TabPanel>
          <TabPanel value={tab} index={TabsApuracao.Demonstracao} style={{ height: '100%' }}>
            <DemonstracaoApuracao />
          </TabPanel>
        </Box>
      </Box>
    </ContextApuracaoEncargos.Provider>
  )
}
