import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { EmpregadorConsulta } from '../EmpregadorConsulta/EmpregadorConsulta'
import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'

const query = '/ApurarEncargos/ObterEmpregadores'

interface RequestProps {
  params: {
    anoMes: string
    grupoId?: string
    indApuracao: IndApuracaoEnum
    isMostrarTodasEmpresas: boolean
  }
}

export function useObterEmpregadores({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: EmpregadorConsulta[] }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
