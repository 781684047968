import { Box, IconButton, Tooltip } from '@material-ui/core'
import { Cached } from '@material-ui/icons'
import { ReactElement } from 'react'

interface AlternateButtonProps {
  title?: string
  isActive: boolean
  show: boolean
  icon?: ReactElement
  onClick: () => void
}

export default function AlternateButton({
  title,
  isActive,
  show,
  icon = <Cached />,
  onClick,
}: AlternateButtonProps) {
  return (
    <Box display={show || isActive ? 'initial' : 'none'}>
      <Tooltip
        arrow
        title={
          title
            ? title
            : isActive
            ? 'Mostrar informações relativas a empresa do ambiente'
            : 'Mostrar informações de todas as empresas'
        }
      >
        <IconButton size="small" onClick={() => onClick()}>
          <Box
            color={isActive ? 'common.white' : 'primary.main'}
            borderRadius="50%"
            display="flex"
            alignItems="center"
            padding={0.5}
            bgcolor={isActive ? 'primary.main' : 'common.white'}
            sx={{
              '&:hover': {
                transition: '0.5s',
                opacity: 0.6,
              },
            }}
          >
            {icon}
          </Box>
        </IconButton>
      </Tooltip>
    </Box>
  )
}
