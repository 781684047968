import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifyPut } from '~/utils/notification'
import { useQueryClient } from './useGetProvisaoFeriasByEstabelecimento'

import { ProvisaoCompetenciaFeriasAtualizarDTO } from './interfaces/ProvisaoCompetenciaFeriasAtualizarDTO'

interface RequestProps {
  data: ProvisaoCompetenciaFeriasAtualizarDTO
  params: {
    id: string
  }
}

export function useAtualizarProvisaoFerias() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useQueryClient()

  async function handleRequest({ data, params }: RequestProps) {
    await api.put('/ProvisaoCompetencia/AtualizarProvisaoFerias', data, { params })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPut()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
