import { cloneElement, ReactElement } from 'react'
import { Popover as MuiPopover, PopoverProps as MuiPopoverProps } from '@material-ui/core'

interface PopoverProps extends Omit<MuiPopoverProps, 'open'> {
  open?: boolean
  children: ReactElement
}

export default function Popover({ open, anchorEl, children, onClose }: PopoverProps) {
  return (
    <MuiPopover
      open={open || false}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      PaperProps={{ style: { borderRadius: '8px' } }}
    >
      {cloneElement(children, { onClose })}
    </MuiPopover>
  )
}
