import { PDFViewer, PrintButton } from '~/components'
import { useTransferDataContext } from '~/contexts/TransferDataContext'

import {
  EstatisticaFuncionariosDTO,
  EstatisticaFuncionariosRelatorioRequestParams,
} from '~/hooks/queries/EstatisticaFuncionarios/dtos'
import useDialog from '~/hooks/useDialog'

export default function Impressao() {
  const { dataTransfer } = useTransferDataContext<EstatisticaFuncionariosDTO>()

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<EstatisticaFuncionariosRelatorioRequestParams>({
    exercicio: 0,
    saldoInicial: 0,
    admitidos: 0,
    demitidos: 0,
    saldoFinal: 0,
    saldo1: 0,
    saldo2: 0,
    saldo3: 0,
    saldo4: 0,
    saldo5: 0,
    saldo9: 0,
  })

  function handleOpenPDFViewer() {
    openPDFViewer(dataTransfer)
  }

  return (
    <>
      <PrintButton onClick={handleOpenPDFViewer} />
      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        title="Relatórios Total de Funcionários para CEF"
        reportKey="estatisticaFuncionarios"
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/EstatisticaFuncionarios/ObterRelatorio',
          data: [dataPDFViewer],
        }}
      />
    </>
  )
}
