import { useEffect, useState } from 'react'

import api from '~/services/api-pessoal'

import { Box, Divider, Grid, Typography, useTheme } from '@material-ui/core'

import { MUIAutoComplete } from '~/components/AutoComplete'
import { Button, Checkbox, ContentDivider, DatePickerNew } from '~/components'

import useOnlyDataC from '~/hooks/useOnlyDataC'

import { tipoLicencaValues, tipoLicencaConsts } from '~/values/tipoLicencaValues'
import { indCalculoFeriasValues, indCalculoFeriasConsts } from '~/values/indCalculoFeriasValues'

import { ParametrosSistema } from '~/hooks/queries/ParametrosSistema/ParametrosSistema'
import { VersaoResponse } from '~/hooks/queries/Versao/interfaces/VersaoResponse'

const numerosMesAvisoFerias = [
  { value: 1, name: '1' },
  { value: 2, name: '2' },
  { value: 3, name: '3' },
  { value: 4, name: '4' },
  { value: 5, name: '5' },
  { value: 6, name: '6' },
  { value: 7, name: '7' },
  { value: 8, name: '8' },
  { value: 9, name: '9' },
  { value: 10, name: '10' },
  { value: 11, name: '11' },
  { value: 12, name: '12' },
]

interface StepConfiguracoesProps {
  isLoading: boolean
  data: ParametrosSistema
  onSubmit: (data: ParametrosSistema) => void
}

export default function StepConfiguracoes({
  isLoading,
  data: _data,
  onSubmit,
}: StepConfiguracoesProps) {
  const [data, setData] = useState(_data)

  const [serverVersion, setServerVersion] = useState<VersaoResponse>({
    serverVersion: '',
    serverDateVersion: '',
    serverDisplayStatus: '',
    serverDataHora: '',
    backgroundTaskQueueCount: 0,
    documento: '',
    clientMinimumVersion: '',
    clientURLDownloadVersion: '',
  })

  const { isUserDataC } = useOnlyDataC()
  const theme: FixLater = useTheme()

  useEffect(() => {
    async function getVersionAPI() {
      if (!isUserDataC) return
      const response = await api.get<VersaoResponse>('/versao')
      setServerVersion(response?.data)
    }
    getVersionAPI()
  }, [isUserDataC])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MUIAutoComplete
          label="Número de meses para Aviso de Férias"
          value={data?.numeroMesAvisoFerias || 1}
          options={numerosMesAvisoFerias}
          optionId="value"
          renderOption={(opt) => opt.name}
          onChange={(e, obj) => {
            const numeroMesAvisoFerias = obj?.value || 1
            setData((prev) => ({
              ...prev,
              numeroMesAvisoFerias,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <MUIAutoComplete
          label="Dias Base de Cálculo de Férias"
          options={indCalculoFeriasValues}
          renderOption={(opt) => opt.name}
          optionId="value"
          value={data.indCalculoFerias}
          onChange={(_, obj) => {
            setData((prev) => ({
              ...prev,
              indCalculoFerias: obj?.value || indCalculoFeriasConsts.baseCalculoTrintaDias,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Relacionar Evento Empregador automaticamente"
          value={data.isRelacionarEventoEmpregadorAutomatico}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isRelacionarEventoEmpregadorAutomatico: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Não gerar auditoria para os Eventos S-2220 e S-2221"
          value={data.isNaoGerarAuditoria2220_2221}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isNaoGerarAuditoria2220_2221: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Remover Recibos Mensais Automaticamente ao criar uma Rescisão"
          value={data.isExcluirRecibosAutomaticoEmTRCT}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isExcluirRecibosAutomaticoEmTRCT: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Realizar a tributação do auxílio enfermidade"
          value={data.isAfastamentoEnfermidadeTributavel}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isAfastamentoEnfermidadeTributavel: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Calcular encargos automaticamente ao fazer o fechamento da competência"
          value={data.isCalcularEncargosAutomaticamenteFechamento}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isCalcularEncargosAutomaticamenteFechamento: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Iniciar Filtro do Recibo de Pagamento como 'TODOS'"
          value={data.isIniciarFiltroReciboComTodos}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isIniciarFiltroReciboComTodos: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Não exibir eventos FGTS no Recibo de Pagamento"
          value={data.isNaoExibirEventosFGTSRecibo}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isNaoExibirEventosFGTSRecibo: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Não exibir colunas de incidências no Recibo de Pagamento"
          value={data.isNaoExibirColunasIncidenciasRecibo}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isNaoExibirColunasIncidenciasRecibo: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Não inserir evento Dias Normais para Comissionista Integral"
          value={data.isNaoInserirEventoDiasNormaisComissionistaIntegral}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isNaoInserirEventoDiasNormaisComissionistaIntegral: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <ContentDivider title="Pró-Labore" />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Pró-Labore proporcional na admissão"
          value={data.isProLaboreProporcionalAdmissao}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isProLaboreProporcionalAdmissao: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Pró-Labore proporcional na rescisão"
          value={data.isProLaboreProporcionalRescisao}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isProLaboreProporcionalRescisao: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          label="Pró-Labore proporcional no afastamento"
          value={data.isProLaboreProporcionalAfastamento}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isProLaboreProporcionalAfastamento: checked,
            }))
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      {/* <Grid item xs={12}>
        <Checkbox
          label="Não validar Fechamento do eSocial"
          value={data.isNaoValidarFechamentoESocial}
          onChange={(_, checked) => {
            setData((prev) => ({
              ...prev,
              isNaoValidarFechamentoESocial: checked,
            }))
          }}
        />
      </Grid> */}

      <Grid item xs={12}>
        <DatePickerNew
          label="Apresentar registros inativos a partir da data"
          value={data.dtApresentarInativos || null}
          onChange={(date) => {
            const dtApresentarInativos = date
            setData((prev) => ({ ...prev, dtApresentarInativos }))
          }}
        />
      </Grid>

      {isUserDataC && (
        <>
          <Grid item xs={12}>
            <MUIAutoComplete
              label="Tipo de Licença"
              options={tipoLicencaValues}
              renderOption={(opt) => opt.name}
              optionId="value"
              value={data.tipoLicenca}
              onChange={(_, obj) => {
                setData((prev) => ({
                  ...prev,
                  tipoLicenca: obj?.value || tipoLicencaConsts.normal_1,
                }))
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Checkbox
              label="Sistema Gerencial"
              value={data.isSistemaGerencial}
              onChange={(_, checked) => {
                setData((prev) => ({
                  ...prev,
                  isSistemaGerencial: checked,
                }))
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box border={theme.shape.border} borderRadius={theme.shape.borderRadius} padding={1}>
              <Typography>Versão do servidor: {serverVersion.serverVersion}</Typography>
              <Typography>Data da versão: {serverVersion.serverDateVersion}</Typography>
              <Typography>Status do servidor: {serverVersion.serverDisplayStatus}</Typography>
              <Typography>Data/hora do servidor: {serverVersion.serverDataHora}</Typography>
              <Typography>Fila processamento: {serverVersion.backgroundTaskQueueCount}</Typography>
              <Typography>Documento: {serverVersion.documento}</Typography>
            </Box>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end" gridGap={theme.spacing(1)}>
          <Button onClick={() => setData(_data)} variant="outlined">
            Cancelar
          </Button>
          <Button onClick={() => onSubmit(data)} variant="contained" isLoading={isLoading}>
            Salvar
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
