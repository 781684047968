import ReactJson from 'react-json-view'

import { formatSimpleDateTime } from '~/utils/utils'

import {
  Box,
  CircularProgress,
  Divider,
  LinearProgress,
  Typography,
  makeStyles,
} from '@material-ui/core'

import { ActionDialog, Button, ButtonBox, LabelDescription, Stack } from '~/components'

import { useConsultaPessoalDetalhado } from '~/hooks/queries-log/useConsultaPessoalDetalhado'

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
}))

interface DetalhamentoConsultaProps {
  isOpen: boolean
  logId: string
  onClose: () => void
}

export default function DetalhamentoConsulta({
  isOpen,
  logId,
  onClose,
}: DetalhamentoConsultaProps) {
  const { data, isLoading, isFetching } = useConsultaPessoalDetalhado({ params: { id: logId } })

  const classes = useStyles()

  return (
    <ActionDialog
      title="Detalhamento da Consulta"
      isOpen={isOpen}
      onClose={onClose}
      dialogProps={{
        maxWidth: 'lg',
        fullWidth: true,
        classes: {
          paper: classes.fullHeight,
        },
      }}
      customActions={
        <ButtonBox bottom={1} right={1} top={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
    >
      <Stack height="100%" gap={0.5}>
        {data && (
          <Box border={0.5} borderColor="gray" borderRadius={4} p={1}>
            <Stack orientation="horizontal">
              <LabelDescription
                label="Data/Hora"
                description={formatSimpleDateTime(data.dataHora)}
              />
              <LabelDescription label="E-mail" description={data.usuarioEmail} />
              <LabelDescription label="Usuário" description={data.usuarioNome} />
              <LabelDescription label="Operação" description={data.operacao} />
              <LabelDescription label="Entidade" description={data.entidade} />
            </Stack>
            <Box maxHeight="50px" overflow="auto">
              <LabelDescription label="Descrição" description={data.descricao} />
            </Box>
          </Box>
        )}
        <Box height={4}>{isFetching && <LinearProgress />}</Box>
        <Box flex={1} overflow="auto">
          {isLoading ? (
            <Box height="100%" display="flex" alignItems="center" justifyContent="center" p={4}>
              <CircularProgress size={80} />
            </Box>
          ) : (
            <Stack orientation="horizontal" gap={2} height="100%">
              <Stack
                border={0.5}
                borderColor="gray"
                p={1}
                borderRadius={4}
                height="100%"
                width="100%"
              >
                <Typography
                  style={{
                    fontWeight: 'bolder',
                    fontSize: '11pt',
                    color: '#666',
                  }}
                >
                  Valor anterior
                </Typography>
                <Divider />
                <Box overflow="auto">
                  <ReactJson src={data?.valorAnterior || {}} />
                </Box>
              </Stack>
              <Stack
                border={0.5}
                borderColor="gray"
                p={1}
                borderRadius={4}
                height="100%"
                width="100%"
              >
                <Typography
                  style={{
                    fontWeight: 'bolder',
                    fontSize: '11pt',
                    color: '#666',
                  }}
                >
                  Valor atual
                </Typography>
                <Divider />
                <Box overflow="auto">
                  <ReactJson src={data?.valorAtual || {}} />
                </Box>
              </Stack>
            </Stack>
          )}
        </Box>
      </Stack>
    </ActionDialog>
  )
}
