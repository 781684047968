import { ButtonBox } from 'mio-library-ui'

import { IconButton } from '@material-ui/core'
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'

import { ToolsDataTable } from '~/components'

import { useObterPorEstabelecimento } from '~/hooks/queries/EventoAutomaticoEstabelecimento/useObterPorEstabelecimento'
import { formatCurrency } from '~/hooks/useUtils'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { formatSimpleDate } from '~/utils/utils'

interface TableProps {
  estabelecimentoId: string
  onItemClick: (event: 'edit' | 'delete', idRow: string) => void
  query: string
}

export default function Table(props: TableProps) {
  const { estabelecimentoId, onItemClick, query } = props

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterPorEstabelecimento({
    params: {
      estabelecimentoId,
    },
  })

  const data = _d || []

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigo',
      label: 'Código',
    },
    {
      name: 'nome',
      label: 'Evento',
    },
    {
      name: 'dtInicio',
      label: 'Data Início',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'dtFim',
      label: 'Data Fim',
      options: {
        customBodyRender: formatSimpleDate,
      },
    },
    {
      name: 'quantidade',
      label: 'Quantidade',
      options: {
        customBodyRender: formatCurrency,
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'vrEvento',
      label: 'Valor do Evento',
      options: {
        customBodyRender: formatCurrency,
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'observacao',
      label: 'Observação',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
                size="small"
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      sherlock={{
        query,
        columns: ['codigo', 'nome', 'dtInicio', 'dtFim'],
      }}
    />
  )
}
