import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'
import { openDownloadData } from '~/hooks/useUtils'

import { HttpRequestInterface } from '~/interfaces'
import { notifySuccess } from '~/utils/notification'
import api from '~/services/api-pessoal'
import { FolhaAnaliticaRequestParams } from './dtos'

const query = '/Relatorio/FolhaAnalitica/ObterRelatorio/Excel'

async function handleRequest({
  data,
  params,
}: HttpRequestInterface<number[], FolhaAnaliticaRequestParams>) {
  const response = await api.post(query, data, {
    headers: {
      'DC-XlsResponse': true,
    },
    responseType: 'blob',
    params,
  })
  return response.data
}

export default function useObterFolhaAnaliticaExcel() {
  const dialogNotification = useDialogNotification()

  return useMutation(handleRequest, {
    onSuccess: async (data) => {
      notifySuccess('Relatório gerado com sucesso')
      openDownloadData('folha-analitica.xlsx', data)
    },
    onError: dialogNotification.extractErrors,
  })
}
