import { FullDialog } from '~/components'
import { TITLE_PAGE } from '..'
import { useObterArquivo } from '~/hooks/queries/FGTSDigital/useObterArquivo'
import { Box, LinearProgress } from '@material-ui/core'

interface DialogFileProps {
  isOpen: boolean
  onClose: () => void
  id: string
}

export default function DialogFile({ isOpen, onClose, id }: DialogFileProps) {
  const { data, isLoading, isFetching } = useObterArquivo({
    params: {
      guiaFGTSDigitalId: id,
    },
  })

  return (
    <FullDialog isOpen={isOpen} onClose={onClose} title={TITLE_PAGE}>
      <Box height={4}>{(isFetching || isLoading) && <LinearProgress />}</Box>
      <iframe
        title={TITLE_PAGE}
        src={data ? 'data:application/pdf;base64,' + data : ''}
        height="100%"
        width="100%"
      />
    </FullDialog>
  )
}
