import React, { useEffect, useState, useCallback, memo } from 'react'

import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Collapse,
  Box,
  Paper,
} from '@material-ui/core'
import { Button, PageHeader, ButtonBox, Finder } from 'mio-library-ui'
import { RiFilterLine, RiFilterOffLine } from 'react-icons/ri'
import { NearMe as NearMeIcon } from '@material-ui/icons'

import Table from './components/Table'
import AvisoArquivoJaGerado from './components/AvisoArquivoJaGerado'
import FormSalarios from './components/FormSalarios'

import { AlertContainer, DatePicker } from '~/components'

import useDialogNotification from '~/hooks/useDialogNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useUtils from '~/hooks/useUtils'

import api from '~/services/api-pessoal'

import moment from 'moment'
import * as yup from 'yup'

const schema = yup.object().shape({
  dtInicio: yup.date().required('Informe a Data de Início').nullable().typeError(''),
  dtFim: yup
    .date()
    .required('Informe a Data de Fim')
    .nullable()
    .typeError('')
    .when('dtInicio', (dtInicio, schema, { value }) => {
      const dtFim = moment(value).format('yyyy-MM-DD')
      const newDtInicio = moment(dtInicio).format('yyyy-MM-DD')
      if (moment(dtFim).isBefore(newDtInicio)) {
        return schema
          .required('Informe a Data de Fim')
          .min(dtInicio, 'Informe a Data Final maior que a Data de Início')
          .nullable()
          .typeError('')
      }
    }),
})

const MemoTable = memo(Table)

const SeguroDesemprego = () => {
  const [data, setData] = useState({})
  const [collection, setCollection] = useState({
    isLoading: false,
    itens: [],
  })
  const [formCollapseIsOpen, setFormCollapseOpen] = useState(true)
  const [selectedItems, setSelectedItems] = useState({ indexs: [], datas: [] })
  const [query, setQuery] = useState('')
  const [avisoArquivoJaGerado, setAvisoArquivoJaGerado] = useState({
    isOpen: false,
    arrayDatas: [],
  })
  const [formSalarios, setFormSalarios] = useState({
    isOpen: false,
    data: {},
  })

  const { estabelecimento, anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()
  const { openDownloadData } = useUtils()
  const notification = useNotification()

  const resetData = useCallback(() => {
    const dtInicio = moment(anoMes).format('yyyy-MM-DD')
    const dtFim = moment(dtInicio).endOf('month').format('yyyy-MM-DD')
    setData({ isTodos: false, dtInicio, dtFim })
    setCollection({ isLoading: false, itens: [] })
    setSelectedItems({ indexs: [], datas: [] })
  }, [anoMes])

  useEffect(() => {
    resetData()
  }, [anoMes, estabelecimento, resetData])

  useEffect(() => {
    setCollection({ isLoading: false, itens: [] })
    setSelectedItems({ indexs: [], datas: [] })
  }, [data.isTodos, data.dtInicio, data.dtFim])

  const handleSearchProcess = (event) => {
    const handleProcess = async () => {
      try {
        const response = await api.post('SeguroDesemprego/GerarArquivo', selectedItems.datas)
        if (response.data) {
          const dataCurrent = moment().format('DDMMYYYY_HHmm')
          openDownloadData(`SD_${dataCurrent}.SD`, response.data)
          notification.success('Registros processados com sucesso')
          resetData()
        }
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
    }

    const handleSearch = async () => {
      setCollection((oldState) => ({
        ...oldState,
        isLoading: true,
      }))
      try {
        const response = await api.get('SeguroDesemprego/GetVinculos', {
          params: {
            dtInicio: data.dtInicio,
            dtFim: data.dtFim,
            isTodos: data.isTodos,
          },
        })
        setCollection((oldState) => ({
          ...oldState,
          itens: response?.data?.data || [],
          isLoading: false,
        }))
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setCollection((oldState) => ({
        ...oldState,
        isLoading: false,
      }))
    }

    if (event === 'process') {
      handleProcess()
      return
    }
    if (event === 'search') {
      handleSearch()
      return
    }
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleSearchProcess,
    data,
  })

  function handleOpenAvisoArquivoJaGerado(indexsDosVinculosQuePossuemDtGeracaoSD) {
    setAvisoArquivoJaGerado({
      isOpen: true,
      arrayDatas: indexsDosVinculosQuePossuemDtGeracaoSD.map((index) => ({
        ...collection.itens[index],
        indexPrincipal: index,
      })),
    })
  }

  const handleCloseAvisoArquivoJaGerado = () => {
    setAvisoArquivoJaGerado((oldState) => ({
      ...oldState,
      isOpen: false,
    }))
  }

  const handleClickOk = ({ indexs, datas }) => {
    setSelectedItems((prevState) => {
      const datasSemDtGeracaoSD = prevState.datas.filter((item) => !item?.dtGeracaoSD)
      const indexsSemDtGeracaoSD = datasSemDtGeracaoSD.map((_, index) => index)

      const newDatas = (prevState.datas = [...datasSemDtGeracaoSD, ...datas])
      const newIndexs = (prevState.indexs = [...indexsSemDtGeracaoSD, ...indexs])
      return { datas: newDatas, indexs: newIndexs }
    })
    handleValidate('process')
    handleCloseAvisoArquivoJaGerado()
  }

  const handleRowSelected = useCallback(
    (dataIndexs) => {
      const arrayDatas = dataIndexs.map((index) => collection.itens[index])
      setSelectedItems({ indexs: dataIndexs, datas: arrayDatas })
    },
    [collection.itens],
  )

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleItemClick = useCallback(
    (id) => {
      const handleOpenFormSalarios = (vinculoId) => {
        const { itens } = collection
        const item = itens.find((i) => i.vinculoId === vinculoId)
        setFormSalarios({
          isOpen: true,
          data: item,
        })
      }
      handleOpenFormSalarios(id)
    },
    [collection],
  )

  const handleCloseFormSalarios = useCallback(() => {
    setFormSalarios({
      isOpen: false,
      data: {},
    })
  }, [])

  const handleAfterSubmitFormSalarios = useCallback(
    (value) => {
      const newDatas = selectedItems.datas.map((i) => (i.vinculoId === value.vinculoId ? value : i))
      setSelectedItems((oldState) => ({ ...oldState, datas: newDatas }))

      const { itens } = collection
      const newItens = itens.map((i) => (i.vinculoId === value.vinculoId ? value : i))
      setCollection((oldState) => ({
        ...oldState,
        itens: newItens,
      }))
      handleCloseFormSalarios()
    },
    [collection, selectedItems.datas, handleCloseFormSalarios],
  )

  function handleClickGerarArquivo() {
    let indexsDosVinculosQuePossuemDtGeracaoSD = []

    selectedItems.indexs.forEach((index) => {
      const newVinculo = collection.itens[index]
      if (newVinculo?.dtGeracaoSD) {
        indexsDosVinculosQuePossuemDtGeracaoSD = [...indexsDosVinculosQuePossuemDtGeracaoSD, index]
      }
    })

    if (indexsDosVinculosQuePossuemDtGeracaoSD.length > 0) {
      handleOpenAvisoArquivoJaGerado(indexsDosVinculosQuePossuemDtGeracaoSD)
      return
    }

    handleValidate('process')
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <Box pb={1}>
        <PageHeader title="Seguro Desemprego">
          <ButtonBox>
            <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
            <IconButton size="small" onClick={() => setFormCollapseOpen(!formCollapseIsOpen)}>
              {formCollapseIsOpen ? <RiFilterOffLine /> : <RiFilterLine />}
            </IconButton>
          </ButtonBox>
        </PageHeader>

        <Box component={Paper} padding={2}>
          <Collapse in={formCollapseIsOpen}>
            <Grid container spacing={2}>
              <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    window.open('https://sd.maisemprego.mte.gov.br/sdweb/empregadorweb/index.jsf')
                  }
                  title="Acesse o Site do Seguro Desemprego"
                >
                  <NearMeIcon size="small" />
                  Site Seguro Desemprego
                </Button>
              </Grid>

              <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
                <FormControlLabel
                  label="Todos os Funcionários"
                  control={
                    <Checkbox
                      size="small"
                      color="secondary"
                      checked={Boolean(data?.isTodos)}
                      onChange={(e) => {
                        const isTodos = e.target.checked
                        setData((oldState) => ({ ...oldState, isTodos }))
                      }}
                    />
                  }
                />
              </Grid>

              <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                <DatePicker
                  label="Data de Início"
                  size="small"
                  required
                  validationErrors={validationErrors}
                  name="dtInicio"
                  value={data?.dtInicio || null}
                  onChange={(date) => {
                    const dtInicio = date ? date.format('yyyy-MM-DD') : null
                    setData((oldState) => ({ ...oldState, dtInicio }))
                  }}
                />
              </Grid>

              <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                <DatePicker
                  label="Data Final"
                  size="small"
                  required
                  validationErrors={validationErrors}
                  name="dtFim"
                  value={data?.dtFim || null}
                  onChange={(date) => {
                    const dtFim = date ? date.format('yyyy-MM-DD') : null
                    setData((oldState) => ({ ...oldState, dtFim }))
                  }}
                />
              </Grid>

              <Grid
                item
                xl={2}
                lg={2}
                md={12}
                sm={12}
                xs={12}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <ButtonBox>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => handleValidate('search')}
                    isLoading={collection.isLoading}
                    variant="contained"
                  >
                    Buscar
                  </Button>
                </ButtonBox>
              </Grid>

              {selectedItems.indexs.length > 0 && (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <AlertContainer>
                    <Grid container spacing={2}>
                      <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
                        Foram selecionados{' '}
                        <strong>
                          {selectedItems.indexs.length}/{collection.itens.length}
                        </strong>{' '}
                        Funcionários para Gerar o Arquivo do Seguro Desemprego
                      </Grid>

                      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <ButtonBox top={1}>
                          <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={handleClickGerarArquivo}
                          >
                            Gerar Arquivo
                          </Button>
                        </ButtonBox>
                      </Grid>
                    </Grid>
                  </AlertContainer>
                </Grid>
              )}
            </Grid>
          </Collapse>
        </Box>
      </Box>

      <MemoTable
        data={collection.itens}
        isLoading={collection.isLoading}
        onRowSelected={handleRowSelected}
        rowsSelecteds={selectedItems.indexs}
        query={query}
        onItemClick={handleItemClick}
        triggersHeight={{
          formCollapseIsOpen,
          validationErrors,
          indexs: selectedItems.indexs,
        }}
      />

      <AvisoArquivoJaGerado
        isOpen={avisoArquivoJaGerado.isOpen}
        onClose={handleCloseAvisoArquivoJaGerado}
        onOkClick={handleClickOk}
        arrayDatas={avisoArquivoJaGerado.arrayDatas}
      />

      <FormSalarios
        isOpen={formSalarios.isOpen}
        data={formSalarios.data}
        onClose={handleCloseFormSalarios}
        onAfterSubmitFormSalarios={handleAfterSubmitFormSalarios}
      />
    </Box>
  )
}

export default SeguroDesemprego
