import { useState } from 'react'

import TableRowsSelectableProvider from '~/contexts/TableRowsSelectableContext'
import PageContainer from '~/components/PageContainer'

import Actions from './components/Actions'
import Filter from './components/Fillter'
import Table from './components/Table'

export default function SolicitacaoAberturaContaSalario() {
  const [queryFilter, setQueryFilter] = useState('')

  return (
    <PageContainer.Root>
      <TableRowsSelectableProvider>
        <PageContainer.Header title="Solicitação Abertura de Conta Salário">
          <Filter onChange={(filters) => setQueryFilter(filters?.query)} />
        </PageContainer.Header>

        <Table queryFilter={queryFilter} />

        <PageContainer.ActionsContainer>
          <Actions />
        </PageContainer.ActionsContainer>
      </TableRowsSelectableProvider>
    </PageContainer.Root>
  )
}
