import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { FGTSDigitalEmpregadorDTO } from './interfaces/FGTSDigitalEmpregadorDTO'

const query = '/FGTSDigital/ObterEmpregadores'

interface RequestProps {
  params: {
    competencia: string
    grupoId: string | null
  }
}

export function useObterEmpregadores({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: FGTSDigitalEmpregadorDTO[] }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
