import { Box, BoxProps } from '@material-ui/core'

export interface VStackProps extends Omit<BoxProps, 'gridGap'> {
  gap?: number
  backgroundColor?: string
}

export default function VStack({ gap, backgroundColor, ...rest }: VStackProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ backgroundColor }}
      gridGap={gap}
      {...rest}
    />
  )
}
