import { useEffect, useRef, useState } from 'react'

import { Box, Typography, makeStyles } from '@material-ui/core'
import { TreeView as MuiTreeView, TreeItem } from '@material-ui/lab'
import { ExpandMore as ExpandMoreIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons'

import { List } from 'react-virtualized'

import CardVinculo from './components/CardVinculo'
import CardItem from './components/CardItem'

import { useContextRecibo } from '~/hooks/useRecibo'
import { formatToAnoMes, formatToDigits } from '~/utils/utils'
import useAmbiente from '~/hooks/useAmbiente'

import { VinculoMenuDTO } from '~/hooks/queries/RP/VinculoMenuDTO'
import { RP } from '~/hooks/queries/RP/RP'
import clsx from 'clsx'
import moment from 'moment'

const HEIGHT_MENU_FUNC = 33
const HEIGHT_MENU_ITEM = 30

const useStyles = makeStyles((theme: FixLater) => ({
  highLightItem: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.lighter,
    borderRadius: '2px',
  },
  labelAjusts: {
    width: 'calc(100% - 18px)',
  },
  removeIcon: {
    display: 'none',
  },
  hightLightError: {
    backgroundColor: theme.palette.error.lighter,
    filter: 'brightness(1.2)',
    '&:hover': {
      filter: 'brightness(1.4)',
    },
  },
  groupTreeItem: {
    marginLeft: theme.spacing(1),
  },
  rootTreeItemRecibo: {
    marginBottom: 0,
    marginTop: 0,
  },
}))

interface TreeViewProps {
  data: VinculoMenuDTO[]
  onSelectItem: (event: 'reciboSelecionado', reciboId: string) => void
  onContextMenuItemClick: (event: any, recibo: RP) => void
}

export default function TreeView({ data, onSelectItem, onContextMenuItemClick }: TreeViewProps) {
  const { reciboPagamento, expanded, setExpanded } = useContextRecibo()
  const classes = useStyles()
  const [reciboIdSelected, setReciboIdSelected] = useState('')

  const listRef = useRef<List[]>([])
  const rootRef = useRef<any | null>(null)

  const { estabelecimento, anoMes } = useAmbiente()

  useEffect(() => {
    const listsRef = listRef.current

    listsRef.forEach((ref) => {
      ref.recomputeRowHeights()
    })
  }, [expanded, anoMes, estabelecimento, reciboPagamento])

  useEffect(() => {
    setExpanded(['0|1'])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estabelecimento])

  const handleToggle = (nodeIds: string[]) => {
    const novosNodesIds: string[] = []

    nodeIds.forEach((d) => {
      const level = d.split('|')[0]
      if (!novosNodesIds.some((x) => x.split('|')[0] === level)) {
        novosNodesIds.push(d)
      }
    })

    setExpanded(novosNodesIds)
  }

  const heightRoot = rootRef?.current?.offsetHeight
  const widthRoot = rootRef?.current?.offsetWidth

  const idTreeViewSelected = '2|' + reciboIdSelected || ''

  return (
    <MuiTreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      onNodeToggle={(_, nodes) => handleToggle(nodes)}
      disableSelection={true}
      selected={[idTreeViewSelected]}
      style={{
        height: 'calc(100% - 4px)',
        minHeight: '300px',
      }}
      ref={rootRef}
    >
      {data?.length === 0 ? (
        <Typography align="center">Registros não encontrados</Typography>
      ) : (
        data.map((group, groupIndex) => {
          const nodeGroupId = `0|${group.agrupamentoId}`
          return (
            <TreeItem
              key={nodeGroupId}
              nodeId={nodeGroupId}
              label={
                <CardItem
                  labelText={group.agrupamentoNome}
                  labelInfo={() => (
                    <Box fontWeight="bold">{formatToDigits(group?.vinculos?.length || 0, 2)}</Box>
                  )}
                  bolder={true}
                />
              }
              classes={{
                group: classes.groupTreeItem,
              }}
            >
              <List
                id="boxTreeVinculo"
                ref={(el) => {
                  if (el) {
                    listRef.current[groupIndex] = el
                  }
                }}
                width={widthRoot - 8}
                height={
                  heightRoot - (data?.length || 1) * HEIGHT_MENU_ITEM <= 200
                    ? 200
                    : heightRoot - (data?.length || 1) * HEIGHT_MENU_ITEM
                }
                autoHeight={estabelecimento?.isRPLotacao}
                rowHeight={({ index }) => {
                  const vinculoCurrent = group.vinculos[index]

                  let heightCurrent = 0

                  if (expanded.some((d) => d.includes(vinculoCurrent.vinculoId))) {
                    heightCurrent = HEIGHT_MENU_ITEM * vinculoCurrent.recibos.length
                  }

                  return HEIGHT_MENU_FUNC + heightCurrent
                }}
                rowCount={group.vinculos.length}
                overscanRowCount={2}
                rowRenderer={({ index: indexSecond, style }) => {
                  const vinculoCurrent = group.vinculos[indexSecond]
                  const recibos = vinculoCurrent?.recibos || []
                  const nodeId = `1|${vinculoCurrent.vinculoId}`

                  const dtRescisaoVinculoCurrentFormatada = formatToAnoMes(
                    vinculoCurrent?.dtRescisao,
                  )
                  const competenciaAnoMesFormatada = formatToAnoMes(anoMes)
                  const anoMesRescisaoEIgualAnoMesCompetencia = moment(
                    dtRescisaoVinculoCurrentFormatada,
                    'YYYY/MM',
                  ).isSame(moment(competenciaAnoMesFormatada, 'YYYY/MM'), 'month')

                  return (
                    <TreeItem
                      style={style}
                      key={nodeId}
                      nodeId={nodeId}
                      onLabelClick={() => {
                        const isOpen = expanded.includes(nodeId)
                        if (recibos.length > 0 && !isOpen) {
                          onSelectItem('reciboSelecionado', recibos[0].reciboId)
                          setReciboIdSelected(recibos[0].reciboId)
                        }
                      }}
                      classes={{
                        root: clsx({
                          [classes.hightLightError]: anoMesRescisaoEIgualAnoMesCompetencia,
                        }),
                        expanded: clsx({
                          [classes.highLightItem]: anoMesRescisaoEIgualAnoMesCompetencia,
                        }),
                        label: classes.labelAjusts,
                        group: classes.groupTreeItem,
                      }}
                      label={
                        <CardVinculo
                          vinculo={vinculoCurrent}
                          agrupamentoId={group.agrupamentoId}
                          onContextMenuItemClick={onContextMenuItemClick}
                        />
                      }
                    >
                      {recibos.map((reciboCurrent) => {
                        const treeItemId = `2|${reciboCurrent.reciboId}`
                        return (
                          <TreeItem
                            key={treeItemId}
                            nodeId={treeItemId}
                            label={
                              <CardItem
                                labelText={`${reciboCurrent.idfDesc}`}
                                showBullet
                                isHightLight={treeItemId === idTreeViewSelected}
                              />
                            }
                            onLabelClick={(e) => {
                              e.preventDefault()
                              onSelectItem('reciboSelecionado', reciboCurrent.reciboId)
                              setReciboIdSelected(reciboCurrent.reciboId)
                            }}
                            onKeyDown={(e) => {
                              const { key } = e
                              if (key === 'Enter') {
                                onSelectItem('reciboSelecionado', reciboCurrent.reciboId)
                                setReciboIdSelected(reciboCurrent.reciboId)
                              }
                            }}
                            classes={{
                              root: classes.rootTreeItemRecibo,
                              iconContainer: classes.removeIcon,
                            }}
                          />
                        )
                      })}
                    </TreeItem>
                  )
                }}
              />
            </TreeItem>
          )
        })
      )}
    </MuiTreeView>
  )
}
