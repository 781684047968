import React from 'react'

import * as yup from 'yup'
import moment from 'moment'

import { Box, Collapse, Grid, IconButton, Paper, Tooltip, useTheme } from '@material-ui/core'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import { Button, DatePickerNew, TextField } from '~/components'
import { AutoCompleteSetor, MUIAutoComplete } from '~/components/AutoComplete'

import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'

import { TableFeriasColetiva, useFeriasColetiva } from '../Context'
import { IndDiasFeriasProporcionaisRestantesValues } from '~/@types/enums/IndDiasFeriasProporcionaisRestantesEnum'
import { FeriasColetivaRequestDTO } from '~/hooks/queries/FeriasColetiva/obterFuncionariosAnalitico'
import { dateHasBetweenYearMonth } from '~/hooks/useUtils'
import useDialogNotification from '~/hooks/useDialogNotification'

function calcDatesFerias(
  dtInicioFerias: string | null,
  nrDiasFerias: number,
): {
  dtInicioFerias: string | null
  dtFimFerias: string | null
  dtPagamentoFerias: string | null
  dtAvisoFerias: string | null
} {
  let dtFimFerias: string | null = null
  let dtPagamentoFerias: string | null = null
  let dtAvisoFerias: string | null = null

  if (dtInicioFerias) {
    dtFimFerias = moment(dtInicioFerias)
      .add(nrDiasFerias - 1, 'days')
      .format('yyyy-MM-DD')
    dtPagamentoFerias = moment(dtInicioFerias).subtract(3, 'days').format('yyyy-MM-DD')
    dtAvisoFerias = moment(dtInicioFerias).subtract(31, 'days').format('yyyy-MM-DD')
  }

  return {
    dtInicioFerias,
    dtFimFerias,
    dtPagamentoFerias,
    dtAvisoFerias,
  }
}

const schema = yup.object().shape({
  setorId: yup
    .string()
    .when(['$isFilterBySetor'], (isFilterBySetor, schema) =>
      isFilterBySetor ? schema.required('Informe um Setor') : schema,
    )
    .nullable(),
  nrDiasFerias: yup
    .number()
    .min(1, 'Informe pelo menos um Dia de Férias')
    .required('Informe os Dias de Férias'),
  dtInicioFerias: yup
    .date()
    .required('Informe o Início das Férias')
    .typeError('Informe uma dataForm válida')
    .when(['$anoMes'], (anoMes, schema) =>
      schema.test(
        'data-deve-ser-dentro-competencia',
        'A Data de Início das Férias deve está dentro da competência atual',
        (dt: string) => dateHasBetweenYearMonth(dt, anoMes),
      ),
    ),
  dtFimFerias: yup
    .date()
    .required('Informe o Fim das Férias')
    .typeError('Informe uma dataForm válida'),
  nrDiasAbono: yup
    .number()
    .when(['$isAbono'], (isAbono, schema) =>
      isAbono
        ? schema.min(1, 'Informe pelo menos um Dia de Abono').required('Informe os Dias de Abono')
        : schema,
    ),
  dtInicioAbono: yup
    .date()
    .when(['$isAbono'], (isAbono, schema) =>
      isAbono
        ? schema.required('Informe o Início do Abono').typeError('Informe uma dataForm válida')
        : schema,
    )
    .nullable(),
  dtFimAbono: yup
    .date()
    .when(['$isAbono'], (isAbono, schema) =>
      isAbono
        ? schema.required('Informe o Fim do Abono').typeError('Informe uma dataForm válida')
        : schema,
    )
    .nullable(),
  dtPagamentoFerias: yup
    .date()
    .required('Informe a Data de Pagamento das Férias')
    .typeError('Informe uma dataForm válida'),
  dtAvisoFerias: yup
    .date()
    .required('Informe a Data de Aviso das Férias')
    .typeError('Informe uma dataForm válida'),
  indDiasFeriasProporcionaisRestantes: yup
    .number()
    .required('Informe o Indicador de Funcionários com Menos de um Ano de Trabalho'),
})

export default function Form() {
  const { formProps, requestProps, tableProps } = useFeriasColetiva()
  const {
    isOpenCollapseForm,
    paramsPage,
    dataForm,
    setDataForm,
    valuesMin,
    setValuesMin,
    onCancel,
  } = formProps
  const { tableCurrent, setTableCurrent } = tableProps
  const { isFilterBySetor, isAbono } = paramsPage
  const { isLoadingSearch, onSearch } = requestProps

  const theme = useTheme()
  const { empregador, anoMes } = useAmbiente()
  const dialogNotification = useDialogNotification()
  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data: dataForm,
    schemaOptions: {
      abortEarly: false,
      context: {
        isAbono,
        isFilterBySetor,
        valuesMin,
        anoMes,
      },
    },
  })

  function handleSubmit() {
    const errors = []

    if (moment(dataForm.dtPagamentoFerias).isAfter(valuesMin.dtPagamentoFerias)) {
      errors.push('Informe a Data de Pagamento pelo menos 2 dias antes a Data de Início das Férias')
    }

    if (moment(dataForm.dtAvisoFerias).isAfter(valuesMin.dtAvisoFerias)) {
      errors.push('Informe a Data de Aviso pelo menos 30 dias antes a Data de Início das Férias')
    }

    if (errors.length > 0) {
      dialogNotification.error({
        title: 'Temos algumas notificações para você',
        descriptions: errors,
        labelOnConfirm: 'Buscar as Férias Coletiva mesmo assim',
        onConfirm: () => onSearch(dataForm as FeriasColetivaRequestDTO),
      } as FixLater)
    } else {
      onSearch(dataForm as FeriasColetivaRequestDTO)
    }
  }

  return (
    <Collapse in={isOpenCollapseForm}>
      <Box component={Paper} p={1}>
        <Grid container spacing={1}>
          {isFilterBySetor && (
            <Grid item xs={12}>
              <AutoCompleteSetor
                required
                onChange={(e, setor) => {
                  const setorId = setor ? setor.id : ''
                  setDataForm((prev) => ({ ...prev, setorId }))
                }}
                value={dataForm.setorId}
                empregadorId={empregador.id}
                name="setorId"
                validationErrors={validationErrors}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={2}>
            <TextField
              label="Dias de Férias"
              required
              onlyNumber
              value={dataForm.nrDiasFerias}
              inputProps={{
                maxLength: 5,
              }}
              name="nrDiasFerias"
              validationErrors={validationErrors}
              onChange={(e) => {
                const nrDiasFerias = parseInt(e?.target?.value || '0')
                setDataForm((prev) => ({
                  ...prev,
                  nrDiasFerias,
                  dtInicioFerias: null,
                  dtFimFerias: null,
                  dtPagamentoFerias: null,
                  dtAvisoFerias: null,
                }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={5}>
            <DatePickerNew
              label="Início das Férias"
              required
              size="small"
              value={dataForm.dtInicioFerias || null}
              name="dtInicioFerias"
              validationErrors={validationErrors}
              autoFocus
              // disabled={dataForm.nrDiasFerias > 0 ? false : true}
              onChange={(date) => {
                const { dtInicioFerias, dtFimFerias, dtPagamentoFerias, dtAvisoFerias } =
                  calcDatesFerias(date, dataForm.nrDiasFerias)

                setValuesMin({
                  dtPagamentoFerias,
                  dtAvisoFerias,
                })
                setDataForm((prev) => ({
                  ...prev,
                  dtInicioFerias,
                  dtFimFerias,
                  dtPagamentoFerias,
                  dtAvisoFerias,
                }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={5}>
            <DatePickerNew
              label="Fim das Férias"
              required
              size="small"
              value={dataForm.dtFimFerias || null}
              name="dtFimFerias"
              validationErrors={validationErrors}
              disabled
            />
          </Grid>

          {isAbono && (
            <>
              <Grid item xs={12} sm={12} md={2}>
                <TextField
                  label="Dias de Abono"
                  required
                  onlyNumber
                  value={dataForm.nrDiasAbono}
                  inputProps={{
                    maxLength: 5,
                  }}
                  name="nrDiasAbono"
                  validationErrors={validationErrors}
                  onChange={(e) => {
                    const nrDiasAbono = parseInt(e?.target?.value || '0')
                    setDataForm((prev) => ({
                      ...prev,
                      nrDiasAbono,
                      dtInicioAbono: null,
                      dtFimAbono: null,
                    }))
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={5}>
                <DatePickerNew
                  label="Início do Abono"
                  required
                  size="small"
                  value={dataForm.dtInicioAbono || null}
                  name="dtInicioAbono"
                  validationErrors={validationErrors}
                  disabled={dataForm.nrDiasAbono && dataForm.nrDiasAbono > 0 ? false : true}
                  onChange={(date) => {
                    const dtInicioAbono = date || ''
                    const dtFimAbono = moment(date)
                      .add((dataForm?.nrDiasAbono ?? 0) - 1, 'days')
                      .format('yyyy-MM-DD')
                    setDataForm((prev) => ({ ...prev, dtInicioAbono, dtFimAbono }))
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={5}>
                <DatePickerNew
                  label="Fim do Abono"
                  required
                  size="small"
                  value={dataForm.dtFimAbono || null}
                  name="dtFimAbono"
                  validationErrors={validationErrors}
                  disabled
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={6} md={4}>
            <DatePickerNew
              label="Pagamento Férias"
              required
              size="small"
              value={dataForm.dtPagamentoFerias || null}
              name="dtPagamentoFerias"
              validationErrors={validationErrors}
              disabled={dataForm.nrDiasFerias > 0 && dataForm.dtInicioFerias ? false : true}
              onChange={(date) => {
                const dtPagamentoFerias = date || ''
                setDataForm((prev) => ({ ...prev, dtPagamentoFerias }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <DatePickerNew
              label="Aviso Férias"
              required
              size="small"
              name="dtAvisoFerias"
              validationErrors={validationErrors}
              value={dataForm.dtAvisoFerias || null}
              disabled={dataForm.nrDiasFerias > 0 && dataForm.dtInicioFerias ? false : true}
              onChange={(date) => {
                const dtAvisoFerias = date || ''
                setDataForm((prev) => ({ ...prev, dtAvisoFerias }))
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <MUIAutoComplete
              label="Funcionários com Menos de um Ano de Trabalho"
              required
              options={IndDiasFeriasProporcionaisRestantesValues}
              renderOption={(option) => option.name}
              value={dataForm.indDiasFeriasProporcionaisRestantes}
              name="indDiasFeriasProporcionaisRestantes"
              validationErrors={validationErrors}
              onChange={(event, object) => {
                const indDiasFeriasProporcionaisRestantes = object?.value
                setDataForm((prev) => ({
                  ...prev,
                  indDiasFeriasProporcionaisRestantes,
                }))
              }}
              optionId="value"
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Tooltip title="Alternar entre as tabelas" arrow>
                <Box display="flex" gridGap={theme.spacing(1)}>
                  <IconButton
                    size="small"
                    color="primary"
                    style={
                      tableCurrent === TableFeriasColetiva.TableDiasFerias
                        ? {
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.primary.main,
                          }
                        : undefined
                    }
                    onClick={() => setTableCurrent(TableFeriasColetiva.TableDiasFerias)}
                  >
                    <ArrowBackIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    color="primary"
                    style={
                      tableCurrent === TableFeriasColetiva.TableValoresSimulacao
                        ? {
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.primary.main,
                          }
                        : undefined
                    }
                    onClick={() => setTableCurrent(TableFeriasColetiva.TableValoresSimulacao)}
                  >
                    <ArrowForwardIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Tooltip>
              <Box display="flex" justifyContent="flex-end" gridGap={theme.spacing(1)}>
                <Button onClick={onCancel}>Cancelar</Button>
                <Button isLoading={isLoadingSearch} onClick={handleValidate} variant="contained">
                  Buscar
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  )
}
