import { useState } from 'react'

import { ActionDialog, TextField } from '~/components'

interface DialogObservacaoProps {
  isOpen: boolean
  onClose: () => void
  value: string
  onSubmit: (value: string) => void
}

export default function DialogObservacao({
  isOpen,
  onClose,
  value: _observacao,
  onSubmit,
}: DialogObservacaoProps) {
  const [observao, setObservacao] = useState(_observacao)
  return (
    <ActionDialog
      title="Adicionar observação nos recibos"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={() => {
        onSubmit(observao)
        onClose()
      }}
    >
      <TextField
        label="Observação"
        value={observao}
        onChange={(e) => setObservacao(e.target.value)}
        multiline
        rows={4}
        inputProps={{
          maxLength: 300,
        }}
      />
    </ActionDialog>
  )
}
