import React, { useState } from 'react'

import { Box, makeStyles } from '@material-ui/core'

import BulletIcon from '@material-ui/icons/FiberManualRecord'
import clsx from 'clsx'

const useStyles = makeStyles((theme: FixLater) => ({
  root: {
    // marginTop: theme.spacing(0.5),
    // marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '24px',
    gap: theme.spacing(0.5),
    color: theme.palette.grey[800],
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  labelText: {
    fontSize: '9pt',
    fontWeight: (props: { bolder: boolean }) => (props.bolder ? 'bolder' : 'inherit'),
    width: 'calc(100% - 22px)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  labelInfo: {
    display: 'flex',
    height: '22px',
    width: '22px',
    justifyContent: 'center',
  },
  hightLight: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.lighter,
    borderRadius: theme.spacing(1),
    filter: 'brightness(0.6)',
  },
}))

interface CardItemProps {
  labelText: string
  labelInfo?: (isHover: boolean) => React.ReactNode
  bolder?: boolean
  showBullet?: boolean
  isHightLight?: boolean
}

export default function CardItem({
  labelText,
  labelInfo,
  bolder = false,
  showBullet = false,
  isHightLight = false,
}: CardItemProps) {
  const classes = useStyles({ bolder })
  const [isHover, setHover] = useState(false)

  return (
    <Box
      className={clsx(classes.root, { [classes.hightLight]: isHightLight })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {showBullet && <BulletIcon fontSize="small" color="action" style={{ fontSize: '8pt' }} />}
      <Box className={classes.labelText} title={labelText}>
        {labelText}
      </Box>
      <Box className={classes.labelInfo}>{labelInfo && labelInfo(isHover)}</Box>
    </Box>
  )
}
