import moment from 'moment'

export function formatToDigits(number: number, digits: number) {
  return number.toLocaleString('en-US', {
    minimumIntegerDigits: digits,
    useGrouping: false,
  })
}

export function insertString(value: string, position: number, valueAdd: string) {
  return value.substring(0, position) + valueAdd + value.substring(position)
}

export function limparString(value: string) {
  return value.replace(/[^\w\d]/gi, '')
}

export function orderBy<TData>(list: TData[], fieldName: keyof TData) {
  return list.sort((a, b) => {
    const nomeA = `${a[fieldName]}`.toUpperCase()
    const nomeB = `${b[fieldName]}`.toUpperCase()
    if (nomeA < nomeB) {
      return -1
    }
    if (nomeA > nomeB) {
      return 1
    }
    return 0
  })
}

export function convertToArrayDescription<TData>(obj: object, descriptions: object) {
  const keys = Object.keys(obj).filter((key) => !isNaN(Number(obj[key as keyof typeof obj])))
  const array: { name: string; value: TData }[] = []
  keys.forEach((key) => {
    const value = obj[key as keyof typeof obj]
    const description = descriptions[obj[key as keyof typeof obj]]
    if (!value) {
      if (!(typeof value === 'number' && value === 0)) {
        return
      }
    }
    if (!description) return
    array.push({
      value: value,
      name: description,
    })
  })
  return array
}

export const formatCurrency = (value?: number | null, acceptZeros = false) => {
  if (!value && !acceptZeros) return ''
  return Number(value).toLocaleString('pt-br', {
    minimumFractionDigits: 2,
  })
}

export function formatTelefone(numero: string) {
  const numeros = numero.replace(/\D/g, '')
  if (numeros.length === 11) {
    return numeros.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
  }
  if (numeros.length === 10) {
    return numeros.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
  }
  return numero
}

export function extractSelectedRowsTable(tableRef: FixLater): number[] {
  const data = tableRef?.current?.state?.selectedRows?.data || []
  return data.map((d: FixLater) => d.dataIndex)
}

//Dates

export function getDateMinAnoMes(anoMes: string) {
  return moment(anoMes, 'YYYYMM').format('yyyy-MM-DD')
}

export function getDateMaxAnoMes(anoMes: string) {
  return moment(anoMes, 'YYYYMM').endOf('month').format('yyyy-MM-DD')
}

export function dateHasInAnoMes(date: string, anoMes: string) {
  const dtMinMes = getDateMinAnoMes(anoMes)
  const dtMaxMes = getDateMaxAnoMes(anoMes)
  return moment(date).isSameOrAfter(dtMinMes) && moment(date).isSameOrBefore(dtMaxMes)
}

export function formatSimpleDate(date?: string | Date | null) {
  if (!date) return ''
  return moment(date, 'yyyy-MM-DD').format('DD/MM/yyyy')
}

export function formatSimpleDateTime(date?: string | Date | null) {
  if (!date) return ''
  return moment(date, 'yyyy-MM-DD HH:mm:ss').format('DD/MM/yyyy - HH:mm:ss')
}

export function formatToAnoMes(date?: string | Date | null) {
  if (!date) return ''
  return moment(date, 'YYYY/MM').format('YYYY/MM')
}

export function getDateCurrent(props?: { format?: 'yyyy-MM-DD' | 'DD/MM/YYYY' | 'YYYY/MM' }) {
  const { format = 'yyyy-MM-DD' } = props || {}
  return moment().format(format || 'yyyy-MM-DD')
}

export function getCurrentYear() {
  return moment().year()
}

export function getCurrentMonth() {
  return moment().month()
}

export const isSameYearAndMonth = (startDate: string | Date, endDate: string | Date) => {
  const start = new Date(startDate)
  const end = new Date(endDate)

  return start.getFullYear() === end.getFullYear() && start.getMonth() === end.getMonth()
}

export function applyNumberMask(input: string, mask: string) {
  let maskedInput = ''
  let inputIndex = 0

  for (let i = 0; i < mask.length; i++) {
    if (mask[i] === '9') {
      maskedInput += input[inputIndex] || ''
      inputIndex++
    } else {
      maskedInput += mask[i]
    }
  }

  return maskedInput
}

export function formatToNIT(nit: string) {
  return applyNumberMask(nit, '999.99999.99-9')
}

export function distinctByField<T>(array: T[], chave: keyof T): T[] {
  const mapaAuxiliar = new Map<any, T>()

  array.forEach((item) => {
    const chaveValor = item[chave]
    if (chaveValor) {
      mapaAuxiliar.set(chaveValor, item)
    }
  })

  return Array.from(mapaAuxiliar.values())
}

export const defaultGuid = '00000000-0000-0000-0000-000000000000'

export interface FileType {
  contentType: string
  enableRangeProcessing: boolean
  entityTag: null
  fileContents: string
  fileDownloadName: string
  lastModified: null
}

export function downloadFile(file: FileType) {
  const byteCharacters = atob(file.fileContents)

  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }

  const byteArray = new Uint8Array(byteNumbers)

  const blob = new Blob([byteArray], { type: file.contentType })

  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = file.fileDownloadName

  link.click()

  URL.revokeObjectURL(link.href)
}

export function formatDateToDayAndDayOfWeek(_data: Date | string) {
  const data = typeof _data === 'string' ? new Date(_data) : _data
  const dia = data.getDate().toString().padStart(2, '0')
  const diaSemana = new Intl.DateTimeFormat('pt-BR', { weekday: 'short' }).format(data)
  return `${dia} - ${diaSemana.charAt(0).toUpperCase() + diaSemana.slice(1)}`
}

export function obterDataFinalSF(date: string) {
  const data14Anos = moment(date).add(14, 'years')
  const ultimoDiaMes = data14Anos.endOf('month')
  return ultimoDiaMes.format('yyyy-MM-DD')
}
