import { useEffect, useMemo } from 'react'
import { ToolsDataTable } from '~/components'

import { useObterEstatisticaFuncionarios } from '~/hooks/queries/EstatisticaFuncionarios'
import useAmbiente from '~/hooks/useAmbiente'

import { formatarData } from './utils/formatarData'
import { ToolsDataTableColumnDef } from '~/interfaces'
import { FilterStateProps } from '../Filter'
import { useTransferDataContext } from '~/contexts/TransferDataContext'
import { EstatisticaFuncionariosDTO } from '~/hooks/queries/EstatisticaFuncionarios/dtos'

interface TableProps {
  filters: FilterStateProps | null
}

export default function Table({ filters }: TableProps) {
  const { estabelecimento } = useAmbiente()
  const { setDataTrasfer } = useTransferDataContext<EstatisticaFuncionariosDTO>()

  const { data, isLoading, isFetching } = useObterEstatisticaFuncionarios({
    params: { estabelecimentoId: estabelecimento?.id, competencia: String(filters?.competencia) },
    enabled: !!filters,
  })

  const dataFormatada = formatarData(data?.data)

  useEffect(() => {
    if (data?.data) {
      setDataTrasfer(data?.data)
    }
  }, [data?.data, setDataTrasfer])

  const columns = useMemo((): ToolsDataTableColumnDef[] => {
    return [
      {
        name: 'desc',
        label: 'Descrição',
        options: {
          setCellProps: (_, rowIndex) => {
            return {
              style: { fontWeight: [0, 3].includes(rowIndex) ? 'bold' : 'normal' },
            }
          },
        },
      },
      {
        name: 'value',
        label: 'Valor',
        type: 'number',
      },
    ]
  }, [])

  return (
    <ToolsDataTable
      columns={columns}
      data={dataFormatada}
      showHeader={false}
      pagination={false}
      isLoading={isLoading}
      isFetching={isFetching}
      cellHeaderWhiteSpaceNowrap
      cellWhiteSpaceNowrap
    />
  )
}
