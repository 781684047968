import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

import { IconButton } from '@material-ui/core'

import { ButtonBox, CopyLabel, ToolsDataTable } from '~/components'

import { formatAnoMes } from '~/hooks/useUtils'
import { formatCurrency } from '~/utils/utils'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { VinculoPensaoBeneficiario } from '~/hooks/queries/VinculoPensaoBeneficiario/interfaces/VinculoPensaoBeneficiario'
import { PessoaFisicaDependente } from '~/hooks/queries/PessoaFisicaDependente/PessoaFisicaDependente'
import { formatToCPF } from 'brazilian-values'

interface TableProps {
  data: VinculoPensaoBeneficiario[]
  isLoading: boolean
  isFetching: boolean
  onItemClick: (event: 'edit' | 'delete', data: VinculoPensaoBeneficiario) => void
}

export default function Table({ data, isLoading, isFetching, onItemClick }: TableProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'pessoaFisicaDependente',
      label: 'CPF',
      options: {
        customBodyRender: (value: PessoaFisicaDependente | null) => (
          <CopyLabel value={value?.nrInscricao || ''}>
            {formatToCPF(value?.nrInscricao || '')}
          </CopyLabel>
        ),
      },
    },
    {
      name: 'pessoaFisicaDependente',
      label: 'Dependente',
      options: {
        customBodyRender: (value: PessoaFisicaDependente | null) => value?.nome || '',
      },
    },
    {
      name: 'anoMes',
      label: 'Ano/Mês',
      options: {
        customBodyRender: formatAnoMes,
      },
    },
    {
      name: 'vrPensao',
      label: 'Valor da Pensão',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: {
            textAlign: 'right',
          },
        }),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const dtCurrent = data.find((d) => d.id === value)
                  if (dtCurrent) onItemClick('edit', dtCurrent)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                title="Deletar este registro"
                size="small"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  const dtCurrent = data.find((d) => d.id === value)
                  if (dtCurrent) onItemClick('delete', dtCurrent)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      disableAutoHeight
      isLoading={isLoading}
      isFetching={isFetching}
    />
  )
}
