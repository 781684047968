import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifyPost } from '~/utils/notification'

import { VinculoPensaoBeneficiario } from './interfaces/VinculoPensaoBeneficiario'

import { useQueryClient } from './useObterPorRecibo'

interface RequestProps {
  data: VinculoPensaoBeneficiario
}

export function useCriar() {
  const dialogNotification = useDialogNotification()

  const { handleInvalidate } = useQueryClient()

  async function handleRequest({ data }: RequestProps) {
    await api.post('/VinculoPensaoBeneficiario/Criar', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPost()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
