import { AutoCompleteVinculo } from '~/components/AutoComplete'
import { Button } from '~/components'
import HStack from '~/components/HStack'

import useAmbiente from '~/hooks/useAmbiente'
import useForm from '~/hooks/useForm'

import * as yup from 'yup'

const schema = yup.object().shape({
  vinculoId: yup.string().required('Informe o Funcionário'),
})

export interface FilterStateProps {
  vinculoId: string
}

interface FilterProps {
  onChange: (filters: FilterStateProps) => void
}

export default function Filter({ onChange }: FilterProps) {
  const { estabelecimento, anoMes } = useAmbiente()

  const { data, changeValue, validationErrors, handleSubmit } = useForm<FilterStateProps>({
    defaultData: { vinculoId: '' },
    schema,
    onSubmit: onChange,
  })

  return (
    <HStack gap={8} width="100%">
      <AutoCompleteVinculo
        name="vinculoId"
        value={data?.vinculoId || ''}
        optionId="id"
        estabelecimentoId={estabelecimento?.id}
        anoMes={anoMes}
        onChange={(_, vinculo) => changeValue('vinculoId', vinculo?.id)}
        validationErrors={validationErrors}
      />
      <Button variant="contained" onClick={() => data && handleSubmit(data)}>
        Buscar
      </Button>
    </HStack>
  )
}
