import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { LogUsuarioEntidadeDTO } from './interfaces/LogUsuarioEntidadeDTO'

const query = '/LogUsuario/ObterEntidades'

export function useLogUsuarioObterEntidades() {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: LogUsuarioEntidadeDTO[] }>(query)
    return response.data.data
  }

  return useQuery([query], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
