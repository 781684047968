import { useState } from 'react'

import { useTableRowsSelectableContext } from '~/contexts/TableRowsSelectableContext'

import { ActionDialog, Button, DatePickerNew, PDFViewer, PrintButton } from '~/components'
import HStack from '~/components/HStack'
import VStack from '~/components/VStack'

import {
  AcordoJornada12x36DTO,
  AcordoJornada12x36RelatorioRequestParams,
} from '~/hooks/queries/AcordoJornada12x36/dtos'
import useDialog from '~/hooks/useDialog'
import useForm from '~/hooks/useForm'

import { getDateCurrent } from '~/utils/utils'

import * as yup from 'yup'

const schema = yup.object().shape({
  dtAssinatura: yup.string().required('Informe a Data Assinatura').nullable(),
})

const defaultData = {
  dtAssinatura: getDateCurrent(),
}

interface StateFormProps {
  dtAssinatura: string
}

export default function Impressao() {
  const { idsSelecionados } = useTableRowsSelectableContext<AcordoJornada12x36DTO>()
  const { data, changeValue, setData } = useForm<StateFormProps>({ defaultData })
  const [validationErrors, setValidationErrors] = useState(false)

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<AcordoJornada12x36RelatorioRequestParams>({
    vinculosIds: [],
    dtAssinatura: '',
  })
  const {
    close: closeActionDialog,
    isOpen: isOpenActionDialog,
    open: openActionDialog,
  } = useDialog()

  function handleCloseActionDialog() {
    closeActionDialog()
    setData(defaultData)
  }

  function handlePrint() {
    setValidationErrors(false)
    schema
      .validate(data, { abortEarly: false })
      .then(() => {
        handleCloseActionDialog()
        openPDFViewer({
          dtAssinatura: data?.dtAssinatura || '',
          vinculosIds: idsSelecionados,
        })
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  return (
    <>
      <PrintButton disabled={!idsSelecionados.length} onClick={openActionDialog} />
      <ActionDialog
        title="Informe a Data da Assinatura"
        maxWidth="xs"
        isOpen={isOpenActionDialog}
        onClose={handleCloseActionDialog}
        customActions={
          <HStack gap={8} padding={1} justifyContent="flex-end">
            <Button onClick={handleCloseActionDialog}>Cancelar</Button>
            <PrintButton onClick={handlePrint} />
          </HStack>
        }
      >
        <VStack height={58}>
          <DatePickerNew
            name="dtAssinatura"
            label="Data Assinatura"
            required
            value={data?.dtAssinatura || null}
            onChange={(date) => changeValue('dtAssinatura', date)}
            validationErrors={validationErrors}
          />
        </VStack>
      </ActionDialog>
      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        title="Relatórios Acordo Jornada 12X36"
        reportKey="AcordoJornada12X36"
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/AcordoJornada12X36/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
    </>
  )
}
