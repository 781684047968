import { makeStyles } from '@material-ui/core'

const calendarStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: '17.5rem',
    height: '14rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 16,
    paddingTop: 54,
    paddingBottom: 10,
  },
}))

export default calendarStyles
