import { useEffect, useState } from 'react'

import * as yup from 'yup'

import { Box, Divider, Grid, Paper } from '@material-ui/core'
import {
  Button,
  ContentDivider,
  CurrencyTextField,
  DatePickerNew,
  Finder,
  PageHeader,
  Stack,
  StackContainer,
} from '~/components'
import { FaFileExcel } from 'react-icons/fa'

import { AutoCompleteSindicato } from '~/components/AutoComplete'

import Table from './Table'

import {
  useObterVinculosSimulados,
  useObterVinculosSimuladosXlsx,
} from '~/hooks/queries/AlteracaoSalarialColetiva/useObterVinculosSimulados'
import { useProcessarVinculos } from '~/hooks/queries/AlteracaoSalarialColetiva/useProcessarVinculos'

import useLocalStorage from '~/hooks/useLocalStorage'
import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'

import constants from '~/utils/constants'

const schema = yup.object().shape({
  dtEfetiva: yup.date().typeError('Informe uma data válida').required('Informe a Data Efetiva'),
  dtAlteracao: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data da Alteração'),
})

export default function AlteracaoSalarialColetiva() {
  const { anoMes, estabelecimento } = useAmbiente()

  const [sindicatoId, setSindicatoId] = useLocalStorage(constants.sindicatoId, '')

  const [dataFormSimular, setDataFormSimular] = useState({
    vrInicialFaixa: 0,
    vrFinalFaixa: 0,
    vrNovoSalarioFaixa: 0,
    vrPercentualReajuste: 0,
  })

  const [dataFormProcesso, setDataFormProcesso] = useState({
    dtAlteracao: '',
    dtEfetiva: '',
  })

  const [rowsSelected, setRowsSelected] = useState<number[]>([])

  const [query, setQuery] = useState('')

  const {
    data: _d,
    isLoading,
    isFetching,
    refetch,
  } = useObterVinculosSimulados({
    data: {
      anoMes,
      estabelecimentoId: estabelecimento.id,
      sindicatoId,
      vrInicialFaixa: dataFormSimular.vrInicialFaixa,
      vrFinalFaixa: dataFormSimular.vrFinalFaixa,
      vrNovoSalarioFaixa: dataFormSimular.vrNovoSalarioFaixa,
      vrPercentualReajuste: dataFormSimular.vrPercentualReajuste,
    },
  })
  const data = _d || []

  const { mutateAsync: mutateAsyncProcessar, isLoading: isProcessing } = useProcessarVinculos()

  const { mutateAsync: mutateAsyncObterXlsx, isLoading: isLoadingXlsx } =
    useObterVinculosSimuladosXlsx()

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleProcess,
    data: dataFormProcesso,
  })

  useEffect(() => {
    setRowsSelected([])
    setDataFormProcesso({
      dtAlteracao: '',
      dtEfetiva: '',
    })
  }, [_d])

  async function handleProcess() {
    const vinculosIds = rowsSelected.map((index) => data[index].id)
    await mutateAsyncProcessar({
      data: {
        anoMes,
        vinculosIds,
        dtEfetiva: dataFormProcesso.dtEfetiva,
        dtAlteracao: dataFormProcesso.dtAlteracao,
        vrInicialFaixa: dataFormSimular.vrInicialFaixa,
        vrFinalFaixa: dataFormSimular.vrFinalFaixa,
        vrNovoSalarioFaixa: dataFormSimular.vrNovoSalarioFaixa,
        vrPercentualReajuste: dataFormSimular.vrPercentualReajuste,
      },
    })
  }

  async function handlePrintXlsx() {
    await mutateAsyncObterXlsx({
      data: {
        anoMes,
        estabelecimentoId: estabelecimento.id,
        sindicatoId,
        vrInicialFaixa: dataFormSimular.vrInicialFaixa,
        vrFinalFaixa: dataFormSimular.vrFinalFaixa,
        vrNovoSalarioFaixa: dataFormSimular.vrNovoSalarioFaixa,
        vrPercentualReajuste: dataFormSimular.vrPercentualReajuste,
      },
    })
  }

  return (
    <StackContainer>
      <PageHeader title="Alteração Salarial Coletiva">
        <Finder onSearch={setQuery} onClose={() => ''} />
      </PageHeader>

      <Stack component={Paper} p={1} gap={1}>
        <Stack orientation="horizontal">
          <Box flex={1} mr={2}>
            <ContentDivider title="Reajuste por Faixa de Valor" bottom={1} />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <CurrencyTextField
                  label="Valor Inicial"
                  value={dataFormSimular.vrInicialFaixa}
                  onChange={(_, value) => {
                    const vrInicialFaixa = value
                    setDataFormSimular((prev) => ({
                      ...prev,
                      vrInicialFaixa,
                      vrPercentualReajuste: 0,
                    }))
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4}>
                <CurrencyTextField
                  label="Valor Final"
                  value={dataFormSimular.vrFinalFaixa}
                  onChange={(_, value) => {
                    const vrFinalFaixa = value
                    setDataFormSimular((prev) => ({
                      ...prev,
                      vrFinalFaixa,
                      vrPercentualReajuste: 0,
                    }))
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={4}>
                <CurrencyTextField
                  label="Novo Salário"
                  value={dataFormSimular.vrNovoSalarioFaixa}
                  onChange={(_, value) => {
                    const vrNovoSalarioFaixa = value
                    setDataFormSimular((prev) => ({
                      ...prev,
                      vrNovoSalarioFaixa,
                      vrPercentualReajuste: 0,
                    }))
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box>
            <ContentDivider title="Reajuste por Percentual" bottom={1} />
            <CurrencyTextField
              label="Percentual para reajuste"
              value={dataFormSimular.vrPercentualReajuste}
              onChange={(_, value) => {
                const vrPercentualReajuste = value
                setDataFormSimular((prev) => ({
                  ...prev,
                  vrPercentualReajuste,
                  vrInicialFaixa: 0,
                  vrFinalFaixa: 0,
                  vrNovoSalarioFaixa: 0,
                }))
              }}
            />
          </Box>
        </Stack>

        <Divider />

        <Stack orientation="horizontal" alignItems="center" justifyContent="space-between">
          <Box maxWidth={700} flex={1}>
            <AutoCompleteSindicato
              value={sindicatoId || null}
              onChange={(e, sindicatoObj) => {
                const sindicatoId = sindicatoObj?.id || ''
                setSindicatoId(sindicatoId)
              }}
            />
          </Box>

          <Stack display="flex" orientation="horizontal" justifyContent="flex-end">
            <Button
              startIcon={<FaFileExcel />}
              variant="outlined"
              isLoading={isLoadingXlsx}
              onClick={handlePrintXlsx}
              disabled={data.length > 0 ? false : true}
            >
              Imprimir
            </Button>
            <Button variant="contained" onClick={() => refetch()}>
              Simular
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Table
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        query={query}
        rowsSelected={rowsSelected}
        setRowsSelected={setRowsSelected}
      />

      <Stack component={Paper} p={1} orientation="horizontal" alignItems="center">
        <Grid container spacing={1} style={{ flex: 1 }}>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <DatePickerNew
              label="Data Efetiva"
              name="dtEfetiva"
              validationErrors={validationErrors}
              value={dataFormProcesso.dtEfetiva || null}
              onChange={(date) => {
                const dtEfetiva = date || ''
                setDataFormProcesso((prev) => ({
                  ...prev,
                  dtEfetiva,
                }))
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <DatePickerNew
              label="Data da Alteração"
              name="dtAlteracao"
              validationErrors={validationErrors}
              value={dataFormProcesso.dtAlteracao || null}
              onChange={(date) => {
                const dtAlteracao = date || ''
                setDataFormProcesso((prev) => ({
                  ...prev,
                  dtAlteracao,
                }))
              }}
            />
          </Grid>
        </Grid>

        <Button
          variant="contained"
          onClick={handleValidate}
          isLoading={isProcessing}
          disabled={rowsSelected.length > 0 ? false : true}
        >
          Processar Alteração
        </Button>
      </Stack>
    </StackContainer>
  )
}
