import { useQuery } from 'react-query'
import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { ESocialGrupoEventoEnum } from '~/@types/enums/ESocialGrupoEventoEnum'
import { IndApuracaoEnum } from '~/@types/enums/IndApuracaoEnum'
import { ESocialEventoEnum } from '~/@types/enums/ESocialEventoEnum'
import { ErroSchema } from './ErroSchema'

export const queryUseObterEventosTransmissaoEnvio = '/ESocial/ObterEventosTransmissaoEnvio'

export interface ESocialAuditoriaXMLDTO {
  errosSchema: ErroSchema[]
  xmlEnvio: string
  retorno_Xml: string
  retorno_DataHora: Date | string | null
  ocorrencias: string[]
}

export interface ESocialAuditoriaTransmitirDTO {
  auditoriaId: string
  auditoriaDescricao: string
  auditoriaIdentificacao: string
  tabelaOrigem: string
  dtMovimento: Date | string
  eSocialRecibo: string
  tipoMovimento: string
  indRetificacao: string
  dtESocialRecibo: Date | string | null
  auditoriaXML: ESocialAuditoriaXMLDTO | null
}

export interface ESocialGrupoEventoTransmitirDTO {
  grupoEventoId: ESocialEventoEnum
  grupoEventoDescricao: string
  totalEventos: number
  eventos: ESocialAuditoriaTransmitirDTO[]
}

export interface ESocialEmpregadorTransmitirDTO {
  empregadorId: string
  empregadorNome: string
  empregadorNrInscricao: string
  totalEventos: number
  grupoEventos: ESocialGrupoEventoTransmitirDTO[]
}

export interface ESocialEmpregadorTransmitirRespostaDTO {
  data: ESocialEmpregadorTransmitirDTO[]
  totalRecords: number
}

interface UseObterEventosTransmissaoEnvioProps {
  params: {
    grupoEvento: ESocialGrupoEventoEnum
    anoMes: string
    grupoId: string | null
    indApuracao: IndApuracaoEnum
    page: number
    pageSize: number
    empregadorNome: string
  }
  data: ESocialEventoEnum[]
}

export function useObterEventosTransmissaoEnvio({
  data,
  params,
}: UseObterEventosTransmissaoEnvioProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: ESocialEmpregadorTransmitirRespostaDTO }>(
      queryUseObterEventosTransmissaoEnvio,
      data,
      {
        params,
      },
    )
    return response?.data?.data
  }

  return useQuery([queryUseObterEventosTransmissaoEnvio, data, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
