import { useState } from 'react'

import api from '~/services/api-pessoal'
import moment from 'moment'
import * as yup from 'yup'

import { Grid } from '@material-ui/core'

import { Checkbox, ActionDialog, TextField, TimePicker, CurrencyTextField } from '~/components'

import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import {
  HorarioTrabalho,
  HorarioTrabalhoForm,
} from '~/hooks/queries/HorarioTrabalho/HorarioTrabalho'
import { HorarioTrabalhoIntervalo } from '~/hooks/queries/HorarioTrabalhoIntervalo/HorarioTrabalhoIntervalo'

export const calcularDuracaoJornada = (
  horaSaida: string,
  horaEntrada: string,
  intervalos: HorarioTrabalhoIntervalo[],
) => {
  const format = 'HHmm'

  const dateEntrada = moment(horaEntrada, format)
  let dateSaida = moment(horaSaida, format)

  if (dateSaida.isBefore(dateEntrada)) {
    dateSaida = moment(dateSaida).add(1, 'days')
  }

  let duracao = dateSaida.diff(dateEntrada, 'minutes')

  intervalos.forEach((intervalo) => (duracao -= intervalo.duracaoIntervalo))

  return duracao
}

const calcularDuracaoJornadaNoturna = (horaSaida: string, horaEntrada: string) => {
  const format = 'HHmm'

  const dateEntrada = moment(horaEntrada, format)
  let dateSaida = moment(horaSaida, format)

  const startHoraNoturna = moment('2200', format)
  const endHoraNoturna = moment('0500', format)

  if (dateSaida.isBefore(dateEntrada)) {
    dateSaida = moment(dateSaida).add(1, 'days')
  }

  let minutosNortuno = 0

  while (dateEntrada.isBefore(dateSaida)) {
    if (dateEntrada.isSameOrAfter(startHoraNoturna) || dateEntrada.isBefore(endHoraNoturna)) {
      minutosNortuno++
    }

    dateEntrada.add(1, 'minutes')
  }

  return minutosNortuno
}

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
  horaEntrada: yup
    .string()
    .required('Informe a Entrada')
    .min(4, 'Informe um horário válido')
    .nullable(),
  horaSaida: yup
    .string()
    .required('Informe a Saída')
    .min(4, 'Informe um horário válido')
    .nullable(),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: HorarioTrabalhoForm | null
  onAfterSubmitForm: (event: 'insert' | 'update', data: HorarioTrabalho) => void
}

export default function Form({ isOpen, onClose, data: _data, onAfterSubmitForm }: FormProps) {
  const [data, setData] = useState(_data || ({} as HorarioTrabalhoForm))

  const [isSubmitting, setSubmitting] = useState(false)

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  const handleSubmit = () => {
    const update = async () => {
      setSubmitting(true)
      try {
        const response = await api.put(`/horarioTrabalho/${data.id}`, data)
        onAfterSubmitForm('update', response.data.data)
        notification.put()
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    const insert = async () => {
      setSubmitting(true)
      try {
        const response = await api.post('/horarioTrabalho', data)
        notification.post()
        onAfterSubmitForm('insert', response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setSubmitting(false)
    }

    if (data.id) {
      update()
      return
    }
    insert()
  }

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  return (
    <ActionDialog
      title="Cadastro de Horário de Trabalho"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{
        maxWidth: 'sm',
        fullWidth: true,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
          <TextField label="Código" fullWidth value={data.codigo || ''} size="small" disabled />
        </Grid>

        <Grid item xl={10} lg={10} md={10} sm={10} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            value={data.nome || ''}
            size="small"
            required
            autoFocus
            validationErrors={validationErrors}
            name="nome"
            onChange={(e) => {
              const nome = e.target.value || ''
              setData({ ...data, nome })
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TimePicker
            label="Entrada"
            value={data?.horaEntrada ? moment(data.horaEntrada, 'HHmm').format('HH:mm') : null}
            required
            validationErrors={validationErrors}
            name="horaEntrada"
            onChange={(_, date) => {
              const horaEntrada = date?.format('HHmm') || null
              const horaSaida = data.horaSaida
              let duracaoJornada: number | null = null
              let duracaoJornadaNoturna: number | null = null
              const intervalos = data.horarioTrabalhoIntervalo || []

              if (horaSaida && horaEntrada) {
                duracaoJornada = calcularDuracaoJornada(horaSaida, horaEntrada, intervalos)
                duracaoJornadaNoturna = calcularDuracaoJornadaNoturna(horaSaida, horaEntrada)
              }

              setData((prev) => ({
                ...prev,
                horaEntrada,
                horaSaida,
                duracaoJornada,
                duracaoJornadaNoturna,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TimePicker
            label="Saída"
            value={data?.horaSaida ? moment(data.horaSaida, 'HHmm').format('HH:mm') : null}
            required
            validationErrors={validationErrors}
            name="horaSaida"
            onChange={(_, date) => {
              const horaEntrada = data.horaEntrada
              const horaSaida = date?.format('HHmm') || null
              let duracaoJornada: number | null = null
              let duracaoJornadaNoturna: number | null = null
              const intervalos = data.horarioTrabalhoIntervalo || []

              if (horaSaida && horaEntrada) {
                duracaoJornada = calcularDuracaoJornada(horaSaida, horaEntrada, intervalos)
                duracaoJornadaNoturna = calcularDuracaoJornadaNoturna(horaSaida, horaEntrada)
              }

              setData((prev) => ({
                ...prev,
                horaEntrada,
                horaSaida,
                duracaoJornada,
                duracaoJornadaNoturna,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Duração (min)"
            fullWidth
            value={data?.duracaoJornada || ''}
            size="small"
            required
            validationErrors={validationErrors}
            name="duracaoJornada"
            disabled
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Duração Jornada Noturna(min)"
            fullWidth
            value={data?.duracaoJornadaNoturna || ''}
            size="small"
            onChange={(e) => {
              const duracaoJornadaNoturna = parseInt(e.target.value || '0')
              setData((prev) => ({
                ...prev,
                duracaoJornadaNoturna,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            label="Minutos compensados de sabádo"
            size="small"
            onlyNumber
            fullWidth
            inputProps={{ maxLength: 10 }}
            value={data?.duracaoJornadaSabado || ''}
            onChange={(e) => {
              const duracaoJornadaSabado = parseInt(e.target.value || '0')
              setData((oldState) => ({
                ...oldState,
                duracaoJornadaSabado,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <CurrencyTextField
            label="Porcentagem horas compensadas de sabádo"
            value={data?.porcentagemSabadoCompensado}
            onChange={(_, value) => {
              const porcentagemSabadoCompensado = value
              setData((prev) => ({
                ...prev,
                porcentagemSabadoCompensado,
              }))
            }}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Checkbox
            label="Horário Flexível"
            value={data?.permiteHorarioFlexivel || false}
            onChange={(e, value) => {
              const permiteHorarioFlexivel = value
              setData({
                ...data,
                permiteHorarioFlexivel,
              })
            }}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  )
}
