import { makeStyles } from '@material-ui/core'

const headerStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: 8,
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  iconButton: {
    width: '1.75rem',
    height: '1.75rem',
    color: 'white',
  },
  arrow: {
    width: 14,
    height: 14,
  },
  yearLabel: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
}))

export default headerStyles
