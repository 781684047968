import { useMemo } from 'react'
import { ToolsDataTable } from '~/components'

import { useObterRelatorioAcordoJornada12x36Vinculos } from '~/hooks/queries/AcordoJornada12x36'
import useAmbiente from '~/hooks/useAmbiente'

import { ToolsDataTableColumnDef } from '~/interfaces'
import { FilterStateProps } from '../Fillter'
import { useTableRowsSelectableContext } from '~/contexts/TableRowsSelectableContext'
import { AcordoJornada12x36DTO } from '~/hooks/queries/AcordoJornada12x36/dtos'

interface TableProps {
  filters: FilterStateProps | null
}

export default function Table({ filters }: TableProps) {
  const { estabelecimento } = useAmbiente()
  const { indexSelecionados, setIndexSelecionados } =
    useTableRowsSelectableContext<AcordoJornada12x36DTO>()

  const { data, isLoading, isFetching } = useObterRelatorioAcordoJornada12x36Vinculos({
    params: {
      estabelecimentoId: estabelecimento?.id,
      dtInicio: filters?.dtInicio || '',
      dtFim: filters?.dtFim || '',
    },
    enabled: !!filters,
  })

  const columns = useMemo((): ToolsDataTableColumnDef[] => {
    return [
      {
        name: 'codigo',
        label: 'Código',
        type: 'number',
      },
      {
        name: 'nrInscricao',
        label: 'Identificação',
        type: 'cpf-cnpj',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'dtAdmissao',
        label: 'Admissão',
        type: 'full-date',
      },
      {
        name: 'dtRescisao',
        label: 'Rescisão',
        type: 'full-date',
      },
      {
        name: 'email',
        label: 'E-mail',
      },
      {
        name: 'isAtivo',
        label: 'Ativo',
        type: 'ativo',
      },
    ]
  }, [])

  return (
    <ToolsDataTable
      columns={columns}
      data={data?.data || []}
      isLoading={isLoading}
      isFetching={isFetching}
      cellHeaderWhiteSpaceNowrap
      cellWhiteSpaceNowrap
      sherlock={{ columns: ['codigo', 'nome'], query: filters?.query }}
      optionsSelectable={{
        type: 'multiple',
      }}
      options={{
        rowsSelected: indexSelecionados,
        onRowSelectionChange: (_: any, __: any, rowsSelected: any) =>
          setIndexSelecionados(rowsSelected, data?.data),
      }}
    />
  )
}
