import { useQuery } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'
import { HttpRequestInterface, HttpResponseInterface } from '~/interfaces'
import api from '~/services/api-pessoal'
import { SaldoFgtsDTO, SaldoFgtsRequestParams } from './dtos'

export const query = '/ConsultaSaldoFGTS/ObterSaldoFGTS'

async function handleRequest({ params }: HttpRequestInterface<unknown, SaldoFgtsRequestParams>) {
  const response = await api.get(query, {
    params,
  })
  return response.data
}

export default function useObterSaldoFgts(
  props: HttpRequestInterface<unknown, SaldoFgtsRequestParams>,
) {
  const dialogNotification = useDialogNotification()
  return useQuery<HttpResponseInterface<SaldoFgtsDTO>>(
    [query, props],
    () => {
      return handleRequest(props)
    },
    {
      onError: dialogNotification.extractErrors,
      enabled: props?.enabled,
    },
  )
}
