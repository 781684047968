import { useCallback, useEffect, useState } from 'react'

import api from '~/services/api-pessoal'

import { Box, Grid, IconButton, makeStyles, Paper, Tooltip, useTheme } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import {
  MultipleSelect,
  PageHeader,
  PDFViewer,
  PrintButton,
  Button,
  Finder,
  Checkbox,
  Stack,
  StackContainer,
} from '~/components'
import { AutoCompleteGrupo } from '~/components/AutoComplete'

import Table from './components/Table'
import DialogObservacao from './components/DialogObservacao'

import useDialogNotification, { extract } from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { EstabelecimentoConsulta } from '~/hooks/queries/EstabelecimentoConsulta/EstabelecimentoConsulta'

import { ReciboIDFEnum, ReciboIDFValues } from '~/@types/enums/ReciboIDFEnum'
import { ReportData } from '~/components/ReportSelector'

const useStyles = makeStyles((theme) => ({
  borderRadius: ({ isActive }: { isActive: boolean }) => ({
    border: 'solid',
    borderColor: isActive ? theme.palette.primary.main : theme.palette.common.white,
    borderWidth: theme.spacing(0.5),
  }),
}))

const recibosIdfsPossibles = [
  ReciboIDFEnum.Mensal_1,
  ReciboIDFEnum.Complementar_3,
  ReciboIDFEnum.Socio_15,
  ReciboIDFEnum.DecimoTerceiro1aParcela_7,
  ReciboIDFEnum.DecimoTerceiro2aParcela_8,
  ReciboIDFEnum.Adiantamento_4,
  ReciboIDFEnum.Cooperado_19,
]

const recibosIdfsToStartForm = [ReciboIDFEnum.Mensal_1, ReciboIDFEnum.Socio_15]

const recibosIdfsObjectStartForm = ReciboIDFValues.filter((idr) =>
  recibosIdfsToStartForm.includes(idr.value),
)
const recibosIdfs = ReciboIDFValues.filter((idr) => recibosIdfsPossibles.includes(idr.value))

export default function RelatorioContraCheque() {
  const [collection, setCollection] = useState<EstabelecimentoConsulta[]>([])
  const [rowsSelecteds, setRowsSelecteds] = useState<number[]>([])
  const [isLoading, setLoading] = useState(false)
  const [isLoadingSendForEmail, setLoadingSendForEmail] = useState(false)
  const [query, setQuery] = useState('')
  const [data, setData] = useState<{
    identificacaoRecibo: { value: ReciboIDFEnum; name: string }[]
    grupoId: string | null
    imprimirRPSImpressos: boolean
    isAgruparPorEstabelecimento: boolean
  }>({
    identificacaoRecibo: recibosIdfsObjectStartForm,
    grupoId: null,
    imprimirRPSImpressos: false,
    isAgruparPorEstabelecimento: false,
  })
  const [report, setReport] = useState<ReportData | null>(null)
  const [observacaoRecibos, setObservacaoRecibos] = useState('')

  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const classes = useStyles({
    isActive: observacaoRecibos ? true : false,
  })
  const theme = useTheme()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()
  const { anoMes } = useAmbiente()

  const {
    close: closeDialogObservacao,
    data: dataDialogObservacao,
    isOpen: isOpenDialogObservacao,
    open: openDialogObservacao,
  } = useDialog('')

  const getCollection = useCallback(async () => {
    setLoading(true)
    try {
      const response = await api.get('/EstabelecimentoConsulta/ObterEstabelecimentoAtivos', {
        params: {
          grupoId: data.grupoId,
        },
      })
      setCollection(response?.data?.data || [])
      setRowsSelecteds([])
      setObservacaoRecibos('')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [data.grupoId])

  useEffect(() => {
    getCollection()
  }, [getCollection])

  useEffect(() => {
    async function getReportKey() {
      const response = await api.get('/Relatorio/GetByChaveId', {
        params: {
          chaveId: 'ReciboPagamento',
        },
      })
      setReport(response?.data?.data[0] || {})
    }
    getReportKey()
  }, [])

  async function validateEmailsInUse() {
    try {
      rowsSelecteds.forEach((rowIndex) => {
        if (!collection[rowIndex].email) {
          throw new Error(
            'Alguns dos estabelecimentos selecionados não tem um email cadastrado, deseja prosseguir?',
          )
        }
      })
      handleSendForEmail()
    } catch (err) {
      const { descriptionsExtracted, detailsExtracted }: FixLater = extract(err)
      dialogNotification.warning({
        descriptions: descriptionsExtracted,
        details: detailsExtracted,
        onConfirm: () => handleSendForEmail(),
      } as FixLater)
    }
  }

  function handleValidate() {
    if (!(data.identificacaoRecibo.length > 0)) {
      throw new Error('Informe a Identificação dos Recibos para Impressão')
    }
  }

  async function handleSendForEmail() {
    setLoadingSendForEmail(true)
    try {
      handleValidate()
      const estabelecimentosIds = rowsSelecteds.map((rowIndex) => collection[rowIndex].id)
      const idfsRecibos = data.identificacaoRecibo.map((obj) => obj.value)

      if (idfsRecibos.some((d) => d === ReciboIDFEnum.Mensal_1)) {
        idfsRecibos.push(ReciboIDFEnum.ConvencaoColetiva_18)
      }

      const imprimirRPSImpressos = data.imprimirRPSImpressos
      const isAgruparPorEstabelecimento = data.isAgruparPorEstabelecimento
      await api.post(
        '/RP/EnviarContraChequesPorEmailEstabelecimentos',
        {
          estabelecimentosIds,
          idfsRecibos,
          anoMes,
          imprimirRPSImpressos,
          isAgruparPorEstabelecimento,
        },
        {
          headers: {
            'dc-printmode': 'PDF',
            'dc-printrelatorioid': report?.id,
          },
          params: {
            observacao: observacaoRecibos,
          },
        },
      )
      setRowsSelecteds([])
      setObservacaoRecibos('')
      notification.success('Os contracheques foram enviados com sucesso')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingSendForEmail(false)
    }
  }

  function handlePrint() {
    try {
      handleValidate()
      const estabelecimentosIds = rowsSelecteds.map((rowIndex) => collection[rowIndex].id)
      const idfsRecibos = data.identificacaoRecibo.map((obj) => obj.value)

      if (idfsRecibos.some((d) => d === ReciboIDFEnum.Mensal_1)) {
        idfsRecibos.push(ReciboIDFEnum.ConvencaoColetiva_18)
      }

      const imprimirRPSImpressos = data.imprimirRPSImpressos
      const isAgruparPorEstabelecimento = data.isAgruparPorEstabelecimento
      openPDFViewer({
        estabelecimentosIds,
        idfsRecibos,
        anoMes,
        imprimirRPSImpressos,
        isAgruparPorEstabelecimento,
      })
      setRowsSelecteds([])
      setObservacaoRecibos('')
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
  }

  return (
    <StackContainer>
      <PageHeader title="Contracheque">
        <Finder onSearch={setQuery} onClose={() => setQuery('')} />
      </PageHeader>

      <Box component={Paper} padding={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <MultipleSelect
              inputProps={{
                label: 'Identificação dos Recibos para Impressão',
              }}
              value={data.identificacaoRecibo}
              options={recibosIdfs}
              getOptionLabel={(option) => option.name}
              onChange={(e, options) =>
                setData((prev) => ({
                  ...prev,
                  identificacaoRecibo: options,
                }))
              }
              limitTags={5}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box display="flex" gridGap={theme.spacing(1)}>
              <AutoCompleteGrupo
                value={data.grupoId}
                onChange={(e, grupo) => {
                  const grupoId = grupo?.id || null
                  setData((prev) => ({ ...prev, grupoId }))
                }}
              />
              <Checkbox
                label="Imprimir recibos já impressos"
                value={data.imprimirRPSImpressos}
                onChange={(_, checked) =>
                  setData((prev) => ({ ...prev, imprimirRPSImpressos: checked }))
                }
              />
              <Checkbox
                label="Agrupar por Estabelecimento"
                value={data.isAgruparPorEstabelecimento}
                onChange={(_, checked) =>
                  setData((prev) => ({ ...prev, isAgruparPorEstabelecimento: checked }))
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Table
        data={collection}
        query={query}
        isLoading={isLoading}
        rowsSelected={rowsSelecteds}
        onRowSelected={setRowsSelecteds}
        onAfterSubmit={getCollection}
      />

      {isOpenPDFViewer && (
        <PDFViewer
          isOpen={isOpenPDFViewer}
          onClose={closePDFViewer}
          reportKey="ReciboPagamento"
          title="Contracheque"
          isSalvarDocumento
          axiosConfig={{
            method: 'post',
            url: '/RP/ImprimirContraChequesPorEstabelecimentos',
            data: dataPDFViewer,
            params: {
              observacao: observacaoRecibos,
            },
          }}
        />
      )}

      {isOpenDialogObservacao && (
        <DialogObservacao
          isOpen={isOpenDialogObservacao}
          onClose={closeDialogObservacao}
          value={dataDialogObservacao}
          onSubmit={setObservacaoRecibos}
        />
      )}

      <Stack
        orientation="horizontal"
        justifyContent="space-between"
        component={Paper}
        p={1}
        alignItems="center"
      >
        <Tooltip title="Adicionar observação nos recibos">
          <IconButton
            className={classes.borderRadius}
            size="small"
            color={observacaoRecibos ? 'primary' : 'inherit'}
            onClick={() => openDialogObservacao(observacaoRecibos)}
          >
            <MoreHorizIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Stack orientation="horizontal">
          <Button
            title="Enviar contracheque para cada email dos estabelecimentos selecionados"
            isLoading={isLoadingSendForEmail}
            disabled={rowsSelecteds.length > 0 ? false : true}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => validateEmailsInUse()}
          >
            Enviar para Estabelecimentos
          </Button>
          <PrintButton
            disabled={rowsSelecteds.length > 0 ? false : true}
            onClick={() => handlePrint()}
          />
        </Stack>
      </Stack>
    </StackContainer>
  )
}
