import React from 'react'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Box, Typography } from '@material-ui/core'

import { ErrorMessage } from 'mio-library-ui'

import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/dist/locale/pt'

import { obterErroValidationErrors } from '~/hooks/useUtils'

moment.locale('pt', { week: { dow: 0 } })

interface DatePickerProps extends Omit<KeyboardDatePickerProps, 'onChange'> {
  warning?: {
    is: boolean
    message: string
  }
  onChange?: (time: string | null, date: MaterialUiPickersDate) => void
  validationErrors?: ValidationErrors
  onChangeSimple?: (date: MaterialUiPickersDate | null, value?: string | null) => void
}

export default function DatePicker(props: DatePickerProps) {
  const { validationErrors, name, onChange, onChangeSimple, warning, ...rest } = props

  const mensagemDeErro = obterErroValidationErrors(name, validationErrors)

  function handleChange(date: MaterialUiPickersDate) {
    if (!onChange) return
    const dateFormated = date ? date.format('yyyy-MM-DD') : null
    if (!dateFormated || dateFormated === 'Invalid date') return onChange(null, null)
    onChange(dateFormated, date)
  }

  return (
    <Box>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="pt" {...rest}>
        <KeyboardDatePicker
          inputVariant="outlined"
          variant="inline"
          size="small"
          fullWidth
          format="DD/MM/yyyy"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          invalidDateMessage="Data inválida"
          name={name}
          onChange={onChangeSimple ? onChangeSimple : handleChange}
          autoOk
          {...rest}
        />
      </MuiPickersUtilsProvider>
      {mensagemDeErro && <ErrorMessage error={mensagemDeErro} />}

      {warning?.is === true && (
        <Typography style={{ fontSize: '14px', color: '#ff9800' }}>{warning.message}</Typography>
      )}
    </Box>
  )
}
