import React, { useState } from 'react'

import * as yup from 'yup'

import { Box, Grid, Container, Paper, LinearProgress } from '@material-ui/core'

import { PageHeader, PrintButton, PDFViewer, TimePicker, DatePicker } from '~/components'
import { AutoCompleteVinculo } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import { getVinculoById } from '~/hooks/queries/useVinculo'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import { getDateCurrent } from '~/utils/utils'

const PAGE_TITLE = 'Término Contrato Experiência'

const initialState = {
  vinculoId: '',
  dtTerminoContrato: null,
  hrComparecimento: null,
  dtPagamento: getDateCurrent(),
}

const schema = yup.object().shape({
  vinculoId: yup.string().required('Informe o Funcionário'),
  dtTerminoContrato: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data do Término do Contrato')
    .nullable(),
  dtEmissaoContrato: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Emissão do Contrato')
    .nullable(),
  hrComparecimento: yup
    .string()
    .required('Informe o Horário para o Funcionário Comparecer')
    .nullable(),
  dtPagamento: yup
    .date()
    .typeError('Informe uma data válida')
    .required('Informe a Data de Pagamento')
    .nullable(),
})

export default function ComunicadoTerminoExperiencia({ vinculoId, estabelecimentoId }) {
  const [data, setData] = useState({
    ...initialState,
    vinculoId: vinculoId || '',
  })

  const [isLoading, setLoading] = useState(false)

  const { estabelecimento, anoMes } = useAmbiente()
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()
  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  async function handleChangeVinculo(e, _vinculo) {
    setLoading(true)
    let vinculo = _vinculo
    if (_vinculo?.id) vinculo = await getVinculoById(_vinculo.id)
    const vinculoId = vinculo?.id || ''

    const dtContratoExperienciaDtVencimento = vinculo?.contratoExperienciaDtVencimento || null
    const dtContratoExperienciaDtProrrogacao = vinculo?.contratoExperienciaDtProrrogacao || null

    const dtTerminoContrato =
      dtContratoExperienciaDtProrrogacao || dtContratoExperienciaDtVencimento || null
    const dtEmissaoContrato = dtTerminoContrato
    setData((oldState) => ({
      ...oldState,
      vinculoId,
      dtTerminoContrato,
      dtEmissaoContrato,
    }))
    setLoading(false)
  }

  function handleSubmit() {
    openPDFViewer({
      vinculosIds: [data.vinculoId],
      ...data,
    })
  }

  return (
    <Box height="100%" width="100%" p={2}>
      <PageHeader title={PAGE_TITLE} />
      <Container maxWidth="sm">
        <Paper>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AutoCompleteVinculo
                  label="Funcionário"
                  value={data?.vinculoId || ''}
                  estabelecimentoId={estabelecimentoId || estabelecimento?.id}
                  anoMes={anoMes}
                  required
                  name="vinculoId"
                  optionId="id"
                  validationErrors={validationErrors}
                  onChange={handleChangeVinculo}
                />
              </Grid>

              <Grid item xs={12}>
                <DatePicker
                  label="Data do Término do Contrato"
                  value={data?.dtTerminoContrato || null}
                  required
                  name="dtTerminoContrato"
                  validationErrors={validationErrors}
                  disabled={isLoading}
                  onChange={(date) => {
                    const dtTerminoContrato = date ? date.format('yyyy-MM-DD') : null
                    setData((prevState) => ({ ...prevState, dtTerminoContrato }))
                  }}
                />
                {isLoading ? <LinearProgress /> : <Box height={4} />}
              </Grid>

              <Grid item xs={12}>
                <DatePicker
                  label="Data de Emissão do Contrato"
                  value={data?.dtEmissaoContrato || null}
                  required
                  name="dtEmissaoContrato"
                  validationErrors={validationErrors}
                  onChange={(date) => {
                    const dtEmissaoContrato = date ? date.format('yyyy-MM-DD') : null
                    setData((prevState) => ({ ...prevState, dtEmissaoContrato }))
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <DatePicker
                  label="Data de Pagamento"
                  value={data?.dtPagamento || null}
                  required
                  name="dtPagamento"
                  validationErrors={validationErrors}
                  onChange={(date) => {
                    const dtPagamento = date ? date.format('yyyy-MM-DD') : null
                    setData((prevState) => ({ ...prevState, dtPagamento }))
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TimePicker
                  label="Horário para o Funcionário Comparecer"
                  title="Horário para o Funcionário Comparecer no Departamento Pessoal da Empresa."
                  value={data?.hrComparecimento || null}
                  onChange={(horas) => {
                    const hrComparecimento = horas
                    setData((prevState) => ({
                      ...prevState,
                      hrComparecimento,
                    }))
                  }}
                  required
                  name="hrComparecimento"
                  validationErrors={validationErrors}
                />
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <PrintButton onClick={handleValidate} />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
      <PDFViewer
        isOpen={isOpenPDFViewer}
        onClose={closePDFViewer}
        reportKey="ComunicadoTerminoExperiencia"
        title={PAGE_TITLE}
        axiosConfig={{
          method: 'post',
          url: '/Relatorio/ComunicadoTerminoExperiencia/ObterRelatorio',
          data: dataPDFViewer,
        }}
      />
    </Box>
  )
}
