import AlertMessage from './AlertMessage'
import Calendar from './Calendar'
import Popover from './Popover'
import Trigger from './Trigger'

const YearMonthDatePicker = {
  AlertMessage,
  Calendar,
  Popover,
  Trigger,
}

export default YearMonthDatePicker
