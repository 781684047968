import { Box, LinearProgress, Theme, makeStyles, useMediaQuery } from '@material-ui/core'

import { ShowNumber } from '~/components'
import StepperForm, { useStepperContext } from '~/components/StepperForm'
import { StepProps } from '~/components/StepperForm/StepperProvider'

import TransmissaoEmpregador from './TransmissaoEmpregador'
import TransmissaoAuditoria from './TransmissaoAuditoria'
import {
  DataContextProps,
  HEIGHT_STEP_ROOT,
  LocationState,
  UseStylesProps,
  WIDTH_STEP_ROOT,
  WIDTH_STEP_ROOT_SMALL,
} from '..'

import useAmbiente from '~/hooks/useAmbiente'

import { useObterTotalEventosGrupoEnvio } from '~/hooks/queries/ESocial/useObterTotalEventosGrupoEnvio'
import { useHistory, useLocation } from 'react-router-dom'

import { ESocialGrupoEventoEnum } from '~/@types/enums/ESocialGrupoEventoEnum'
import { AutoSizer } from 'react-virtualized'

import { useSelector } from 'react-redux'
import { ParametrosSistema } from '~/hooks/queries/ParametrosSistema/ParametrosSistema'
import { TipoLicencaEnum } from '~/@types/enums/TipoLicencaEnum'
import { GrupoEventosESocialValue } from '~/interfaces'
import { useMemo } from 'react'

export const useStylesRootSteps = makeStyles<Theme, UseStylesProps>((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    gap: theme.spacing(1),
  },
  rootSteps: {
    width: (props) => (props.isSmallWidth ? WIDTH_STEP_ROOT_SMALL : WIDTH_STEP_ROOT),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  steps: {
    display: 'flex',
    justifyContent: (props) => (props.isSmallWidth ? 'center' : 'initial'),
  },
  content: {
    flex: 1,
    marginTop: -HEIGHT_STEP_ROOT,
  },
}))

export default function EnvioESocial() {
  const { anoMes } = useAmbiente()

  const isSmallWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const history = useHistory()
  const location = useLocation<LocationState | undefined>()
  const { state } = location

  const useStylesProps: UseStylesProps = {
    isSmallWidth,
  }

  const { dataControlled: dataContext, setStepCurrent: setStepCurrentEnvioConsulta } =
    useStepperContext<DataContextProps>()
  const {
    data,
    isLoading: _isLoading,
    isFetching,
  } = useObterTotalEventosGrupoEnvio({
    anoMes,
    grupoId: dataContext.grupoId,
    filterEventos: dataContext.eventosFilter,
    indApuracao: dataContext.indApuracao,
  })
  const classes = useStylesRootSteps(useStylesProps)
  const parametrosSistema = useSelector(
    (state: { app: { parametrosSistema: ParametrosSistema } }) => state.app.parametrosSistema,
  )

  const isLicencaSST = parametrosSistema.tipoLicenca === TipoLicencaEnum.SST_2

  const isLoading = _isLoading || isFetching

  const totalEventsPerGroup = {
    [ESocialGrupoEventoEnum.Empregador]: 0,
    [ESocialGrupoEventoEnum.Tabelas]: 0,
    [ESocialGrupoEventoEnum.NaoPeriodicos]: 0,
    [ESocialGrupoEventoEnum.Periodicos]: 0,
    [ESocialGrupoEventoEnum.Fechamento]: 0,
    [ESocialGrupoEventoEnum.Reabertura]: 0,
    [ESocialGrupoEventoEnum.Exclusao]: 0,
  }
  data.forEach((d) => {
    totalEventsPerGroup[d.grupoEventos] = d.totalEventos
  })

  const stepsLicencaNormal: StepProps[] = [
    {
      label: 'Empregador',
      icon: (active) =>
        totalEventsPerGroup[ESocialGrupoEventoEnum.Empregador] > 0 ? (
          <ShowNumber
            number={totalEventsPerGroup[ESocialGrupoEventoEnum.Empregador]}
            active={active}
          />
        ) : (
          'account_circle'
        ),
      children: <TransmissaoEmpregador grupo={ESocialGrupoEventoEnum.Empregador} />,
    },
    {
      label: 'Tabelas',
      icon: (active) =>
        totalEventsPerGroup[ESocialGrupoEventoEnum.Tabelas] > 0 ? (
          <ShowNumber
            number={totalEventsPerGroup[ESocialGrupoEventoEnum.Tabelas]}
            active={active}
          />
        ) : (
          'toc'
        ),
      children: <TransmissaoAuditoria grupo={ESocialGrupoEventoEnum.Tabelas} />,
    },
    {
      label: 'Não Periódicos',
      icon: (active) =>
        totalEventsPerGroup[ESocialGrupoEventoEnum.NaoPeriodicos] > 0 ? (
          <ShowNumber
            number={totalEventsPerGroup[ESocialGrupoEventoEnum.NaoPeriodicos]}
            active={active}
          />
        ) : (
          'people_outline'
        ),
      children: <TransmissaoAuditoria grupo={ESocialGrupoEventoEnum.NaoPeriodicos} />,
    },
    {
      label: 'Periódicos',
      icon: (active) =>
        totalEventsPerGroup[ESocialGrupoEventoEnum.Periodicos] > 0 ? (
          <ShowNumber
            number={totalEventsPerGroup[ESocialGrupoEventoEnum.Periodicos]}
            active={active}
          />
        ) : (
          'people'
        ),
      children: <TransmissaoAuditoria grupo={ESocialGrupoEventoEnum.Periodicos} />,
    },
    {
      label: 'Fechamento',
      icon: (active) =>
        totalEventsPerGroup[ESocialGrupoEventoEnum.Fechamento] > 0 ? (
          <ShowNumber
            number={totalEventsPerGroup[ESocialGrupoEventoEnum.Fechamento]}
            active={active}
          />
        ) : (
          'event_available'
        ),
      // children: <TransmissaoEmpregador grupo={ESocialGrupoEventoEnum.Fechamento} />,
      children: <></>,
      onClick: () => history.push('/fechamento-esocial'),
    },
  ]

  const stepsLicencaSST: StepProps[] = [
    {
      label: 'Não Periódicos',
      icon: (active) =>
        totalEventsPerGroup[ESocialGrupoEventoEnum.NaoPeriodicos] > 0 ? (
          <ShowNumber
            number={totalEventsPerGroup[ESocialGrupoEventoEnum.NaoPeriodicos]}
            active={active}
          />
        ) : (
          'people_outline'
        ),
      children: <TransmissaoAuditoria grupo={ESocialGrupoEventoEnum.NaoPeriodicos} />,
    },
  ]

  const initialStep = useMemo(() => {
    const { grupoEvento } = state || {}
    if (grupoEvento) {
      const stepBasedOnGrupoEventosMapping: Record<GrupoEventosESocialValue, number> = {
        empregador: 0,
        tabelas: 1,
        'nao-periodico': 2,
        periodico: 3,
        fechamento: 4,
        'Nenhum Evento': 0,
      }

      return stepBasedOnGrupoEventosMapping[grupoEvento]
    }

    if (isLicencaSST) {
      return undefined
    }

    const grupoStepFinded = data.find((d) => d.totalEventos > 0)
    return grupoStepFinded
      ? grupoStepFinded.grupoEventos - 1 < ESocialGrupoEventoEnum.Empregador ||
        grupoStepFinded.grupoEventos - 1 > ESocialGrupoEventoEnum.Periodicos
        ? 0
        : grupoStepFinded.grupoEventos - 1
      : 0
  }, [data, state, isLicencaSST])

  return (
    <StepperForm.Provider
      disabledAlert
      initialStep={initialStep}
      dataControlled={{
        ...dataContext,
        changeEnvioConsulta: setStepCurrentEnvioConsulta,
      }}
      steps={isLicencaSST ? stepsLicencaSST : stepsLicencaNormal}
    >
      <Box className={classes.root}>
        <Box className={classes.rootSteps}>
          {isLoading ? <LinearProgress /> : <Box height={4} />}
          <Box className={classes.steps}>
            <StepperForm.Steps orientation="vertical" />
          </Box>
        </Box>

        <Box className={classes.content}>
          <AutoSizer>
            {({ height, width }) => (
              <Box height={height - 35} width={width} overflow="auto">
                <StepperForm.Content
                  style={{
                    height: '100%',
                  }}
                />
              </Box>
            )}
          </AutoSizer>
        </Box>
      </Box>
    </StepperForm.Provider>
  )
}
