import { useState } from 'react'

import { IconButton, makeStyles } from '@material-ui/core'
import { ButtonBox } from 'mio-library-ui'

import { ToolsDataTable } from '~/components'
import Ativo from '~/components/Displays/Ativo'

import TableRecibosItems from './components/TableRecibosItems'

import { Delete, Visibility as ViewIcon } from '@material-ui/icons'

import useDialogNotification from '~/hooks/useDialogNotification'
import useUtils from '~/hooks/useUtils'
import useOnlyDataC from '~/hooks/useOnlyDataC'
import { useRemoverRP } from '~/hooks/queries/RP/useRemoverRP'

import api from '~/services/api-pessoal'

const useStyles = makeStyles(() => ({
  renderRight: { textAlign: 'right' },
}))

const CollapsibleTable = (props) => {
  const {
    data: _data,
    isLoading,
    onItemClick,
    triggersHeight,
    rowsExpanded,
    setRowsExpanded,
    rowsSelected,
    setRowsSelected,
    afterSubmit,
  } = props

  const classes = useStyles()

  const [dataOfTableExpanded, setDataOfTableExpanded] = useState({
    isLoading: false,
    data: [],
  })

  const { formatAnoMes, formatIdfReciboPagamento, formatCurrency } = useUtils()
  const dialogNotification = useDialogNotification()
  const { isUserDataC } = useOnlyDataC()
  const { mutateAsync, isLoading: isDeletting } = useRemoverRP()

  const data = _data.map((item) => {
    return {
      ...item,
      anoMes: formatAnoMes(item?.anoMes),
      fatoGerador: formatAnoMes(item?.fatoGerador),
      idf: formatIdfReciboPagamento(item?.idf),
      vrTotalVencimento: formatCurrency(item?.vrTotalVencimento),
      vrTotalDesconto: formatCurrency(item?.vrTotalDesconto),
      vrTotalLiquido: formatCurrency(item?.vrTotalLiquido),
      lotacao: item?.lotacao?.nome,
      vrSalarioContratual: formatCurrency(item?.vrSalarioContratual),
    }
  })

  async function handleRemove(id) {
    await mutateAsync({ rpId: id })
    afterSubmit()
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'nrRecibo',
      label: 'Identificador',
    },
    {
      name: 'anoMes',
      label: 'Ano/Mês',
    },
    {
      name: 'fatoGerador',
      label: 'Fato Gerador',
    },
    {
      name: 'idf',
      label: 'Identificação',
    },
    {
      name: 'vrTotalVencimento',
      label: 'Vencimentos',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
    {
      name: 'vrTotalDesconto',
      label: 'Descontos',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
    {
      name: 'vrTotalLiquido',
      label: 'Líquido',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
    {
      name: 'isFinalizado',
      label: 'Impresso',
      options: {
        customBodyRender: (value) => (value ? <Ativo ativo={value} /> : ''),
      },
    },
    {
      name: 'lotacaoNome',
      label: 'Lotação',
    },
    {
      name: 'nrReciboeSocial',
      label: 'Recibo eSocial',
    },
    {
      name: 'vrSalarioContratual',
      label: 'Salário Contratual',
      options: {
        setCellProps: () => {
          return {
            className: classes.renderRight,
          }
        },
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox justifyContent="center" spacing={0}>
              <IconButton
                title="Visualizar valores Bases INSS, FGTS e IRRF"
                color="primary"
                aria-label="Visualizar"
                onClick={() => {
                  onItemClick(value)
                }}
                size="small"
              >
                <ViewIcon fontSize="small" color="primary" size="small" />
              </IconButton>

              {isUserDataC && (
                <IconButton
                  title="Remover Registro"
                  color="primary"
                  aria-label="Remover"
                  onClick={() => handleRemove(value)}
                  size="small"
                >
                  <Delete fontSize="small" color="primary" size="small" />
                </IconButton>
              )}
            </ButtonBox>
          )
        },
      },
    },
  ]

  const onRowExpand = async (indexs) => {
    setRowsExpanded(indexs)
    if (indexs.length === 0) return
    const obj = data[indexs[0]]
    setDataOfTableExpanded((oldState) => ({
      ...oldState,
      isLoading: true,
    }))
    try {
      const response = await api.get('RPItem/GetByRp', {
        params: {
          rpId: obj.id,
        },
      })
      setDataOfTableExpanded({
        data: response.data.data,
        isLoading: false,
      })
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setDataOfTableExpanded((oldState) => ({
      ...oldState,
      isLoading: false,
    }))
  }

  function renderExpandableRow() {
    return (
      <TableRecibosItems
        data={dataOfTableExpanded.data}
        isLoading={dataOfTableExpanded.isLoading}
      />
    )
  }

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isDeletting}
      triggersHeight={triggersHeight}
      optionsExpandable={{
        type: 'single',
        onRowExpanded: onRowExpand,
        expandedChildren: renderExpandableRow,
        rowsExpanded: rowsExpanded,
      }}
      optionsSelectable={{
        onRowSelected: setRowsSelected,
        rowsSelected,
        type: 'multiple',
      }}
    />
  )
}

export default CollapsibleTable
