import { ReactNode, createContext, useContext, useState } from 'react'

interface TableRowsSelectableData<T> {
  indexSelecionados: number[]
  idsSelecionados: string[]
  dataSelecionados: T[]
  setIndexSelecionados: (
    indexs: number[],
    data?: T[],
    fieldName?: keyof T,
  ) => void
  clearSelecionados: () => void
}

interface ItemProps {
  id?: string
  [key: string]: any
}

interface TableRowsSelectableProps {
  children: ReactNode
}

export const TableRowsSelectableContext = createContext(
  {} as TableRowsSelectableData<any>,
)

export default function TableRowsSelectableProvider<T>({
  children,
}: TableRowsSelectableProps) {
  const [indexs, setIndexs] = useState<number[]>([])
  const [ids, setIds] = useState<string[]>([])
  const [data, setData] = useState<T[]>([])

  function clearSelecionados() {
    setIds([])
    setIndexs([])
    setData([])
  }

  function setIndexSelecionados<T extends ItemProps>(
    indexs: number[],
    data?: T[],
    fieldName?: keyof T,
  ) {
    if (data) {
      const _ids = indexs.map((indexSelecionado) => {
        return data[indexSelecionado][fieldName || 'id'] || ''
      })
      const _data = indexs.map((indexSelecionado) => {
        return data[indexSelecionado]
      })
      setData(_data as any)
      setIds(_ids)
    }
    setIndexs(indexs)
  }

  return (
    <TableRowsSelectableContext.Provider
      value={{
        indexSelecionados: indexs,
        idsSelecionados: ids,
        dataSelecionados: data,
        setIndexSelecionados,
        clearSelecionados,
      }}
    >
      {children}
    </TableRowsSelectableContext.Provider>
  )
}

export function useTableRowsSelectableContext<T>() {
  return useContext<TableRowsSelectableData<T>>(TableRowsSelectableContext)
}
