import { useState } from 'react'

import * as yup from 'yup'

import { Box } from '@material-ui/core'
import { ActionDialog, CurrencyTextField, Stack } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'
import useAmbiente from '~/hooks/useAmbiente'

import { useSaldoInicial } from '~/hooks/queries/ProvisaoCompetencia/useSaldoInicial'

import { SaldoProvisaoDTO } from '~/hooks/queries/ProvisaoCompetencia/interfaces/SaldoProvisaoDTO'
import { IdentificadorSaldoEnum } from '~/@types/enums/IdentificadorSaldoEnum'

const schema = yup.object().shape({
  vrSaldoProvisao: yup
    .number()
    .min(0.01, 'Informe um valor válido')
    .required('Informe o Valor Saldo Provisão'),
})

interface FormCreateProps {
  onClose: () => void
  vinculoId: string
  vinculoFeriasId: string
}

export default function Form({ onClose, vinculoId, vinculoFeriasId }: FormCreateProps) {
  const { anoMes } = useAmbiente()

  const [data, setData] = useState<SaldoProvisaoDTO>({
    anoMes,
    vinculoId,
    vinculoFeriasId,
    identificadorSaldo: IdentificadorSaldoEnum.Ferias_F,
  } as SaldoProvisaoDTO)

  const { mutateAsync: mutateAsyncProcess, isLoading: isSubmitting } = useSaldoInicial()

  const { handleValidate, validationErrors } = useValidationErrors({
    schema,
    data,
    handleSubmit,
  })

  async function handleSubmit() {
    await mutateAsyncProcess({ data })
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro de Provisão Saldo Inicial"
      isOpen={true}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
    >
      <Stack gap={2}>
        <Box>
          <CurrencyTextField
            label="Valor Saldo Provisão"
            value={data?.vrSaldoProvisao || ''}
            validationErrors={validationErrors}
            required
            name="vrSaldoProvisao"
            onChange={(_, value) => {
              const vrSaldoProvisao = value
              setData((oldState) => ({
                ...oldState,
                vrSaldoProvisao,
              }))
            }}
          />
        </Box>
        <CurrencyTextField
          label="Valor Contribuição Previdência"
          value={data?.vrContribuicaoPrevidencia || ''}
          onChange={(_, value) => {
            const vrContribuicaoPrevidencia = value
            setData((oldState) => ({
              ...oldState,
              vrContribuicaoPrevidencia,
            }))
          }}
        />
        <CurrencyTextField
          label="Valor Contribuição Terceiros"
          value={data?.vrContribuicaoTerceiros || ''}
          onChange={(_, value) => {
            const vrContribuicaoTerceiros = value
            setData((oldState) => ({
              ...oldState,
              vrContribuicaoTerceiros,
            }))
          }}
        />
        <CurrencyTextField
          label="Valor FGTS"
          value={data?.vrFGTS || ''}
          onChange={(_, value) => {
            const vrFGTS = value
            setData((oldState) => ({
              ...oldState,
              vrFGTS,
            }))
          }}
        />
        <CurrencyTextField
          label="Valor PIS"
          value={data?.vrPIS || ''}
          onChange={(_, value) => {
            const vrPIS = value
            setData((oldState) => ({
              ...oldState,
              vrPIS,
            }))
          }}
        />
      </Stack>
    </ActionDialog>
  )
}
