import { useState } from 'react'
import { ValidationError } from 'yup'

export default function useValidations() {
  const [_validationErrors, _setValidationErrors] = useState<ValidationError | null>(null)

  function validationMessage(fieldName: string) {
    if (!_validationErrors) return false

    const { inner } = _validationErrors
    if (!inner) return false
    const erroEncontrado = inner.find((item) => {
      const { path } = item
      return fieldName === path
    })

    if (!erroEncontrado) return false

    return erroEncontrado.message
  }

  function validationProps(fieldName: string) {
    const message = validationMessage(fieldName)

    if (message) {
      return {
        helperText: message,
        error: true,
      }
    }

    return {
      helperText: '',
      error: false,
    }
  }

  function setValidationErrors(validation: ValidationError | null) {
    _setValidationErrors(validation)
  }

  return {
    validationMessage,
    validationProps,
    setValidationErrors,
    _validationErrors,
  }
}
