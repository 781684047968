import { makeStyles } from '@material-ui/core'

const alertMessageStyles = makeStyles((theme) => ({
  collapse: {
    position: 'absolute',
    top: 44,
    left: 0,
    right: 0,
    zIndex: 99,
  },
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.warning.main,
    padding: 4,
  },
  message: {
    fontSize: '0.625rem',
    color: 'white',
  },
  iconButton: {
    width: '1.25rem',
    height: '1.25rem',
    color: 'white',
  },
}))

export default alertMessageStyles
