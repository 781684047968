import { useCallback, useEffect, useState } from 'react'

import { Box, Grid, Typography } from '@material-ui/core'
import {
  ActionDialog,
  NumeroInscricaoTextField,
  TextField,
  Button,
  ButtonBox,
  PageHeader,
} from '~/components'

import { AutoCompleteBancoAgencia, MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import useDialogNotification from '~/hooks/useDialogNotification'

import Table from './components/Table'

import api from '~/services/api-pessoal'
import useNotification from '~/hooks/useNotification'

import * as yup from 'yup'
import { IndLayoutCNABEnum, IndLayoutCNABValues } from '~/@types/enums/IndLayoutCNABEnum'
import { TipoInscricaoEnum } from '~/@types/enums/TipoInscricaoEnum'
import {
  ParametroTransmissaoEnum,
  parametroTransmissaoValues,
} from '~/@types/enums/ParametroTransmissaoEnum'

const bancoCaixa = '104'

const pageTitle = 'Estabelecimento | CNAB'
const apiRoute = '/CNAB'
const HEADER_HEIGHT = '50px'

const schema = yup.object().shape({
  bancoAgencia: yup.string().required('Informe a Agência').nullable(),
  convenio: yup.string().required('Informe o Convênio'),
  contaCorrente: yup.string().required('Informe a Conta Corrente'),
})

const CNAB = ({ estabelecimentoId }) => {
  const [currentList, setCurrentList] = useState([])
  const [current, setCurrent] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const notifications = useNotification()
  const dialogNotification = useDialogNotification()

  const getEmpregadorCNAB = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await api.get(`${apiRoute}/GetByEstabelecimento?id=${estabelecimentoId}`)
      if (response.data.data) {
        setCurrentList(response.data.data)
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsLoading(false)

    // eslint-disable-next-line
  }, [estabelecimentoId])

  useEffect(() => {
    getEmpregadorCNAB()
    // eslint-disable-next-line
  }, [getEmpregadorCNAB])

  const handleStartNew = () => {
    setIsOpen(true)

    setCurrent({
      ...current,
      estabelecimentoId,
      isRegistroB: true,
      parametroTransmissao: ParametroTransmissaoEnum.OPCAO_1,
    })
  }

  const handleCancelNew = () => {
    setIsOpen(false)

    setCurrent({})
  }

  const getCurrent = async (id) => {
    try {
      const response = await api.get(`${apiRoute}/${id}`)
      if (response.data.data) {
        setCurrent(response.data.data)
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
  }

  const handleEdit = (id) => {
    setCurrent(getCurrent(id))
    setIsOpen(true)
  }

  const handleDelete = (id) => {
    setCurrent({ id })

    if (!id) {
      notifications.warning(
        `Selecione um ${pageTitle} para excluir!`,

        'Ops!',
        4000,
      )
      return
    }

    setIsOpenDelete(true)
  }

  const handleClickItem = (event, data) => {
    const functions = {
      edit: handleEdit,
      delete: handleDelete,
    }
    functions[event](data)
  }

  const handleSave = useCallback(() => {
    const update = async () => {
      setIsLoading(true)
      try {
        await api.put(`/CNAB/${current.id}`, current)
        notifications.put()

        getEmpregadorCNAB()

        setIsOpen(false)
        setCurrent({})
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setIsLoading(false)
    }

    const insert = async () => {
      setIsLoading(true)
      try {
        await api.post('/CNAB', current)
        notifications.post()

        getEmpregadorCNAB()

        setIsOpen(false)
        setCurrent({})
      } catch (err) {
        dialogNotification.extractErrors(err)
      }
      setIsLoading(false)
    }

    if (current.id) {
      update()
      return
    }
    insert()
    // eslint-disable-next-line
  }, [current])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleSave,
    data: current,
  })

  const deletar = async () => {
    setIsProcessing(true)

    try {
      const response = await api.delete(`${apiRoute}/${current.id}`)

      if (response.status === 200) {
        notifications.success('Registro foi removido', 'Sucesso!', 4000)
        getEmpregadorCNAB()
      }
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setIsOpenDelete(false)
    setIsProcessing(false)
    setCurrent({})
  }

  const optionNaoInformar = IndLayoutCNABValues[0]
  const indLayoutValuesNew = [optionNaoInformar]

  IndLayoutCNABValues.forEach((d, index) => {
    if (current?.bancoAgencia?.bancoId === bancoCaixa && index !== 0) {
      indLayoutValuesNew.push(d)
    }
  })

  return (
    <>
      <Box height={HEADER_HEIGHT}>
        <PageHeader>
          <ButtonBox top={1}>
            <Button size="small" onClick={handleStartNew} color="primary" variant="contained">
              Adicionar
            </Button>
          </ButtonBox>
        </PageHeader>
      </Box>

      <Box height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Table data={currentList} onItemClick={handleClickItem} isLoading={isLoading} query="" />
      </Box>

      <ActionDialog
        title={'Cadastro de ' + pageTitle}
        okLabel="Salvar"
        isOpen={isOpen}
        onClose={handleCancelNew}
        onOkClick={handleValidate}
        onCancelClick={handleCancelNew}
        isOkProcessing={isLoading}
      >
        <Box>
          <Grid container spacing={2}>
            <Grid item sm={8} xs={12}>
              <AutoCompleteBancoAgencia
                label="Agência"
                value={current?.bancoAgencia || null}
                required
                validationErrors={validationErrors}
                name="bancoAgencia"
                onChange={(e, bancoAgenciaObj) => {
                  const bancoAgenciaId = bancoAgenciaObj?.id || null
                  const bancoAgencia = bancoAgenciaObj || null
                  const idfLayout = IndLayoutCNABEnum.NaoInformar
                  setCurrent({ ...current, bancoAgenciaId, bancoAgencia, idfLayout })
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <MUIAutoComplete
                name="unidadeMedida"
                label="Parâmetro Trasmissão"
                options={parametroTransmissaoValues}
                optionId="value"
                renderOption={(option) => option.name}
                value={parseInt(current?.parametroTransmissao)}
                onChange={(_, obj) => {
                  const parametroTransmissao = obj ? parseInt(obj.value) : ''
                  setCurrent((oldState) => ({
                    ...oldState,
                    parametroTransmissao,
                  }))
                }}
                validationErrors={validationErrors}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <TextField
                label="Convênio"
                fullWidth
                value={current.convenio || ''}
                variant="outlined"
                size="small"
                required
                validationErrors={validationErrors}
                name="convenio"
                inputProps={{
                  maxLength: 20,
                }}
                onChange={(e) => {
                  const convenio = e.target.value.replace(/[^0-9]/g, '')
                  setCurrent({ ...current, convenio })
                }}
              />
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <TextField
                label="Conta Corrente"
                fullWidth
                value={current.contaCorrente || ''}
                variant="outlined"
                size="small"
                required
                onlyNumber
                inputProps={{ maxLength: 20 }}
                validationErrors={validationErrors}
                name="contaCorrente"
                onChange={(e) => {
                  const contaCorrente = e.target.value
                  setCurrent({ ...current, contaCorrente })
                }}
              />
            </Grid>

            <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
              <TextField
                label="DV"
                fullWidth
                value={current.dvContaCorrente || ''}
                variant="outlined"
                size="small"
                validationErrors={validationErrors}
                onlyNumber
                inputProps={{ maxLength: 1 }}
                name="dvContaCorrente"
                onChange={(e) => {
                  const dvContaCorrente = e?.target.value || null
                  setCurrent({ ...current, dvContaCorrente })
                }}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <MUIAutoComplete
                label="Versão Layout"
                options={indLayoutValuesNew}
                renderOption={(option) => option.name}
                value={current?.idfLayout || IndLayoutCNABEnum.NaoInformar}
                optionId="value"
                onChange={(e, obj) => {
                  const idfLayout = obj?.value || IndLayoutCNABEnum.NaoInformar
                  setCurrent({ ...current, idfLayout })
                }}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <TextField
                label="Nr. Sequência"
                fullWidth
                value={current.nrSequencialArquivo || ''}
                variant="outlined"
                size="small"
                onlyNumber
                inputProps={{ maxLength: 9 }}
                onChange={(e) => {
                  const nrSequencialArquivo = e.target.value
                  setCurrent({ ...current, nrSequencialArquivo })
                }}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <TextField
                label="Empresa para Unificação"
                value={current.empresaUnificacao || ''}
                onChange={(e) => {
                  const empresaUnificacao = e.target.value
                  setCurrent({ ...current, empresaUnificacao })
                }}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <NumeroInscricaoTextField
                label="Número de Inscrição da Unificação"
                value={current.nrInscricaoUnificacao || ''}
                typeMask={TipoInscricaoEnum.CNPJ_1}
                onChange={(e, value) => {
                  const nrInscricaoUnificacao = value
                  setCurrent((oldState) => ({
                    ...oldState,
                    nrInscricaoUnificacao,
                  }))
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </ActionDialog>

      <ActionDialog
        title="Excluir Registro"
        okLabel="Sim"
        isOpen={isOpenDelete}
        onClose={() => setIsOpenDelete(false)}
        onOkClick={deletar}
        onCancelClick={() => setIsOpenDelete(false)}
        isOkProcessing={isProcessing}
      >
        <Typography>Você quer mesmo excluir este registro</Typography>
      </ActionDialog>
    </>
  )
}

export default CNAB
