import { useQuery } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

const query = '/RP/ObterDataGozoFerias'

interface RequestProps {
  params: {
    rpId: string | null
  }
}

export function useObterDataGozoFerias({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    if (!params.rpId) return
    const response = await api.get<{ data: { data: string } }>(query, { params })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}
