import { useState, memo, useMemo, useCallback } from 'react'

import { makeStyles, Grid, Collapse, IconButton, Box, Paper } from '@material-ui/core'
import { RiFilterLine, RiFilterOffLine } from 'react-icons/ri'
import { Print as PrintIcon } from '@material-ui/icons'

import {
  AlertContainer,
  PDFViewer,
  Button,
  PageHeader,
  Finder,
  ButtonBox,
  StackContainer,
  ContainerTable,
} from '~/components'

import Table from './components/Table'
import Footer from './components/Footer'

import TableItens from './components/TableItens'
import Afastamento from './components/Afastamento'
import Ferias from './components/Ferias'
import DialogConfirmPrint from './components/DialogConfirmPrint'
import FormUpdate from './components/FormUpdate'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'
import useUtils from '~/hooks/useUtils'

import {
  useGetProvisaoFeriasByEstabelecimento,
  useQueryClient,
} from '~/hooks/queries/ProvisaoCompetencia/useGetProvisaoFeriasByEstabelecimento'

import api from '~/services/api-pessoal'

const useStyles = makeStyles(() => ({
  contentAlert: {
    width: '100%',
    maxHeight: '56px',
  },
  header: {
    maxHeight: '56px',
    overflow: 'auto',
  },
}))

const MemoTable = memo(Table)
const MemoFooter = memo(Footer)

const ProvisaoFerias = () => {
  const [tableItens, setTableItens] = useState({
    itens: [],
    isOpen: false,
    vinculo: {},
  })
  const [formAfastamento, setFormAfastamento] = useState({
    isOpen: false,
    vinculo: {},
  })
  const [formFerias, setFormFerias] = useState({
    isOpen: false,
    vinculo: {},
  })
  const [isLoadingProvisoes, setLoadingProvisoes] = useState(false)

  const [formCollapseIsOpen, setFormCollapseOpen] = useState(true)
  const [query, setQuery] = useState('')
  const [isLoadingProcess, setLoadingProcess] = useState(false)

  const classes = useStyles()
  const { formatCurrency, formatAnoMes } = useUtils()
  const dialogNotification = useDialogNotification()
  const notification = useNotification()
  const { estabelecimento, anoMes } = useAmbiente()
  const {
    close: closeFormUpdate,
    data: dataFormUpdate,
    isOpen: isOpenFormUpdate,
    open: openFormUpdate,
  } = useDialog(null)
  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog()
  const {
    close: closeDialogConfirmPrint,
    isOpen: isOpenDialogConfirmPrint,
    open: openDialogConfirmPrint,
  } = useDialog()

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useGetProvisaoFeriasByEstabelecimento({
    params: {
      anoMes,
      estabelecimentoId: estabelecimento.id,
    },
  })

  const data = useCallback(() => _d || [], [_d])()

  const { handleInvalidate } = useQueryClient()

  const handleProcess = async () => {
    setLoadingProcess(true)
    try {
      await api.get('ProvisaoCompetencia/ProcessarFerias', {
        params: {
          estabelecimentoId: estabelecimento.id,
          anoMes,
        },
      })
      handleInvalidate()
      notification.success('Provisões processadas com Sucesso')
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingProcess(false)
    }
  }

  const handleClickItem = (event, dt) => {
    const handleClickProvisoes = async (dt) => {
      setLoadingProvisoes(true)
      try {
        const response = await api.get('ProvisaoCompetencia/GetProvisaoFeriasByVinculo', {
          params: {
            vinculoId: dt.id,
          },
        })
        setTableItens({
          itens: response.data.data,
          isOpen: true,
          vinculo: dt,
        })
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoadingProvisoes(false)
      }
    }

    const handleClickAfastamentos = (dt) => {
      setFormAfastamento({
        isOpen: true,
        vinculo: dt,
      })
    }

    const handleClickFerias = (dt) => {
      setFormFerias({
        isOpen: true,
        vinculo: dt,
      })
    }

    function handleFormUpdate() {
      openFormUpdate(dt)
    }

    const maps = {
      provisoes: handleClickProvisoes,
      afastamentos: handleClickAfastamentos,
      ferias: handleClickFerias,
      update: handleFormUpdate,
    }
    maps[event](dt)
  }
  const valuesForFooter = useMemo(() => {
    let totalVrProvisao = 0
    let totalVrContribuicaoPrevidenciaria = 0
    let totalVrContribuicaoTerceiros = 0
    let totalVrFGTS = 0
    let totalVrPIS = 0

    data.forEach((item) => {
      totalVrProvisao = totalVrProvisao + item.vrProvisao
      totalVrContribuicaoPrevidenciaria =
        totalVrContribuicaoPrevidenciaria + item.vrContribuicaoPrevidenciaria
      totalVrContribuicaoTerceiros = totalVrContribuicaoTerceiros + item.vrContribuicaoTerceiros
      totalVrFGTS = totalVrFGTS + item.vrFGTS
      totalVrPIS = totalVrPIS + item.vrPIS
    })

    return {
      totalVrProvisao: formatCurrency(totalVrProvisao),
      totalVrContribuicaoPrevidenciaria: formatCurrency(totalVrContribuicaoPrevidenciaria),
      totalVrContribuicaoTerceiros: formatCurrency(totalVrContribuicaoTerceiros),
      totalVrFGTS: formatCurrency(totalVrFGTS),
      totalVrPIS: formatCurrency(totalVrPIS),
    }
  }, [data, formatCurrency])

  const handleCloseTableItens = () => {
    setTableItens((oldState) => ({
      ...oldState,
      isOpen: false,
    }))
  }

  const handleCloseFormAfastamento = () => {
    setFormAfastamento({
      isOpen: false,
      vinculo: {},
    })
  }

  const handleCloseFormFerias = () => {
    setFormFerias({
      isOpen: false,
      vinculo: {},
    })
  }

  function handleAfterSubmitDialogConfirmPrint(dt) {
    openPDFViewer({
      estabelecimentoId: estabelecimento.id,
      competencia: anoMes,
      ...dt,
    })
  }

  return (
    <StackContainer>
      <Box>
        <PageHeader title="Provisão de Férias">
          <ButtonBox>
            <IconButton
              size="small"
              disabled={!(data.length > 0)}
              onClick={openDialogConfirmPrint}
              color="primary"
              title="Imprimir relatório de provisão"
            >
              <PrintIcon fontSize="small" />
            </IconButton>
            <Finder onSearch={setQuery} onClose={() => setQuery('')} />
            <IconButton size="small" onClick={() => setFormCollapseOpen(!formCollapseIsOpen)}>
              {formCollapseIsOpen ? <RiFilterOffLine /> : <RiFilterLine />}
            </IconButton>
          </ButtonBox>
        </PageHeader>

        <Collapse in={formCollapseIsOpen}>
          <AlertContainer>
            <Grid container spacing={2}>
              <Grid item xl={8} lg={8} md={8} sm={8} xs={12} className={classes.header}>
                Processar Provisões de Férias do Estabelecimento{' '}
                <strong>
                  {estabelecimento.codigo} - {estabelecimento.nome}
                </strong>{' '}
                na competência <strong>{formatAnoMes(anoMes)}</strong>
              </Grid>

              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <ButtonBox>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={handleProcess}
                    isLoading={isLoadingProcess}
                  >
                    Processar
                  </Button>
                </ButtonBox>
              </Grid>
            </Grid>
          </AlertContainer>
        </Collapse>
      </Box>

      <ContainerTable>
        <MemoTable
          data={data}
          isLoading={isLoading}
          query={query}
          onItemClick={handleClickItem}
          triggersHeight={formCollapseIsOpen}
          isFetching={isFetching || isLoadingProvisoes}
        />
      </ContainerTable>

      <Box component={Paper} p={2}>
        <MemoFooter totais={valuesForFooter} />

        {tableItens.isOpen && (
          <TableItens
            isOpen={tableItens.isOpen}
            onClose={handleCloseTableItens}
            data={tableItens.itens}
            vinculo={tableItens.vinculo}
          />
        )}

        {formAfastamento.isOpen && (
          <Afastamento
            isOpen={formAfastamento.isOpen}
            onClose={handleCloseFormAfastamento}
            vinculo={formAfastamento.vinculo}
          />
        )}

        {formFerias.isOpen && (
          <Ferias
            isOpen={formFerias.isOpen}
            onClose={handleCloseFormFerias}
            vinculo={formFerias.vinculo}
          />
        )}

        {isOpenDialogConfirmPrint && (
          <DialogConfirmPrint
            isOpen={isOpenDialogConfirmPrint}
            onClose={closeDialogConfirmPrint}
            onAfterSubmit={handleAfterSubmitDialogConfirmPrint}
          />
        )}

        {isOpenFormUpdate && (
          <FormUpdate isOpen={isOpenFormUpdate} data={dataFormUpdate} onClose={closeFormUpdate} />
        )}

        {isOpenPDFViewer && (
          <PDFViewer
            isOpen={isOpenPDFViewer}
            onClose={closePDFViewer}
            reportKey="ProvisaoFerias"
            title="Provisão de Férias"
            axiosConfig={{
              method: 'post',
              url: '/ProvisaoCompetencia/ObterRelatorioFerias',
              data: dataPDFViewer,
            }}
          />
        )}
      </Box>
    </StackContainer>
  )
}

export default ProvisaoFerias
