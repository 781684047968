import { useQuery } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'
import { HttpRequestInterface } from '~/interfaces'
import api from '~/services/api-pessoal'
import { MovimentoAuditoriaDTO, MovimentoAuditoriaRequestParams } from './dtos'
import { HttpObjResponseInterface } from '~/interfaces/HttpObjResponseInterface'

export const query = '/Auditoria/ObterMovimentoEmpregadores'

async function handleRequest({
  params,
}: HttpRequestInterface<unknown, MovimentoAuditoriaRequestParams>) {
  const response = await api.get(query, {
    params,
  })
  return response.data
}

export default function useObterMovimentoEmpregadores(
  props: HttpRequestInterface<unknown, MovimentoAuditoriaRequestParams>,
) {
  const dialogNotification = useDialogNotification()
  return useQuery<HttpObjResponseInterface<MovimentoAuditoriaDTO>>(
    [query, props],
    () => {
      return handleRequest(props)
    },
    {
      onError: dialogNotification.extractErrors,
      enabled: props?.enabled,
    },
  )
}
