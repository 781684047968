import { useEffect, useState } from 'react'

import { Box, Collapse, IconButton, Typography } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import alertMessageStyles from './alertMessageStyles'

interface AlertMessageProps {
  message: string
  isVisible?: boolean
}

export default function AlertMessage({ message, isVisible = false }: AlertMessageProps) {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(isVisible)
  }, [isVisible])

  const classes = alertMessageStyles()

  return (
    <Collapse in={visible} className={classes.collapse}>
      <Box className={classes.container}>
        <Typography className={classes.message}>{message}</Typography>
        <IconButton className={classes.iconButton} onClick={() => setVisible(false)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    </Collapse>
  )
}
