import React, { useEffect, useRef } from 'react'

import moment from 'moment'

import { makeStyles } from '@material-ui/core'

import '@fullcalendar/react/dist/vdom'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

import { VinculoFaltasRegistroDTO } from '~/hooks/queries/VinculoFaltas/VinculoFaltasDTO'

const useStyles = makeStyles(() => ({
  pointerCursorHover: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'ghostwhite',
    },
  },
}))

interface CalendarProps {
  registros: VinculoFaltasRegistroDTO[]
  anoMes?: string
  onClickDate?: (date: string) => void
  onClickMultipleDate?: (dateStart: string, dateEnd: string) => void
  renderEventContent?: (registro: VinculoFaltasRegistroDTO) => React.ReactNode
}

export default function Calendar({
  registros,
  anoMes,
  onClickDate,
  onClickMultipleDate,
  renderEventContent,
}: CalendarProps) {
  const calendarRef = useRef<FullCalendar>(null)
  const classes = useStyles()

  useEffect(() => {
    if (calendarRef.current && anoMes) {
      const calendarApi = calendarRef.current.getApi()
      calendarApi.gotoDate(anoMes)
    }
  }, [anoMes])

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()
      calendarApi.setOption('height', '100%')
    }
  }, [])

  function handleSelect(dateStart: string, dateEnd: string) {
    if (onClickMultipleDate) {
      const fixDateEnd = moment(dateEnd).subtract(1, 'day').format('yyyy-MM-DD')
      if (dateStart === fixDateEnd) return
      onClickMultipleDate(dateStart, fixDateEnd)
    }
  }

  const events = registros.map<{ date: string; extendedProps: VinculoFaltasRegistroDTO }>((d) => ({
    date: d.data,
    extendedProps: {
      id: d.id,
      data: d.data,
      tipo: d.tipo,
      registro: d.registro,
    },
  }))

  return (
    <FullCalendar
      ref={calendarRef}
      plugins={[dayGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      locale="pt-br"
      showNonCurrentDates={false}
      headerToolbar={false}
      selectable={true}
      dayCellClassNames={() => classes.pointerCursorHover}
      events={events}
      dateClick={({ dateStr }) => onClickDate && onClickDate(dateStr)}
      select={({ startStr, endStr }) => handleSelect(startStr, endStr)}
      eventContent={({ event }) =>
        renderEventContent && renderEventContent(event.extendedProps as VinculoFaltasRegistroDTO)
      }
    />
  )
}
