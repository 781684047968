import { Children, cloneElement, isValidElement, ReactNode } from 'react'
import ButtonBox from '../ButtonBox'
import TableClickItemProps from '~/interfaces/TableClickItemProps'

interface DataTableCellActionsRootProps {
  children: ReactNode
  onClick?: (event: TableClickItemProps) => void
}

export default function DataTableCellActionsRoot({
  children,
  onClick: onItemClick,
}: DataTableCellActionsRootProps) {
  return (
    <ButtonBox justifyContent="center" spacing={0}>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child as React.ReactElement<any>, {
            onClick: onItemClick,
          })
        }
      })}
    </ButtonBox>
  )
}
