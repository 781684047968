import { Box, BoxProps } from '@material-ui/core'
import { ReactNode } from 'react'

interface FlatListProps<T> {
  data: T[]
  keyExtractor: (item: T) => string | number
  renderItem: (item: T) => ReactNode
  boxProps?: BoxProps
  renderItemBoxProps?: BoxProps
}

export default function FlatList<T>({
  data,
  keyExtractor,
  renderItem,
  boxProps,
  renderItemBoxProps,
}: FlatListProps<T>) {
  return (
    <Box display="flex" flexDirection="column" {...boxProps}>
      {data.map((item) => {
        return (
          <Box
            key={keyExtractor(item)}
            display="flex"
            flexDirection="columns"
            {...renderItemBoxProps}
          >
            {renderItem(item)}
          </Box>
        )
      })}
    </Box>
  )
}
