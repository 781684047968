import { useEffect } from 'react'

import { ActionDialog, DatePickerNew, TextField } from '~/components'
import HStack from '~/components/HStack'

import { useFormContext } from '~/contexts/FormContext'

import { useAdicionarIndiceFgts, useAlterarIndiceFgts } from '~/hooks/queries/IndiceFgts'
import { IndiceFgtsDTO } from '~/hooks/queries/IndiceFgts/dtos'
import useForm from '~/hooks/useForm'

import * as yup from 'yup'

const schema = yup.object().shape({
  anoMes: yup.date().required('Informe o Ano/Mês').typeError('Data Inválida').nullable(),
  aliqIndice: yup.string().required('Informe a Alíquota Índice').nullable(),
})

export default function Form() {
  const { formData, isOpen, closeFormDialog } = useFormContext<IndiceFgtsDTO>()

  const { mutateAsync: adicionarIndiceFgts, isLoading: isAdding } = useAdicionarIndiceFgts()
  const { mutateAsync: alterarIndiceFgts, isLoading: isUpdating } = useAlterarIndiceFgts()

  const { data, changeValue, setData, handleSubmit, validationErrors } = useForm<IndiceFgtsDTO>({
    defaultData: null,
    schema,
    onSubmit(vData) {
      const { aliqIndice, anoMes, id } = vData
      const parsedAliqIndice = parseFloat(String(aliqIndice))

      if (id) {
        return alterarIndiceFgts({
          data: {
            id,
            aliqIndice: parsedAliqIndice,
            anoMes,
          },
        })
      }
      return adicionarIndiceFgts({
        data: {
          aliqIndice: parsedAliqIndice,
          anoMes,
        },
      })
    },
    onSuccess: closeFormDialog,
  })

  useEffect(() => {
    setData(formData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  return (
    <ActionDialog
      title={`${data?.id ? 'Alterar' : 'Adicionar'} Índice FGTS`}
      maxWidth="xs"
      isOpen={isOpen}
      isOkProcessing={isAdding || isUpdating}
      onClose={closeFormDialog}
      onOkClick={() => data && handleSubmit(data)}
      onCancelClick={closeFormDialog}
      okLabel="Salvar"
    >
      <HStack gap={12}>
        <DatePickerNew
          name="anoMes"
          label="Ano/Mês"
          views={['year', 'month']}
          format="YYYY/MM"
          required
          value={data?.anoMes || null}
          onChange={(_, date) => changeValue('anoMes', date?.format('YYYYMM'))}
          validationErrors={validationErrors}
        />
        <TextField
          name="aliqIndice"
          label="Alíquota Índice"
          required
          value={data?.aliqIndice || ''}
          onChange={(e) => {
            const value = e.target.value
            // Permitir apenas números e ponto decimal, com no máximo 4 casas decimais
            if (/^\d*\.?\d{0,6}$/.test(value) || value === '') {
              changeValue('aliqIndice', value)
            }
          }}
          validationErrors={validationErrors}
        />
      </HStack>
    </ActionDialog>
  )
}
