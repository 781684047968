import { useCallback, useMemo } from 'react'

import { ConfirmDeleteDialog, ToolsDataTable } from '~/components'
import DataTableCellActions from '~/components/DataTableCellActions'

import { useFormContext } from '~/contexts/FormContext'
import { useObterIndiceFgts, useRemoverIndiceFgts } from '~/hooks/queries/IndiceFgts'
import { IndiceFgtsDTO } from '~/hooks/queries/IndiceFgts/dtos'
import useDialog from '~/hooks/useDialog'

import TableClickItemProps from '~/interfaces/TableClickItemProps'
import { ToolsDataTableColumnDef } from '~/interfaces'

import { findData } from '~/utils'

export default function Table() {
  const { openFormDialog } = useFormContext<IndiceFgtsDTO>()

  const { data, isLoading, isFetching } = useObterIndiceFgts()
  const { mutateAsync: removerIndiceFgts, isLoading: isRemoving } = useRemoverIndiceFgts()

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog<string>()

  async function handleRemoverIndiceFgts() {
    await removerIndiceFgts({ data: { id: dataConfirmDelete } })
    closeConfirmDelete()
  }

  const handleClickItem = useCallback(
    (event: TableClickItemProps, rowData?: IndiceFgtsDTO) => {
      switch (event) {
        case 'edit':
          if (rowData) openFormDialog(rowData)
          break
        case 'delete':
          if (rowData) openConfirmDelete(rowData.id)
          break
        default:
          break
      }
    },
    [openFormDialog, openConfirmDelete],
  )

  const columns = useMemo((): ToolsDataTableColumnDef[] => {
    return [
      {
        name: 'anoMes',
        label: 'Ano/Mês',
        type: 'ano/mes',
      },
      {
        name: 'aliqIndice',
        label: 'Alíquota Índice',
        type: 'number',
      },
      {
        name: 'id',
        label: 'Ações',
        width: 100,
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (id) => {
            const rowData = findData<IndiceFgtsDTO>(data?.data || [], id)
            return (
              <DataTableCellActions.Root onClick={(event) => handleClickItem(event, rowData)}>
                <DataTableCellActions.Edit />
                <DataTableCellActions.Delete />
              </DataTableCellActions.Root>
            )
          },
        },
      },
    ]
  }, [handleClickItem, data?.data])

  return (
    <>
      <ToolsDataTable
        columns={columns}
        data={data?.data || []}
        isLoading={isLoading}
        isFetching={isFetching}
        pagination={false}
        cellHeaderWhiteSpaceNowrap
        cellWhiteSpaceNowrap
      />
      <ConfirmDeleteDialog
        isOpen={isOpenConfirmDelete}
        isDeleting={isRemoving}
        onCancel={closeConfirmDelete}
        onConfirm={handleRemoverIndiceFgts}
      />
    </>
  )
}
