import { useTableRowsSelectableContext } from '~/contexts/TableRowsSelectableContext'
import { PrintButton } from '~/components'

import { VinculoConsulta } from '~/hooks/queries/VinculoConsulta/VinculoConsulta'
import useDialog from '~/hooks/useDialog'

import Form from './components/Form'

export default function Impressao() {
  const { idsSelecionados } = useTableRowsSelectableContext<VinculoConsulta>()

  const {
    close: closeActionDialog,
    isOpen: isOpenActionDialog,
    open: openActionDialog,
  } = useDialog()

  return (
    <>
      <PrintButton disabled={!idsSelecionados.length} onClick={openActionDialog} />
      <Form isOpen={isOpenActionDialog} onClose={closeActionDialog} />
    </>
  )
}
