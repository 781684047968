import { makeStyles } from '@material-ui/core'

const mainStyles = makeStyles((theme) => ({
  iconButton: {
    textTransform: 'uppercase',
    fontSize: '0.7rem',
    borderRadius: 50,
    width: 55,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))

export default mainStyles
