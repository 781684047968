import { Search } from '@material-ui/icons'

import { AutoCompleteGrupo } from '~/components/AutoComplete'
import HStack from '~/components/HStack'

import AlternateButton from '~/pages/Painel/components/AlternateButton'
import useForm from '~/hooks/useForm'
import { Button } from '~/components'

export interface FilterStateProps {
  grupoId: string
}

interface FilterProps {
  origem?: 'painel' | 'auditoria'
  onChange: (filters: FilterStateProps) => void
}

export default function Filter({ onChange, origem = 'auditoria' }: FilterProps) {
  const { data, changeValue, handleSubmit } = useForm<FilterStateProps>({
    defaultData: { grupoId: '' },
    onSubmit: onChange,
  })

  return (
    <HStack width="100%" maxWidth={500} gap={8}>
      <AutoCompleteGrupo
        value={data?.grupoId || ''}
        onChange={(_, grupo) => changeValue('grupoId', grupo?.id)}
      />

      {origem === 'painel' && (
        <AlternateButton
          isActive
          title="Buscar"
          show
          icon={<Search />}
          onClick={() => data && handleSubmit(data)}
        />
      )}
      {origem === 'auditoria' && (
        <Button variant="contained" onClick={() => data && handleSubmit(data)}>
          Buscar
        </Button>
      )}
    </HStack>
  )
}
