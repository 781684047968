import { IconButton } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import {
  ActionDialog,
  Button,
  ButtonBox,
  ConfirmDeleteDialog,
  ContainerTable,
  Stack,
  ToolsDataTable,
} from '~/components'

import { useObterPorVinculo } from '~/hooks/queries/ControleDebito/useObterPorVinculo'

import { formatAnoMes } from '~/hooks/useUtils'
import { formatCurrency } from '~/utils/utils'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { useRemover } from '~/hooks/queries/ControleDebito/useRemover'
import useDialog from '~/hooks/useDialog'

interface ControleDebitosProps {
  isOpen: boolean
  onClose: () => void
  vinculoId: string
}

export default function ControleDebitos({ isOpen, onClose, vinculoId }: ControleDebitosProps) {
  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterPorVinculo({
    params: {
      vinculoId,
    },
  })
  const data = _d || []

  const { mutateAsync: mutateAsyncRemove, isLoading: isRemoving } = useRemover()

  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog('')

  async function handleDelete() {
    await mutateAsyncRemove({
      params: {
        controleDebitoId: dataConfirmDelete,
      },
    })
    closeConfirmDelete()
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'anoMes',
      label: 'Ano/Mês',
      options: {
        customBodyRender: formatAnoMes,
      },
    },
    {
      name: 'vrLancamento',
      label: 'Valor do Lançamento',
      options: {
        customBodyRender: (value) => formatCurrency(value),
        setCellProps: () => ({
          style: { textAlign: 'end' },
        }),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                title="Deletar este registro"
                size="small"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  openConfirmDelete(value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ActionDialog
      maxWidth="lg"
      isOpen={isOpen}
      onClose={onClose}
      title="Controle de Débitos"
      customActions={
        <ButtonBox right={1} bottom={1}>
          <Button variant="contained" onClick={onClose}>
            Fechar
          </Button>
        </ButtonBox>
      }
    >
      <Stack>
        <ContainerTable>
          <ToolsDataTable
            columns={columns}
            data={data}
            isLoading={isLoading}
            isFetching={isFetching}
            disableAutoHeight
          />
        </ContainerTable>
      </Stack>
      {isOpenConfirmDelete && (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          isDeleting={isRemoving}
          onConfirm={handleDelete}
        />
      )}
    </ActionDialog>
  )
}
