import React, { useState, useRef, useMemo, useEffect } from 'react'

import {
  createMuiTheme,
  ThemeProvider,
  TableRow,
  TableCell,
  Box,
  LinearProgress,
  Checkbox,
  makeStyles,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import MUIDataTable from 'mui-datatables'
import PropTypes from 'prop-types'

import useHeightParents from '~/hooks/useHeightParents'

import theme from '~/styles/theme'
import useAmbiente from '~/hooks/useAmbiente'
import clsx from 'clsx'
import { formatCurrency, formatSimpleDate, formatToAnoMes } from '~/utils/utils'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import { Ativo } from '../Displays'
import CopyLabel from '../CopyLabel'

const HEIGHT_FOOTER = '60px'
const HEIGHT_IND_FETCHING = '4px'

const skeletonData = [
  {
    col1: '',
  },
]

const useStyles = makeStyles((theme) => ({
  hightLightRow: {
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    backgroundColor: 'rgba(89, 195, 224, 30%)',
  },
}))

const skeletonColumns = [
  {
    name: 'col1',
    label: 'Carregando...',
    options: {
      customBodyRender: () => <Skeleton />,
    },
  },
]

const DataGridTextLabels = {
  body: {
    noMatch: 'Nenhum registro encontrado',
    toolTip: 'Ordenar',
  },
  pagination: {
    next: 'Próximo',
    previous: 'Anterior',
    rowsPerPage: 'Linhas: ',
    displayRows: 'de',
  },
  toolbar: {
    search: 'Procurar',
    downloadCsv: 'Download CSV',
    print: 'Imprimir',
    viewColumns: 'Colunas',
    filterTable: 'Procurar na Tabela',
  },
  filter: {
    all: 'Todos',
    title: 'Filtros',
    reset: 'Limpar',
  },
  viewColumns: {
    title: 'Visualizar Colunas',
    titleAria: 'Mostrar/Ocultar Colunas da Tabela',
  },
  selectedRows: {
    text: 'Linhas Selecionadas',
    delete: 'Remover',
    deleteAria: 'Remover Linhas Selecionadas',
  },
}

export default function ToolsDataTable(props) {
  const {
    data,
    columns,
    isLoading,
    isFetching,
    pagination,
    showHeader,
    disableAutoHeight,
    options: _options,
    components,
    triggersHeight,
    sherlock,
    optionsSelectable,
    optionsExpandable,
    highlightRowFieldName,
    cellHeaderWhiteSpaceNowrap,
    cellWhiteSpaceNowrap,
    tableRef,
    heightHeader,
    tableBodyHeight,
    onRowDoubleClick,
  } = props

  const { estabelecimento } = useAmbiente()

  const [rowsPerPage, setRowsPerPage] = useState(_options?.rowsPerPage || 25)

  const refRootTable = useRef(null)
  const classes = useStyles()

  useHeightParents({
    refElement: refRootTable,
    triggers: triggersHeight,
    disabled: disableAutoHeight,
    minHeight: 300,
  })

  const heightTable = pagination ? `calc(100% - ${HEIGHT_FOOTER} - 1px)` : '100%'

  const tableTheme = createMuiTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MUIDataTableFooter: {
        ...theme?.overrides?.MUIDataTableFooter,
        root: {
          ...theme?.overrides?.MUIDataTableFooter?.root,
          height: pagination ? HEIGHT_FOOTER : '0',
          borderBottom: theme.shape.border,
        },
      },
      MUIDataTable: {
        ...theme?.overrides?.MUIDataTable,
        paper: {
          ...theme?.overrides?.MUIDataTable?.paper,
          height: `calc(100% - ${HEIGHT_IND_FETCHING})`,
        },
        responsiveBase: {
          ...theme?.overrides?.MUIDataTable?.responsiveBase,
          borderTop: theme.shape.border,
        },
      },
      MuiTableHead: {
        ...theme?.overrides?.MuiTableHead,
        root: {
          ...theme?.overrides?.MuiTableHead?.root,
          height: '0px',
          display: showHeader ? 'table-header-group' : 'none',
        },
      },
      MUIDataTableHeadCell: {
        ...theme?.overrides?.MUIDataTableHeadCell,
        toolButton: {
          height: heightHeader || '48px',
        },
      },
      MuiTableBody: {
        ...theme?.overrides?.MuiTableBody,
        root: {
          ...theme?.overrides?.MuiTableBody?.root,
          borderBottom: theme.shape.border,
        },
      },
    },
  })

  const optionsTableSelectable = {
    selectableRows: isLoading ? 'none' : optionsSelectable?.type || 'none',
    selectableRowsHeader: data?.length > 0 ? true : false,
    selectableRowsOnClick: optionsSelectable?.selectOnClick || false,
    rowsSelected: optionsSelectable?.rowsSelected,
    onRowSelectionChange: optionsSelectable?.onRowSelected
      ? (rowsSelectedData, allRows, rowsSelecteds) =>
          optionsSelectable?.onRowSelected(rowsSelecteds)
      : undefined,
    isRowSelectable: optionsSelectable?.isRowSelectable || undefined,
  }

  const optionsTableExpandable = {
    expandableRowsHeader:
      optionsExpandable?.type === 'multiple' ? (data.length > 0 ? true : false) : false,
    expandableRowsOnClick: optionsExpandable?.expandOnClick || false,
    expandableRows: isLoading ? false : true,
    rowsExpanded: optionsExpandable?.rowsExpanded,
    renderExpandableRow: (rowData, { dataIndex }) => {
      const colSpan = rowData.length + 1
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>{optionsExpandable?.expandedChildren(dataIndex)}</TableCell>
        </TableRow>
      )
    },
    onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) => {
      const indexs = allRowsExpanded.map(({ dataIndex }) => dataIndex)
      if (!optionsExpandable?.onRowExpanded) return
      if (indexs.length === 0) return optionsExpandable.onRowExpanded(indexs)
      if (optionsExpandable?.type === 'single')
        return optionsExpandable.onRowExpanded([indexs[indexs.length - 1]])
      optionsExpandable.onRowExpanded(indexs)
    },
  }

  const defaultOptions = {
    selectableRows: 'none',
    selectableRowsOnClick: false,
    selectableRowsHeader: false,
    selectToolbarPlacement: 'none',
    viewColumns: false,
    filter: false,
    search: false,
    print: false,
    download: false,
    sort: true,
    elevation: 0,
    responsive: 'simple',
    textLabels: DataGridTextLabels,
    onChangeRowsPerPage: setRowsPerPage,
    rowsPerPage,
    rowsPerPageOptions: [25, 50, 100],
    searchText: sherlock?.query || '',
    customSearch: (searchQuery, currentRow, columns) =>
      columns.some(({ name }, index) => {
        if (!currentRow[index]) return false

        return (
          sherlock.columns.includes(name) &&
          currentRow[index].toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      }),
    pagination,
    tableBodyHeight: tableBodyHeight ? tableBodyHeight : disableAutoHeight ? '' : heightTable,
  }

  let options = {
    setRowProps: (_, __, rowIndex) => {
      const fieldName = highlightRowFieldName || 'id'

      const destacarLinhaComEstabelecimentoOuEmpregador =
        data?.[rowIndex]?.[fieldName] === estabelecimento?.id ||
        data?.[rowIndex]?.[fieldName] === estabelecimento?.empregadorId
      return {
        className: clsx({
          [classes.hightLightRow]: destacarLinhaComEstabelecimentoOuEmpregador,
        }),
      }
    },
    ...defaultOptions,
  }

  if (optionsSelectable) options = { ...options, ...optionsTableSelectable }
  if (optionsExpandable) options = { ...options, ...optionsTableExpandable }

  options = { ...options, ..._options }

  const parsedColumns = useMemo(() => {
    return columns.map((column) => {
      return {
        ...column,
        options: {
          setCellHeaderProps: () => ({
            style: {
              minWidth: column?.minWidth,
              whiteSpace: cellHeaderWhiteSpaceNowrap ? 'nowrap' : 'initial',
              position: column?.position,
              left: column?.left,
              right: column?.right,
              zIndex:
                column?.position === 'sticky'
                  ? column?.headerZindex || 101
                  : column?.headerZindex
                  ? column?.headerZindex
                  : 'initial',
            },
          }),
          setCellProps: () => ({
            style: {
              width: column?.width,
              position: column?.position,
              left: column?.left,
              right: column?.right,
              zIndex:
                column?.position === 'sticky'
                  ? column?.cellZindex || 100
                  : column?.cellZindex
                  ? column?.cellZindex
                  : 'initial',
              whiteSpace: cellWhiteSpaceNowrap ? 'nowrap' : 'initial',
              backgroundColor: 'inherit',
              textAlign:
                column.type === 'number' || column.type === 'currency' ? 'right' : 'initial',
            },
          }),
          customBodyRender(value) {
            let parsedValue = value
            switch (column.type) {
              case 'currency': {
                parsedValue = formatCurrency(value || '')
                break
              }
              case 'cpf-cnpj': {
                parsedValue = formatToCPFOrCNPJ(value || '')
                break
              }
              case 'cpf-cnpj-copy-label': {
                parsedValue = <CopyLabel value={value}> {value}</CopyLabel>
                break
              }
              case 'full-date': {
                parsedValue = formatSimpleDate(value || '')
                break
              }
              case 'ano/mes': {
                parsedValue = formatToAnoMes(value || '')
                break
              }
              case 'ativo': {
                parsedValue = value ? <Ativo ativo={true} title="Ativo" /> : ''
                break
              }
              default:
                parsedValue = value
                break
            }

            return parsedValue
          },
          ...column?.options,
        },
      }
    })
  }, [columns, cellHeaderWhiteSpaceNowrap, cellWhiteSpaceNowrap])

  useEffect(() => {
    const tableBody = refRootTable.current?.querySelector('tbody')

    if (tableBody) {
      const handleDoubleClick = (event) => {
        const row = event.target.closest('tr')
        if (row) {
          const rowIndex = Array.from(tableBody.children).indexOf(row)
          onRowDoubleClick(data[rowIndex], rowIndex)
          console.log('Duplo clique detectado na linha:')
        }
      }

      tableBody.addEventListener('dblclick', handleDoubleClick)

      return () => {
        tableBody.removeEventListener('dblclick', handleDoubleClick)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <ThemeProvider theme={tableTheme}>
      <Box ref={refRootTable} position="relative">
        <LinearProgress
          style={{ position: 'absolute', inset: 0, zIndex: 999, opacity: isFetching ? 1 : 0 }}
        />
        <MUIDataTable
          data={isLoading ? skeletonData : data}
          columns={isLoading ? skeletonColumns : parsedColumns}
          options={options}
          components={{
            Checkbox: (props) => <Checkbox {...props} size="small" />,
            ...components,
          }}
          innerRef={tableRef}
        />
      </Box>
    </ThemeProvider>
  )
}

ToolsDataTable.defaultProps = {
  isLoading: false,
  isFetching: false,
  pagination: true,
  showHeader: true,
  disableAutoHeight: false,
  cellHeaderWhiteSpaceNowrap: false,
  cellWhiteSpaceNowrap: false,
  tableBodyHeight: undefined,
  options: {},
  components: {},
  triggersHeight: {},
  sherlock: {
    query: '',
    columns: [],
  },
  optionsSelectable: undefined,
  optionsExpandable: undefined,
}

ToolsDataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,

  tableBodyHeight: PropTypes.string,
  highlightRowFieldName: PropTypes.string,
  isLoading: PropTypes.bool,
  isFetching: PropTypes.bool,
  pagination: PropTypes.bool,
  showHeader: PropTypes.bool,
  cellHeaderWhiteSpaceNowrap: PropTypes.bool,
  cellWhiteSpaceNowrap: PropTypes.bool,
  disableAutoHeight: PropTypes.bool,
  options: PropTypes.object,
  components: PropTypes.object,
  triggersHeight: PropTypes.object,
  sherlock: PropTypes.shape({
    query: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.string),
  }),
  optionsSelectable: PropTypes.shape({
    type: PropTypes.oneOf(['single', 'multiple']).isRequired,
    onRowSelected: PropTypes.func,
    rowsSelected: PropTypes.arrayOf(PropTypes.number),
    selectOnClick: PropTypes.bool,
    isRowSelectable: PropTypes.func,
  }),
  optionsExpandable: PropTypes.shape({
    type: PropTypes.oneOf(['single', 'multiple']).isRequired,
    onRowExpanded: PropTypes.func,
    rowsExpanded: PropTypes.arrayOf(PropTypes.number),
    expandOnClick: PropTypes.bool,
    expandedChildren: PropTypes.func,
  }),
  onRowDoubleClick: PropTypes.func,
  tableRef: PropTypes.any,
  heightHeader: PropTypes.string,
}
