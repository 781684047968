import { useState } from 'react'

import * as yup from 'yup'

import { ActionDialog, CurrencyTextField, Stack } from '~/components'

import useValidationErrors from '~/hooks/useValidationErrors'

import { useCriar } from '~/hooks/queries/VinculoPensaoBeneficiario/useCriar'
import { useAtualizar } from '~/hooks/queries/VinculoPensaoBeneficiario/useAtualizar'

import { VinculoPensaoBeneficiario } from '~/hooks/queries/VinculoPensaoBeneficiario/interfaces/VinculoPensaoBeneficiario'
import { AutoCompletePessoaFisicaDependenteVinculo } from '~/components/AutoComplete'

const schema = yup.object().shape({
  pessoaFisicaDependenteId: yup.string().required('Informe o Dependente'),
  vrPensao: yup.string().required('Informe o Valor da Pensão'),
})

interface FormProps {
  isOpen: boolean
  onClose: () => void
  data: VinculoPensaoBeneficiario | null
  dataInitial: {
    vinculoId: string
    rpId: string
    anoMes: string
  }
}

export default function Form({ isOpen, onClose, data: _data, dataInitial }: FormProps) {
  const [data, setData] = useState(_data || ({ ...dataInitial } as VinculoPensaoBeneficiario))

  const { mutateAsync: mutateAsyncCreate, isLoading: isCreating } = useCriar()
  const { mutateAsync: mutateAsyncUpdate, isLoading: isUpdating } = useAtualizar()
  const isLoading = isCreating || isUpdating

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  async function handleSubmit() {
    if (_data) {
      await mutateAsyncUpdate({
        data,
        params: {
          id: _data.id,
        },
      })
    } else {
      await mutateAsyncCreate({
        data,
      })
    }
    onClose()
  }

  return (
    <ActionDialog
      title="Cadastro Pensão Alimentícia do Recibo"
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleValidate}
      isOkProcessing={isLoading}
    >
      <Stack>
        <AutoCompletePessoaFisicaDependenteVinculo
          required
          validationErrors={validationErrors}
          name="pessoaFisicaDependenteId"
          value={data.pessoaFisicaDependenteId}
          onChange={(e, d) => {
            const pessoaFisicaDependenteId = d?.id || ''
            setData((prev) => ({
              ...prev,
              pessoaFisicaDependenteId,
            }))
          }}
          vinculoId={dataInitial.vinculoId}
        />

        <CurrencyTextField
          label="Valor da Pensão"
          required
          validationErrors={validationErrors}
          name="vrPensao"
          value={data.vrPensao}
          onChange={(_, value) => {
            const vrPensao = value
            setData((oldState) => ({
              ...oldState,
              vrPensao,
            }))
          }}
        />
      </Stack>
    </ActionDialog>
  )
}
