import useCredentials from './useCredentials'

const routesOnlyDataC = [
  '/configuracao-relatorio',
  '/auditoria-empregador',
  '/limpar-gerar-ambiente',
  '/terceiros',
  '/transmissao-esocial-old',
  // '/fgts-digital-guia-empregador',
]

export default function useOnlyDataC() {
  const { userLogged } = useCredentials()

  const email = userLogged?.email || ''

  const isUserDataC = email.endsWith('@datac.com.br') ? true : false

  return { isUserDataC, routesOnlyDataC }
}
