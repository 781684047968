import { useState } from 'react'
import { Paper } from '@material-ui/core'

import PageContainer from '~/components/PageContainer'
import HStack from '~/components/HStack'

import TransferDataProvider from '~/contexts/TransferDataContext'

import Filter, { FilterStateProps } from './components/Filter'
import Actions from './components/Actions'
import Table from './components/Table'

export default function RelatorioEstatisticaFuncionarios() {
  const [filters, setFilters] = useState<FilterStateProps | null>(null)

  return (
    <PageContainer.Root gap={4}>
      <PageContainer.Header title="Total de Funcionários para CEF" />
      <TransferDataProvider>
        <HStack component={Paper} justifyContent="flex-end" padding={1}>
          <Filter onChange={setFilters} />
        </HStack>
        <Table filters={filters} />
        <HStack component={Paper} justifyContent="flex-end" padding={1}>
          <Actions />
        </HStack>
      </TransferDataProvider>
    </PageContainer.Root>
  )
}
