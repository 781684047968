import { useQuery, useQueryClient as useQueryClientReact } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { EventoAutomaticoEstabelecimentoResponseDTO } from './interfaces/EventoAutomaticoEstabelecimentoResponseDTO'

const query = '/EventoAutomaticoEstabelecimento/ObterPorEstabelecimento'

interface RequestProps {
  params: { estabelecimentoId: string }
}

export function useObterPorEstabelecimento({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: EventoAutomaticoEstabelecimentoResponseDTO[] }>(query, {
      params,
    })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useQueryClient() {
  const queryClient = useQueryClientReact()

  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }

  return { handleInvalidate }
}
