import { useEffect, useState } from 'react'

import { Paper } from '@material-ui/core'
import {
  Button,
  ContainerTable,
  DatePickerNew,
  PageHeader,
  PDFViewer,
  Stack,
  StackContainer,
} from '~/components'

import Table from './components/Table'

import useAmbiente from '~/hooks/useAmbiente'
import useValidationErrors from '~/hooks/useValidationErrors'

import useObterEscalaRevezamento from '~/hooks/queries/EscalaRevezamento/useObterEscalaRevezamento'

import { getDateMinAnoMes } from '~/utils/utils'
import * as yup from 'yup'
import { AutoCompleteSetor } from '~/components/AutoComplete'
import { Print } from '@material-ui/icons'
import {
  EscalaRevezamentoRequestRelatorioDTO,
  EscalaRevezamentoRequestRelatorioVinculoDTO,
} from '~/hooks/queries/EscalaRevezamento/interfaces/EscalaRevezamentoRequestRelatorioDTO'
import useDialog from '~/hooks/useDialog'

const schema = yup.object().shape({
  dtInicioEscala: yup
    .date()
    .required('Informe a Data de Início da Escala')
    .typeError('Informe uma data válida'),
})

export default function EscalaRevezamento() {
  const { estabelecimento, anoMes } = useAmbiente()

  const [setorId, setSetorId] = useState('')
  const [dataForm, setDataForm] = useState({ dtInicioEscala: getDateMinAnoMes(anoMes) })
  const [dataSelected, setDataSelected] = useState<string[]>([])

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<EscalaRevezamentoRequestRelatorioDTO | null>(null)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit: handleSearch,
    data: { ...dataForm, setorId },
  })

  const {
    data: _d,
    isLoading,
    isFetching,
    refetch,
  } = useObterEscalaRevezamento({
    data: {
      estabelecimentoId: estabelecimento.id,
      setorId: setorId || null,
      dtInicioEscala: dataForm.dtInicioEscala,
    },
  })

  const data = _d || {
    colunaPeriodos: [],
    colunaDatas: [],
    vinculos: [],
  }

  useEffect(() => {
    setDataSelected([])
  }, [_d])

  function handleSearch() {
    refetch()
  }

  function handlePrint() {
    const vinculos = dataSelected.map<EscalaRevezamentoRequestRelatorioVinculoDTO>((d) => ({
      vinculoId: d.split(',')[0],
      data: d.split(',')[1],
    }))

    openPDFViewer({
      estabelecimentoId: estabelecimento.id,
      setorId: setorId || null,
      dtInicioEscala: dataForm.dtInicioEscala,
      vinculos,
    })
  }

  return (
    <StackContainer>
      <PageHeader title="Escala de Revezamento" />
      <Stack component={Paper} p={1}>
        <Stack orientation="horizontal">
          <DatePickerNew
            label="Data de Início da Escala"
            value={dataForm.dtInicioEscala}
            onChange={(d) => {
              const dtInicioEscala = d || ''
              setDataForm({
                dtInicioEscala,
              })
            }}
            validationErrors={validationErrors}
            name="dtInicioEscala"
          />

          <AutoCompleteSetor
            value={setorId}
            onChange={(_, d) => setSetorId(d?.id || '')}
            empregadorId={estabelecimento.empregadorId}
          />
        </Stack>

        <Stack alignItems="flex-end">
          <Button variant="contained" isLoading={isLoading} onClick={handleValidate}>
            Buscar
          </Button>
        </Stack>
      </Stack>

      <ContainerTable>
        <Table
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          dataSelected={dataSelected}
          onChangeDataSelected={setDataSelected}
        />
      </ContainerTable>

      <Stack component={Paper} p={1} alignItems="flex-end">
        <Button startIcon={<Print />} variant="contained" onClick={handlePrint}>
          Imprimir
        </Button>
      </Stack>

      {isOpenPDFViewer && (
        <PDFViewer
          isOpen={isOpenPDFViewer}
          onClose={closePDFViewer}
          reportKey="EscalaRevezamento"
          title="Escala de Revezamento"
          axiosConfig={{
            method: 'post',
            url: '/EscalaRevezamento/ObterRelatorio',
            data: dataPDFViewer,
          }}
        />
      )}
    </StackContainer>
  )
}
