import { styled } from '@material-ui/core'
import {
  Close as CloseIcon,
  Warning as WarningIcon,
  Error as InfoIcon,
  Mood as MoodIcon,
  Brightness5 as Brightness5Icon,
  EmojiPeople as EmojiPeopleIcon,
  DirectionsWalk as DirectionsWalkIcon,
  Weekend as WeekendIcon,
} from '@material-ui/icons'
import { VinculoFaltasTipoDiaEnum } from '~/@types/enums/VinculoFaltasTipoDiaEnum'

const CloseIconStyled = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}))
const WarningIconStyled = styled(WarningIcon)(({ theme }) => ({
  color: theme.palette.warning.main,
}))
const InfoIconStyled = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.info.main,
}))
const Brightness5IconStyled = styled(Brightness5Icon)(({ theme }) => ({
  color: theme.palette.warning.light,
}))
const MoodIconStyled = styled(MoodIcon)(({ theme }) => ({
  color: theme.palette.info.light,
}))
const DirectionsWalkIconStyled = styled(DirectionsWalkIcon)(({ theme }) => ({
  color: theme.palette.primary.dark,
}))
const WeekendIconStyled = styled(WeekendIcon)(({ theme }) => ({
  color: theme.palette.error.light,
}))

interface IconProps {
  dateType: VinculoFaltasTipoDiaEnum
}

export default function Icon(props: IconProps) {
  const { dateType } = props

  switch (dateType) {
    case VinculoFaltasTipoDiaEnum.Falta:
      return <WarningIconStyled />
    case VinculoFaltasTipoDiaEnum.Atraso:
      return <InfoIconStyled />
    case VinculoFaltasTipoDiaEnum.DSRDescontado:
      return <CloseIconStyled />
    case VinculoFaltasTipoDiaEnum.PercaSabadoCompensado:
      return <WeekendIconStyled />
    case VinculoFaltasTipoDiaEnum.Ferias:
      return <Brightness5IconStyled />
    case VinculoFaltasTipoDiaEnum.Afastado:
      return <EmojiPeopleIcon />
    case VinculoFaltasTipoDiaEnum.DescansoSemanal:
      return <MoodIconStyled />
    case VinculoFaltasTipoDiaEnum.DiaNaoTrabalha:
      return <DirectionsWalkIconStyled />
    default:
      return <>Ícone</>
  }
}
