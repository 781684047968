import { useMemo } from 'react'
import { ToolsDataTable } from '~/components'
import { useObterSaldoFgts } from '~/hooks/queries/SladoFgts'
import { ToolsDataTableColumnDef } from '~/interfaces'
import { FilterStateProps } from '../Filter'

interface TableProps {
  filters: FilterStateProps | null
}

export default function Table({ filters }: TableProps) {
  const { data, isLoading, isFetching } = useObterSaldoFgts({
    params: { vinculoId: filters?.vinculoId || '' },
    enabled: !!filters,
  })

  const columns = useMemo((): ToolsDataTableColumnDef[] => {
    return [
      {
        name: 'anoMes',
        label: 'Competência',
        type: 'ano/mes',
      },
      {
        name: 'vrFGTS',
        label: 'Valor Recebido',
        type: 'currency',
      },
      {
        name: 'dtIndice',
        label: 'Data do Índice',
        type: 'full-date',
      },
      {
        name: 'aliqIndice',
        label: 'Índice',
        type: 'number',
      },
      {
        name: 'vrCorrecao',
        label: 'Valor da Correção',
        type: 'number',
      },
      {
        name: 'vrSaldo',
        label: 'Valor do Saldo',
        type: 'currency',
      },
      {
        name: 'observacao',
        label: 'Observação',
      },
    ]
  }, [])

  return (
    <ToolsDataTable
      columns={columns}
      data={data?.data || []}
      isLoading={isLoading}
      isFetching={isFetching}
      cellHeaderWhiteSpaceNowrap
      cellWhiteSpaceNowrap
    />
  )
}
