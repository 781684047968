import { makeStyles } from '@material-ui/core'

const footerStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    fontWeight: 'normal',
    height: 22,
  },
}))

export default footerStyles
