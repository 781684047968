import { useState, useEffect } from 'react'

import { Box, IconButton, useTheme } from '@material-ui/core'
import { FilterList } from '@material-ui/icons'

import { ActionDialog, Stack } from '~/components'
import { AutoCompleteCargo, AutoCompleteLotacao } from '~/components/AutoComplete'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'

interface DataButtonFiltro {
  cargoId: string | null
  lotacaoId: string | null
}

interface ButtonFiltroProps {
  data: DataButtonFiltro | null
  onChange: (d: DataButtonFiltro) => void
}

export default function ButtonFiltro({ data: _data, onChange }: ButtonFiltroProps) {
  const [data, setData] = useState<DataButtonFiltro>({
    cargoId: null,
    lotacaoId: null,
  })

  const { close, isOpen, open } = useDialog()
  const { estabelecimento } = useAmbiente()

  const theme = useTheme()

  const hasFilled = _data?.cargoId || _data?.lotacaoId

  useEffect(() => {
    if (_data) setData(_data)
  }, [_data])

  return (
    <Box position="relative">
      <Box
        position="absolute"
        right={0}
        top={-16}
        bgcolor="white"
        border={1}
        borderColor={hasFilled ? theme.palette.primary.main : theme.palette.grey[800]}
        borderRadius="50%"
        boxShadow={1}
        zIndex={999}
      >
        <IconButton size="small" color={hasFilled ? 'primary' : 'default'} onClick={open}>
          <FilterList fontSize="small" />
        </IconButton>

        {isOpen && (
          <ActionDialog
            title="Filtro dos Vínculos"
            isOpen={isOpen}
            onClose={close}
            okLabel="Aplicar"
            onOkClick={() => {
              onChange(data)
              close()
            }}
          >
            <Stack>
              <AutoCompleteCargo
                empregadorId={estabelecimento.empregadorId}
                value={data.cargoId}
                onChange={(_, obj: any) =>
                  setData((prev) => ({ ...prev, cargoId: obj?.id || null }))
                }
              />
              <AutoCompleteLotacao
                empregadorId={estabelecimento.empregadorId}
                value={data.lotacaoId}
                onChange={(_: any, obj: any) =>
                  setData((prev) => ({ ...prev, lotacaoId: obj?.id || null }))
                }
              />
            </Stack>
          </ActionDialog>
        )}
      </Box>
    </Box>
  )
}
