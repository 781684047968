import React, { useState } from 'react'

import {
  Avatar,
  Button,
  Box,
  Divider,
  MenuItem,
  Popover,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { VpnKey as KeyIcon, ExitToApp as ExitIcon, ArrowDropDown } from '@material-ui/icons'

import useCredentials from '~/hooks/useCredentials'

import { TOOL_BAR_HEIGHT } from '~/@types/consts/tool_bar_size'

interface UserMenuProps {
  handleChangePassword: () => void
}

const useStyles = makeStyles((theme) => ({
  box1: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    // marginRight: 22,
  },
  avatar: {
    width: '32px',
    height: '32px',
    marginRight: '8px',
  },
  arrowDropDown: {
    color: theme.palette.text.primary,
    marginLeft: '8px',
  },
  menuItemPopover: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  header: {
    marginTop: '16px',
    marginBottom: '16px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
}))

export default function UserMenu(props: UserMenuProps) {
  const { handleChangePassword } = props

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const { userLogged, logout } = useCredentials()

  const menuOpen = Boolean(anchorEl)

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleLogout() {
    logout()
  }

  return (
    <Box className={classes.box1}>
      <Button
        aria-label="more"
        aria-controls="settings-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
        style={{ height: TOOL_BAR_HEIGHT }}
      >
        <Avatar alt="photoURL" className={classes.avatar} />
        <Typography color="textPrimary" variant="body2" className={classes.title}>
          {userLogged?.name || ''}
        </Typography>

        <ArrowDropDown className={classes.arrowDropDown} />
      </Button>

      <Popover
        id="settings-menu"
        anchorEl={anchorEl}
        keepMounted
        open={menuOpen}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <Box className={classes.header}>
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>
            {userLogged ? userLogged.name : 'Usuário do Sistema'}
          </Typography>
          <Typography variant="body2">{userLogged ? userLogged.email : '---'}</Typography>
        </Box>

        <Divider />

        <MenuItem onClick={handleChangePassword}>
          <Box className={classes.menuItemPopover}>
            <KeyIcon />
            Alterar Senha
          </Box>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Box className={classes.menuItemPopover}>
            <ExitIcon />
            Sair
          </Box>
        </MenuItem>
      </Popover>
    </Box>
  )
}
