import { useQuery, useQueryClient as useQueryClientReact } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'

import { VinculoPensaoBeneficiario } from './interfaces/VinculoPensaoBeneficiario'

const query = '/VinculoPensaoBeneficiario/ObterPorRecibo'

interface RequestProps {
  params: {
    rpId: string
  }
}

export function useObterPorRecibo({ params }: RequestProps) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.get<{ data: VinculoPensaoBeneficiario[] }>(query, { params })
    return response.data.data
  }

  return useQuery([query, params], handleRequest, {
    onError: dialogNotification.extractErrors,
  })
}

export function useQueryClient() {
  const queryClient = useQueryClientReact()

  function handleInvalidate() {
    queryClient.invalidateQueries(query)
  }

  return { handleInvalidate }
}
