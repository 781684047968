import React from 'react'

import {
  KeyboardTimePicker,
  KeyboardTimePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'
import { AccessTime as TimeIcon } from '@material-ui/icons'
import { ErrorMessage } from 'mio-library-ui'

import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/pt'

import { obterErroValidationErrors } from '~/hooks/useUtils'

moment.locale('pt', { week: { dow: 0 } })

interface TimePickerProps extends Omit<KeyboardTimePickerProps, 'onChange'> {
  onChange?: (time: string | null, date: MaterialUiPickersDate) => void
  validationErrors?: ValidationErrors
}

function formatValue(value: ParsableDate): moment.Moment | null {
  if (!value) return null

  const valueString = value.toString()

  const [hours, minutes, seconds] = valueString.split(':')

  const newDate = moment()
  newDate
    .set('hours', parseInt(hours))
    .set('minutes', parseInt(minutes))
    .set('seconds', parseInt(seconds))

  return newDate
}

export default function TimePicker(props: TimePickerProps) {
  const { validationErrors, name, onChange, value: _value, ...rest } = props

  function handleChange(date: MaterialUiPickersDate) {
    if (!onChange) return
    const time = date ? date.format('HH:mm:ss') : null
    if (!time || time === 'Invalid date') return onChange(null, null)
    onChange(time, date)
  }

  const mensagemDeErro = obterErroValidationErrors(name, validationErrors)
  const value = formatValue(_value)

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="pt">
        <KeyboardTimePicker
          inputVariant="outlined"
          variant="inline"
          fullWidth
          size="small"
          id="time-picker"
          invalidDateMessage={'Hora inválida'}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
          value={value}
          onChange={handleChange}
          name={name}
          ampm={false}
          keyboardIcon={<TimeIcon fontSize="small" />}
          {...rest}
        />
      </MuiPickersUtilsProvider>
      {mensagemDeErro && <ErrorMessage error={mensagemDeErro} />}
    </>
  )
}
