import { useState } from 'react'
import useValidations from './useValidations'
import * as Yup from 'yup'

interface HookUseFormProps<T> {
  defaultData?: T | null
  schema?: Yup.ObjectSchema<any>
  validateOptions?: Yup.ValidateOptions<any>
  isValidateParams?: boolean
  onSubmit?: (vData: T) => Promise<any> | void
  // onSuccess?: (
  //   response: Result<T> | ObjectResponseInterface<T> | T,
  // ) => void
  onSuccess?: (response: unknown) => void
  onError?: (error: any) => void
}

export default function useForm<T>({
  defaultData,
  schema,
  onSubmit,
  onError,
  onSuccess,
  validateOptions,
}: HookUseFormProps<T>) {
  const [data, setData] = useState<T | null | undefined>(defaultData)
  const { setValidationErrors, validationProps, _validationErrors } = useValidations()

  function changeValue(name: keyof T, value: any) {
    setData((prevState) => ({
      ...((prevState ?? {}) as T),
      [name]: value,
    }))
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const type = e.target.type
    const name = e.target.name

    let value = type === 'text' ? e.target.value : e.target.checked

    switch (type) {
      case 'text':
        value = e.target.value
        break
      case 'checkbox':
        value = e.target.checked
        break
      default:
        value = e.target.value
    }

    setData((prevState) => ({ ...((prevState ?? {}) as T), [name]: value }))
  }

  function handleSetData(newData: T) {
    setData(newData)
  }

  async function handleSubmit(data: T) {
    setValidationErrors(null)
    // toggleSubmit(true)

    try {
      schema && (await schema.validate(data, { abortEarly: false, ...validateOptions }))

      if (!onSubmit) return console.log('A função onSubmit do useForm não foi declarada!')

      const response = await onSubmit(data)

      if (onSuccess) {
        onSuccess(response)
      }
    } catch (error: any) {
      if (error?.name === 'ValidationError') {
        setValidationErrors(error)
      }
      if (onError) {
        onError(error)
      }
    } finally {
      // toggleSubmit(false)
    }
  }

  return {
    data,
    changeValue,
    handleChange,
    setData: handleSetData,
    handleSubmit,
    validationProps,
    validationErrors: _validationErrors,
    setValidationErrors,
  }
}
