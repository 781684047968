import { Box, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { ActionDialog } from '~/components'
import ComunicadoTerminoExperiencia from '~/pages/ComunicadoTerminoExperiencia'

interface DialogTerminoContratoProps {
  isOpen: boolean
  onClose: () => void
  funcionarioId: string
  estabelecimentoId: string
}

export default function DialogTerminoContrato({
  isOpen,
  onClose,
  funcionarioId,
  estabelecimentoId,
}: DialogTerminoContratoProps) {
  return (
    <ActionDialog isOpen={isOpen} onClose={onClose} customActions={<></>} maxWidth="md">
      <Box m={-1}>
        <Box position="absolute" right={0} top={0} p={2}>
          <IconButton size="small" onClick={onClose}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
        <ComunicadoTerminoExperiencia
          vinculoId={funcionarioId}
          estabelecimentoId={estabelecimentoId}
        />
      </Box>
    </ActionDialog>
  )
}
