import { useState } from 'react'

import { ActionDialog, Stack } from '~/components'
import { MUIAutoComplete } from '~/components/AutoComplete'

import useValidationErrors from '~/hooks/useValidationErrors'
import { useAtualizarRP } from '~/hooks/queries/RP/useAtualizarRP'

import * as yup from 'yup'

import { RP } from '~/hooks/queries/RP/RP'

import { IndSimplesValues } from '~/@types/enums/IndSimplesEnum'
import { IndMultiploVinculoValues } from '~/@types/enums/IndMultiploVinculoEnum'

const schema = yup.object().shape({
  indSimples: yup.string().required('Informe o Indicador Simples Nacional'),
  indMultiplosVinculos: yup.string().required('Informe o Indicador Múltiplos Vínculos'),
})

interface DialogFormEditProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  rp: RP
}

export default function DialogFormEdit({ isOpen, onClose, onSubmit, rp }: DialogFormEditProps) {
  const [data, setData] = useState(rp)

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  const { mutateAsync, isLoading } = useAtualizarRP()

  async function handleSubmit() {
    await mutateAsync({
      data,
      params: {
        id: rp.id,
      },
    })
    onSubmit()
    onClose()
  }

  return (
    <ActionDialog
      isOpen={isOpen}
      onClose={onClose}
      title="Atualizar Recibo"
      onOkClick={handleValidate}
      isOkProcessing={isLoading}
    >
      <Stack>
        <MUIAutoComplete
          label="Indicador Simples Nacional"
          options={IndSimplesValues}
          optionId="value"
          renderOption={(option) => option.name}
          required
          validationErrors={validationErrors}
          name="indSimples"
          value={data.indSimples}
          onChange={(e, obj) => {
            const indSimples = obj ? obj.value : ''
            setData({ ...data, indSimples })
          }}
        />
        <MUIAutoComplete
          label="Indicador Múltiplos Vínculos"
          options={IndMultiploVinculoValues}
          renderOption={(option) => option.name}
          optionId="value"
          required
          validationErrors={validationErrors}
          name="indMultiplosVinculos"
          value={data.indMultiplosVinculos}
          onChange={(e, obj) => {
            const indMultiplosVinculos = obj ? obj.value : ''
            setData({ ...data, indMultiplosVinculos })
          }}
        />
      </Stack>
    </ActionDialog>
  )
}
