import { convertToArrayDescription } from '~/utils/utils'

export enum RescisaoAntecipadaExperienciaIniciativaEnum {
  Empregador_0,
  Empregado_1,
}

const RescisaoAntecipadaExperienciaIniciativaLabels = {
  [RescisaoAntecipadaExperienciaIniciativaEnum.Empregador_0]: 'Empregador',
  [RescisaoAntecipadaExperienciaIniciativaEnum.Empregado_1]: 'Empregado',
}

export const RescisaoAntecipadaExperienciaIniciativaValues = convertToArrayDescription(
  RescisaoAntecipadaExperienciaIniciativaEnum,
  RescisaoAntecipadaExperienciaIniciativaLabels,
)
