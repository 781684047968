import { convertToArrayDescription } from '~/utils/utils'

export enum ContratoFuncionarioManutencaoEnum {
  Prorrogacao_0,
  Efetivacao_1,
  Rescisao_2,
}

export const ContratoFuncionarioManutencaoLabels = {
  [ContratoFuncionarioManutencaoEnum.Prorrogacao_0]: 'Prorrogação',
  [ContratoFuncionarioManutencaoEnum.Efetivacao_1]: 'Efetivação',
  [ContratoFuncionarioManutencaoEnum.Rescisao_2]: 'Rescisão',
}

export const ContratoFuncionarioManutencaoValues = convertToArrayDescription(
  ContratoFuncionarioManutencaoEnum,
  ContratoFuncionarioManutencaoLabels,
)
