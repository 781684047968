import { IconButton, makeStyles } from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'

import {
  Visibility as ViewIcon,
  EventBusy as EventBusyIcon,
  BeachAccess as BeachAccessIcon,
} from '@material-ui/icons'

import { ToolsDataTable, ButtonBox } from '~/components'

import useUtils from '~/hooks/useUtils'

import moment from 'moment'

import { IndProvisaoEnum, IndProvisaoValues } from '~/@types/enums/IndProvisaoEnum'

const useStyles = makeStyles(() => ({
  aliengRight: { textAlign: 'right' },
}))

const Table = (props) => {
  const { data: _data, onItemClick, isLoading, query, isFetching } = props

  const { formatCurrency } = useUtils()

  const classes = useStyles()

  const data = _data.map((item) => {
    return {
      ...item,
      pessoaNome: item?.vinculo?.pessoaFisica?.nome || '',
      dtAdmissao: item?.vinculo?.dtAdmissao
        ? moment(item.vinculo.dtAdmissao).format('DD/MM/YYYY')
        : null,
      vrSalarioContratual: item?.vrSalarioContratual
        ? formatCurrency(item.vrSalarioContratual)
        : null,
      vrSalarioAdicional: item?.vrSalarioAdicional ? formatCurrency(item.vrSalarioAdicional) : null,
      vrSalarioVariavel: item?.vrSalarioVariavel ? formatCurrency(item.vrSalarioVariavel) : null,
      vrBaseProvisao: item?.vrBaseProvisao ? formatCurrency(item.vrBaseProvisao) : null,
      vrProvisao: item?.vrProvisao ? formatCurrency(item.vrProvisao) : null,
      vrContribuicaoPrevidenciaria: item?.vrContribuicaoPrevidenciaria
        ? formatCurrency(item.vrContribuicaoPrevidenciaria)
        : null,
      vrContribuicaoTerceiros: item?.vrContribuicaoTerceiros
        ? formatCurrency(item.vrContribuicaoTerceiros)
        : null,
      vrFGTS: item?.vrFGTS ? formatCurrency(item.vrFGTS) : null,
      vrPIS: item?.vrPIS ? formatCurrency(item.vrPIS) : null,
      indLancamento: item?.indLancamento
        ? IndProvisaoValues.find((d) => d.value === item.indLancamento).name
        : '',
      lotacaoNome: item?.lotacao?.nome ? item.lotacao.nome : null,
    }
  })

  const columns = [
    {
      name: 'vinculo',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'pessoaNome',
      label: 'Nome',
      // options: {
      //   customBodyRender: (value) => {
      //     const nameSplited = value.split(' ')
      //     let name = ''
      //     if (nameSplited[1].length <= 2) {
      //       name = `${nameSplited[0]} ${nameSplited[1]} ${nameSplited[2]}`
      //     } else {
      //       name = `${nameSplited[0]} ${nameSplited[1]}`
      //     }
      //     return <div title={value}>{name}</div>
      //   },
      // },
    },
    {
      name: 'dtAdmissao',
      label: 'Admissão',
    },
    {
      name: 'vrSalarioContratual',
      label: 'Salário Contratual',
      options: {
        setCellProps: () => ({
          className: classes.aliengRight,
        }),
      },
    },
    {
      name: 'vrSalarioAdicional',
      label: 'Salário Adicional',
      options: {
        setCellProps: () => ({
          className: classes.aliengRight,
        }),
      },
    },
    {
      name: 'vrSalarioVariavel',
      label: 'Salário Variável',
      options: {
        setCellProps: () => ({
          className: classes.aliengRight,
        }),
      },
    },
    {
      name: 'vrBaseProvisao',
      label: 'Base Provisão',
      options: {
        setCellProps: () => ({
          className: classes.aliengRight,
        }),
      },
    },
    {
      name: 'vrProvisao',
      label: 'Provisão',
      options: {
        setCellProps: () => ({
          className: classes.aliengRight,
        }),
      },
    },
    {
      name: 'vrContribuicaoPrevidenciaria',
      label: 'Contribuição Previdenciária',
      options: {
        setCellProps: () => ({
          className: classes.aliengRight,
        }),
      },
    },
    {
      name: 'vrContribuicaoTerceiros',
      label: 'Contribuição Terceiros',
      options: {
        setCellProps: () => ({
          className: classes.aliengRight,
        }),
      },
    },
    {
      name: 'vrFGTS',
      label: 'FGTS',
      options: {
        setCellProps: () => ({
          className: classes.aliengRight,
        }),
      },
    },
    {
      name: 'vrPIS',
      label: 'PIS',
      options: {
        setCellProps: () => ({
          className: classes.aliengRight,
        }),
      },
    },
    {
      name: 'indLancamento',
      label: 'Ind. Lançamento',
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta) => {
          const vinculo = tableMeta.rowData[0]
          const dtFinded = _data.find((d) => d.id === value)
          return (
            <ButtonBox spacing={0} justifyContent="center">
              {dtFinded.indLancamento === IndProvisaoEnum.SaldoInicial && (
                <IconButton
                  size="small"
                  title="Abrir formulário para atualizar"
                  color="primary"
                  aria-label="Atualizar"
                  onClick={() => {
                    onItemClick('update', dtFinded)
                  }}
                >
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>
              )}

              <IconButton
                size="small"
                title="Visualizar todas as Provisões"
                color="primary"
                aria-label="Provisões"
                onClick={() => {
                  onItemClick('provisoes', vinculo)
                }}
              >
                <ViewIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Visualizar Afastamentos"
                color="primary"
                aria-label="Afastamentos"
                onClick={() => {
                  onItemClick('afastamentos', vinculo)
                }}
              >
                <EventBusyIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Visualizar Férias"
                color="primary"
                aria-label="Férias"
                onClick={() => {
                  onItemClick('ferias', vinculo)
                }}
              >
                <BeachAccessIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      disableAutoHeight
      title=""
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      sherlock={{
        query,
        columns: ['pessoaNome', 'lotacaoNome'],
      }}
    />
  )
}

Table.defaultProps = {
  query: '',
}

export default Table
