import { useEffect, useState } from 'react'
import moment from 'moment'

import { Box, IconButton, useTheme } from '@material-ui/core'
import {
  Delete as DeleteIcon,
  Description as DescriptionIcon,
  GetApp as GetAppIcon,
  PostAdd as PostAddIcon,
  Email as EmailIcon,
  Edit as EditIcon,
} from '@material-ui/icons'

import {
  Button,
  ButtonBox,
  Checkbox,
  ConfirmDeleteDialog,
  Finder,
  PageHeader,
  ToolsDataTable,
} from '~/components'

import DrawerDocumento from './DrawerDocumento'
import DialogUploadFile from './DialogUploadFile'
import Form from './Form'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useObterDocumentos } from '~/hooks/queries/GerenciadorDocumentos/useObterDocumentos'
import { useRemoverDocumento } from '~/hooks/queries/GerenciadorDocumentos/useRemoverDocumento'
import { useBaixaVariosDocumentos } from '~/hooks/queries/GerenciadorDocumentos/useBaixaVariosDocumentos'
import { useEnviarEmailEstabelecimentoVariosDocumentos } from '~/hooks/queries/GerenciadorDocumentos/useEnviarEmailEstabelecimentoVariosDocumentos'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { EstabelecimentoConsulta } from '~/hooks/queries/EstabelecimentoConsulta/EstabelecimentoConsulta'
import { GerenciadorDocumentos } from '~/hooks/queries/GerenciadorDocumentos/GerenciadorDocumentos'

export const TITLE_PAGE = 'Gerenciador de Documentos'

interface ContentProps {
  estabelecimento: EstabelecimentoConsulta
}

export default function Content(props: ContentProps) {
  const { estabelecimento } = props

  const [rowsSelected, setRowsSelected] = useState<number[]>([])
  const [query, setQuery] = useState('')
  const [isMostrarTodosDocumentos, setMostrarTodosDocumentos] = useState(false)

  const { anoMes } = useAmbiente()
  const theme = useTheme()

  const {
    close: closeDrawerDocumento,
    data: dataDrawerDocumento,
    isOpen: isOpenDrawerDocumento,
    open: openDrawerDocumento,
  } = useDialog<{ descricao: string; documento: string } | null>(null)
  const {
    close: closeDialogUploadFile,
    data: dataDialogUploadFile,
    isOpen: isOpenDialogUploadFile,
    open: openDialogUploadFile,
  } = useDialog<string | null>(null)
  const {
    close: closeConfirmDelete,
    data: dataConfirmDelete,
    isOpen: isOpenConfirmDelete,
    open: openConfirmDelete,
  } = useDialog<string | null>(null)
  const {
    close: closeForm,
    data: dataForm,
    isOpen: isOpenForm,
    open: openForm,
  } = useDialog<GerenciadorDocumentos | null>(null)

  const {
    data: _d,
    isLoading,
    isFetching,
  } = useObterDocumentos({
    params: {
      estabelecimentoId: estabelecimento.id,
      competencia: anoMes,
      isMostrarTodosDocumentos,
    },
  })
  const _data = _d || []
  const data = _data.map((d) => ({
    ...d,
    dtDocumentoCriado: d?.dtDocumentoCriado
      ? moment(d.dtDocumentoCriado).format('DD/MM/yyyy - HH:mm:ss')
      : '',
    dtDocumentoEnviado: d?.dtDocumentoEnviado
      ? moment(d.dtDocumentoEnviado).format('DD/MM/yyyy - HH:mm:ss')
      : '',
    dtDocumentoBaixado: d?.dtDocumentoBaixado
      ? moment(d.dtDocumentoBaixado).format('DD/MM/yyyy - HH:mm:ss')
      : '',
  }))

  const { mutateAsync: mutateAsyncRemoverdocumento, isLoading: isLoadingRemoverDocumento } =
    useRemoverDocumento()

  const {
    mutateAsync: mutateAsyncBaixarVariosDocumentos,
    isLoading: isLoadingBaixarVariosDocumentos,
  } = useBaixaVariosDocumentos()

  const {
    mutateAsync: mutateAsyncEnviarEmailEstabelecimentoVariosDocumentos,
    isLoading: isLoadingEnviarEmailEstabelecimentoVariosDocumentos,
  } = useEnviarEmailEstabelecimentoVariosDocumentos()

  useEffect(() => {
    setRowsSelected([])
  }, [_d])

  async function handleDownloadDocumentos() {
    const documentosIds = rowsSelected.map((index) => _data[index].id)
    await mutateAsyncBaixarVariosDocumentos({
      documentosIds,
      nomeArquivo: estabelecimento.nome.replaceAll(' ', '-') + '-' + estabelecimento.nrInscricao,
    })
  }

  async function handleSendEmailDocumentos() {
    const documentosIds = rowsSelected.map((index) => _data[index].id)
    await mutateAsyncEnviarEmailEstabelecimentoVariosDocumentos({
      documentosIds,
      estabelecimentoId: estabelecimento.id,
    })
  }

  async function handleRemoveItem(id: string) {
    await mutateAsyncRemoverdocumento(id)
    closeConfirmDelete()
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'descricao',
      label: 'Descrição',
    },
    {
      name: 'dtDocumentoCriado',
      label: 'Criado',
    },
    {
      name: 'dtDocumentoBaixado',
      label: 'Baixado',
    },
    {
      name: 'dtDocumentoEnviado',
      label: 'Enviado',
    },
    {
      name: 'id',
      label: 'Documento',
      options: {
        setCellProps: () => ({
          style: {
            width: '80px',
          },
        }),
        customBodyRender: (value) => {
          const dataCurrent = _data.find((d) => d.id === value)
          if (!dataCurrent) return
          return (
            <Box display="flex" alignItems="center" justifyContent="center">
              <IconButton
                size="small"
                color="primary"
                onClick={() =>
                  openDrawerDocumento({
                    descricao: dataCurrent.descricao,
                    documento: dataCurrent.documento,
                  })
                }
              >
                <DescriptionIcon fontSize="small" />
              </IconButton>
            </Box>
          )
        },
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        setCellProps: () => ({
          style: {
            width: '80px',
          },
        }),
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  const dataCurrent = _data.find((d) => d.id === value)
                  if (!dataCurrent) return
                  openForm(dataCurrent)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>

              <IconButton
                size="small"
                title="Remover este registro"
                color="primary"
                aria-label="Remover"
                onClick={() => openConfirmDelete(value)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <Box height="100%" display="flex" flexDirection="column" gridGap={theme.spacing(0.5)}>
      <PageHeader title={TITLE_PAGE}>
        <Box display="flex" gridGap={theme.spacing(1)}>
          <Box minWidth={205}>
            <Checkbox
              label="Mostrar todos documentos"
              value={isMostrarTodosDocumentos}
              onChange={(_, checked) => setMostrarTodosDocumentos(checked)}
            />
          </Box>

          <IconButton
            size="small"
            color="primary"
            onClick={() => openDialogUploadFile(estabelecimento.id)}
            title="Inserir Documentos"
          >
            <PostAddIcon fontSize="small" />
          </IconButton>
          <Finder onSearch={setQuery} onClose={() => setQuery('')} />
        </Box>
      </PageHeader>
      <Box flex={1}>
        <ToolsDataTable
          disableAutoHeight
          columns={columns}
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          optionsSelectable={{
            type: 'multiple',
            rowsSelected,
            onRowSelected: setRowsSelected,
          }}
          sherlock={{
            columns: ['descricao'],
            query,
          }}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" gridGap={theme.spacing(1)}>
        <Button
          isLoading={isLoadingBaixarVariosDocumentos}
          onClick={handleDownloadDocumentos}
          disabled={rowsSelected.length > 0 ? false : true}
        >
          <Box display="flex" gridGap={theme.spacing(1)}>
            <GetAppIcon fontSize="small" />
            Baixar Documentos
          </Box>
        </Button>

        <Button
          isLoading={isLoadingEnviarEmailEstabelecimentoVariosDocumentos}
          onClick={handleSendEmailDocumentos}
          disabled={rowsSelected.length > 0 ? false : true}
        >
          <Box display="flex" gridGap={theme.spacing(1)}>
            <EmailIcon fontSize="small" />
            Enviar Documentos
          </Box>
        </Button>
      </Box>

      <DrawerDocumento
        isOpen={isOpenDrawerDocumento}
        onClose={closeDrawerDocumento}
        title={TITLE_PAGE}
        subtitle={dataDrawerDocumento?.descricao || ''}
        documento={dataDrawerDocumento?.documento || ''}
      />

      {isOpenForm && dataForm ? (
        <Form isOpen={isOpenForm} data={dataForm} onClose={closeForm} />
      ) : (
        <></>
      )}

      {isOpenDialogUploadFile && (
        <DialogUploadFile
          estabelecimentoId={dataDialogUploadFile || ''}
          isOpen={isOpenDialogUploadFile}
          onClose={closeDialogUploadFile}
        />
      )}

      {isOpenConfirmDelete && dataConfirmDelete ? (
        <ConfirmDeleteDialog
          isOpen={isOpenConfirmDelete}
          onCancel={closeConfirmDelete}
          onConfirm={() => handleRemoveItem(dataConfirmDelete)}
          isDeleting={isLoadingRemoverDocumento}
        />
      ) : (
        <></>
      )}
    </Box>
  )
}
