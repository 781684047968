import { useEffect, useState } from 'react'

import { TextField } from 'mio-library-ui'
import {
  LinearProgress,
  Box,
  makeStyles,
  InputAdornment,
  IconButton,
  MenuItem,
  Paper,
} from '@material-ui/core'
import {
  Search as SearchIcon,
  Close as CloseIcon,
  ChevronRight as NextIcon,
  ChevronLeft as PreviousIcon,
  FirstPage as FirstIcon,
  LastPage as LastIcon,
} from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'

import TreeViewVinculos from './components/TreeViewVinculos'

import useAmbiente from '~/hooks/useAmbiente'
import { useContextRecibo } from '~/hooks/useRecibo'
import { useSelector } from 'react-redux'

import { ReciboIDFEnum } from '~/@types/enums/ReciboIDFEnum'
import { CategoriaEnum } from '~/@types/enums/CategoriaEnum'
import { useGetVinculosMenu2 } from '~/hooks/queries/RP/useGetVinculosMenu2'
import ButtonFiltro from './components/ButtonFiltro'

export const IntermitenteNormal = CategoriaEnum.Empregado_Intermitente_111 + 'normal'
export const IntermitenteRescisao = CategoriaEnum.Empregado_Intermitente_111 + 'rescisao'

function handleSearchMenu(query, initialData) {
  if (!query) return initialData
  const queryLow = query.toLowerCase()
  const dataFormated = initialData.map((m) => {
    const nVinculos = m.vinculos.filter((m) => m.vinculoNome.toLowerCase().includes(queryLow))
    return {
      ...m,
      vinculos: nVinculos || [],
    }
  })
  return dataFormated
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    border: theme.shape.border,
    borderRadius: theme.shape.borderRadius,
  },
  boxTools: {
    height: '105px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderBottom: theme.shape.border,
    padding: theme.spacing(1),
  },
  boxTreeVinculo: {
    height: 'calc(100% - 150px)',
    overflow: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  boxNavigation: {
    borderTop: theme.shape.border,
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      display: 'grid',
    },
  },
}))

export const TODOS = 99
export const DECIMO_TERCEIRO_SALARIO = 999

const Menu = (props) => {
  const { onSelectItem, onContextMenuItemClick, param } = props

  const [query, setQuery] = useState('')
  const [categoria, setCategoria] = useState(null)
  const [filtroMenu, setFiltroMenu] = useState([])

  const parametrosSistema = useSelector((state) => state.app.parametrosSistema)

  const isIniciarFiltroReciboComTodos = parametrosSistema.isIniciarFiltroReciboComTodos

  const [filtroDialog, setFiltroDialog] = useState({
    cargoId: null,
    lotacaoId: null,
  })

  const {
    setReciboPagamento,
    expanded,
    setExpanded,
    filterValue,
    setFilterValue,
    isLoadingRecibo,
  } = useContextRecibo()

  const { estabelecimento, mes, anoMes } = useAmbiente()
  const classes = useStyles()
  const {
    data: _d,
    isLoading,
    isFetching,
  } = useGetVinculosMenu2({
    data: filtroMenu,
    params: {
      estabelecimentoId: estabelecimento.id,
      anoMes,
      categoria,
      cargoId: filtroDialog.cargoId,
      lotacaoId: filtroDialog.lotacaoId,
    },
  })
  const _data = _d || []

  const displayMenus = handleSearchMenu(query, _data)

  const isDisabledFilter = param === 'rescisao-simulada'

  useEffect(() => {
    let filtro = []
    let filterValue = TODOS
    if (param === 'mensal' && !isIniciarFiltroReciboComTodos) {
      filtro = [
        ReciboIDFEnum.Mensal_1,
        ReciboIDFEnum.Complementar_3,
        ReciboIDFEnum.Adiantamento_4,
        ReciboIDFEnum.Socio_15,
      ]
      filterValue = ReciboIDFEnum.Mensal_1
    }
    if (param === 'rescisao') {
      filtro = [ReciboIDFEnum.Rescisao_5, ReciboIDFEnum.Resilicao_14]
      filterValue = ReciboIDFEnum.Rescisao_5
    }
    if (param === 'rescisao-tsv') {
      filtro = [ReciboIDFEnum.RescisaoTSV_20]
      filterValue = ReciboIDFEnum.RescisaoTSV_20
    }
    if (param === 'rescisao-complementar') {
      filtro = [ReciboIDFEnum.RescisaoComplementar_6]
      filterValue = ReciboIDFEnum.RescisaoComplementar_6
    }
    if (param === 'rescisao-simulada') {
      filtro = [ReciboIDFEnum.RescisaoSimulada_11]
      filterValue = ReciboIDFEnum.RescisaoSimulada_11
    }
    if (param === 'ferias') {
      filtro = [ReciboIDFEnum.Ferias_2]
      filterValue = ReciboIDFEnum.Ferias_2
    }
    if (param === 'adiantamento-salario') {
      filtro = [ReciboIDFEnum.Adiantamento_4]
      filterValue = ReciboIDFEnum.Adiantamento_4
    }
    if (param === 'autonomo') {
      filtro = [ReciboIDFEnum.Autonomo_16]
      filterValue = ReciboIDFEnum.Autonomo_16
    }
    if (param === 'intermitente') {
      filtro = [IntermitenteNormal]
      filterValue = IntermitenteNormal
    }
    if (param === 'intermitente-rescisao') {
      filtro = [IntermitenteRescisao]
      filterValue = IntermitenteRescisao
    }

    setFilterValue(filterValue)
    setFiltroMenu(filtro)
    setReciboPagamento(null)
    setCategoria(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param, isIniciarFiltroReciboComTodos])

  useEffect(() => {
    let filterMenu = [filterValue]
    let categoria = null

    if (filterValue === ReciboIDFEnum.Mensal_1) {
      filterMenu = [
        ReciboIDFEnum.Mensal_1,
        ReciboIDFEnum.Complementar_3,
        ReciboIDFEnum.Adiantamento_4,
        ReciboIDFEnum.Socio_15,
      ]
    }

    if (filterValue === ReciboIDFEnum.Rescisao_5) {
      filterMenu = [ReciboIDFEnum.Rescisao_5, ReciboIDFEnum.Resilicao_14]
    }

    if (filterValue === DECIMO_TERCEIRO_SALARIO) {
      filterMenu = [ReciboIDFEnum.DecimoTerceiro1aParcela_7]
      if (mes === '12') {
        filterMenu = [ReciboIDFEnum.DecimoTerceiro2aParcela_8]
      }
    }

    if (filterValue === IntermitenteNormal) {
      filterMenu = [
        ReciboIDFEnum.Mensal_1,
        ReciboIDFEnum.Complementar_3,
        ReciboIDFEnum.Adiantamento_4,
      ]
      categoria = CategoriaEnum.Empregado_Intermitente_111
    }

    if (filterValue === IntermitenteRescisao) {
      filterMenu = [
        ReciboIDFEnum.Rescisao_5,
        ReciboIDFEnum.RescisaoZerada_12,
        ReciboIDFEnum.Resilicao_14,
      ]
      categoria = CategoriaEnum.Empregado_Intermitente_111
    }

    if (filterValue === TODOS) {
      filterMenu = []
    }

    setFiltroMenu(filterMenu)
    setReciboPagamento(null)
    setCategoria(categoria)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, mes])

  const changeNavigation = (event) => {
    const boxTreeVinculo = document.getElementById('boxTreeVinculo')

    let newExpandedList = []
    let firstReciboId = null

    const idFirstExpanded = (expanded.find((d) => d.split('|')[0] === '0') || '').split('|').at(1)

    if (idFirstExpanded) {
      const optionExpanded = displayMenus.find((d) => d.agrupamentoId === parseInt(idFirstExpanded))
      if (!optionExpanded) return
      newExpandedList.push('0|' + optionExpanded.agrupamentoId)

      let scrollTo = 0
      let vinculo = null

      if ('first' === event) {
        scrollTo = 0
        vinculo = optionExpanded.vinculos.at(0)
      }

      if ('previous' === event || 'next' === event) {
        const idVinculoCurrent = (expanded.find((d) => d.split('|')[0] === '1') || '')
          .split('|')
          .at(1)

        if (!idVinculoCurrent) return

        let indexVinculo = optionExpanded.vinculos.findIndex(
          (d) => d.vinculoId === idVinculoCurrent,
        )

        if ('previous' === event) indexVinculo -= 1
        if ('next' === event) indexVinculo += 1

        if (indexVinculo < 0) return

        scrollTo =
          ((boxTreeVinculo.scrollHeight - 250) / optionExpanded.vinculos.length) * indexVinculo
        vinculo = optionExpanded.vinculos.at(indexVinculo)
      }

      if ('last' === event) {
        scrollTo = boxTreeVinculo.scrollHeight
        vinculo = optionExpanded.vinculos.at(optionExpanded.vinculos.length - 1)
      }

      boxTreeVinculo.scrollTo({
        top: scrollTo,
        behavior: 'smooth',
      })

      if (!vinculo) return

      newExpandedList.push('1|' + vinculo.vinculoId)
      firstReciboId = vinculo.recibos.at(0)?.reciboId || null
    } else {
      let optionIndex = 0

      if ('first' === event || 'previous' === event) {
        optionIndex = 0
      }

      if ('next' === event || 'last' === event) {
        optionIndex = displayMenus.length - 1
      }

      const option = displayMenus.at(optionIndex)
      if (!option) return
      newExpandedList.push('0|' + option.agrupamentoId)

      const firstVinculo = option.vinculos.at(0)
      if (!firstVinculo) return
      newExpandedList.push('1|' + firstVinculo.vinculoId)

      firstReciboId = firstVinculo.recibos.at(0)?.reciboId || null
    }

    setExpanded(newExpandedList)

    if (firstReciboId) {
      onSelectItem('reciboSelecionado', firstReciboId)
    }
  }

  return (
    <Paper className={classes.root}>
      <Box className={classes.boxTools}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={filterValue || ''}
          select
          disabled={isDisabledFilter}
          placeholder="Filtrar Recibos"
          onChange={(e) => setFilterValue(e?.target?.value || TODOS)}
        >
          <MenuItem value={TODOS}>Todos</MenuItem>
          <MenuItem value={ReciboIDFEnum.Mensal_1}>Mensal</MenuItem>
          <MenuItem value={ReciboIDFEnum.Ferias_2}>Férias</MenuItem>
          <MenuItem value={ReciboIDFEnum.Ferias_Coletivas_21}>Férias Coletivas</MenuItem>
          <MenuItem value={ReciboIDFEnum.Complementar_3}>Complementar</MenuItem>
          <MenuItem value={ReciboIDFEnum.Adiantamento_4}>Adiantamento Salário</MenuItem>
          <MenuItem value={ReciboIDFEnum.Rescisao_5}>Rescisão</MenuItem>
          <MenuItem value={ReciboIDFEnum.RescisaoTSV_20}>Rescisão TSV</MenuItem>
          <MenuItem value={ReciboIDFEnum.RescisaoComplementar_6}>Rescisão Complementar</MenuItem>
          <MenuItem value={DECIMO_TERCEIRO_SALARIO}>Décimo Terceiro Salário</MenuItem>
          <MenuItem value={ReciboIDFEnum.RescisaoSimulada_11}>Rescisão Simulada</MenuItem>
          <MenuItem value={ReciboIDFEnum.Resilicao_14}>Resilição</MenuItem>
          <MenuItem value={ReciboIDFEnum.Socio_15}>Pró-Labore</MenuItem>
          <MenuItem value={ReciboIDFEnum.Autonomo_16}>Autônomo</MenuItem>
          <MenuItem value={ReciboIDFEnum.ConvencaoColetiva_18}>Convenção Coletiva</MenuItem>
          <MenuItem value={ReciboIDFEnum.Cooperado_19}>Cooperado</MenuItem>
          <MenuItem value={IntermitenteNormal}>Intermitente</MenuItem>
          <MenuItem value={IntermitenteRescisao}>Rescisão Intermitente</MenuItem>
        </TextField>

        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={query || ''}
          placeholder="Procurar"
          onChange={(e) => setQuery(e?.target?.value || '')}
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton position="end" onClick={() => setQuery('')}>
                <CloseIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Box>

      <ButtonFiltro data={filtroDialog} onChange={setFiltroDialog} />

      <Box height="4px">{isLoadingRecibo && <LinearProgress />}</Box>

      <Box className={classes.boxTreeVinculo}>
        {isLoading ? (
          <>
            <Skeleton variant="text" />
            <Skeleton variant="text" width="40%" />
            <Skeleton variant="text" width="40%" />
            <Skeleton variant="text" width="40%" />
          </>
        ) : (
          <>
            {isFetching ? <LinearProgress /> : <Box height="4px" />}
            <TreeViewVinculos
              data={displayMenus}
              onSelectItem={onSelectItem}
              onContextMenuItemClick={onContextMenuItemClick}
            />
          </>
        )}
      </Box>

      <Box className={classes.boxNavigation}>
        <Box display="flex">
          <IconButton title="Primeiro Registro" onClick={() => changeNavigation('first')}>
            <FirstIcon fontSize="small" />
          </IconButton>
          <IconButton title="Registro Anterior" onClick={() => changeNavigation('previous')}>
            <PreviousIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box display="flex">
          <IconButton title="Próximo Registro" onClick={() => changeNavigation('next')}>
            <NextIcon fontSize="small" />
          </IconButton>
          <IconButton title="Último Registro" onClick={() => changeNavigation('last')}>
            <LastIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  )
}

export default Menu
