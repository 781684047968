import { useQueryClient } from 'react-query'

import { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Button, Finder, ButtonBox } from 'mio-library-ui'
import ConfirmDeleteAlert from '~/components/ConfirmInscricaoDelete'

import Table from './components/Table'
import Container from './components/Container'

import api from '~/services/api-pessoal'
import { tipoInscricaoConsts } from '~/values/tipoInscricaoValues'

import useDialog from '~/hooks/useDialog'
import useAmbiente from '~/hooks/useAmbiente'
import useNotification from '~/hooks/useNotification'
import useDialogNotification from '~/hooks/useDialogNotification'

import { Creators as AppActions } from '~/store/ducks/app'
import {
  estabelecimentoObterFiltradoQuery,
  useEstabelecimentoObterFiltrado,
} from '~/hooks/queries/EstabelecimentoConsulta/useEstabelecimentoObterFiltrado'
import { useEstabelecimentoObterRelatorio } from '~/hooks/queries/EstabelecimentoConsulta/useEstabelecimentoObterRelatorio'

import { tipoCAEPFConsts } from '~/values/tipoCAEPFValues'
import { IndAtividadeEstabelecimentoEnum } from '~/@types/enums/IndAtividadeEstabelecimentoEnum'
import DrawerFilter from './components/DrawerFilter'
import { ExcelButton, PDFViewer, PrintButton } from '~/components'
import { IconButton } from '@material-ui/core'
import { FilterList } from '@material-ui/icons'
import PageContainer from '~/components/PageContainer'

const { CNPJ_1 } = tipoInscricaoConsts
const { naoInformado_0 } = tipoCAEPFConsts

const Estabelecimento = () => {
  const [container, setContainer] = useState({
    isOpen: false,
    data: {},
  })

  const [filter, setFilter] = useState({
    isOpen: false,
    data: {
      indAtividadeEstabelecimento: IndAtividadeEstabelecimentoEnum.Todos,
      grupoId: undefined,
    },
  })

  const [isLoadingOnClick, setLoadingOnClick] = useState(false)
  const [isLoadingConfirmDelete, setLoadingConfirmDelete] = useState(false)

  const [query, setQuery] = useState('')
  const notification = useNotification()
  const { anoMes } = useAmbiente(false, true)

  const queryClient = useQueryClient()
  const {
    data: _d,
    isLoading,
    isFetching,
  } = useEstabelecimentoObterFiltrado({
    indAtividadeEstabelecimentoEnum: filter.data.indAtividadeEstabelecimento,
    grupoId: filter.data.grupoId,
    anoMes,
  })
  const data = _d || []

  const { mutateAsync: obterRelatorio, isLoading: isLoadingRelatorio } =
    useEstabelecimentoObterRelatorio()

  const {
    isOpen: isOpenConfirmDeleteAlert,
    open: openConfirmDeleteAlert,
    close: closeConfirmDeleteAlert,
    data: dataConfirmDeleteAlert,
  } = useDialog({
    nrInscricao: '',
    id: '',
  })

  const {
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
    close: closePDFViewer,
    data: dataPDFViewer,
  } = useDialog({
    indAtividadeEstabelecimento: null,
    grupoId: null,
  })

  const dialogNotification = useDialogNotification()
  const dispatch = useDispatch()
  const ambiente = useSelector((state) => state.app.ambiente)

  const handleQuery = useCallback((q) => {
    setQuery(q)
  }, [])

  const handleClickItem = (event, value) => {
    const handleClickEditItem = async (id) => {
      setLoadingOnClick(true)
      try {
        const response = await api.get(`/Estabelecimento/${id}`)
        handleOpenContainer(response.data.data)
      } catch (err) {
        dialogNotification.extractErrors(err)
      } finally {
        setLoadingOnClick(false)
      }
    }

    const handleClickDeleteItem = (id) => {
      const estabelecimentoCurrent = data.find((d) => d.id === id)
      openConfirmDeleteAlert({
        id,
        nrInscricao: estabelecimentoCurrent.nrInscricao,
      })
    }

    const functions = {
      edit: handleClickEditItem,
      delete: handleClickDeleteItem,
    }
    functions[event](value)
  }

  const handleDispatchNewEstabelecimento = useCallback(
    async (estabelecimentoId) => {
      if (!estabelecimentoId) return
      try {
        const response = await api.get('/EstabelecimentoConsulta/GetById', {
          params: {
            estabelecimentoId,
          },
        })
        if (!response?.data?.data) throw new Error('Estabelecimento não encontrado')
        dispatch(
          AppActions.setAmbiente({
            ...ambiente,
            empresa: {
              empregador: null,
              estabelecimento: response.data.data,
            },
            hasAmbiente: true,
          }),
        )
      } catch (error) {
        console.warn(error)
        dispatch(
          AppActions.setAmbiente({
            ...ambiente,
            empresa: {
              empregador: null,
              estabelecimento: null,
            },
            hasAmbiente: false,
          }),
        )
      }
    },
    [ambiente, dispatch],
  )

  const handleDeleteItem = async () => {
    try {
      setLoadingConfirmDelete(true)
      await api.post('/Estabelecimento/ExcluirEstabelecimento', [dataConfirmDeleteAlert.id])
      await queryClient.invalidateQueries(estabelecimentoObterFiltradoQuery)
      closeConfirmDeleteAlert()
      handleDispatchNewEstabelecimento(dataConfirmDeleteAlert.id)
      notification.remove()
    } catch (err) {
      dialogNotification.extractErrors(err)
    } finally {
      setLoadingConfirmDelete(false)
    }
  }

  const handleOpenContainer = useCallback((data) => {
    setContainer((oldState) => ({
      ...oldState,
      isOpen: true,
      data,
    }))
  }, [])

  const handleClickAddItem = useCallback(() => {
    handleOpenContainer({
      tipoCAEPF: naoInformado_0,
      tipoInscricao: CNPJ_1,
      modalidadeSEFIP: '0',
      codigoSEFIP: '115',
    })
  }, [handleOpenContainer])

  const handleCloseContainer = () => {
    setContainer((oldState) => ({
      ...oldState,
      isOpen: false,
    }))
  }

  const handleAfterSubmitForm = async (event, value) => {
    const handleAfterInsert = (data) => {
      handleCloseContainer()
      handleOpenContainer(data)
      handleDispatchNewEstabelecimento(data?.id)
    }

    const handleAfterUpdate = () => {
      // const { itens } = collection
      // const newItens = itens.map((i) => (i.id === data.id ? data : i))
      // setCollection((oldState) => ({
      //   ...oldState,
      //   itens: newItens,
      // }))
    }

    const functions = {
      insert: handleAfterInsert,
      update: handleAfterUpdate,
    }
    await queryClient.invalidateQueries(estabelecimentoObterFiltradoQuery)
    functions[event](value)
  }

  async function handlePrintExcel() {
    await obterRelatorio({
      indAtividadeEstabelecimentoEnum: filter.data.indAtividadeEstabelecimento,
      grupoId: filter.data.grupoId,
      anoMes,
    })
  }

  return (
    <>
      <PageContainer.Root>
        <PageContainer.Header title="Estabelecimento">
          <ButtonBox>
            <IconButton
              size="small"
              onClick={() =>
                setFilter((prev) => ({
                  ...prev,
                  isOpen: true,
                }))
              }
              title="Estabelecimento - Filtros"
              color="primary"
            >
              <FilterList />
            </IconButton>
            <Finder onSearch={handleQuery} onClose={() => handleQuery('')} />
            <PrintButton
              onClick={() =>
                openPDFViewer({
                  indAtividadeEstabelecimento: filter.data.indAtividadeEstabelecimento,
                  grupoId: filter.data.grupoId,
                })
              }
            />
            <ExcelButton onClick={() => handlePrintExcel()} isLoading={isLoadingRelatorio} />
            <Button size="small" color="primary" onClick={handleClickAddItem} variant="contained">
              Adicionar
            </Button>
          </ButtonBox>
        </PageContainer.Header>
        <Table
          data={data}
          isLoading={isLoading}
          query={query}
          onItemClick={handleClickItem}
          isFetching={isLoadingOnClick || isFetching}
        />
      </PageContainer.Root>

      <Container
        anoMes={anoMes}
        isOpen={container.isOpen}
        data={container.data}
        onClose={handleCloseContainer}
        onAfterSubmitForm={handleAfterSubmitForm}
      />

      {isOpenConfirmDeleteAlert && (
        <ConfirmDeleteAlert
          isLoading={isLoadingConfirmDelete}
          isOpen={isOpenConfirmDeleteAlert}
          onCancel={closeConfirmDeleteAlert}
          onConfirm={handleDeleteItem}
          nrInscricao={dataConfirmDeleteAlert.nrInscricao}
        />
      )}

      <DrawerFilter
        isOpen={filter.isOpen}
        data={filter.data}
        onSubmit={(data) =>
          setFilter({
            isOpen: false,
            data,
          })
        }
        onClose={() =>
          setFilter((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
      />

      <PDFViewer
        isOpen={isOpenPDFViewer}
        reportKey="Estabelecimento"
        onClose={closePDFViewer}
        title="Relação de Estabelecimentos"
        axiosConfig={{
          method: 'get',
          url: '/EstabelecimentoConsulta/ObterRelatorio',
          params: {
            indAtividadeEstabelecimentoEnum: dataPDFViewer.indAtividadeEstabelecimentoEnum,
            grupoId: dataPDFViewer.grupoId,
            isExcel: false,
            anoMes,
          },
        }}
      />
    </>
  )
}

export default Estabelecimento
