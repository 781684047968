import React, { useState, useEffect, useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { ActionDialog, TextField } from '~/components'

import FormReplicar from './components/FormReplicar'
import DialogPerguntaReplicar from './components/DialogPerguntaReplicar'
import DialogPerguntaData from './components/DialogPerguntaData'

import useDialogNotification from '~/hooks/useDialogNotification'
import useNotification from '~/hooks/useNotification'
import useValidationErrors from '~/hooks/useValidationErrors'
import useDialog from '~/hooks/useDialog'

import api from '~/services/api-pessoal'

import * as yup from 'yup'
import { AutoCompleteRubricaESocial, MUIAutoComplete } from '~/components/AutoComplete'
import {
  CodigoIncidenciaCPCategorias,
  CodigoIncidenciaCPValues,
} from '~/@types/enums/CodigoIncidenciaCPEnum'
import {
  CodigoIncidenciaIRCategorias,
  CodigoIncidenciaIRValues,
} from '~/@types/enums/CodigoIncidenciaIREnum'
import { CodigoIncidenciaFGValues } from '~/@types/enums/CodigoIncidenciaFGEnum'
import { indEventoValues } from '~/values/indEventoValues'
import { CodigoIncidenciaPISPASEPValues } from '~/@types/enums/CodigoIncidenciaPISPASEPEnum'

const schema = yup.object().shape({
  codigoIncidenciaCP: yup.string().required('Informe a Incidência CP'),
  codigoIncidenciaIR: yup.string().required('Informe a Incidência IRRF'),
  codigoIncidenciaFG: yup.string().required('Informe a Incidência FGTS'),
  codigoIncidenciaPISPASEP: yup.string().required('Informe a Incidência PISPASEP'),
})

const FormEventoEmpregador = (props) => {
  const { isOpen, onClose, data: _data, onAfterSubmitForm } = props
  const [data, setData] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)
  const {
    open: onOpenFormReplicar,
    close: onCloseFormReplicar,
    isOpen: isOpenFormReplicar,
    data: dataFormReplicar,
  } = useDialog({ data: {}, dtInicioValidade: null })
  const {
    open: onOpenDialogPerguntaReplicar,
    close: onCloseDialogPerguntaReplicar,
    isOpen: isOpenDialogPerguntaReplicar,
    data: dataDialogPerguntaReplicar,
  } = useDialog()
  const {
    open: onOpenDialogPerguntaData,
    close: onCloseDialogPerguntaData,
    isOpen: isOpenDialogPerguntaData,
    data: dataDialogPerguntaData,
  } = useDialog()

  const dialogNotification = useDialogNotification()
  const notification = useNotification()

  useEffect(() => {
    setData(_data)
    onCloseFormReplicar()
    onCloseDialogPerguntaReplicar()
    onCloseDialogPerguntaData()
    // eslint-disable-next-line
  }, [_data])

  const update = async () => {
    setSubmitting(true)
    try {
      const response = await api.put(`/EventoEmpregador/${data.id}`, data)
      onOpenDialogPerguntaReplicar(response.data.data)
      notification.put()
    } catch (err) {
      dialogNotification.extractErrors(err)
    }
    setSubmitting(false)
  }

  const handleSubmit = useCallback(() => {
    update()
    //eslint-disable-next-line
  }, [data])

  const { validationErrors, handleValidate } = useValidationErrors({
    schema,
    handleSubmit,
    data,
  })

  function closeDialogPerguntaReplicar() {
    onCloseDialogPerguntaReplicar()
    onAfterSubmitForm('update', dataDialogPerguntaReplicar)
  }

  function closeDialogPerguntaData() {
    onCloseDialogPerguntaData()
    onAfterSubmitForm('update', dataDialogPerguntaData)
  }

  function closeDialogFormReplicar() {
    onCloseFormReplicar()
    onAfterSubmitForm('update', dataFormReplicar?.data || {})
  }

  return (
    <ActionDialog
      title="Cadastro de Evento Empregador"
      isOpen={isOpen}
      onClose={onClose}
      okLabel="Salvar"
      isOkProcessing={isSubmitting}
      onOkClick={handleValidate}
      onCancelClick={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
    >
      <Grid container spacing={2}>
        <Grid item md={4} sm={12} xs={12}>
          <TextField
            label="Código"
            fullWidth
            disabled
            value={data?.evento?.codigo || ''}
            variant="outlined"
            size="small"
          />
        </Grid>

        <Grid item md={8} sm={12} xs={12}>
          <TextField
            label="Nome"
            fullWidth
            value={data?.nome || ''}
            variant="outlined"
            size="small"
            onChange={(e) => {
              const nome = e.target.value
              setData((oldState) => ({
                ...oldState,
                nome,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoCompleteRubricaESocial
            name="rubricaId"
            value={data?.rubricaId || ''}
            optionId="id"
            onChange={(_, rubrica) => {
              const rubricaId = rubrica?.id || ''
              setData((oldState) => ({
                ...oldState,
                rubricaId,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            name="indEvento"
            label="Indicador Evento"
            options={indEventoValues}
            optionId="value"
            renderOption={(option) => option.name}
            value={data.indEvento}
            onChange={(_, obj) => {
              const indEvento = obj?.value || ''
              setData((oldState) => ({
                ...oldState,
                indEvento,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Incidência CP"
            groupBy={(option) => CodigoIncidenciaCPCategorias[option.categoria]}
            options={CodigoIncidenciaCPValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            name="codigoIncidenciaCP"
            validationErrors={validationErrors}
            value={data.codigoIncidenciaCP}
            onChange={(e, obj) => {
              const codigoIncidenciaCP = obj ? obj.value : ''
              setData((oldState) => ({
                ...oldState,
                codigoIncidenciaCP,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Incidência IRRF"
            options={CodigoIncidenciaIRValues}
            optionId="value"
            renderOption={(option) => option.name}
            groupBy={(option) => CodigoIncidenciaIRCategorias[option.categoria]}
            required
            name="codigoIncidenciaIR"
            validationErrors={validationErrors}
            value={data.codigoIncidenciaIR}
            onChange={(e, obj) => {
              const codigoIncidenciaIR = obj ? obj.value : ''
              setData((oldState) => ({
                ...oldState,
                codigoIncidenciaIR,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Incidência FGTS"
            options={CodigoIncidenciaFGValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            name="codigoIncidenciaFG"
            validationErrors={validationErrors}
            value={data.codigoIncidenciaFG}
            onChange={(e, obj) => {
              const codigoIncidenciaFG = obj ? obj.value : ''
              setData((oldState) => ({
                ...oldState,
                codigoIncidenciaFG,
              }))
            }}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MUIAutoComplete
            label="Incidência PISPASEP"
            options={CodigoIncidenciaPISPASEPValues}
            optionId="value"
            renderOption={(option) => option.name}
            required
            name="codigoIncidenciaPISPASEP"
            validationErrors={validationErrors}
            value={data.codigoIncidenciaPISPASEP}
            onChange={(e, obj) => {
              const codigoIncidenciaPISPASEP = obj ? obj.value : ''
              setData((oldState) => ({
                ...oldState,
                codigoIncidenciaPISPASEP,
              }))
            }}
          />
        </Grid>
      </Grid>

      {isOpenDialogPerguntaReplicar && (
        <DialogPerguntaReplicar
          isOpen={isOpenDialogPerguntaReplicar}
          onClose={closeDialogPerguntaReplicar}
          onSubmit={() => onOpenDialogPerguntaData(dataDialogPerguntaReplicar)}
        />
      )}

      {isOpenDialogPerguntaData && (
        <DialogPerguntaData
          isOpen={isOpenDialogPerguntaData}
          onClose={closeDialogPerguntaData}
          onSubmit={(dtInicioValidade) =>
            onOpenFormReplicar({ data: dataDialogPerguntaData, dtInicioValidade })
          }
        />
      )}

      {isOpenFormReplicar && (
        <FormReplicar
          isOpen={isOpenFormReplicar}
          eventoEmpregador={dataFormReplicar?.data || {}}
          dtInicioValidade={dataFormReplicar?.dtInicioValidade || null}
          onClose={closeDialogFormReplicar}
          onAfterSubmitForm={onAfterSubmitForm}
        />
      )}
    </ActionDialog>
  )
}

export default FormEventoEmpregador
