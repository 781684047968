import { useEffect, useState } from 'react'

import LaunchIcon from '@material-ui/icons/Launch'
import { Email, Print } from '@material-ui/icons'
import { Box, Grid, LinearProgress, Paper, Typography } from '@material-ui/core'

import {
  Button,
  ContainerTable,
  MenuSelect,
  PageHeader,
  PDFViewer,
  Stack,
  StackContainer,
} from '~/components'
import { AutoCompleteGrupo } from '~/components/AutoComplete'
import BadgeInfo from '../ReciboPagamento/components/Menu/components/TreeViewVinculos/components/CardMenu/components/BadgeInfo'

import Content, { TITLE_PAGE } from './Content'

import api from '~/services/api-pessoal'

import useAmbiente from '~/hooks/useAmbiente'
import useDialog from '~/hooks/useDialog'

import { useObterEmpregadores } from '~/hooks/queries/FGTSDigital/useObterEmpregadores'
import { useEnviarVariosPorEmailEmpregadores } from '~/hooks/queries/FGTSDigital/useEnviarVariosPorEmailEmpregadores'

import { FGTSDigitalEmpregadorDTO } from '~/hooks/queries/FGTSDigital/interfaces/FGTSDigitalEmpregadorDTO'
import { VersaoResponse } from '~/hooks/queries/Versao/interfaces/VersaoResponse'

function openApp(fallbackUrl: string) {
  const appUrl = 'datacweb://'

  let opened = false
  const timeout = setTimeout(() => {
    if (!opened) {
      window.location.href = fallbackUrl
    }
  }, 200)

  const onVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      opened = true
      clearTimeout(timeout)
    }
  }

  const onBlur = () => {
    opened = true
    clearTimeout(timeout)
  }

  window.addEventListener('visibilitychange', onVisibilityChange)
  window.addEventListener('blur', onBlur)

  window.location.href = appUrl

  setTimeout(() => {
    window.removeEventListener('visibilitychange', onVisibilityChange)
    window.removeEventListener('blur', onBlur)
  }, 5000)
}

export default function FGTSDigitalEmpregador() {
  const [grupoId, setGrupoId] = useState(null)
  const [menuSelected, setMenuSelected] = useState(-1)
  const [menuCheckeds, setMenuCheckeds] = useState<number[]>([])
  const [urlDownloadAplicativo, setUrlDownloadAplicativo] = useState('')

  const { anoMes } = useAmbiente()

  const {
    close: closePDFViewer,
    data: dataPDFViewer,
    isOpen: isOpenPDFViewer,
    open: openPDFViewer,
  } = useDialog<{ data: string[]; params: { competencia: string } }>({
    data: [],
    params: {
      competencia: '',
    },
  })

  useEffect(() => {
    async function getVersionAPI() {
      const response = await api.get<VersaoResponse>('/versao')
      setUrlDownloadAplicativo(response?.data?.clientURLDownloadVersion || '')
    }
    getVersionAPI()
  }, [])

  const {
    data: _d,
    isLoading: isLoadingMenu,
    isFetching,
  } = useObterEmpregadores({
    params: {
      competencia: anoMes,
      grupoId,
    },
  })

  const dataMenu = _d || []

  const empregadorId = dataMenu[menuSelected]?.id || null

  const { mutateAsync: mutateAsyncSendEmail, isLoading: isLoadingSendEmail } =
    useEnviarVariosPorEmailEmpregadores()

  useEffect(() => {
    setMenuCheckeds([])
    setMenuSelected(-1)
  }, [_d])

  function handleClickPrint() {
    const empregadoresIds = menuCheckeds.map((index) => dataMenu[index].id)
    openPDFViewer({
      data: empregadoresIds,
      params: {
        competencia: anoMes,
      },
    })
  }

  function handleClosePDFViewer() {
    closePDFViewer()
    setMenuCheckeds([])
  }

  function handleClickSendEmail() {
    const empregadoresIds = menuCheckeds.map((index) => dataMenu[index].id)
    mutateAsyncSendEmail({
      data: empregadoresIds,
      params: {
        competencia: anoMes,
      },
    })
  }

  return (
    <StackContainer>
      <Grid container spacing={1} style={{ height: '100%', margin: 0 }}>
        <Grid item xs={12} sm={5} md={4} lg={3} style={{ height: '100%' }}>
          <Stack component={Paper} gap={0} p={1} height="100%">
            <Box mb={1}>
              <AutoCompleteGrupo
                value={grupoId}
                onChange={(e, grupo) => {
                  const grupoId = grupo?.id || null
                  setGrupoId(grupoId)
                }}
              />
            </Box>
            <Box height={4}>{isFetching && <LinearProgress />}</Box>
            <ContainerTable>
              <MenuSelect
                options={dataMenu}
                onSelect={setMenuSelected}
                selectedOption={menuSelected}
                onCheckOption={setMenuCheckeds}
                optionsCheckeds={menuCheckeds}
                renderOption={(d: FGTSDigitalEmpregadorDTO) => d.nome}
                renderRight={(d: FGTSDigitalEmpregadorDTO) => {
                  const qtd = d?.quantidadeGuias || 0
                  if (qtd <= 0) return <></>
                  return <BadgeInfo value={qtd} success />
                }}
                isLoading={isLoadingMenu}
                searchField="nome"
                idField="id"
              />
            </ContainerTable>

            <Stack mt={1} orientation="horizontal" alignItems="center">
              <Button
                fullWidth
                startIcon={<Print />}
                variant="contained"
                disabled={menuCheckeds.length > 0 ? false : true}
                onClick={handleClickPrint}
              >
                Imprimir
              </Button>
              <Button
                fullWidth
                startIcon={<Email />}
                variant="contained"
                disabled={menuCheckeds.length > 0 ? false : true}
                onClick={handleClickSendEmail}
                isLoading={isLoadingSendEmail}
              >
                Enviar Email
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9} style={{ height: '100%' }}>
          <Stack height="100%">
            <PageHeader
              title={TITLE_PAGE}
              renderRight={
                <Button
                  startIcon={<LaunchIcon />}
                  onClick={() => openApp(urlDownloadAplicativo)}
                  variant="contained"
                >
                  Abrir Aplicativo
                </Button>
              }
            />
            {empregadorId ? (
              <Content empregadorId={empregadorId} />
            ) : (
              <Typography>Selecione o Empregador</Typography>
            )}
          </Stack>
        </Grid>
      </Grid>

      {isOpenPDFViewer && (
        <PDFViewer
          title={TITLE_PAGE}
          isOpen={isOpenPDFViewer}
          onClose={handleClosePDFViewer}
          axiosConfig={{
            url: '/FGTSDigital/ImprimirVarios',
            method: 'post',
            data: dataPDFViewer.data,
            params: dataPDFViewer.params,
          }}
        />
      )}
    </StackContainer>
  )
}
