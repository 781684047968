import api from '~/services/api-pessoal'

import { useQuery } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'

import { EscalaRevezamentoRequestDTO } from './interfaces/EscalaRevezamentoRequestDTO'
import { EscalaRevezamentoResponseDTO } from './interfaces/EscalaRevezamentoResponseDTO'

const query = '/EscalaRevezamento/ObterEscalaRevezamento'

interface RequestParams {
  data: EscalaRevezamentoRequestDTO
}

export default function useObterEscalaRevezamento({ data }: RequestParams) {
  const dialogNotification = useDialogNotification()

  async function handleRequest() {
    const response = await api.post<{ data: EscalaRevezamentoResponseDTO }>(query, data)
    return response.data.data
  }

  return useQuery([query, data], handleRequest, {
    onError: dialogNotification.extractErrors,
    enabled: false,
  })
}
