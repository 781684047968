import { Box, Grid } from '@material-ui/core'

import AdmissoesChart from './AdmissoesChart'
import RescisoesChart from './RescisoesChart'

import TransmissaoChartConsulta from './TransmissaoChartConsulta'
import TransmissaoChartEnvio from './TransmissaoChartEnvio'
import RecibosChart from './RecibosChart'
import CompetenciaChart from './CompetenciaChart'
import VencimentosChart from './VencimentosChart'
import MovimentoAuditoria from './MovimentoAuditoria'
import PageContainer from '~/components/PageContainer'
import theme from '~/styles/theme'

export const MAX_WIDTH_CHART = 375
export const MAX_HEIGHT_CHART = 375

export default function Painel() {
  return (
    <Box position="relative">
      <Box
        position="absolute"
        left={0}
        right={0}
        p={2}
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(1)}
      >
        <PageContainer.Header title="Painel" />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MovimentoAuditoria />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <TransmissaoChartEnvio />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <TransmissaoChartConsulta />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <VencimentosChart />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <AdmissoesChart />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <RescisoesChart />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <RecibosChart />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <CompetenciaChart />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
