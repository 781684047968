import { ButtonBox } from 'mio-library-ui'

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import { ToolsDataTable } from '~/components'

const Table = (props) => {
  const { data, onItemClick, isLoading, pagination, query, isFetching } = props

  const columns = [
    {
      name: 'codigoString',
      label: 'Código',
      type: 'number',
      width: 80,
    },
    {
      name: 'nome',
      label: 'Nome',
    },
    {
      name: 'nrInscricaoMascara',
      label: 'Número de Inscrição',
      type: 'cpf-cnpj-copy-label',
      width: 100,
    },
    {
      name: 'grupo',
      label: 'Grupo',
    },
    {
      name: 'id',
      label: 'Ações',
      position: 'sticky',
      right: 0,
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Editar este registro"
                color="primary"
                aria-label="Editar"
                onClick={() => {
                  onItemClick('edit', value)
                }}
              >
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                size="small"
                title="Deletar este registro"
                color="primary"
                aria-label="Deletar"
                onClick={() => {
                  onItemClick('delete', value)
                }}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <ToolsDataTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isFetching={isFetching}
      pagination={pagination}
      cellHeaderWhiteSpaceNowrap
      cellWhiteSpaceNowrap
      onRowDoubleClick={(rowData) => onItemClick('edit', rowData?.id)}
      sherlock={{
        query,
        columns: ['codigo', 'nome', 'nrInscricaoMascara'],
      }}
    />
  )
}

Table.defaultProps = {
  pagination: true,
}

export default Table
