import { useState } from 'react'
import PageContainer from '~/components/PageContainer'

import Filter, { FilterStateProps } from './components/Filter'
import Table from './components/Table'

export default function SaldoFgts() {
  const [filters, setFilters] = useState<FilterStateProps | null>(null)

  return (
    <PageContainer.Root>
      <PageContainer.Header title="Saldo FGTS" />
      <PageContainer.ActionsContainer marginBottom={0.5}>
        <Filter onChange={setFilters} />
      </PageContainer.ActionsContainer>
      <Table filters={filters} />
    </PageContainer.Root>
  )
}
