import { useEffect, useMemo } from 'react'
import { ToolsDataTable } from '~/components'

import useQueryObterFolhaAnalitica from '~/hooks/queries/FolhaAnalitica/useObterFolhaAnalitica'
import useAmbiente from '~/hooks/useAmbiente'

import { useTransferDataContext } from '~/contexts/TransferDataContext'
import { ToolsDataTableColumnDef } from '~/interfaces'
import formatarDataFolhaAnalitica from './utils/formatarDataFolhaAnalitica'

interface TableProps {
  filters: number[] | null
}

export default function Table({ filters }: TableProps) {
  const { estabelecimento, anoMes } = useAmbiente()
  const { setDataTrasfer } = useTransferDataContext()

  const { data, isLoading, isFetching } = useQueryObterFolhaAnalitica({
    data: filters || [],
    params: { estabelecimentoId: estabelecimento?.id, anoMes },
    enabled: !!filters,
  })

  const dataFormatada = useMemo(() => {
    return formatarDataFolhaAnalitica(data?.data || [])
  }, [data?.data])

  const columns = useMemo((): ToolsDataTableColumnDef[] => {
    return [
      {
        name: 'nome',
        label: 'Nome',
        minWidth: 330,
        position: 'sticky',
        left: 0,
      },
      {
        name: 'nrInscricao',
        label: 'Identificação',
        type: 'cpf-cnpj',
        minWidth: 100,
        position: 'sticky',
        left: 330,
      },
      {
        name: 'vrTotalVencimento',
        label: 'Valor Total Vencimento',
        type: 'currency',
      },
      {
        name: 'vrTotalDesconto',
        label: 'Valor Total Desconto',
        type: 'currency',
      },
      {
        name: 'vrTotalLiquido',
        label: 'Valor Total Líquido',
        type: 'currency',
      },
      ...dataFormatada.colunasEventos.map((evento) => ({
        name: evento,
        label: evento,
        type: 'currency' as any,
      })),
    ]
  }, [dataFormatada])

  useEffect(() => {
    setDataTrasfer({ disabledExcelButton: data?.data && data.data.length > 0 ? false : true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data])

  return (
    <ToolsDataTable
      columns={columns}
      data={dataFormatada.dadosNormalizados || []}
      isLoading={isLoading}
      isFetching={isFetching}
      pagination={false}
      cellHeaderWhiteSpaceNowrap
      cellWhiteSpaceNowrap
    />
  )
}
