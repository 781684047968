import { useState } from 'react'
import { ExitToApp } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import HStack from '~/components/HStack'

import AuditoriaTable from '~/pages/MovimentoAuditoria/components/Table'
import AuditoriaFilter, { FilterStateProps } from '~/pages/MovimentoAuditoria/components/Filter'

import { useInvalidateQuery } from '~/hooks/useInvalidateQuery'

import AlternateButton from '../components/AlternateButton'
import ContainerChart from '../components/ContainerChart'
import ChartHeader from '../components/ChartHeader'

export default function MovimentoAuditoria() {
  const [isHover, setHover] = useState(false)
  const [filters, setFilters] = useState<FilterStateProps | null>(null)

  const history = useHistory()

  const { handleInvalidate } = useInvalidateQuery('/Auditoria/ObterMovimentoEmpregadores')

  return (
    <ContainerChart onHover={setHover}>
      <ChartHeader
        title="Auditoria eSocial"
        icon="content_paste_search"
        isActiveAlternate
        alternateTitle="Recarregar informações da auditoria"
        onClickRedirect={() => history.push('/movimento-auditoria')}
        onClickAlternate={handleInvalidate}
        isHover={isHover}
        isLoading={false}
        extraOptions={
          <HStack width="100%" maxWidth={340} gap={8} justifyContent="flex-end">
            <AuditoriaFilter onChange={setFilters} origem="painel" />
            <AlternateButton
              isActive
              title="Ir para a página de Transmissão eSocial"
              show
              icon={<ExitToApp />}
              onClick={() => history.push('/transmissao-esocial/0')}
            />
          </HStack>
        }
      />
      <AuditoriaTable filters={filters} tableBodyHeight="300px" disableAutoHeight />
    </ContainerChart>
  )
}
