import { useMutation } from 'react-query'

import api from '~/services/api-pessoal'

import useDialogNotification from '~/hooks/useDialogNotification'
import { notifyRemove } from '~/utils/notification'

import { useQueryClient } from './useObterPorVinculo'

interface RequestProps {
  params: { controleDebitoId: string }
}

export function useRemover() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useQueryClient()

  async function handleRequest({ params }: RequestProps) {
    await api.delete('/ControleDebito/Remover', { params })
  }

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyRemove()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
