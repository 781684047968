import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifyPut } from '~/utils/notification'
import { query } from './useObterIndiceFgts'
import { useInvalidateQuery } from '~/hooks/useInvalidateQuery'
import { HttpRequestInterface } from '~/interfaces'
import { IndiceFgtsAlterarDTO } from './dtos'

async function handleRequest({ data }: HttpRequestInterface<IndiceFgtsAlterarDTO>) {
  await api.put(`/IndiceFGTS/${data?.id}`, data)
}

export default function useAlterarIndiceFgts() {
  const dialogNotification = useDialogNotification()
  const { handleInvalidate } = useInvalidateQuery(query)

  return useMutation(handleRequest, {
    onSuccess: () => {
      notifyPut()
      handleInvalidate()
    },
    onError: dialogNotification.extractErrors,
  })
}
