import { ReactNode } from 'react'
import { Box, Divider, LinearProgress, Typography, useTheme } from '@material-ui/core'
import HStack from '~/components/HStack'

import AlternateButton from '../AlternateButton'
import IconPaper from '../IconPaper'

interface ChartHeaderProps {
  title: string
  subtitle?: string
  icon: string
  onClickRedirect?: () => void
  onClickAlternate: () => void
  isActiveAlternate: boolean
  alternateTitle?: string
  isHover: boolean
  isLoading: boolean
  divider?: boolean
  extraOptions?: ReactNode
}

export default function ChartHeader({
  title,
  subtitle,
  icon,
  onClickRedirect,
  onClickAlternate,
  isActiveAlternate,
  alternateTitle,
  isHover,
  isLoading,
  divider = true,
  extraOptions,
}: ChartHeaderProps) {
  const theme = useTheme()

  return (
    <Box position="relative">
      <HStack
        position="absolute"
        width="calc(100% - 54px)"
        justifyContent="flex-end"
        right={0}
        top={0}
        bottom={8}
        alignItems="center"
        gap={8}
      >
        {extraOptions}
        <AlternateButton
          title={alternateTitle}
          isActive={isActiveAlternate}
          show={isHover}
          onClick={onClickAlternate}
        />
      </HStack>

      <Box display="flex" alignItems="center" mb={1} gridGap={theme.spacing(2)}>
        <IconPaper icon={icon} onClick={onClickRedirect} />
        <Box>
          <Box component={Typography} fontWeight="bolder" fontSize="12pt" color="#666">
            {title}
          </Box>
          <Typography
            variant="h5"
            style={{
              fontWeight: 'bold',
            }}
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
      {divider && <Divider />}
      <Box height={4}>{isLoading && <LinearProgress />}</Box>
    </Box>
  )
}
