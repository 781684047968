import { Box, IconButton, Paper, TablePagination } from '@material-ui/core'
import { Assessment } from '@material-ui/icons'

import { ButtonBox, ContainerTable, Stack, ToolsDataTable } from '~/components'

import { formatSimpleDateTime } from '~/utils/utils'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { ConsultaPessoalResponse } from '~/hooks/queries-log/@types/ConsultaPessoalResponse'

export interface PaginationType {
  page: number
  pageSize: number
}

interface TableProps {
  isLoading: boolean
  isFetching: boolean
  data: ConsultaPessoalResponse[]
  totalRecords: number
  pagination: PaginationType
  changePagination: (pagination: PaginationType) => void
  openDetalhamento: (logId: string) => void
}

export default function Table({
  openDetalhamento,
  isLoading,
  isFetching,
  data,
  totalRecords,
  pagination,
  changePagination,
}: TableProps) {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dataHora',
      label: 'Data/Hora',
      options: {
        sort: false,
        setCellProps: () => {
          return {
            style: {
              width: '130px',
              whiteSpace: 'nowrap',
            },
          }
        },
        customBodyRender: formatSimpleDateTime,
      },
    },
    {
      name: 'usuarioEmail',
      label: 'E-mail',
      options: {
        sort: false,
        setCellProps: () => {
          return {
            style: {
              width: '220px',
              whiteSpace: 'nowrap',
            },
          }
        },
      },
    },
    {
      name: 'usuarioNome',
      label: 'Usuário',
      options: {
        sort: false,
        setCellProps: () => {
          return {
            style: {
              width: '220px',
              whiteSpace: 'nowrap',
            },
          }
        },
      },
    },
    {
      name: 'operacao',
      label: 'Operação',
      options: {
        sort: false,
        setCellProps: () => {
          return {
            style: {
              width: '100px',
              whiteSpace: 'nowrap',
            },
          }
        },
      },
    },
    {
      name: 'entidade',
      label: 'Entidade',
      options: {
        sort: false,
        setCellProps: () => {
          return {
            style: {
              width: '100px',
              whiteSpace: 'nowrap',
            },
          }
        },
      },
    },
    {
      name: 'descricao',
      label: 'Descrição',
      options: {
        sort: false,
        customBodyRender: (value) => (
          <Box
            title={value}
            whiteSpace="nowrap"
            maxWidth="500px"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {value}
          </Box>
        ),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          return (
            <ButtonBox spacing={0} justifyContent="center">
              <IconButton
                size="small"
                title="Abrir detalhamento da consulta"
                color="primary"
                aria-label="Transportar"
                onClick={() => openDetalhamento(value)}
              >
                <Assessment fontSize="small" color="primary" />
              </IconButton>
            </ButtonBox>
          )
        },
      },
    },
  ]

  return (
    <Stack height="100%" gap={0.5}>
      <ContainerTable>
        <ToolsDataTable
          columns={columns}
          isLoading={isLoading}
          isFetching={isFetching}
          data={data}
          disableAutoHeight
          pagination={false}
        />
      </ContainerTable>
      <Box component={Paper}>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          labelRowsPerPage="Linhas por página:"
          component="div"
          count={totalRecords}
          rowsPerPage={pagination.pageSize}
          page={pagination.page}
          onChangePage={(_, page) =>
            changePagination({
              ...pagination,
              page,
            })
          }
          onChangeRowsPerPage={(e) =>
            changePagination({
              ...pagination,
              pageSize: +e.target.value,
              page: 0,
            })
          }
        />
      </Box>
    </Stack>
  )
}
