import PageContainerActionsContainer from './PageContainerActionsContainer'
import PageContainerAddButtonForm from './PageContainerAddButtonForm'
import PageContainerHeader from './PageContainerHeader'
import PageContainerRoot from './PageContainerRoot'

const PageContainer = {
  ActionsContainer: PageContainerActionsContainer,
  AddButtonForm: PageContainerAddButtonForm,
  Header: PageContainerHeader,
  Root: PageContainerRoot,
}

export default PageContainer
