import { useQuery } from 'react-query'
import useDialogNotification from '~/hooks/useDialogNotification'
import { HttpRequestInterface, HttpResponseInterface } from '~/interfaces'
import api from '~/services/api-pessoal'

import { FolhaAnaliticaRequestParams, FolhaAnaliticaDTO } from './dtos'

export const query = '/Relatorio/FolhaAnalitica/FolhaAnalitica'

async function handleRequest({
  data,
  params,
}: HttpRequestInterface<number[], FolhaAnaliticaRequestParams>) {
  const response = await api.post(query, data, {
    params,
  })
  return response.data
}

export default function useQueryObterFolhaAnalitica(
  props: HttpRequestInterface<number[], FolhaAnaliticaRequestParams>,
) {
  const dialogNotification = useDialogNotification()
  return useQuery<HttpResponseInterface<FolhaAnaliticaDTO>>(
    [query, props],
    () => {
      return handleRequest(props)
    },
    {
      onError: dialogNotification.extractErrors,
      enabled: props?.enabled,
    },
  )
}
