import api from '~/services/api-pessoal'

import { useMutation } from 'react-query'

import useDialogNotification from '~/hooks/useDialogNotification'

import { notifySuccess } from '~/utils/notification'

import { SaldoProvisaoDTO } from './interfaces/SaldoProvisaoDTO'

interface RequestProps {
  data: SaldoProvisaoDTO
}

export function useSaldoInicial() {
  const dialogNotification = useDialogNotification()

  async function handleRequest({ data }: RequestProps) {
    await api.post('/ProvisaoCompetencia/SaldoInicial', data)
  }

  return useMutation(handleRequest, {
    onSuccess: () => notifySuccess('Provisão Saldo Inicial inserido com sucesso'),
    onError: dialogNotification.extractErrors,
  })
}
